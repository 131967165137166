import React, { useEffect, useState } from "react";
import "../../styles/lostfound.css";
import { Button, Col, Container, Form, Spinner } from "react-bootstrap";
import CommonSection from "./CommonSection";
import { notifyError, notifySuccess } from "../../SharedComponent/notify";
import configWeb from "../../config.js/configWeb";
import { simpleGetCall, simplePostCall } from "../../config.js/SetUp";
import { useSelector } from "react-redux";
import { Row } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import FindCarForm2 from "./FindCarForm2";
import MetaHelmet from "../Helmet/MetaHelmet";
const Lostandfound = () => {
  const recaptchaRef = React.createRef();
  const [enquiryState, setEnquiryState] = useState({
    first_name: "",
    last_name: "",
    phone_code: "",
    phone_number: "",
    email: "",
    emirate_id: 0,
    detail: "",
    reference_number: "",
   
    captcha: false,
  });
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language.language);
  const [validated, setValidated] = useState(false);
  const [countries, setCountries] = useState([]);
  const [emiratesArray, setEmiratesArray] = useState([]);
  const [captchaValue, setCaptchaValue] = useState([]);
  const [carListArray, setCarListArray] = useState([]);
  const [enquiry_loading, set_enquiry_loading] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEnquiryState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const getCountriesData = () => {
    const url = `${configWeb.GET_COUNTRY_LIST}?lang=${language}&page_size=260`;
    simpleGetCall(url)
      .then((res) => {
        setCountries(res?.data);
      })
      .catch((errr) => {
        console.log("errr", errr);
      })
      .finally(() => {});
  };

  const emiratesData = () => {
    const url = `${configWeb.GET_EMIRATES}?lang=${language}`;
    simpleGetCall(url)
      .then((res) => {
        setEmiratesArray(res);
      })
      .catch((errr) => {
        console.log("errr", errr);
      })
      .finally(() => {
        // set_loading(false);
      });
  };
  useEffect(() => {
    getCountriesData();
    emiratesData();
  
  }, [language]);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false /* || !enquiryState.captcha */) {
      e.stopPropagation();
      setValidated(true);
    } else {
      // Process form data (e.g., send API request)
      const captcha = await postRecaptcha();
      if (captcha) {
        const enquire = await postEnquireMain();

        if (!enquire) setValidated(true);
        else setValidated(false);
      }
    }
    // setValidated(true);
  };

 

  const token = "";
  const postEnquireMain = () => {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        first_name: enquiryState?.first_name,
        last_name: enquiryState?.last_name,
        phone_code: enquiryState?.phone_code,
        phone_number: enquiryState?.phone_number,
        email: enquiryState?.email,
        reference_number : enquiryState?.reference_number,
        emirate_id: enquiryState?.emirate_id * 1,
        detail: enquiryState?.detail,
      });

      const url = configWeb.POST_LOST_AND_FOUND;
      set_enquiry_loading(true);
      simplePostCall(url, body)
        .then((res) => {
          if (res?.status === "success") {
            // setUserDetails(res);
            notifySuccess("Enquire Successfully");

            resolve(true);
            setEnquiryState({
              first_name: "",
              last_name: "",
              phone_code: "",
              phone_number: "",
              email: "",
              emirate_id: 0,
              detail: "",
              reference_number: "",
             
              captcha: false,
            });
          } else if (res?.error) {
            notifyError(res?.message[0]);
            resolve(false);
          }
        })
        .catch((error) => {
          console.error("Banner failed:", error);
          notifyError("Something went wrong. Please try again letter.");
          resolve(false);
        })
        .finally(() => {
          set_enquiry_loading(false);
          setCaptchaValue([]);
          window.grecaptcha.reset();
        });
    });
  };
  const postRecaptcha = () => {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        g_recaptcha_response: captchaValue,
      });

      const url = configWeb.POST_CAPTCHA_VERIFY;
      set_enquiry_loading(true);
      simplePostCall(url, body)
        .then((res) => {
          // setUserDetails(res);
       
          if(res?.success === true){
            resolve(true);
            }else{
              resolve(false);
              notifyError("Wrong Captcha")
            }
          if (res?.error) {
            resolve(false);
           
          }
        })
        .catch((error) => {
          console.error("Banner failed:", error);
          resolve(false);
        })
        .finally(() => {});
    });
  };
  // const onChangeRecaptcha = (value) => {
  //   // token = value;
  //   console.log("onChangeRecaptcha", JSON.stringify(value));
  // };

  function onChange(value) {
  
    setCaptchaValue(value);
    // setCaptchaValue(recaptchaRef.current.getValue());
  }
  return (
    <div>
        <MetaHelmet
        title="Lost And Found Page"
        description=""
        keywords="car rental, affordable cars, rent a car"
        canonicalUrl = {`${configWeb.BASE_WEB_URL}/en/Lostandfound`}
        hreflangs={[
          { hreflang: "en", href: `${configWeb.BASE_WEB_URL}/en/Lostandfound` },
          { hreflang: "ar", href: `${configWeb.BASE_WEB_URL}/en/Lostandfound` },
        ]}

        />
       {/* <section className="pb-1 form-section" >
        <h1
          className="pt-2 m-0 text-center text-light booking__tital-dream"
          style={{ fontFamily: "Rubik" }}
        >
          {t("BOOK YOUR DREAM CAR NOW")}!
        </h1>
        <Col id="booking-form-container" lg="12" md="12" sm="12">
          <Container>
            <FindCarForm2 emiratesArray={emiratesArray} />
          </Container>
        </Col>
      </section> */}
      <CommonSection title="LOST & FOUND" />
      <section className="p-0">
        <Container>
          <div className="inner_section_main">
            <div className="blog_section padding-70">
              <div className="container">
                <div className="row">
                  <div
                    className="col-lg-8 col-md-12 col-sm-12"
                    style={{ float: "none", margin: "0 auto" }}
                  >
                    <p className="text-justify " style={{ fontSize: "18px" }}>
                      While renting from Autostrad Rent a Car, it is important
                      to know that you are responsible for your belongings.
                      However, we recognize that there would still be times that
                      you or your family may accidentally lose your belongings
                      in one of our rentals. In such cases, you can be rest
                      assured, that our team will do their best in assisting you
                      with their recovery!
                      <br />
                      <br />
                      Get started by filling this form:
                    </p>

                    <div className="row add-color rounded-3 p-4">
                      <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                      >
                        <Row className="">
                          <div className="col-lg-12 mb-4">
                            <div className="form-group ajax-msg w-100"></div>
                            <Form.Group controlId="emirate_id">
                              <Form.Label>
                                Emirate{" "}
                                <span className="text-danger">&#8727;</span>
                              </Form.Label>

                              <Form.Select
                                name="emirate_id"
                                value={enquiryState.emirate_id}
                                onChange={handleChange}
                                placeholder="Enter emirate ID"
                                required
                              >
                                <option value="">Select Emirates</option>
                                {emiratesArray?.map((emirate) => (
                                  <option key={emirate.id} value={emirate.id}>
                                    {emirate.name}
                                  </option>
                                ))}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Please Select an emirate ID.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>

                          <div className="col-lg-12 mb-4">
                            <Form.Group controlId="detail">
                              <Form.Label>
                                Brief Description of the Lost or Forgotten Item{" "}
                                <span className="text-danger">&#8727;</span>
                              </Form.Label>
                              <Form.Control
                                required
                                as="textarea"
                                name="detail"
                                value={enquiryState.detail}
                                onChange={handleChange}
                                placeholder="Enter details"
                                rows={3}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please provide some details.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>

                          <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                            <div className="form-group ajax-field">
                              <Form.Group controlId="first_name">
                                <Form.Label>
                                  First Name{" "}
                                  <span className="text-danger">&#8727;</span>
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  name="first_name"
                                  value={enquiryState.first_name}
                                  onChange={handleChange}
                                  placeholder="Enter your first name"
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please enter your first name.
                                </Form.Control.Feedback>
                              </Form.Group>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                            <div className="form-group ajax-field">
                              <Form.Group controlId="last_name">
                                <Form.Label>
                                  Last Name{" "}
                                  <span className="text-danger">&#8727;</span>
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  name="last_name"
                                  value={enquiryState.last_name}
                                  onChange={handleChange}
                                  placeholder="Enter your last name"
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please enter your last name.
                                </Form.Control.Feedback>
                              </Form.Group>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                            <div className="form-group ajax-field">
                              <Form.Group controlId="phone_code">
                                <Form.Label>
                                  Phone Code{" "}
                                  <span className="text-danger">&#8727;</span>
                                </Form.Label>

                                <Form.Select
                                  name="phone_code"
                                  value={enquiryState.phone_code}
                                  onChange={handleChange}
                                  placeholder="Enter phone code"
                                  required
                                >
                                  <option value="">Code</option>
                                  {countries?.map((country) => (
                                    <option
                                      key={country.id}
                                      value={country.phone_code}
                                    >
                                      {country.code} {country.phone_code}
                                    </option>
                                  ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                  Please enter your phone code.
                                </Form.Control.Feedback>
                              </Form.Group>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                            <div className="form-group ajax-field">
                              <Form.Group controlId="phone_number">
                                <Form.Label>
                                  Phone Number{" "}
                                  <span className="text-danger">&#8727;</span>
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  name="phone_number"
                                  value={enquiryState.phone_number}
                                  onChange={handleChange}
                                  placeholder="Enter phone number"
                                  maxLength={10}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please enter a valid 10-digit phone number.
                                </Form.Control.Feedback>
                              </Form.Group>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                            <div className="form-group ajax-field">
                              <Form.Group controlId="email">
                                <Form.Label>
                                  Email{" "}
                                  <span className="text-danger">&#8727;</span>
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="email"
                                  name="email"
                                  value={enquiryState.email}
                                  onChange={handleChange}
                                  placeholder="Enter your email"
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please enter a valid email.
                                </Form.Control.Feedback>
                              </Form.Group>
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
                            <div className="form-group ajax-field">
                              <Form.Group controlId="reference_number">
                                <Form.Label>
                                  Rental Agreement Number/Vehicle Plate Number{" "}
                                  <span className="text-danger">&#8727;</span>
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  name="reference_number"
                                  value={enquiryState.reference_number}
                                  onChange={handleChange}
                                  placeholder="Enter number"
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please enter number.
                                </Form.Control.Feedback>
                              </Form.Group>
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 m">
                           
                            <ReCAPTCHA
                              onChange={onChange}
                              ref={recaptchaRef}
                              sitekey="6Lfp_nwpAAAAAOR7LqsSxWWZtt3vrTfroNRFqxlp"
                            
                            />
                          </div>

                          <div className="col-lg-12 col-md-12 col-sm-12 m">
                            <Button
                              type="submit"
                              variant="#1E5486"
                              style={{
                                backgroundColor: "#4078ab",
                                color: "white",
                              }}
                              className="w-100 btn btn-secondry"
                              disabled={enquiry_loading ? true : false}
                            >
                              {enquiry_loading ? <Spinner /> : "Submit"}
                            </Button>
                          </div>
                        </Row>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Lostandfound;

import React, { useEffect, useState } from "react";
import "../../styles/account.css";
import Helmet from "../../components/Helmet/Helmet";
import { Link } from "react-router-dom";
import {
  Nav,
  NavItem,
  Tab,
  Accordion,
  Badge,
  Form,
  Button,
  Spinner,
  Modal,
  CardText,
  Card as BootstrapCard,
  CardBody,
  CardTitle,
  Carousel,
} from "react-bootstrap";
import threedots from "../../assets/all-images/options3dots.svg";

import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  IconButton,
  Typography,
} from "@mui/material";
import { compose, nanoid } from "@reduxjs/toolkit";
import threedotsvertical from "../../assets/all-images/three-dots-vertical-svgrepo-com.svg";
import carImage from "../../assets/all-images/cars-img/tesla.jpg";
import { red } from "@mui/material/colors";
import dropdownshow from "../../assets/all-images/dropdown-arrow-svgrepo-com.svg";
import { notifyError, notifySuccess } from "../../SharedComponent/notify";
import {
  multipartPostCall,
  simpleGetCall,
  simpleGetCallAuth,
  simplePostCallAuth,
  simplePutCallAuth,
} from "../../config.js/SetUp";
import configWeb from "../../config.js/configWeb";
import { useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import AdditionalDriver from "./AdditionaDriver";
import Booking from "./Booking";

const MyAccount = () => {
  const { type } = useParams();
  const location = useLocation();
  const { tab_type } = location.state || {};

  const language = useSelector((state) => state.language.language);
  const [displayData, setDispalyData] = useState(false);
  const [drlist, setDrlist] = useState([]);
  const [displayoptions, setdisplayOptions] = useState(false);
  const [countries, setCountries] = useState([]);
  const [emiratesArray, setEmiratesArray] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [userAddress, setUserAddress] = useState([]);
  const [validated, setValidated] = useState(false);
  const [documentSetArray, setDocumentSetArray] = useState([]);
  const [validated_for_password, setValidated_for_password] = useState(false);
  const [validated_for_address, set_validated_for_address] = useState(false);
  const [loading_document, set_loading_document] = useState(false);

  const [loading_profile, set_loading_profile] = useState(false);
  const [loading_password, set_loading_password] = useState(false);
  const [loading_address, set_loading_address] = useState(false);
  const [documentSet, setDocumentSet] = useState("uae_resident");
  const [confirmPasswordError, setConfirmPasswordError] = useState(
    "Please confirm your new password."
  );

  const token = localStorage.getItem("token");
  const parse_token = token ? JSON.parse(token) : null;
  const user_id = parse_token ? parse_token.user_id : null;
  const [disable_personal_field, set_disable_personal_field] = useState(true);

  const [passwordState, setPasswordState] = useState({
    currentpass: "",
    newpass: "",
    confirmpass: "",
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const [AddressState, setAddressState] = useState({
    housing: "",
    street: "",
    country: "",
    state: "",
    city: "",
    postalCode: "",
  });
  const handleChangeAddress = (e) => {
    const { name, value } = e.target;
    setAddressState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleDocumentSetChange = (event) => {
    setDocumentSet(event.target.value);
    setUserDocumentData({
      licenseno: "",
      issuedate: "",
      expiry: "",
      licenseFront: null,
      licenseBack: null,
      idno: "",
      idExpiry: "",
      gccIdFront: null,
      gccIdBack: null,
      passno: "",
      passIssueDate: "",
      passExpiry: "",
      passportFront: null,
      passportBack: null,
      entryStamp: null,
      intlicno: "",
      intIssueDate: "",
      intExpiry: "",
      permit: null,
      translation_dl_no: "",
      translation_dl: null,
      touristVisa: null,
    });
  };
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [formData, setFormData] = useState({
    // personalfirstname: userDetails?.first_name || "",
    // personallastname: userDetails?.last_name || "",
    // phonenumberCode: userDetails?.phone_code || "",
    // phonenumber: userDetails?.phone_number || "",
    // alterphoneCode: userDetails?.alt_phone_code || "",
    // alterphone: userDetails?.alt_phone_number || "",
    // emailid: userDetails?.email || "",
    // dob: userDetails?.dob || "",
    // gender: userDetails?.gender || "",
    // nationality: userDetails?.country_id || "",
    // emirates: userDetails?.emirate_id || "",
    personalfirstname: userDetails?.first_name || "",
    personallastname: userDetails?.last_name || "",
    phonenumberCode: userDetails?.phone_code || "",
    phonenumber: userDetails?.phone_number || "",
    alterphoneCode: userDetails?.alt_phone_code || "",
    alterphone: userDetails?.alt_phone_number || "",
    emailid: userDetails?.email || "",
    dob: userDetails?.dob || "",
    gender: userDetails?.gender || "",
    nationality: userDetails?.country_id || "",
    emirates: userDetails?.emirate_id || "",
  });
  const [getUserDocumentsArray, setGetUserDocumentsArray] = useState([]);
  const [userDocumentValidated, setUserDocumentValidated] = useState(false);
  const [userDocumentValidated_1, setUserDocumentValidated_1] = useState(false);
  const [userDocumentValidated_2, setUserDocumentValidated_2] = useState(false);
  const [userDocumentValidated_3, setUserDocumentValidated_3] = useState(false);
  const [userDocumentValidated_4, setUserDocumentValidated_4] = useState(false);
  const [userDocumentValidated_5, setUserDocumentValidated_5] = useState(false);
  const [userDocumentValidated_6, setUserDocumentValidated_6] = useState(false);
  const [userDocumentValidated_7, setUserDocumentValidated_7] = useState(false);
  const [userDocumentData, setUserDocumentData] = useState({
    licenseno: "",
    issuedate: "",
    expiry: "",
    licenseFront: null,
    licenseBack: null,
    idno: "",
    idExpiry: "",
    gccIdFront: null,
    gccIdBack: null,
    passno: "",
    passIssueDate: "",
    passExpiry: "",
    passportFront: null,
    passportBack: null,
    entryStamp: null,
    intlicno: "",
    intIssueDate: "",
    intExpiry: "",
    permit: null,
    translation_dl_no: "",
    translation_dl: null,
    touristVisa: null,
  });

  const modifyUrlWithPlaceholders = (url, userId, docSetType, docType) => {
    // Using dynamic replacement for placeholders
    return url
      ?.replace("[user_id]", userId) // Replacing [user_id]
      ?.replace("[doc_set_type]", docSetType) // Replacing [doc_set_type]
      ?.replace("[doc_type]", docType); // Replacing [doc_type] (static or dynamic value)
  };

  const appendDocumentToFormData = async (
    formData,
    documentPath,
    fieldName
  ) => {
    // Check if documentPath is a string (likely a URL or base64)
    if (typeof documentPath === "string") {
      try {
        // Fetch the file from the URL
        const response = await fetch(documentPath);
        const blob = await response.blob(); // Convert response to Blob

        // Append the Blob (binary data) to formData
        formData.append(fieldName, blob, `${fieldName}.jpg`); // You can specify a filename here
      } catch (error) {
        console.error("Error converting URL to binary:", error);
      }
    } else if (documentPath instanceof Blob || documentPath instanceof File) {
      // If it's already binary, just append it
      formData.append(fieldName, documentPath);
    }
  };

  const displayUserDocumentsData = () => {
    if (getUserDocumentsArray && Array.isArray(getUserDocumentsArray)) {
    
      if (documentSet === "uae_resident") {
        //for license
        const license = getUserDocumentsArray?.find(
          (item) => item.doc_type === "driving_license"
        );
        const frontImage = modifyUrlWithPlaceholders(
          license?.front_image,
          user_id,
          "uae_resident",
          "driving_license"
        );
        const backImage = modifyUrlWithPlaceholders(
          license?.back_image,
          user_id,
          "uae_resident",
          "driving_license"
        );

        //for emirate ID
        const emirateID = getUserDocumentsArray?.find(
          (item) => item.doc_type === "emirates_id"
        );
        const frontImage_emirateID = modifyUrlWithPlaceholders(
          emirateID?.front_image,
          user_id,
          "uae_resident",
          "emirates_id"
        );
        const backImage_emirateID = modifyUrlWithPlaceholders(
          emirateID?.back_image,
          user_id,
          "uae_resident",
          "emirates_id"
        );

        setUserDocumentData((prevState) => ({
          ...prevState,
          licenseno: license?.doc_number,
          issuedate: license?.issue_date,
          expiry: license?.expiry_date,
          licenseFront: frontImage,
          licenseBack: backImage,
          idno: emirateID?.doc_number,
          exp: emirateID?.expiry_date,
          gccIdFront: frontImage_emirateID,
        }));
      } else if (documentSet === "non_resident_gcc") {
        //for license
        const license_gcc = getUserDocumentsArray?.find(
          (item) => item.doc_type === "driving_license_gcc"
        );

        const frontImage = modifyUrlWithPlaceholders(
          license_gcc?.front_image,
          user_id,
          "non_resident_gcc",
          "driving_license_gcc"
        );
        const backImage = modifyUrlWithPlaceholders(
          license_gcc?.back_image,
          user_id,
          "non_resident_gcc",
          "driving_license_gcc"
        );
        //for gccId
        const gcc_id = getUserDocumentsArray?.find(
          (item) => item.doc_type === "gcc_id"
        );
        const gcc_id_front_img = modifyUrlWithPlaceholders(
          gcc_id?.front_image,
          user_id,
          "non_resident_gcc",
          "gcc_id"
        );

        //for passport
        const passport = getUserDocumentsArray?.find(
          (item) => item.doc_type === "passport"
        );
        const passport_front_img = modifyUrlWithPlaceholders(
          passport?.front_image,
          user_id,
          "non_resident_gcc",
          "passport"
        );

        //for international driving license/permit
        const int_permit = getUserDocumentsArray?.find(
          (item) => item.doc_type === "international_driving_license"
        );
        const permit_front_img = modifyUrlWithPlaceholders(
          int_permit?.front_image,
          user_id,
          "non_resident_gcc",
          "international_driving_license"
        );

        setUserDocumentData((prevState) => ({
          ...prevState,
          licenseno: license_gcc?.doc_number,
          issuedate: license_gcc?.issue_date,
          expiry: license_gcc?.expiry_date,
          licenseFront: frontImage,
          licenseBack: backImage,

          idno: gcc_id?.doc_number,
          exp: gcc_id?.expiry_date,
          gccIdFront: gcc_id_front_img,

          passno: passport?.doc_number,
          passIssueDate: passport?.issue_date,
          passExpiry: passport?.expiry_date,
          passportFront: passport_front_img,

          intlicno: int_permit?.doc_number,
          intIssueDate: int_permit?.issue_date,
          intExpiry: int_permit?.expiry_date,
          permit: permit_front_img,
        }));
      } else if (documentSet === "non_resident_other") {
        //for home country license
        const driving_license_home_country = getUserDocumentsArray?.find(
          (item) => item.doc_type === "driving_license_home_country"
        );

        const frontImage = modifyUrlWithPlaceholders(
          driving_license_home_country?.front_image,
          user_id,
          "non_resident_other",
          "driving_license_home_country"
        );
        const backImage = modifyUrlWithPlaceholders(
          driving_license_home_country?.back_image,
          user_id,
          "non_resident_other",
          "driving_license_home_country"
        );
        //for passport
        const passport = getUserDocumentsArray?.find(
          (item) => item.doc_type === "passport"
        );
        const passport_front_img = modifyUrlWithPlaceholders(
          passport?.front_image,
          user_id,
          "non_resident_other",
          "passport"
        );
        //for entry stamp
        const entery_stamp = getUserDocumentsArray?.find(
          (item) => item.doc_type === "entery_stamp"
        );
        const stamp_front_img = modifyUrlWithPlaceholders(
          entery_stamp?.front_image,
          user_id,
          "non_resident_other",
          "entery_stamp"
        );
        //for international driving license/permit
        const int_permit = getUserDocumentsArray?.find(
          (item) => item.doc_type === "international_driving_license"
        );
        const permit_front_img = modifyUrlWithPlaceholders(
          int_permit?.front_image,
          user_id,
          "non_resident_other",
          "international_driving_license"
        );
        //translation of DL
        const dl = getUserDocumentsArray?.find(
          (item) => item.doc_type === "translation_of_driving_license"
        );
        const dl_front_img = modifyUrlWithPlaceholders(
          int_permit?.front_image,
          user_id,
          "non_resident_other",
          "translation_of_driving_license"
        );
        //tourist visa
        const touristVisa = getUserDocumentsArray?.find(
          (item) => item.doc_type === "tourist_visa"
        );
        const touristVisa_front_img = modifyUrlWithPlaceholders(
          touristVisa?.front_image,
          user_id,
          "non_resident_other",
          "tourist_visa"
        );

        setUserDocumentData((prevState) => ({
          ...prevState,
          licenseno: driving_license_home_country?.doc_number,
          issuedate: driving_license_home_country?.issue_date,
          expiry: driving_license_home_country?.expiry_date,
          licenseFront: frontImage,
          licenseBack: backImage,

          passno: passport?.doc_number,
          passIssueDate: passport?.issue_date,
          passExpiry: passport?.expiry_date,
          passportFront: passport_front_img,

          entryStamp: stamp_front_img,

          intlicno: int_permit?.doc_number,
          intIssueDate: int_permit?.issue_date,
          intExpiry: int_permit?.expiry_date,
          permit: permit_front_img,

          translation_dl_no: dl?.doc_number,
          translation_dl: dl_front_img,

          touristVisa: touristVisa_front_img,
        }));
      }
    }
  };

  useEffect(() => {
    if (getUserDocumentsArray) {
      displayUserDocumentsData();
    }
  }, [getUserDocumentsArray]);

  const handleUserDocumentDataChange = (event) => {
    const { name, value, type, files } = event.target;
    if (type === "file") {
      setUserDocumentData((prevState) => ({
        ...prevState,
        [name]: files[0],
      }));
    } else {
      setUserDocumentData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmitUserDocuments = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      // setUserDocumentValidated(true); this is wrong placement.

      if (documentSet !== "non_resident_other") {
        await postUserDocuments_id();
      }
      if (documentSet !== "uae_resident") {
        await postUserDocuments_passport();
        await postUserDocuments_entry_stamp();
      }

      await postUserDocuments_international_driving_license();
      if (documentSet === "non_resident_other") {
        await postUserDocuments_translation_dl();
        await postUserDocuments_tourist_visa();
      }
      await postUserDocuments_driving_license();
    }
    setUserDocumentValidated(true);
  };
  const handleSubmitUserDocuments_1 = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      // setUserDocumentValidated(true); this is wrong placement.

      await postUserDocuments_driving_license();
    }
    setUserDocumentValidated_1(true);
  };
  const handleSubmitUserDocuments_2 = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      // setUserDocumentValidated(true); this is wrong placement.

      if (documentSet !== "non_resident_other") {
        await postUserDocuments_id();
      }
    }
    setUserDocumentValidated_2(true);
  };
  const handleSubmitUserDocuments_3 = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      // setUserDocumentValidated(true); this is wrong placement.

      if (documentSet !== "uae_resident") {
        await postUserDocuments_passport();
      }
    }
    setUserDocumentValidated_3(true);
  };
  const handleSubmitUserDocuments_4 = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      // setUserDocumentValidated(true); this is wrong placement.

      if (documentSet !== "uae_resident") {
        await postUserDocuments_entry_stamp();
      }
    }
    setUserDocumentValidated_4(true);
  };
  const handleSubmitUserDocuments_5 = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      // setUserDocumentValidated(true); this is wrong placement.

      await postUserDocuments_international_driving_license();
    }
    setUserDocumentValidated_5(true);
  };
  const handleSubmitUserDocuments_6 = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      // setUserDocumentValidated(true); this is wrong placement.
      if (documentSet === "non_resident_other") {
        await postUserDocuments_translation_dl();
      }
    }
    setUserDocumentValidated_6(true);
  };
  const handleSubmitUserDocuments_7 = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      // setUserDocumentValidated(true); this is wrong placement.
      if (documentSet === "non_resident_other") {
        await postUserDocuments_tourist_visa();
      }
    }
    setUserDocumentValidated_7(true);
  };

  const passwordReset = () => {
    // Your password reset logic here
   
  };
  const addressReset = () => {
    // Your password reset logic here
   
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  // const [expanded, setExpanded] = React.useState(false);
  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };

  const [formdata, setFormdata] = useState({
    firstname: "",
    dremail: "",
    drPhoneNumber: "",
  });
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormdata((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleSaveChanges = () => {
    const newDriver = {
      id: nanoid(),
      name: formdata.firstname,
      email: formdata.dremail,
      mobile: formdata.drPhoneNumber,
    };
    setDrlist([...drlist, newDriver]);
    alert("user is added successfully");
    setFormdata({ firstname: "", dremail: "", drPhoneNumber: "" });
  };
  const handleDelete = (xid) => {
    setDrlist(drlist.filter((x) => x.id !== xid));
  };
  const [showCardButtons, setShowCardButtons] = useState(null);
  const [ShowlistID, setShowListID] = useState(null);
  const handleShowListByID = (xid) => {
    setdisplayOptions(!displayoptions);
    setShowListID((prevID) => (prevID === xid ? null : xid));
  };

  const getUserDetails = () => {
    const url = configWeb.GET_USER_DETAILS(user_id);

    simpleGetCallAuth(url)
      .then((res) => {
        if (!res?.error) {
          setUserDetails(res);
        }
      })
      .catch((error) => {
        console.error("Banner failed:", error);
      })
      .finally(() => {});
  };
  const getDocumentsSetType = () => {
    const url = configWeb.GET_DOCUMENTS_SET_TYPE("set_type");

    simpleGetCallAuth(url)
      .then((res) => {
        if (!res?.error) {
          setDocumentSetArray(res);
        }
      })
      .catch((error) => {
        console.error("Banner failed:", error);
      })
      .finally(() => {});
  };

  useEffect(() => {
    getDocumentsSetType();
    getUserDocuments();
    // getBookingList();
  }, [language, documentSet]);
  const getUserDocuments = () => {
    const url = configWeb.GET_USER_DOCUMENTS(
      documentSet,
      user_id
    ); /* ('uae_resident') */

    simpleGetCallAuth(url)
      .then((res) => {
        if (!res?.error) {
          setGetUserDocumentsArray(res);
        }
      })
      .catch((error) => {
        console.error("Banner failed:", error);
      })
      .finally(() => {});
  };
  const formatDocumentSetName = (set) => {
    switch (set) {
      case "uae_resident":
        return "Resident-UAE";
      case "non_resident_gcc":
        return "Non Resident(GCC Nationals)";
      case "non_resident_other":
        return "Non Resident(Other Nationals)";
      default:
        return set;
    }
  };

  const putUserDetails = () => {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        first_name: formData?.personalfirstname,
        last_name: formData?.personallastname,
        gender: formData?.gender,
        dob: formData?.dob,
        phone_code: formData?.phonenumberCode,
        phone_number: formData?.phonenumber,
        alt_phone_code: formData?.alterphoneCode,
        alt_phone_number: formData?.alterphone,
        country_id: formData?.nationality,
        emirate_id: formData.emirates || null,
      });

      const url = configWeb.PUT_USER_DETAILS(user_id);
      set_loading_profile(true);
      simplePutCallAuth(url, body)
        .then((res) => {
          if (res?.status === "success") {
            // setUserDetails(res);
            notifySuccess("Updated Successfully");
            resolve(true);
          } else if (res?.error) {
            if (Array.isArray(res?.message)) {
              notifyError(res?.message[0]);
            } else {
              notifyError(res?.message);
            }
          }
        })
        .catch((error) => {
          console.error("Banner failed:", error);
          notifySuccess("Something went wrong. Please try again letter.");
          resolve(false);
        })
        .finally(() => {
          set_loading_profile(false);
          // getUserDetails();
        });
    });
  };

  const postUserDocuments_driving_license = () => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("doc_number", userDocumentData?.licenseno);
      formData.append("issue_date", userDocumentData?.issuedate);
      formData.append("expiry_date", userDocumentData?.expiry);
      formData.append(
        "doc_type",
        documentSet === "non_resident_gcc"
          ? "driving_license_gcc"
          : documentSet === "uae_resident"
          ? "driving_license"
          : "driving_license_home_country"
      );
      formData.append(
        "doc_set_type",
        documentSet === "uae_resident"
          ? "uae_resident"
          : documentSet === "non_resident_gcc"
          ? "non_resident_gcc"
          : "non_resident_other"
      );
      formData.append("user_id", user_id);
      formData.append("front_image", userDocumentData?.licenseFront);
      formData.append("back_image", userDocumentData?.licenseBack);
      const url = configWeb.POST_USER_DOCUMENTS;
      set_loading_document(true);
      multipartPostCall(url, formData)
        .then((res) => {
          if (res?.status === "success") {
            // setUserDetails(res);
            notifySuccess("Updated Successfully");
            resolve(true);
          } else if (res?.error) {
            if (Array.isArray(res?.message)) {
              notifyError(res?.message[0]);
            } else {
              notifyError(res?.message);
            }
          }
        })
        .catch((error) => {
          console.error("Banner failed:", error);
          notifyError("Something went wrong. Please try again letter.");
          resolve(false);
        })
        .finally(() => {
          set_loading_document(false);
          // getUserDetails();
        });
    });
  };
  const postUserDocuments_id = () => {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      formData.append("doc_number", userDocumentData?.idno);
      // formData.append("issue_date", "2023-09-12");
      formData.append("expiry_date", userDocumentData?.exp);
      formData.append(
        "doc_type",
        documentSet === "non_resident_gcc"
          ? "gcc_id"
          : documentSet === "uae_resident"
          ? "emirates_id"
          : ""
      );
      formData.append(
        "doc_set_type",
        documentSet === "uae_resident"
          ? "uae_resident"
          : documentSet === "non_resident_gcc"
          ? "non_resident_gcc"
          : "non_resident_other"
      );

      formData.append("user_id", user_id);
      await appendDocumentToFormData(
        formData,
        userDocumentData?.gccIdFront,
        "front_image"
      );
      // formData.append("front_image", userDocumentData?.gccIdFront);
      // formData.append("back_image", userDocumentData?.gccIdBack);
      const url = configWeb.POST_USER_DOCUMENTS;
      set_loading_profile(true);
      multipartPostCall(url, formData)
        .then((res) => {
          if (res?.status === "success") {
            // setUserDetails(res);
            notifySuccess("Updated Successfully");
            resolve(true);
          } else if (res?.error) {
            if (Array.isArray(res?.message)) {
              notifyError(res?.message[0]);
            } else {
              notifyError(res?.message);
            }
          }
        })
        .catch((error) => {
          console.error("Banner failed:", error);
          notifyError("Something went wrong. Please try again letter.");
          resolve(false);
        })
        .finally(() => {
          set_loading_profile(false);
          // getUserDetails();
        });
    });
  };
  const postUserDocuments_passport = () => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("doc_number", userDocumentData?.passno);
      formData.append("issue_date", userDocumentData?.passIssueDate);
      formData.append("expiry_date", userDocumentData?.passExpiry);
      formData.append(
        "doc_type",
        /* documentSet === "non_resident_gcc" ? "passport" : "" */ "passport"
      );
      formData.append(
        "doc_set_type",
        documentSet === "uae_resident"
          ? "uae_resident"
          : documentSet === "non_resident_gcc"
          ? "non_resident_gcc"
          : "non_resident_other"
      );

      formData.append("user_id", user_id);
      formData.append("front_image", userDocumentData?.passportFront);
      // formData.append("back_image", userDocumentData?.passportBack);
      const url = configWeb.POST_USER_DOCUMENTS;
      set_loading_profile(true);
      multipartPostCall(url, formData)
        .then((res) => {
          if (res?.status === "success") {
            // setUserDetails(res);
            notifySuccess("Updated Successfully");
            resolve(true);
          } else if (res?.error) {
            if (Array.isArray(res?.message)) {
              notifyError(res?.message[0]);
            } else {
              notifyError(res?.message);
            }
          }
        })
        .catch((error) => {
          console.error("Banner failed:", error);
          notifyError("Something went wrong. Please try again letter.");
          resolve(false);
        })
        .finally(() => {
          set_loading_profile(false);
          // getUserDetails();
        });
    });
  };
  const postUserDocuments_entry_stamp = () => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      // formData.append("doc_number", "12345");
      // formData.append("issue_date", userDocumentData?.passIssueDate);
      // formData.append("expiry_date", userDocumentData?.passExpiry);
      formData.append(
        "doc_type",
        /*  documentSet === "non_resident_gcc" ? "entery_stamp" : "" */ "entery_stamp"
      );
      formData.append(
        "doc_set_type",
        documentSet === "uae_resident"
          ? "uae_resident"
          : documentSet === "non_resident_gcc"
          ? "non_resident_gcc"
          : "non_resident_other"
      );

      formData.append("user_id", user_id);
      formData.append("front_image", userDocumentData?.entryStamp);
      // formData.append("back_image", userDocumentData?.passportBack);
      const url = configWeb.POST_USER_DOCUMENTS;
      set_loading_profile(true);
      multipartPostCall(url, formData)
        .then((res) => {
          if (res?.status === "success") {
            // setUserDetails(res);
            notifySuccess("Updated Successfully");
            resolve(true);
          } else if (res?.error) {
            if (Array.isArray(res?.message)) {
              notifyError(res?.message[0]);
            } else {
              notifyError(res?.message);
            }
          }
        })
        .catch((error) => {
          console.error("Banner failed:", error);
          notifyError("Something went wrong. Please try again letter.");
          resolve(false);
        })
        .finally(() => {
          set_loading_profile(false);
          // getUserDetails();
        });
    });
  };
  const postUserDocuments_international_driving_license = () => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("doc_number", userDocumentData?.intlicno);
      formData.append("issue_date", userDocumentData?.intIssueDate);
      formData.append("expiry_date", userDocumentData?.intExpiry);
      formData.append(
        "doc_type",
        documentSet === "uae_resident"
          ? "driving_license_home_country"
          : "international_driving_license"
      );
      formData.append(
        "doc_set_type",
        documentSet === "uae_resident"
          ? "uae_resident"
          : documentSet === "non_resident_gcc"
          ? "non_resident_gcc"
          : "non_resident_other"
      );

      formData.append("user_id", user_id);
      formData.append("front_image", userDocumentData?.permit);
      if (documentSet === "uae_resident") {
        formData.append("back_image", userDocumentData?.entryStamp);
      }
      const url = configWeb.POST_USER_DOCUMENTS;
      set_loading_profile(true);
      multipartPostCall(url, formData)
        .then((res) => {
          if (res?.status === "success") {
            // setUserDetails(res);
            notifySuccess("Updated Successfully");
            resolve(true);
          } else if (res?.error) {
            if (Array.isArray(res?.message)) {
              notifyError(res?.message[0]);
            } else {
              notifyError(res?.message);
            }
          }
        })
        .catch((error) => {
          console.error("Banner failed:", error);
          notifyError("Something went wrong. Please try again letter.");
          resolve(false);
        })
        .finally(() => {
          set_loading_profile(false);
          // getUserDetails();
        });
    });
  };

  const postUserDocuments_translation_dl = () => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("doc_number", userDocumentData?.translation_dl_no);
      // formData.append("issue_date", "2022-01-01");
      // formData.append("expiry_date", "2026-01-01");
      formData.append("doc_type", "translation_of_driving_license");
      formData.append(
        "doc_set_type",
        documentSet === "uae_resident"
          ? "uae_resident"
          : documentSet === "non_resident_gcc"
          ? "non_resident_gcc"
          : "non_resident_other"
      );

      formData.append("user_id", user_id);
      formData.append("front_image", userDocumentData?.translation_dl);
      // formData.append("back_image", userDocumentData?.passportBack);
      const url = configWeb.POST_USER_DOCUMENTS;
      set_loading_profile(true);
      multipartPostCall(url, formData)
        .then((res) => {
          if (res?.status === "success") {
            // setUserDetails(res);
            notifySuccess("Updated Successfully");
            resolve(true);
          } else if (res?.error) {
            if (Array.isArray(res?.message)) {
              notifyError(res?.message[0]);
            } else {
              notifyError(res?.message);
            }
          }
        })
        .catch((error) => {
          console.error("Banner failed:", error);
          notifyError("Something went wrong. Please try again letter.");
          resolve(false);
        })
        .finally(() => {
          set_loading_profile(false);
          // getUserDetails();
        });
    });
  };
  const postUserDocuments_tourist_visa = async () => {
    // return new Promise ((resolve, reject)  => {
    const formData = new FormData();
    // formData.append("doc_number", "12345");
    // formData.append("issue_date", "2022-01-01");
    // formData.append("expiry_date", "2026-01-01");
    formData.append("doc_type", "tourist_visa");
    formData.append(
      "doc_set_type",
      documentSet === "uae_resident"
        ? "uae_resident"
        : documentSet === "non_resident_gcc"
        ? "non_resident_gcc"
        : "non_resident_other"
    );

    formData.append("user_id", user_id);
    formData.append("front_image", userDocumentData?.touristVisa);
    //  await appendTouristVisaToFormData(formData);
    // formData.append("back_image", userDocumentData?.passportBack);
    const url = configWeb.POST_USER_DOCUMENTS;
    set_loading_profile(true);
    multipartPostCall(url, formData)
      .then((res) => {
        if (res?.status === "success") {
          // setUserDetails(res);
          notifySuccess("Updated Successfully");
          // resolve(true);
        } else if (res?.error) {
          if (Array.isArray(res?.message)) {
            notifyError(res?.message[0]);
          } else {
            notifyError(res?.message);
          }
        }
      })
      .catch((error) => {
        console.error("Banner failed:", error);
        notifyError("Something went wrong. Please try again letter.");
        // resolve(false);
      })
      .finally(() => {
        set_loading_profile(false);
        // getUserDetails();
      });
    // });
  };

  const getUserAddress = () => {
    const url = configWeb.GET_USER_ADDRESS(user_id);

    simpleGetCallAuth(url)
      .then((res) => {
        if (!res?.error) {
          setUserAddress(res);
        }
      })
      .catch((error) => {
        console.error("Banner failed:", error);
      })
      .finally(() => {});
  };

  useEffect(() => {
    getUserDetails();
    getUserAddress();
  }, []);

  useEffect(() => {
    if (userDetails) {
      setFormData(() => ({
        personalfirstname: userDetails?.first_name || "",
        personallastname: userDetails?.last_name || "",
        phonenumberCode: userDetails?.phone_code || "",
        phonenumber: userDetails?.phone_number || "",
        alterphoneCode: userDetails?.alt_phone_code || "",
        alterphone: userDetails?.alt_phone_number || "",
        emailid: userDetails?.email || "",
        dob: userDetails?.dob || "",
        gender: userDetails?.gender || "",
        nationality: userDetails?.country_id || "",
        emirates: userDetails?.emirate_id || "",
      }));
    }
  }, [userDetails]);

  useEffect(() => {
    if (userAddress) {
      setAddressState(() => ({
        housing: userAddress?.house_number || "",
        street: userAddress?.street_name || "",
        country:
          countries?.filter(
            (country) => country.id === userAddress?.country_id
          )?.[0]?.id || "",
        state: userAddress?.state || "",
        city: userAddress?.city || "",
        postalCode: userAddress?.zip_code || "",
        //emirate_id
      }));
    }
  }, [userAddress]);

  const getCountriesData = () => {
    const url = `${configWeb.GET_COUNTRY_LIST}?lang=${language}&page_size=260`;
    simpleGetCall(url)
      .then((res) => {
        setCountries(res?.data);
      })
      .catch((errr) => {
        console.log("errr", errr);
      })
      .finally(() => {});
  };

  useEffect(() => {
    getCountriesData();
    emiratesData();
  }, [language]);

  const emiratesData = () => {
    const url = `${configWeb.GET_EMIRATES}?lang=${language}`;
    simpleGetCall(url)
      .then((res) => {
        setEmiratesArray(res);
      })
      .catch((errr) => {
        console.log("errr", errr);
      })
      .finally(() => {
        // set_loading(false);
      });
  };
  const handleResetPassword = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else if (passwordState.newpass !== passwordState.confirmpass) {
      setConfirmPasswordError(
        "New password and confirm password do not match!"
      );
      setValidated_for_password(true);
    } else {
      putPassword();
    }
    setValidated_for_password(true);
  };
  const handleAddressUpdate = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    const isPutAddress = await putAddress();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      if (isPutAddress) {
        set_validated_for_address(false);
      }
      getUserAddress();
    }
    if (!isPutAddress) {
      set_validated_for_address(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      // Handle form submission here, e.g., send data to the server
      // putUserDetails();

      const isPutUserDetails = await putUserDetails();

      getUserDetails();
      set_disable_personal_field(true);

      // passwordReset();
      // addressReset();
    }

    setValidated(true);
  };

  var c = countries?.filter((country) => country.id === AddressState.country);
  useEffect(() => {}, [countries, AddressState]);

  const handleEditProfile = () => {
    set_disable_personal_field(false);
  };
  const handleCancelEditProfile = () => {
    set_disable_personal_field(true);
    getUserDetails();
  };

  const putPassword = () => {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        current_password: passwordState.currentpass,
        password: passwordState.newpass,
        confirm_password: passwordState.confirmpass,
      });

      const url = configWeb.PUT_RESET_PASSWORD(user_id);
      set_loading_password(true);
      simplePutCallAuth(url, body)
        .then((res) => {
          if (res?.status === "success") {
            // setUserDetails(res);
            notifySuccess("Updated Successfully");

            resolve(true);
            setPasswordState({
              currentpass: "",
              newpass: "",
              confirmpass: "",
            });
            setValidated_for_password(false);
          } else if (res?.error) {
            notifyError(res?.message[0]);
          }
        })
        .catch((error) => {
          console.error("Banner failed:", error);
          notifySuccess("Something went wrong. Please try again letter.");
          resolve(false);
        })
        .finally(() => {
          set_loading_password(false);
          // getUserDetails();
        });
    });
  };

  const putAddress = () => {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        house_number: AddressState.housing,
        street_name: AddressState.street,
        country_id: AddressState.country,
        // emirate_id: 0,
        state: AddressState.state,
        city: AddressState.city,
        zip_code: AddressState.postalCode,
      });

      const url = configWeb.PUT_USER_ADDRESS(user_id);
      set_loading_address(true);
      simplePutCallAuth(url, body)
        .then((res) => {
          if (res?.status === "success") {
            // setUserDetails(res);
            notifySuccess("Updated Successfully");

            resolve(true);
            setPasswordState({
              currentpass: "",
              newpass: "",
              confirmpass: "",
            });
            setValidated_for_password(false);
          } else if (res?.error) {
            notifyError(res?.message[0]);
          }
        })
        .catch((error) => {
          console.error("Banner failed:", error);
          notifySuccess("Something went wrong. Please try again letter.");
          resolve(false);
        })
        .finally(() => {
          set_loading_address(false);
          // getUserDetails();
        });
    });
  };
  return (
    <>
      <Helmet title="My-Account">
        <div className="myAccountMainBox">
          <div className="landingBg">
            <div className="titleInner">
              <div className="innerTxt">
                <h1>My Account</h1>
                <Link to={`/`}>Home / </Link> <span>My Account</span>
              </div>
            </div>
          </div>
          <div className="tabsSection">
            <Tab.Container defaultActiveKey={tab_type || "first"}>
              {/* <Nav
                variant="pills"
                className=""
                //   onSelect={(selectedKey) =>
                //     setCurrentTab(`${selectedKey}`)
                //   }
              >
                <Nav.Item>
                  <Nav.Link eventKey="first">My Account</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Documents</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">Additional Driver</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="bookings">Bookings</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="fifth"
                    className="fifthinvoice position-relative"
                  >
                    Invoices
                    <span className="displaycomment badge">coming soon</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="sixth"
                    className="sixthloyalty position-relative"
                  >
                    Autostrad Loyalty
                    <span className="displaycomment badge">coming soon</span>
                  </Nav.Link>
                </Nav.Item>
              </Nav> */}

              <>
                <div className="d-sm-none mx-auto carousel-at-mobile">
                  <Carousel interval={null}>
                    <Carousel.Item>
                      <Nav.Link eventKey="first">My Account</Nav.Link>
                    </Carousel.Item>
                    <Carousel.Item>
                      <Nav.Link eventKey="second">Documents</Nav.Link>
                    </Carousel.Item>
                    <Carousel.Item>
                      <Nav.Link eventKey="third">Additional Driver</Nav.Link>
                    </Carousel.Item>
                    <Carousel.Item>
                      <Nav.Link eventKey="bookings">Bookings</Nav.Link>
                    </Carousel.Item>
                    {/* <Carousel.Item>
                      <Nav.Link
                        eventKey="fifth"
                        className="fifthinvoice position-relative"
                      >
                        Invoices
                        <span className="displaycomment badge">
                          coming soon
                        </span>
                      </Nav.Link>
                    </Carousel.Item>
                    <Carousel.Item>
                      <Nav.Link
                        eventKey="sixth"
                        className="sixthloyalty position-relative"
                      >
                        Autostrad Loyalty
                        <span className="displaycomment badge">
                          coming soon
                        </span>
                      </Nav.Link>
                    </Carousel.Item> */}
                  </Carousel>
                </div>

                <Nav variant="pills" className="d-none d-sm-flex">
                  <Nav.Item>
                    <Nav.Link eventKey="first">My Account</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">Documents</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">Additional Driver</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="bookings">Bookings</Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link
                      eventKey="fifth"
                      className="fifthinvoice position-relative"
                    >
                      Invoices
                      <span className="displaycomment badge">coming soon</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="sixth"
                      className="sixthloyalty position-relative"
                    >
                      Autostrad Loyalty
                      <span className="displaycomment badge">coming soon</span>
                    </Nav.Link>
                  </Nav.Item> */}
                </Nav>
              </>

              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <div className="accordBox">
                    <Accordion defaultActiveKey="1">
                      <Accordion.Item eventKey="1" className="shadow">
                        <Accordion.Header>Personal Details</Accordion.Header>
                        <Accordion.Body>
                          <Form
                            noValidate
                            validated={validated}
                            onSubmit={handleSubmit}
                          >
                            <div className="row justify-content-between">
                              <div className="col-lg-3 col-md-6">
                                <Form.Group controlId="personalfirstname">
                                  <Form.Label>First Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="personalfirstname"
                                    value={formData.personalfirstname}
                                    onChange={handleChange}
                                    required
                                    disabled={disable_personal_field}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please provide a first name.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </div>
                              <div className="col-lg-3 col-md-6 margin-small-screen">
                                <Form.Group controlId="personallastname">
                                  <Form.Label>Last Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="personallastname"
                                    value={formData.personallastname}
                                    onChange={handleChange}
                                    required
                                    disabled={disable_personal_field}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please provide a last name.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </div>

                              <div className="col-lg-3 col-md-6 col-sm-6 margin-small-screen">
                                <Form.Group controlId="phonenumberCode">
                                  <Form.Label>Phone Number</Form.Label>
                                  <div className="row">
                                    <div className="col-5">
                                      <Form.Select
                                        name="phonenumberCode"
                                        value={formData.phonenumberCode}
                                        onChange={handleChange}
                                        required
                                        disabled={disable_personal_field}
                                        className="px-0 custom-fontsize"
                                      >
                                        <option value="">Code</option>
                                        {countries?.map((country) => (
                                          <option
                                            key={country.id}
                                            value={country.phone_code}
                                          >
                                            {country.code} {country.phone_code}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      {/* <Form.Control.Feedback type="invalid">
                                        Please select a phone code.
                                      </Form.Control.Feedback> */}
                                    </div>
                                    <div className="col-7">
                                      <Form.Control
                                        type="number"
                                        name="phonenumber"
                                        value={formData.phonenumber}
                                        onChange={handleChange}
                                        required
                                        disabled={disable_personal_field}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please provide a phone number.
                                      </Form.Control.Feedback>
                                    </div>
                                  </div>
                                </Form.Group>
                              </div>

                              <div className="col-lg-3 col-md-6 col-sm-6 margin-small-screen">
                                <Form.Group controlId="alterphoneCode">
                                  <Form.Label>
                                    Alternate Phone Number
                                  </Form.Label>
                                  <div className="row">
                                    <div className="col-5">
                                      <Form.Select
                                        name="alterphoneCode"
                                        value={formData.alterphoneCode}
                                        onChange={handleChange}
                                        required
                                        disabled={disable_personal_field}
                                        className="px-0 custom-fontsize"
                                      >
                                        <option value="">Code</option>
                                        {countries?.map((country) => (
                                          <option
                                            key={country.id}
                                            value={country.phone_code}
                                          >
                                            {country.code} {country.phone_code}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      {/* <Form.Control.Feedback type="invalid">
                                        Please select a phone code.
                                      </Form.Control.Feedback> */}
                                    </div>
                                    <div className="col-7">
                                      <Form.Control
                                        type="number"
                                        name="alterphone"
                                        value={formData.alterphone}
                                        onChange={handleChange}
                                        required
                                        disabled={disable_personal_field}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please provide an alternate phone
                                        number.
                                      </Form.Control.Feedback>
                                    </div>
                                  </div>
                                </Form.Group>
                              </div>
                            </div>

                            <div className="row justify-content-between">
                              <div className="col-lg-3 col-md-6 mt-3">
                                <Form.Group controlId="emailid">
                                  <Form.Label>Email</Form.Label>
                                  <Form.Control
                                    type="email"
                                    name="emailid"
                                    value={formData.emailid}
                                    onChange={handleChange}
                                    required
                                    disabled={disable_personal_field}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please provide a valid email.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </div>

                              <div className="col-lg-3 col-md-6 mt-3">
                                <Form.Group controlId="dob">
                                  <Form.Label>Date Of Birth</Form.Label>
                                  <Form.Control
                                    type="date"
                                    name="dob"
                                    value={formData.dob}
                                    onChange={handleChange}
                                    required
                                    disabled={disable_personal_field}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please provide your date of birth.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </div>

                              {/* <div className="col-lg-3 col-md-6 mt-3">
                                <Form.Group controlId="gender">
                                  <Form.Label className="form-label">
                                    Select Gender
                                  </Form.Label>
                                  <div className="d-flex">
                                    <Form.Check
                                      type="radio"
                                      id="male"
                                      name="gender"
                                      className="form-check-input"
                                      value="male"
                                      checked={formData.gender === "male"}
                                      onChange={handleChange}
                                      required
                                    />
                                    <Form.Label
                                      htmlFor="male"
                                      className="form-check-label me-2"
                                    >
                                      Male
                                    </Form.Label>
                                    <Form.Check
                                      type="radio"
                                      id="female"
                                      name="gender"
                                      className="form-check-input"
                                      value="female"
                                      checked={formData.gender === "female"}
                                      onChange={handleChange}
                                      required
                                    />
                                    <Form.Label
                                      htmlFor="female"
                                      className="form-check-label"
                                    >
                                      Female
                                    </Form.Label>
                                    <Form.Control.Feedback type="invalid">
                                      Please select a gender.
                                    </Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                              </div> */}
                              <div className="col-lg-3 col-md-6 mt-3">
                                <label className="form-label">
                                  Select Gender
                                </label>
                                <br />
                                <div className="d-flex">
                                  <div className="form-check">
                                    <input
                                      type="radio"
                                      id="male"
                                      name="gender"
                                      className="form-check-input"
                                      value="male"
                                      checked={formData.gender === "male"}
                                      onChange={handleChange}
                                      disabled={disable_personal_field}
                                    />
                                    <label
                                      htmlFor="male"
                                      className="form-check-label"
                                    >
                                      Male
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      type="radio"
                                      id="female"
                                      name="gender"
                                      className="form-check-input"
                                      value="female"
                                      checked={formData.gender === "female"}
                                      onChange={handleChange}
                                      disabled={disable_personal_field}
                                    />
                                    <label
                                      htmlFor="female"
                                      className="form-check-label"
                                    >
                                      Female
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6 mt-3">
                                <Form.Group controlId="nationality">
                                  <Form.Label>Nationality</Form.Label>
                                  <Form.Select
                                    name="nationality"
                                    value={formData.nationality}
                                    // value={AddressState.country?.[0]?.id}
                                    onChange={handleChange}
                                    required
                                    disabled={disable_personal_field}
                                  >
                                    <option value="">Nationality</option>
                                    {countries?.map((country) => (
                                      <option
                                        key={country.id}
                                        value={country.id}
                                      >
                                        {country.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                  <Form.Control.Feedback type="invalid">
                                    Please select a nationality.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </div>
                            </div>

                            <div className="row justify-content-between">
                              {formData.nationality == "229" ? (
                                <div className="col-lg-3 col-md-6 mt-3">
                                  <Form.Group controlId="emirates">
                                    <Form.Label>Emirates</Form.Label>
                                    <Form.Select
                                      name="emirates"
                                      value={formData.emirates}
                                      onChange={handleChange}
                                      required
                                      disabled={disable_personal_field}
                                    >
                                      <option value="">Select Emirates</option>
                                      {emiratesArray?.map((emirate) => (
                                        <option
                                          key={emirate.id}
                                          value={emirate.id}
                                        >
                                          {emirate.name}
                                        </option>
                                      ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                      Please select an emirate.
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </div>
                              ) : (
                                <div className="col-lg-3 col-md-6 mt-3"></div>
                              )}

                              <div className="col-lg-4 col-md-6 d-flex align-items-end justify-content-end mt-3">
                                {disable_personal_field ? (
                                  <Button
                                    type="button"
                                    className="defButton me-2 text-nowrap"
                                    onClick={(e) => {
                                      e.preventDefault(); // Prevent form submission if this is inside a form
                                      handleEditProfile();
                                    }}
                                  >
                                    Edit Profile
                                  </Button>
                                ) : (
                                  <Button
                                    type="submit"
                                    className="defButton me-2 text-nowrap"
                                    disabled={loading_profile}
                                  >
                                    {loading_profile ? (
                                      <Spinner />
                                    ) : (
                                      "Save Changes"
                                    )}
                                  </Button>
                                )}
                                {!disable_personal_field && (
                                  <Button
                                    type="button"
                                    className="defButton text-nowrap"
                                    onClick={handleCancelEditProfile}
                                  >
                                    Cancel Edit Profile
                                  </Button>
                                )}
                              </div>
                            </div>
                          </Form>
                        </Accordion.Body>
                      </Accordion.Item>
                   
                      <Accordion.Item eventKey="2" className="shadow">
                        <Accordion.Header>Password</Accordion.Header>
                        <Accordion.Body>
                          <Form
                            noValidate
                            validated={validated_for_password}
                            onSubmit={handleResetPassword}
                          >
                            <div className="row justify-content-between">
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <Form.Group controlId="currentpass">
                                  <Form.Label>Current Password</Form.Label>
                                  <Form.Control
                                    type="password"
                                    name="currentpass"
                                    value={passwordState.currentpass}
                                    onChange={handlePasswordChange}
                                    required
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please provide your current password.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </div>

                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <Form.Group controlId="newpass">
                                  <Form.Label>New Password</Form.Label>
                                  <Form.Control
                                    type="password"
                                    name="newpass"
                                    value={passwordState.newpass}
                                    onChange={handlePasswordChange}
                                    required
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please provide a new password.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </div>

                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <Form.Group controlId="confirmpass">
                                  <Form.Label>Confirm New Password</Form.Label>
                                  <Form.Control
                                    type="password"
                                    name="confirmpass"
                                    value={passwordState.confirmpass}
                                    onChange={handlePasswordChange}
                                    required
                                    isInvalid={
                                      passwordState.newpass !==
                                      passwordState.confirmpass
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {/* {confirmPasswordError} */}
                                    {passwordState.confirmpass
                                      ? "New password and confirm password do not match!"
                                      : " Please confirm your new password."}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </div>
                            </div>

                            <div className="row justify-content-end">
                              <div className="col-5 d-flex justify-content-end">
                                <Button
                                  type="submit"
                                  className="defButton me-3 text-nowrap"
                                  disabled={loading_password}
                                >
                                  {loading_password ? (
                                    <Spinner />
                                  ) : (
                                    "Save Changes"
                                  )}
                                </Button>
                              </div>
                            </div>
                          </Form>
                        </Accordion.Body>
                      </Accordion.Item>
                      {/* <Accordion.Item eventKey="3" className="shadow">
                        <Accordion.Header>Billing address</Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-12">
                              <label htmlFor="housing">
                                House Number / Building Number
                              </label>
                              <input
                                type="text"
                                id="housing"
                                name="housing"
                                className="form-control"
                              ></input>
                            </div>
                            <div className="col-12 mt-3">
                              <label htmlFor="street">
                                Street Name / Area{" "}
                              </label>
                              <input
                                type="text"
                                id="street"
                                name="street"
                                className="form-control"
                              ></input>
                            </div>
                          </div>

                          <div className="row mt-3">
                            <div className="col-lg-3 col-md-6 ">
                              <label htmlFor="country" className="form-label">
                                Country
                              </label>
                              <select className="form-select">
                                <option>India</option>
                                <option>India</option>
                                <option>India</option>
                                <option>India</option>
                                <option>India</option>
                                <option>India</option>
                              </select>
                            </div>
                            <div className="col-lg-3 col-md-6 margin-small-screen">
                              <label htmlFor="state" className="form-label">
                                State
                              </label>
                              <select className="form-select">
                                <option>Maharashtra</option>
                                <option>Maharashtra</option>
                                <option>Maharashtra</option>
                                <option>Maharashtra</option>
                                <option>Maharashtra</option>
                                <option>Maharashtra</option>
                              </select>
                            </div>
                            <div className="col-lg-3 col-md-6 margin-small-screen">
                              <label className="form-label">Town/City</label>
                              <select className="form-select">
                                <option>Aurangabad</option>
                                <option>Aurangabad</option>
                                <option>Aurangabad</option>
                                <option>Aurangabad</option>
                                <option>Aurangabad</option>
                                <option>Aurangabad</option>
                              </select>
                            </div>
                            <div className="col-lg-3 col-md-6 margin-small-screen">
                              <label className="form-label">
                                Zip/Postal Code
                              </label>
                              <input
                                type="number"
                                className="form-control"
                              ></input>
                            </div>
                            <div className="d-flex justify-content-end">
                              <div className="col-6 d-flex justify-content-end mt-3">
                                <button className="defButtonbilling text-nowrap px-3 ">
                                  UPDATE BILLING ADDRESS
                                </button>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item> */}

                      <Accordion.Item eventKey="3" className="shadow">
                        <Accordion.Header>Billing Address</Accordion.Header>
                        <Accordion.Body>
                          <Form
                            noValidate
                            validated={validated_for_address}
                            onSubmit={handleAddressUpdate}
                          >
                            <div className="row">
                              <div className="col-12">
                                <Form.Group controlId="housing">
                                  <Form.Label>
                                    House Number / Building Number
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="housing"
                                    value={AddressState.housing}
                                    onChange={handleChangeAddress}
                                    required
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please provide your house/building number.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </div>

                              <div className="col-12 mt-3">
                                <Form.Group controlId="street">
                                  <Form.Label>Street Name / Area</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="street"
                                    value={AddressState.street}
                                    onChange={handleChangeAddress}
                                    required
                                    isValid={false}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please provide your street name or area.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-lg-3 col-md-6">
                                <Form.Group controlId="country">
                                  <Form.Label>Country</Form.Label>
                                  <Form.Select
                                    name="country"
                                    value={AddressState.country}
                                    onChange={handleChangeAddress}
                                    required
                                    isValid={false}
                                  >
                                    <option value="">Select Country</option>
                                    {countries?.map((country) => (
                                      <option
                                        key={country.id}
                                        value={country.id}
                                      >
                                        {country.name}
                                      </option>
                                    ))}
                                    {/* Add more countries as needed */}
                                  </Form.Select>

                                  <Form.Control.Feedback type="invalid">
                                    Please select your country.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <Form.Group controlId="state">
                                  <Form.Label>State</Form.Label>
                                  <Form.Control
                                    name="state"
                                    value={AddressState.state}
                                    onChange={handleChangeAddress}
                                    required
                                    isValid={false}
                                  >
                                    {/* <option value="">Select State</option>
                                    <option value="Maharashtra">
                                      Maharashtra
                                    </option> */}
                                    {/* Add more states as needed */}
                                  </Form.Control>
                                  <Form.Control.Feedback type="invalid">
                                    Please select your state.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <Form.Group controlId="city">
                                  <Form.Label>Town/City</Form.Label>
                                  <Form.Control
                                    name="city"
                                    value={AddressState.city}
                                    onChange={handleChangeAddress}
                                    required
                                    isValid={false}
                                  >
                                    {/* <option value="">Enter City</option>
                                    <option value="Aurangabad">
                                      Aurangabad
                                    </option> */}
                                    {/* Add more cities as needed */}
                                  </Form.Control>
                                  <Form.Control.Feedback type="invalid">
                                    Please select your city.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </div>

                              <div className="col-lg-3 col-md-6">
                                <Form.Group controlId="postalCode">
                                  <Form.Label>Zip/Postal Code</Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="postalCode"
                                    value={AddressState.postalCode}
                                    onChange={handleChangeAddress}
                                    required
                                    isValid={false}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please provide your postal code.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </div>
                            </div>

                            <div className="row d-flex justify-content-end">
                              <div className="col-6 d-flex justify-content-end mt-3">
                                <Button
                                  type="submit"
                                  className="defButtonbilling text-nowrap px-3"
                                  disabled={loading_address}
                                >
                                  {loading_address ? (
                                    <Spinner />
                                  ) : (
                                    "UPDATE BILLING ADDRESS"
                                  )}
                                </Button>
                              </div>
                            </div>
                          </Form>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="bookings">
                  {/*      <div id="innerTabs">
                    <Tab.Container defaultActiveKey="first">
                      <Nav variant="pills" className="">
                        <Nav.Item>
                          <Nav.Link eventKey="first">
                            Upcoming Reservations
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="second">
                            Current Reservations
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="third">
                            Past Reservations
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="bookings">
                            Cancelled Reservations
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>

                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <div className="row justify-content-center">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-12 position-relative ">
                              <div className="p-2 shadow rounded-3">
                                <div className="row reserved-row">
                                  <div className="col-lg-5 col-md-5 col-xs-6 d-flex flex-column align-items-start">
                                    <img
                                      src={carImage}
                                      className="img-fluid rounded-3"
                                      alt="Car"
                                    />
                                  </div>
                                  <div className="col-lg-5 col-md-5 col-xs-6 card-body my-auto text-under-small-screen">
                                    <span
                                      className="badge p-2 mt-1"
                                      style={{
                                        backgroundColor: "#6C505E",
                                        fontFamily: "monospace",
                                        fontSize: "18px",
                                      }}
                                    >
                                      Mitsubishi Attrage
                                    </span>
                                    <p className="mb-0">
                                      Reservation Number: ARC14497
                                    </p>
                                    <p className="mb-0">
                                      <strong>Pick Up Location:</strong> Dubai
                                      International Airport - Terminal 1 Arrival
                                    </p>
                                    <p className="mb-0">
                                      <strong>Pick Up Date:</strong> Wednesday,
                                      Aug 14, 2024 07:00 PM
                                    </p>
                                    <hr className="my-1" />
                                    <p className="mb-0">
                                      <strong>Drop Off Location:</strong> Al
                                      Jazah Street, Ras Al Khaimah
                                    </p>
                                    <p className="mb-0">
                                      <strong>Drop Off Date:</strong> Wednesday,
                                      Aug 21, 2024 01:00 PM
                                    </p>
                                  </div>
                                  <div className="col-lg-1 col-md-1 move-it-right">
                                    <img
                                      src={threedotsvertical}
                                      className=""
                                      onClick={() =>
                                        setShowCardButtons(!showCardButtons)
                                      }
                                      alt="Menu"
                                    />
                                  </div>
                                </div>
                              </div>
                              {showCardButtons && (
                                <ul className="list-unstyled make-position-absolute">
                                  <li>Edit Reservation</li>
                                  <li>Cancel Reservation</li>
                                </ul>
                              )}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-12 position-relative">
                              <div className="p-2 shadow rounded-3">
                                <div className="row reserved-row">
                                  <div className="col-lg-5 col-md-5 col-xs-6 d-flex flex-column align-items-start">
                                    <img
                                      src={carImage}
                                      className="img-fluid rounded-3"
                                      alt="Car"
                                    />
                                  </div>
                                  <div className="col-lg-5 col-md-5 col-xs-6 card-body my-auto text-under-small-screen">
                                    <span
                                      className="badge p-2 mt-1"
                                      style={{
                                        backgroundColor: "#6C505E",
                                        fontFamily: "monospace",
                                        fontSize: "18px",
                                      }}
                                    >
                                      Mitsubishi Attrage
                                    </span>
                                    <p className="mb-0">
                                      Reservation Number: ARC14497
                                    </p>
                                    <p className="mb-0">
                                      <strong>Pick Up Location:</strong> Dubai
                                      International Airport - Terminal 1 Arrival
                                    </p>
                                    <p className="mb-0">
                                      <strong>Pick Up Date:</strong> Wednesday,
                                      Aug 14, 2024 07:00 PM
                                    </p>
                                    <hr className="my-1" />
                                    <p className="mb-0">
                                      <strong>Drop Off Location:</strong> Al
                                      Jazah Street, Ras Al Khaimah
                                    </p>
                                    <p className="mb-0">
                                      <strong>Drop Off Date:</strong> Wednesday,
                                      Aug 21, 2024 01:00 PM
                                    </p>
                                  </div>
                                  <div className="col-lg-1 col-md-1 move-it-right">
                                    <img
                                      src={threedotsvertical}
                                      className=""
                                      onClick={() =>
                                        setShowCardButtons(!showCardButtons)
                                      }
                                      alt="Menu"
                                    />
                                  </div>
                                </div>
                              </div>
                              {showCardButtons && (
                                <ul className="list-unstyled make-position-absolute">
                                  <li>Edit Reservation</li>
                                  <li>Cancel Reservation</li>
                                </ul>
                              )}
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                      <Tab.Content>
                        <Tab.Pane eventKey="third">
                          <div className="row justify-content-center">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-12 position-relative ">
                              <div className="p-2 shadow rounded-3">
                                <div className="row reserved-row">
                                  <div className="col-lg-5 col-md-5 col-xs-6 d-flex flex-column align-items-start">
                                    <img
                                      src={carImage}
                                      className="img-fluid rounded-3"
                                      alt="Car"
                                    />
                                  </div>
                                  <div className="col-lg-5 col-md-5 col-xs-6 card-body my-auto text-under-small-screen">
                                    <span
                                      className="badge p-2 mt-1"
                                      style={{
                                        backgroundColor: "#6C505E",
                                        fontFamily: "monospace",
                                        fontSize: "18px",
                                      }}
                                    >
                                      Mitsubishi Attrage
                                    </span>
                                    <p className="mb-0">
                                      Reservation Number: ARC14497
                                    </p>
                                    <p className="mb-0">
                                      <strong>Pick Up Location:</strong> Dubai
                                      International Airport - Terminal 1 Arrival
                                    </p>
                                    <p className="mb-0">
                                      <strong>Pick Up Date:</strong> Wednesday,
                                      Aug 14, 2024 07:00 PM
                                    </p>
                                    <hr className="my-1" />
                                    <p className="mb-0">
                                      <strong>Drop Off Location:</strong> Al
                                      Jazah Street, Ras Al Khaimah
                                    </p>
                                    <p className="mb-0">
                                      <strong>Drop Off Date:</strong> Wednesday,
                                      Aug 21, 2024 01:00 PM
                                    </p>
                                  </div>
                                  <div className="col-lg-1 col-md-1 move-it-right">
                                    <img
                                      src={threedotsvertical}
                                      className=""
                                      onClick={() =>
                                        setShowCardButtons(!showCardButtons)
                                      }
                                      alt="Menu"
                                    />
                                  </div>
                                </div>
                              </div>
                              {showCardButtons && (
                                <ul className="list-unstyled make-position-absolute">
                                  <li>Edit Reservation</li>
                                  <li>Cancel Reservation</li>
                                </ul>
                              )}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-12 position-relative ">
                              <div className="p-2 shadow rounded-3">
                                <div className="row reserved-row">
                                  <div className="col-lg-5 col-md-5 col-xs-6 d-flex flex-column align-items-start">
                                    <img
                                      src={carImage}
                                      className="img-fluid rounded-3"
                                      alt="Car"
                                    />
                                  </div>
                                  <div className="col-lg-5 col-md-5 col-xs-6 card-body my-auto text-under-small-screen">
                                    <span
                                      className="badge p-2 mt-1"
                                      style={{
                                        backgroundColor: "#6C505E",
                                        fontFamily: "monospace",
                                        fontSize: "18px",
                                      }}
                                    >
                                      Mitsubishi Attrage
                                    </span>
                                    <p className="mb-0">
                                      Reservation Number: ARC14497
                                    </p>
                                    <p className="mb-0">
                                      <strong>Pick Up Location:</strong> Dubai
                                      International Airport - Terminal 1 Arrival
                                    </p>
                                    <p className="mb-0">
                                      <strong>Pick Up Date:</strong> Wednesday,
                                      Aug 14, 2024 07:00 PM
                                    </p>
                                    <hr className="my-1" />
                                    <p className="mb-0">
                                      <strong>Drop Off Location:</strong> Al
                                      Jazah Street, Ras Al Khaimah
                                    </p>
                                    <p className="mb-0">
                                      <strong>Drop Off Date:</strong> Wednesday,
                                      Aug 21, 2024 01:00 PM
                                    </p>
                                  </div>
                                  <div className="col-lg-1 col-md-1 move-it-right">
                                    <img
                                      src={threedotsvertical}
                                      className=""
                                      onClick={() =>
                                        setShowCardButtons(!showCardButtons)
                                      }
                                      alt="Menu"
                                    />
                                  </div>
                                </div>
                              </div>
                              {showCardButtons && (
                                <ul className="list-unstyled make-position-absolute">
                                  <li>Edit Reservation</li>
                                  <li>Cancel Reservation</li>
                                </ul>
                              )}
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>

                      <Tab.Content>
                        <Tab.Pane eventKey="bookings">
                          <div className="row justify-content-center">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-12 position-relative ">
                              <div className="p-2 shadow rounded-3">
                                <div className="row reserved-row">
                                  <div className="col-lg-5 col-md-5 col-xs-6 d-flex flex-column align-items-start">
                                    <img
                                      src={carImage}
                                      className="img-fluid rounded-3"
                                      alt="Car"
                                    />
                                  </div>
                                  <div className="col-lg-5 col-md-5 col-xs-6 card-body my-auto text-under-small-screen">
                                    <span
                                      className="badge p-2 mt-1"
                                      style={{
                                        backgroundColor: "#6C505E",
                                        fontFamily: "monospace",
                                        fontSize: "18px",
                                      }}
                                    >
                                      Mitsubishi Attrage
                                    </span>
                                    <p className="mb-0">
                                      Reservation Number: ARC14497
                                    </p>
                                    <p className="mb-0">
                                      <strong>Pick Up Location:</strong> Dubai
                                      International Airport - Terminal 1 Arrival
                                    </p>
                                    <p className="mb-0">
                                      <strong>Pick Up Date:</strong> Wednesday,
                                      Aug 14, 2024 07:00 PM
                                    </p>
                                    <hr className="my-1" />
                                    <p className="mb-0">
                                      <strong>Drop Off Location:</strong> Al
                                      Jazah Street, Ras Al Khaimah
                                    </p>
                                    <p className="mb-0">
                                      <strong>Drop Off Date:</strong> Wednesday,
                                      Aug 21, 2024 01:00 PM
                                    </p>
                                  </div>
                                  <div className="col-lg-1 col-md-1 move-it-right">
                                    <img
                                      src={threedotsvertical}
                                      className=""
                                      onClick={() =>
                                        setShowCardButtons(!showCardButtons)
                                      }
                                      alt="Menu"
                                    />
                                  </div>
                                </div>
                              </div>
                              {showCardButtons && (
                                <ul className="list-unstyled make-position-absolute">
                                  <li>Edit Reservation</li>
                                  <li>Cancel Reservation</li>
                                </ul>
                              )}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-12 position-relative ">
                              <div className="p-2 shadow rounded-3">
                                <div className="row reserved-row">
                                  <div className="col-lg-5 col-md-5 col-xs-6 d-flex flex-column align-items-start">
                                    <img
                                      src={carImage}
                                      className="img-fluid rounded-3"
                                      alt="Car"
                                    />
                                  </div>
                                  <div className="col-lg-5 col-md-5 col-xs-6 card-body my-auto text-under-small-screen">
                                    <span
                                      className="badge p-2 mt-1"
                                      style={{
                                        backgroundColor: "#6C505E",
                                        fontFamily: "monospace",
                                        fontSize: "18px",
                                      }}
                                    >
                                      Mitsubishi Attrage
                                    </span>
                                    <p className="mb-0">
                                      Reservation Number: ARC14497
                                    </p>
                                    <p className="mb-0">
                                      <strong>Pick Up Location:</strong> Dubai
                                      International Airport - Terminal 1 Arrival
                                    </p>
                                    <p className="mb-0">
                                      <strong>Pick Up Date:</strong> Wednesday,
                                      Aug 14, 2024 07:00 PM
                                    </p>
                                    <hr className="my-1" />
                                    <p className="mb-0">
                                      <strong>Drop Off Location:</strong> Al
                                      Jazah Street, Ras Al Khaimah
                                    </p>
                                    <p className="mb-0">
                                      <strong>Drop Off Date:</strong> Wednesday,
                                      Aug 21, 2024 01:00 PM
                                    </p>
                                  </div>
                                  <div className="col-lg-1 col-md-1 move-it-right">
                                    <img
                                      src={threedotsvertical}
                                      className=""
                                      onClick={() =>
                                        setShowCardButtons(!showCardButtons)
                                      }
                                      alt="Menu"
                                    />
                                  </div>
                                </div>
                              </div>
                              {showCardButtons && (
                                <ul className="list-unstyled make-position-absolute">
                                  <li>Edit Reservation</li>
                                  <li>Cancel Reservation</li>
                                </ul>
                              )}
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div> */}
                  <Booking user_id={user_id} />
                </Tab.Pane>
              </Tab.Content>

              <Tab.Content>
                <Tab.Pane eventKey="second">
                  <div className="row border rounded-3 p-2 shadow">
                    <h2>DOCUMENTS</h2>
                    <h4>ONE PLACE TO MANAGE YOUR ACCOUNT</h4>
                    <div className="col-lg-4 col-md-6 mb-6">
                      <label htmlFor="docset">Your Document Set</label>
                      <select
                        className="form-select"
                        value={documentSet}
                        onChange={handleDocumentSetChange}
                      >
                        {documentSetArray.length > 0 &&
                          documentSetArray?.map((set, index) => (
                            <option key={index} value={set}>
                              {" "}
                              {formatDocumentSetName(set)}
                            </option>
                          ))}
                      
                      </select>

                    </div>
                    {/* <hr className="my-3" /> */}
                    <Accordion defaultActiveKey="1.1" className="mt-4">
                      <Accordion.Item eventKey="1.1" className="shadow mb-2 ">
                        <Accordion.Header>
                          {" "}
                          {documentSet === "uae_resident"
                            ? "UAE Driving License"
                            : documentSet === "non_resident_gcc"
                            ? "GCC Driving License"
                            : "Home Country Driving License"}
                        </Accordion.Header>
                        <Accordion.Body>
                          <Form
                            noValidate
                            validated={userDocumentValidated_1}
                            onSubmit={handleSubmitUserDocuments_1}
                          >
                            <div className="row">
                              <div className="col-lg-4 col-md-6">
                                <Form.Group controlId="licenseno">
                                  <Form.Label
                                    /* htmlFor="licenseno */ className="text-nowrap"
                                  >
                                    {/* {documentSet === "uae_resident"
                                      ? "UAE Driving License - Front"
                                      : documentSet === "non_resident_gcc"
                                      ? "GCC Driving License - Front"
                                      : "Home Country Driving License - Front"} */}
                                    License Number
                                  </Form.Label>

                                  <Form.Control
                                    type="text"
                                    name="licenseno"
                                    placeholder="License Number"
                                    value={
                                      userDocumentData.licenseno
                                    } 
                                    onChange={handleUserDocumentDataChange}
                                    required
                                  />

                                  <Form.Control.Feedback type="invalid">
                                    Please provide a license number.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </div>
                              <div className="col-lg-4 col-md-6 margin-small-screen">
                                <Form.Group controlId="issuedate">
                                  <Form.Label>Issue Date</Form.Label>
                                  <Form.Control
                                    type="date"
                                    name="issuedate"
                                    value={userDocumentData.issuedate}
                                    onChange={handleUserDocumentDataChange}
                                    required
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please provide the issue date.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </div>
                              <div className="col-lg-4 col-md-6 margin-small-screen">
                                <Form.Group controlId="expiry">
                                  <Form.Label>Expiry Date</Form.Label>
                                  <Form.Control
                                    type="date"
                                    name="expiry"
                                    value={userDocumentData.expiry}
                                    onChange={handleUserDocumentDataChange}
                                    required
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please provide the expiry date.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </div>
                              {/* <div className="w-100"></div> */}
                              <div className="col-lg-4 col-md-6 mt-3">
                                <p className="text-nowrap">
                                  {documentSet === "uae_resident"
                                    ? "UAE Driving License - Front"
                                    : documentSet === "non_resident_gcc"
                                    ? "GCC Driving License - Front"
                                    : "Home Country Driving License - Front"}
                                </p>
                                <Form.Group controlId="license-front">
                                  {/* <Form.Label className="defButton">
                                    Choose Front Image
                                  </Form.Label> */}
                                  <Form.Control
                                    type="file"
                                    name="licenseFront"
                                    onChange={handleUserDocumentDataChange}
                                    required
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please upload the front of your license.
                                  </Form.Control.Feedback>

                                  {userDocumentData?.licenseFront && (
                                    <div className="mt-2">
                                      <a
                                        href={
                                          typeof userDocumentData.licenseFront ===
                                          "string"
                                            ? userDocumentData.licenseFront // Use server URL if it's a string
                                            : URL?.createObjectURL(
                                                userDocumentData?.licenseFront
                                              ) // Use createObjectURL for local file
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-primary"
                                      >
                                        View Document
                                      </a>
                                    </div>
                                  )}
                                </Form.Group>
                              </div>

                              {/* <hr className="my-3" /> */}

                              {/* <div className="row"> */}
                              <div className="col-lg-4 col-md-6 mt-3">
                                <p className="text-nowrap">
                                  {documentSet === "uae_resident"
                                    ? "UAE Driving License - Back"
                                    : documentSet === "non_resident_gcc"
                                    ? "GCC Driving License - Back"
                                    : "Home Country Driving License - Back"}
                                </p>
                                <Form.Group controlId="license-back">
                                  {/* <Form.Label className="defButton">
                                    Choose Back Image
                                  </Form.Label> */}
                                  <Form.Control
                                    type="file"
                                    name="licenseBack"
                                    onChange={handleUserDocumentDataChange}
                                    required
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please upload the back of your license.
                                  </Form.Control.Feedback>
                                  {userDocumentData?.licenseBack && (
                                    <div className="mt-2">
                                      <a
                                        href={
                                          typeof userDocumentData?.licenseBack ===
                                          "string"
                                            ? userDocumentData?.licenseBack // Use server URL if it's a string
                                            : URL?.createObjectURL(
                                                userDocumentData?.licenseBack
                                              ) // Use createObjectURL for local file
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-primary"
                                      >
                                        View Document
                                      </a>
                                    </div>
                                  )}
                                </Form.Group>
                              </div>
                            </div>

                            {/* </div> */}

                            <div className="row justify-content-center mt-3">
                              <div className="col-7 d-flex justify-content-center">
                                <button
                                  type="submit"
                                  className="defButton text-nowrap"
                                  // onClick={handleSubmitUserDocuments}
                                  disabled={loading_document}
                                >
                                  {loading_document ? (
                                    <Spinner />
                                  ) : (
                                    "Save Documents"
                                  )}
                                </button>
                              </div>
                            </div>
                          </Form>
                        </Accordion.Body>
                      </Accordion.Item>
                      {documentSet !== "non_resident_other" && (
                        <Accordion.Item eventKey="1.2" className="shadow mb-2">
                          <Accordion.Header>
                            {" "}
                            {documentSet === "uae_resident"
                              ? "Emirate ID"
                              : documentSet === "non_resident_gcc"
                              ? "GCC ID"
                              : ""}
                          </Accordion.Header>
                          <Accordion.Body>
                            {/* <hr className="my-3" /> */}
                            <Form
                              noValidate
                              validated={userDocumentValidated_2}
                              onSubmit={handleSubmitUserDocuments_2}
                            >
                              {documentSet === "non_resident_other" ? (
                                <></>
                              ) : (
                                <>
                                  <div className="row">
                                    <div className="col-lg-4 col-md-6 ">
                                      <Form.Group controlId="idno">
                                        <Form.Label className="text-nowrap">
                                          {documentSet === "uae_resident"
                                            ? "Emirate ID Number"
                                            : documentSet === "non_resident_gcc"
                                            ? "GCC ID Number"
                                            : "something"}
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="idno"
                                          placeholder="ID Number"
                                          value={userDocumentData?.idno}
                                          onChange={
                                            handleUserDocumentDataChange
                                          }
                                          required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          Please provide a ID number.
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-4 col-md-6 margin-small-screen">
                                      <Form.Group controlId="exp">
                                        <Form.Label>Expiry Date</Form.Label>
                                        <Form.Control
                                          type="date"
                                          name="exp"
                                          // placeholder="License Number"
                                          value={userDocumentData?.exp}
                                          onChange={
                                            handleUserDocumentDataChange
                                          }
                                          required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          Please provide expiry date.
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    </div>

                                    <div className="col-lg-4 col-md-6 ">
                                      <Form.Group controlId="gcc-id-front">
                                        <Form.Label>
                                          {documentSet === "uae_resident"
                                            ? "Emirate ID Front"
                                            : documentSet === "non_resident_gcc"
                                            ? "GCC ID Front"
                                            : "something"}
                                        </Form.Label>
                                        <Form.Control
                                          type="file"
                                          name="gccIdFront"
                                          onChange={
                                            handleUserDocumentDataChange
                                          }
                                          // required
                                          required={
                                            userDocumentData?.gccIdFront
                                              ? false
                                              : true
                                          }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          Please upload front of GCC ID.
                                        </Form.Control.Feedback>

                                        {userDocumentData?.gccIdFront && (
                                          <div className="mt-2">
                                            <a
                                              href={
                                                typeof userDocumentData.gccIdFront ===
                                                "string"
                                                  ? userDocumentData.gccIdFront // Use server URL if it's a string
                                                  : URL?.createObjectURL(
                                                      userDocumentData?.gccIdFront
                                                    ) // Use createObjectURL for local file
                                              }
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="text-primary"
                                            >
                                              View Document
                                            </a>
                                          </div>
                                        )}
                                      </Form.Group>
                                    </div>
                                    {/* <hr className="my-3" /> */}
                                  </div>

                                  {/* <div className="row">
                                  <div className="col-4">
                                    <p>
                                      {" "}
                                      {documentSet === "uae_resident"
                                        ? "Emirate ID Back"
                                        : documentSet === "non_resident_gcc"
                                        ? "GCC ID Back"
                                        : "something"}
                                    </p>

                                    <Form.Group controlId="gcc-id-back">
                                      <Form.Label className="defButton">
                                        Upload Now
                                      </Form.Label>
                                      <Form.Control
                                        type="file"
                                        name="gccIdBack"
                                        onChange={handleUserDocumentDataChange}
                                        required
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please upload back of GCC ID.
                                      </Form.Control.Feedback>

                                      {userDocumentData?.gccIdBack && (
                                        <div className="mt-2-">
                                          <a
                                            href={URL.createObjectURL(
                                              userDocumentData?.gccIdBack
                                            )}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-primary"
                                          >
                                            {" "}
                                            View Document
                                          </a>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </div>
                                </div> */}
                                </>
                              )}
                              {documentSet === "non_resident_other" ? null : (
                                <div className="row justify-content-center mt-3">
                                  <div className="col-7 d-flex justify-content-center">
                                    <button
                                      type="submit"
                                      className="defButton text-nowrap"
                                      // onClick={handleSubmitUserDocuments}
                                      disabled={loading_document}
                                    >
                                      {loading_document ? (
                                        <Spinner />
                                      ) : (
                                        "Save Documents"
                                      )}
                                    </button>
                                  </div>
                                </div>
                              )}
                            </Form>
                          </Accordion.Body>
                        </Accordion.Item>
                      )}
                      {documentSet !== "uae_resident" && (
                        <Accordion.Item eventKey="1.3" className="shadow mb-2">
                          <Accordion.Header> Passport</Accordion.Header>
                          <Accordion.Body>
                            {/* <hr className="my-3" /> */}

                            <Form
                              noValidate
                              validated={userDocumentValidated_3}
                              onSubmit={handleSubmitUserDocuments_3}
                            >
                              {documentSet === "uae_resident" ? (
                                <></>
                              ) : (
                                <div className="row">
                                  <div className="col-lg-4 col-md-6 ">
                                    <Form.Group controlId="passno">
                                      <Form.Label className="text-nowrap">
                                        Passport
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="passno"
                                        placeholder="Passport Number"
                                        value={userDocumentData?.passno}
                                        onChange={handleUserDocumentDataChange}
                                        required
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please provide passport number.
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </div>
                                  <div className="col-lg-4 col-md-6 margin-small-screen">
                                    <Form.Group controlId="passIssueDate">
                                      <Form.Label>Issue Date</Form.Label>
                                      <Form.Control
                                        type="date"
                                        name="passIssueDate"
                                        // placeholder="Issue Date"
                                        value={userDocumentData?.passIssueDate}
                                        onChange={handleUserDocumentDataChange}
                                        required
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please provide date.
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </div>
                                  <div className="col-lg-4 col-md-6 margin-small-screen">
                                    <Form.Group controlId="passExpiry">
                                      <Form.Label>Expiry Date</Form.Label>
                                      <Form.Control
                                        type="date"
                                        name="passExpiry"
                                        // placeholder="Issue Date"
                                        value={userDocumentData?.passExpiry}
                                        onChange={handleUserDocumentDataChange}
                                        required
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please provide date.
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </div>
                                  <div className="w-100"></div>
                                  <div className="col-4 mt-2">
                                    <Form.Group controlId="passport-front">
                                      <Form.Label>Passport Front</Form.Label>
                                      <Form.Control
                                        type="file"
                                        name="passportFront"
                                        onChange={handleUserDocumentDataChange}
                                        required
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please upload front of passport.
                                      </Form.Control.Feedback>

                                      {userDocumentData?.passportFront && (
                                        <div className="mt-2">
                                          <a
                                            href={
                                              typeof userDocumentData.passportFront ===
                                              "string"
                                                ? userDocumentData.passportFront // Use server URL if it's a string
                                                : URL?.createObjectURL(
                                                    userDocumentData?.passportFront
                                                  ) // Use createObjectURL for local file
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-primary"
                                          >
                                            View Document
                                          </a>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </div>

                                  {/* <div className="row">
                                    <div className="col-4">
                                      <p className="text-nowrap">
                                        Passport - Back Page
                                      </p>

                                      <Form.Group controlId="passport-back">
                                        <Form.Label className="defButton">
                                          Upload Now
                                        </Form.Label>
                                        <Form.Control
                                          type="file"
                                          name="passportBack"
                                          onChange={
                                            handleUserDocumentDataChange
                                          }
                                          required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          Please upload back of passport.
                                        </Form.Control.Feedback>

                                        {userDocumentData?.passportBack && (
                                          <div className="mt-2-">
                                            <a
                                              href={URL.createObjectURL(
                                                userDocumentData?.passportBack
                                              )}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="text-primary"
                                            >
                                              {" "}
                                              View Document
                                            </a>
                                          </div>
                                        )}
                                      </Form.Group>
                                    </div>
                                  </div> */}
                                </div>
                              )}
                              {documentSet === "uae_resident" ? null : (
                                <div className="row justify-content-center">
                                  <div className="col-7 d-flex justify-content-center">
                                    <button
                                      type="submit"
                                      className="defButton text-nowrap"
                                      // onClick={handleSubmitUserDocuments}
                                      disabled={loading_document}
                                    >
                                      {loading_document ? (
                                        <Spinner />
                                      ) : (
                                        "Save Documents"
                                      )}
                                    </button>
                                  </div>
                                </div>
                              )}
                            </Form>
                          </Accordion.Body>
                        </Accordion.Item>
                      )}

                      {/* <hr className="my-3" /> */}

                      {documentSet !== "uae_resident" &&
                        documentSet !== "non_resident_gcc" && (
                          <Accordion.Item
                            eventKey="1.4"
                            className="shadow mb-2"
                          >
                            <Accordion.Header>Entry Stamp</Accordion.Header>
                            <Accordion.Body>
                              <Form
                                noValidate
                                validated={userDocumentValidated_4}
                                onSubmit={handleSubmitUserDocuments_4}
                              >
                                {documentSet !== "uae_resident" && (
                                  <div className="row">
                                    <div className="col-4">
                                      <p className="text-nowrap">Entry Stamp</p>

                                      <Form.Group controlId="entry-stamp">
                                        {/* <Form.Label className="defButton">
                                          Upload Now
                                        </Form.Label> */}
                                        <Form.Control
                                          type="file"
                                          name="entryStamp"
                                          onChange={
                                            handleUserDocumentDataChange
                                          }
                                          required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          Please upload entry stamp.
                                        </Form.Control.Feedback>

                                        {userDocumentData?.entryStamp && (
                                          <div className="mt-2-">
                                            <a
                                              href={
                                                typeof userDocumentData.entryStamp ===
                                                "string"
                                                  ? userDocumentData.entryStamp // Use server URL if it's a string
                                                  : URL?.createObjectURL(
                                                      userDocumentData?.entryStamp
                                                    ) // Use createObjectURL for local file
                                              }
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="text-primary"
                                            >
                                              View Document
                                            </a>
                                          </div>
                                        )}
                                      </Form.Group>
                                    </div>
                                  </div>
                                )}

                                {documentSet !== "uae_resident" && (
                                  <div className="row justify-content-center">
                                    <div className="col-7 d-flex justify-content-center">
                                      <button
                                        type="submit"
                                        className="defButton text-nowrap"
                                        // onClick={handleSubmitUserDocuments}
                                        disabled={loading_document}
                                      >
                                        {loading_document ? (
                                          <Spinner />
                                        ) : (
                                          "Save Documents"
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </Form>
                            </Accordion.Body>
                          </Accordion.Item>
                        )}

                      {/* <hr className="my-3" /> */}

                      {documentSet !== "uae_resident" && (
                        <Accordion.Item eventKey="1.5" className="shadow mb-2">
                          <Accordion.Header>
                            {" "}
                            {documentSet === "uae_resident"
                              ? "Home Country Driving License - Front"
                              : documentSet === "non_resident_gcc"
                              ? "International Driving License/Permit"
                              : "International Driving License/Permit"}
                          </Accordion.Header>
                          <Accordion.Body>
                            <Form
                              noValidate
                              validated={userDocumentValidated_5}
                              onSubmit={handleSubmitUserDocuments_5}
                            >
                              <div className="row">
                                <div className="col-lg-4 col-md-6">
                                  <Form.Group controlId="idno">
                                    <Form.Label className="text-nowrap">
                                      {documentSet === "uae_resident"
                                        ? "Home Country Driving License - Front"
                                        : documentSet === "non_resident_gcc"
                                        ? "International Driving License/Permit"
                                        : "International Driving License/Permit"}
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="intlicno"
                                      placeholder="License Number"
                                      value={userDocumentData?.intlicno}
                                      onChange={handleUserDocumentDataChange}
                                      required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Please provide number.
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </div>
                                <div className="col-lg-4 col-md-6 margin-small-screen">
                                  <Form.Group controlId="idno">
                                    <Form.Label>Issue Date</Form.Label>
                                    <Form.Control
                                      type="date"
                                      name="intIssueDate"
                                      value={userDocumentData?.intIssueDate}
                                      onChange={handleUserDocumentDataChange}
                                      required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Please provide date.
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </div>
                                <div className="col-lg-4 col-md-6 margin-small-screen">
                                  <Form.Group controlId="idno">
                                    <Form.Label>Expiry Date</Form.Label>
                                    <Form.Control
                                      type="date"
                                      name="intExpiry"
                                      value={userDocumentData?.intExpiry}
                                      onChange={handleUserDocumentDataChange}
                                      required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Please provide date.
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </div>
                                <div className="w-100"></div>
                                <div className="col-4 mt-2">
                                  <Form.Group controlId="permit">
                                    <Form.Label>
                                      {documentSet === "uae_resident"
                                        ? "Home Country Driving License - Front"
                                        : documentSet === "non_resident_gcc"
                                        ? "International Driving License/Permit - Front"
                                        : "International Driving License/Permit - Front"}
                                    </Form.Label>
                                    <Form.Control
                                      type="file"
                                      name="permit"
                                      onChange={handleUserDocumentDataChange}
                                      required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Please upload permit.
                                    </Form.Control.Feedback>

                                    {userDocumentData?.permit && (
                                      <div className="mt-2">
                                        <a
                                          href={
                                            typeof userDocumentData.permit ===
                                            "string"
                                              ? userDocumentData.permit // Use server URL if it's a string
                                              : URL?.createObjectURL(
                                                  userDocumentData?.permit
                                                ) // Use createObjectURL for local file
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="text-primary"
                                        >
                                          View Document
                                        </a>
                                      </div>
                                    )}
                                  </Form.Group>
                                </div>
                              </div>

                              {documentSet === "uae_resident" && (
                                <div className="row">
                                  <div className="col-4">
                                    <p className="text-nowrap">
                                      Home Country Driving License - Back
                                    </p>

                                    <Form.Group controlId="entry-stamp">
                                      {/* <Form.Label className="defButton">
                                        Upload Now
                                      </Form.Label> */}
                                      <Form.Control
                                        type="file"
                                        name="entryStamp"
                                        onChange={handleUserDocumentDataChange}
                                        required
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please upload driving license.
                                      </Form.Control.Feedback>

                                      {userDocumentData?.entryStamp && (
                                        <div className="mt-2">
                                          <a
                                            href={URL.createObjectURL(
                                              userDocumentData?.entryStamp
                                            )}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-primary"
                                          >
                                            {" "}
                                            View Document
                                          </a>
                                        </div>
                                      )}
                                    </Form.Group>
                                  </div>
                                </div>
                              )}

                              <div className="row justify-content-center">
                                <div className="col-7 d-flex justify-content-center">
                                  <button
                                    type="submit"
                                    className="defButton text-nowrap"
                                    // onClick={handleSubmitUserDocuments}
                                    disabled={loading_document}
                                  >
                                    {loading_document ? (
                                      <Spinner />
                                    ) : (
                                      "Save Documents"
                                    )}
                                  </button>
                                </div>
                              </div>
                            </Form>
                          </Accordion.Body>
                        </Accordion.Item>
                      )}
                      {/* <hr className="my-3" /> */}

                      {documentSet !== "uae_resident" &&
                        documentSet !== "non_resident_gcc" && (
                          <Accordion.Item
                            eventKey="1.6"
                            className="shadow mb-2"
                          >
                            <Accordion.Header>
                              {" "}
                              Translation of DL
                            </Accordion.Header>
                            <Accordion.Body>
                              <Form
                                noValidate
                                validated={userDocumentValidated_6}
                                onSubmit={handleSubmitUserDocuments_6}
                              >
                                {/* ////////////////////////////////////////////////////////////////////// */}
                                {documentSet === "non_resident_other" && (
                                  <>
                                    <div className="row">
                                      <div className="col-lg-4 col-md-6">
                                        <Form.Group controlId="translation_dl_no">
                                          <Form.Label>
                                            Translation of DL Number
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            name="translation_dl_no"
                                            placeholder="License Number"
                                            value={
                                              userDocumentData?.translation_dl_no
                                            }
                                            onChange={
                                              handleUserDocumentDataChange
                                            }
                                            required
                                          />
                                          <Form.Control.Feedback type="invalid">
                                            Please provide number.
                                          </Form.Control.Feedback>
                                        </Form.Group>
                                      </div>
                                      {/* <div className="w-100"></div> */}
                                      <div className="col-4">
                                        <Form.Group controlId="translation_dl">
                                          <Form.Label>
                                            Translation of DL Image
                                          </Form.Label>
                                          <Form.Control
                                            type="file"
                                            name="translation_dl"
                                            onChange={
                                              handleUserDocumentDataChange
                                            }
                                            required
                                          />
                                          <Form.Control.Feedback type="invalid">
                                            Please upload translation of DL.
                                          </Form.Control.Feedback>

                                          {userDocumentData?.translation_dl && (
                                            <div className="mt-2">
                                              <a
                                                href={
                                                  typeof userDocumentData.translation_dl ===
                                                  "string"
                                                    ? userDocumentData.translation_dl // Use server URL if it's a string
                                                    : URL?.createObjectURL(
                                                        userDocumentData?.translation_dl
                                                      ) // Use createObjectURL for local file
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-primary"
                                              >
                                                View Document
                                              </a>
                                            </div>
                                          )}
                                        </Form.Group>
                                      </div>
                                    </div>{" "}
                                  </>
                                )}
                                {documentSet === "non_resident_other" && (
                                  <div className="row justify-content-center mt-3">
                                    <div className="col-7 d-flex justify-content-center">
                                      <button
                                        type="submit"
                                        className="defButton text-nowrap"
                                        // onClick={handleSubmitUserDocuments}
                                        disabled={loading_document}
                                      >
                                        {loading_document ? (
                                          <Spinner />
                                        ) : (
                                          "Save Documents"
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </Form>
                            </Accordion.Body>
                          </Accordion.Item>
                        )}
                      {/* <hr className="my-3" /> */}

                      {documentSet !== "uae_resident" &&
                        documentSet !== "non_resident_gcc" && (
                          <Accordion.Item
                            eventKey="1.7"
                            className="shadow mb-2"
                          >
                            <Accordion.Header> Tourist Visa</Accordion.Header>
                            <Accordion.Body>
                              <Form
                                noValidate
                                validated={userDocumentValidated_7}
                                onSubmit={handleSubmitUserDocuments_7}
                              >
                                {documentSet === "non_resident_other" && (
                                  <>
                                    <div className="row">
                                      <div className="col-4">
                                        <p>Tourist Visa</p>

                                        <Form.Group controlId="touristVisa">
                                          {/* <Form.Label className="defButton">
                                            Upload Now
                                          </Form.Label> */}
                                          <Form.Control
                                            type="file"
                                            name="touristVisa"
                                            onChange={
                                              handleUserDocumentDataChange
                                            }
                                            // required={userDocumentData?.touristVisa ? false : true}
                                            required
                                          />
                                          <Form.Control.Feedback type="invalid">
                                            Please upload tourist visa.
                                          </Form.Control.Feedback>
                                          {userDocumentData?.touristVisa && (
                                            <div className="mt-2-">
                                              <a
                                                href={
                                                  typeof userDocumentData.touristVisa ===
                                                  "string"
                                                    ? userDocumentData.touristVisa // Use server URL if it's a string
                                                    : URL?.createObjectURL(
                                                        userDocumentData?.touristVisa
                                                      ) // Use createObjectURL for local file
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-primary"
                                              >
                                                View Document
                                              </a>
                                            </div>
                                          )}
                                        </Form.Group>
                                      </div>
                                    </div>
                                  </>
                                )}

                                {documentSet === "non_resident_other" && (
                                  <div className="row justify-content-center">
                                    <div className="col-7 d-flex justify-content-center">
                                      <button
                                        type="submit"
                                        className="defButton text-nowrap"
                                        // onClick={handleSubmitUserDocuments}
                                        disabled={loading_document}
                                      >
                                        {loading_document ? (
                                          <Spinner />
                                        ) : (
                                          "Save Documents"
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </Form>
                            </Accordion.Body>
                          </Accordion.Item>
                        )}
                    </Accordion>
                    {/* <hr className="my-3" /> */}
                    <Form>
                      {/* //////////////////////////////////////////////////////////////////////////////////////// */}
                    </Form>
                  </div>
                </Tab.Pane>
              </Tab.Content>

              <Tab.Content>
                <Tab.Pane eventKey="third">
                  <AdditionalDriver
                    countries={countries}
                    emiratesArray={emiratesArray}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </Helmet>
    </>
  );
};

export default MyAccount;

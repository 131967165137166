import React, { useEffect, useState } from "react";
import './chauffeurServices.css'
import CommonSection from "../CommonSection";
import carlogo from "../../../assets/new-logo/logo.png";
import {  Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import configWeb from "../../../config.js/configWeb";
import { simpleGetCall } from "../../../config.js/SetUp";
import { Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import MetaHelmet from "../../Helmet/MetaHelmet";

const ChauffeurServices = () => { useEffect(() => {
  window.scrollTo(0, 0);
}, []);
const language = useSelector((state) => state.language.language);
const { t, i18n } = useTranslation();

const [corporateLeasing, setCorporateLeasing] = useState([]);
const [loading, setLoading] = useState(true);
const getCorporateLeasing = () => {
  const url = `${configWeb.GET_PRIVACY_POLICY(
    "chauffeur_service"
  )}?lang=${language}`;
  simpleGetCall(url)
    .then((res) => {
      if (!res?.error) {
        setCorporateLeasing(res || []); // Ensure it's always an array
      }
    })
    .catch((error) => {
      console.log("Special offers API failed-->", error);
    })
    .finally(() => {
      setLoading(false);
    });
};
useEffect(() => {
  getCorporateLeasing();
}, [language]);

const generateStructuredData = () => {
  return {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": window.location.href,
    },
    headline: corporateLeasing?.title,
    description: corporateLeasing?.seo_meta_description,
    image: corporateLeasing?.image,
    author: {
      "@type": "Organization",
      name: "Autostrad",
    },
    publisher: {
      "@type": "Organization",
      name: "Autostrad",
      logo: {
        "@type": "ImageObject",
        url: carlogo,
      },
    },
    datePublished: "2024-10-11",
    dateModified: "2024-10-12",
  };
};

  return (
    <div>
    {loading ? (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh", width: "100vw" }}
      >
        <Spinner />
      </div>
    ) : (
      <>
        <CommonSection title="CHAUFFEUR SERVICES" />
        <MetaHelmet
          title={corporateLeasing?.title}
          description={corporateLeasing?.seo_meta_description}
          keywords={
            corporateLeasing?.seo_meta_tags ||
            "car rental, affordable cars, rent a car"
          }
          ogTitle={corporateLeasing?.title}
          ogDescription={corporateLeasing?.seo_meta_description}
          ogImage={corporateLeasing?.image}
          ogUrl={window.location.href}
          twitterTitle={corporateLeasing?.title}
          twitterDescription={corporateLeasing?.seo_meta_description}
          twitterImage={corporateLeasing?.image}
          twitterCard="summary_large_image"
          // twitterSite="@YourTwitterHandle"
          // twitterCreator="@AuthorTwitterHandle"
          canonicalUrl={`${configWeb.BASE_WEB_URL}/en/chauffeur-services`}
          hreflangs={[
            {
              hreflang: "en",
              href: `${configWeb.BASE_WEB_URL}/en/chauffeur-services`,
            },
            {
              hreflang: "ar",
              href: `${configWeb.BASE_WEB_URL}/ae/chauffeur-services`,
            },
          ]}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(generateStructuredData()),
          }}
        />
        <section className="p-2-">
          <Container>
   {/* <section class='main-section-chauffeur' >
    <div class='max-width-wrapper-chauffeur'>
    <div class= 'headline-chauffeur'>
<h2>Chauffeur Drive Services by Autostrad Rent a Car</h2>
    </div>
    <section class='img-txt-grid-chauffeur'>
       <div class='txt-chauffeur'>
       Enhance your travel experience with our premium Chauffeur Drive Services, provided in collaboration with our partners. We provide a blend of luxury, comfort, and professionalism, ensuring your journey is as enjoyable as your destination. 
       </div>
       <div class='main-img-div-chauffeur'>
         <img class='main-img-chauffeur'  src='https://files.staging.autostrad.com/admin/pages/chauffeur_service/main.jpg' alt="main-image"  />
       </div>
    </section>
    <section class='our-service-section-chauffeur'>
    <h2>Our Services Include: </h2>
<ul class='services-list-chauffeur'>
  <li>
  <strong>Airport Transfers:</strong> Seamless and punctual transfers to and from all UAE airports, with real-time flight tracking to accommodate any schedule changes. 

  </li>
  <li>
  <strong>Corporate Transportation:</strong> Tailored solutions for business meetings, corporate events, and client hospitality, ensuring you and your guests arrive in style. 

  </li>
  <li>
  <strong>Event Transportation:</strong> Reliable and elegant transport for weddings, proms, and special occasions, making your events memorable. 
 </li>
 <li>
  <strong>City Tours:</strong> Explore the UAE's iconic landmarks with our knowledgeable chauffeurs, offering personalized sightseeing experiences. 

  </li>
</ul>



    </section>

    </div>

    <section class='our-luxry-fleet-includes-chauffeur'>
     <div class='our-luxry-fleet-headline-chauffeur'>
      <h2>
      Our Luxury Fleet Includes: 
      </h2>
      </div>
      <div class='car-img-grids-chauffeur'>
      <div class="card-chauffeur">
        <img class='car-img-v2-chauffeur' src="https://files.staging.autostrad.com/admin/pages/chauffeur_service/car.avif" alt="Car Image"/>
        <h2 class="car-name-v2-chauffeur">Lexus ES300h</h2>
        <p class="car-description-chauffeur">Elegance meets eco-efficiency.</p>
        <a href='https://staging.autostrad.com/en/enquire-chauffeur-services?value=Genesis%20G80' >
        
        <button class="enquire-btn-chauffeur">Enquire Now</button>
        </a>

      
    </div>
      <div class="card-chauffeur">
        <img class='car-img-v2-chauffeur' src="https://files.staging.autostrad.com/admin/pages/chauffeur_service/car.avif" alt="Car Image"/>
        <h2 class="car-name-v2-chauffeur">Genesis G80</h2>
        <p class="car-description-chauffeur">Refined interiors with advanced safety.</p>
        <a href='https://staging.autostrad.com/en/enquire-chauffeur-services?value=Genesis%20G80'>
        <button class="enquire-btn-chauffeur">Enquire Now</button>
        </a>
    </div>
      <div class="card-chauffeur">
        <img class='car-img-v2-chauffeur' src="https://files.staging.autostrad.com/admin/pages/chauffeur_service/car.avif" alt="Car Image"/>
        <h2 class="car-name-v2-chauffeur">BMW 730i</h2>
        <p class="car-description-chauffeur">Ultimate prestige and comfort.</p>
        <a href='https://staging.autostrad.com/en/enquire-chauffeur-services?value=BMW%20730i'>
        <button class="enquire-btn-chauffeur">Enquire Now</button>
        </a>
    </div>
      <div class="card-chauffeur">
        <img class='car-img-v2-chauffeur' src="https://files.staging.autostrad.com/admin/pages/chauffeur_service/car.avif" alt="Car Image"/>
        <h2 class="car-name-v2-chauffeur">Exeed (New Addition)</h2>
        <p class="car-description-chauffeur">Modern luxury with cutting-edge features.</p>
        <a href='https://staging.autostrad.com/en/enquire-chauffeur-services?value=Exeed%20(New Addition)'>
        <button class="enquire-btn-chauffeur">Enquire Now</button>
        </a>
    </div>
      <div class="card-chauffeur">
        <img class='car-img-v2-chauffeur' src="https://files.staging.autostrad.com/admin/pages/chauffeur_service/car.avif" alt="Car Image"/>
        <h2 class="car-name-v2-chauffeur">Highlander & Citroën (7-Seaters)</h2>
        <p class="car-description-chauffeur">Spacious options for group travel.
</p>
<a href='https://staging.autostrad.com/en/enquire-chauffeur-services?value=Highlander%20%26%20Citroën%20(7-Seaters)'>
        <button class="enquire-btn-chauffeur">Enquire Now</button>
        </a>
    </div>


      </div>
    
    </section>

    <div class='max-width-wrapper-chauffeur'>

    <section class='our-service-section-chauffeur'>
    <h2>Why Choose Us: </h2>
<ul class='services-list-chauffeur'>
  <li>
  <strong> Professional Chauffeurs –</strong> Trained for safety, discretion, and excellence.

  </li>
  <li>
  <strong>Corporate & VIP Travel –</strong> Tailored solutions for executive meetings, events, and client transfers.

  </li>
  <li>
  <strong>Luxury on Every Journey – </strong> Premium amenities, including climate control and entertainment systems. 
 
 </li>
 <li>
  <strong>Efficiency & Convenience –</strong> Time-saving travel with flexible billing options. 

  </li>
  

</ul>
<a href='https://staging.autostrad.com/en/enquire-chauffeur-services'>
<button class="enquire-btn-chauffeur margin-bottom">Enquire Now</button>
</a>

<p>Experience the pinnacle of chauffeur-driven luxury with Autostrad. Contact us at 050 899 2579 to book your next journey. </p>


    </section>
    </div>
  
   </section> */}

   <section className='px-1- px-lg-5-'
              dangerouslySetInnerHTML={{
                __html: corporateLeasing?.content,
              }}
            />
            </Container>
          </section>
        </>
      )}
    </div>
  );
  
}

export default ChauffeurServices
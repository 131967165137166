import React from "react";

import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/footer.css";
import { useSelector } from "react-redux";



const Footer = () => {
const language = useSelector((state) => state.language.language);
const isLoginFromRegister = useSelector((state) => state.isLoginFromRegister.isLoginFromRegister);

  const date = new Date();
  const year = date.getFullYear();

  const quickLinks = [
    {
      path: `/${language}/our-services`,
      display: "Our Services",
    },
    {
      path: `/${language}/chauffeur-services`,
      display: "Chauffeur Services",
    },
    {
      path: `/${language}/about`,
      display: "About the Company",
    },
  
    {
      path: `/${language}/awards-and-accreditations`,
      display: "Awards and Recognition",
    },
  
    // {
    //   path: "",
    //   display: "Corporate Responsibility",
    // },
    {
      path: `/${language}/CareersPage`,
      display: "Careers",
    },
  ];
  
  const accountLinks = [
    {
      path: 
        (localStorage.getItem("token") && !isLoginFromRegister)
          ? `/${language}/MyAccount`
          : `/${language}/Login`
      ,
      display: "My Account",
    },
  
    {
      // path: `/${language}/MyAccount`,
      display: "Bookings",
    },
  
    // {
    //   path: "",
    //   display: "Invoices",
    // },
    // {
    //   path: "",
    //   display: "Autostrad Rewards",
    // },
  ];
  
  const leasingLinks = [
    {
      path: `/${language}/Contact`,
      display: "Request a Quote",
    },
  
    {
      path: `/${language}/corporate-leasing`,
      display: "Corporate Leasing",
    },
  
    {
      path: `/${language}/CommercialVehicles`,
      display: "Buses and Commercial",
    },
  ];
  
  const blogsLinks = [
    {
      // path: "/Allblog",
      path: `/${language}/blogs`,
      display: "All Blogs",
    },
  
    {
      path: `/${language}/AboutUae`,
      display: "About UAE",
    },
  
    {
      path: `/${language}/OtherBlog`,
      display: "Other Blogs",
    },
    {
      path: "",
      display: "News & Events",
    },
  ];
  const helpsLinks = [
    {
      path: `/${language}/FqaPage`,
      display: "FAQ",
    },
  
    {
      path: `/${language}/Lostandfound`,
      display: "Lost and Found",
    },
  
    {
      path: `/${language}/ContectUsPage`,
      display: "Contact Us",
    },
    {
      path: `/${language}/customer-feedback`,
      display: "Customer Feedback",
    },
    {
      path: `/${language}/TermsCondition`,
      display: "Terms & Conditions",
    },
    {
      path: `/${language}/PrivacyPolicy`,
      display: "Privacy Policy",
    },
    // {
    //   path: "#",
    //   display: "Video/Photo Gallery",
    // },
  ];
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="2" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title">ABOUT US</h5>
              {/* <ListGroup>
                {quickLinks.map((item, index) => (
                  <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                    <Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))}
              </ListGroup> */}
              <ListGroup>
                {quickLinks.map((item, index) => (
                  <ListGroupItem
                    key={index}
                    className="p-0 mt-3 quick__link"
                    style={{ position: "relative" }} // Added relative positioning for badge placement
                  >
                    <Link to={item.path} className="footer-badge">
                      {item.display}
                      {item.display === "Corporate Responsibility" && (
                        <span className="displaycomment badge">
                          coming soon
                        </span>
                      )}
                    </Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>
          <Col lg="2" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title">MY ACCOUNT</h5>
              <ListGroup>
                {accountLinks.map((item, index) => (
                  <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                    <Link
                      to={
                        (localStorage.getItem("token") && !isLoginFromRegister)  ?`/${language}/MyAccount` :  `/${language}/Login`
                      }
                      state={{
                        tab_type:
                          item.display === "Bookings" ? "bookings" : "first",
                      }}
                      className="footer-badge"
                    >
                      {item.display}

                      {/* {item.display === "Autostrad Rewards" && (
                        <span className="displaycomment badge">
                          coming soon
                        </span>
                      )}
                      {item.display === "Invoices" && (
                        <span className="displaycomment badge">
                          coming soon
                        </span>
                      )} */}
                    </Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>
          <Col lg="2" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title">LEASING</h5>
              <ListGroup>
                {leasingLinks.map((item, index) => (
                  <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                    <Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>
          <Col lg="2" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title">BLOGS</h5>
              <ListGroup>
                {blogsLinks.map((item, index) => (
                  <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                    <Link to={item.path} className="footer-badge">
                      {item.display}
                      {item.display === "News & Events" && (
                        <span className="displaycomment badge">
                          coming soon
                        </span>
                      )}
                    </Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>
          <Col lg="2" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title">HELP AND MORE</h5>
              <ListGroup>
                {helpsLinks.map((item, index) => (
                  <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                    <Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>
          <Col lg="2" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title mb-4">Head Office</h5>
              <p className="office__info">Abu Dhabi, UAE</p>
              <p className="office__info">Phone: +971 2 815 2652</p>
              <p className="office__info">Toll-Free: 800 2772</p>

              <p className="office__info">Booking Assistance: <a href="mailto:reservations@autostrad.com">reservations@autostrad.com</a></p>
              <p className="office__info"> </p>

              <p className="office__info">Office Time: 08:00 AM - 05:30 PM</p>
            </div>
          </Col>

          {/* <Col
            lg="12"
            md="4"
            sm="12"
            className="d-flex align-items-center justify-content-center"
          >
            <div className="mb-4">
              <h5 className="footer__link-title">Newsletter</h5>
              <p className="section__description">Subscribe our newsletter</p>
              <div className="newsletter">
                <input type="email" placeholder="Email" />
                <span>
                  <i className="ri-send-plane-line"></i>
                </span>
              </div>
            </div>
          </Col> */}

          <Col lg="12">
            <div className="footer__bottom">
              <p className="section__description-1 d-flex align-items-center justify-content-center gap-1 pt-4">
                <i className="ri-copyright-line"></i>
                Copyright © 2023 All Rights Reserved By Autostrad Rent a Car
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;

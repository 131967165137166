import React, { Fragment, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Routers from "../../routers/Routers";
import "../../styles/stickybar.css";
import "../../styles/ourFleet.css";

const Layout = () => {
  const [isCollapse, setIsCollapse] = useState(false);

  return (
    <Fragment>
      <Header />
      <div className="overflow-x-hidden">
        <Routers />
        <div className={isCollapse ? "d-none" : "sticky-media-bar"}>
          <div className="social-share-container --chatbot-sticky-bar-- d-none">
            <div
              className="icons share icon-canter social-share-side"
              onClick={() => {
                setIsCollapse(true);
              }}
            >
              <i className="fas fa-share social-share-side"></i>{" "}
            </div>
          </div>
        </div>
        <div
          className={
            isCollapse ? "sticky-media-bar d-block" : " sticky-media-bar d-none"
          }
        >
          <div className="social-container">
            <div
              className="icons share icon-canter bg-secondary p-sm-0  social-share-side"
              onClick={() => {
                setIsCollapse(false);
              }}
            >
              <i className="bi-x"></i>{" "}
            </div>
            <div className="icons twitter icon-canter">
              {/* <a href="" aria-label="Twitter "> */}
                <i className="fab fa-twitter"></i>
              {/* </a> */}
            </div>
            <div className="icons github  icon-canter">
              {/* <a className href="" aria-label=""> */}
                <i className="fab fa-github "></i>
              {/* </a> */}
            </div>
            <div className="icons linkedin  icon-canter">
              {/* <a href="" aria-label="Linkedin -"> */}
                <i className="fab fa-linkedin-in "></i>
              {/* </a> */}
            </div>
            <div className="icons facebook icon-canter">
              {/* <a href="" aria-label="Facebook - "> */}
                <i className="fab fa-facebook"></i>
              {/* </a> */}
            </div>
            <div className="icons reddit icon-canter">
              {/* <a href="" aria-label="Reddit - "> */}
                <i className="fab fa-reddit  "></i>
              {/* </a> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Layout;

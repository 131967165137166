import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, FormGroup, Input } from "reactstrap";

import { useParams } from "react-router-dom";
import blogData from "../assets/data/blogData.js";
import Helmet from "../components/Helmet/Helmet";
import { Link } from "react-router-dom";
import carlogo from "../assets/new-logo/logo.png";
import commentImg from "../assets/all-images/ava-1.jpg";

import "../styles/blog-details.css";
import configWeb from "../config.js/configWeb.js";
import { simpleGetCall } from "../config.js/SetUp.js";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import MetaHelmet from "../components/Helmet/MetaHelmet.js";

const BlogDetails = () => {
  const { slug, id } = useParams(); 
  const language = useSelector((state) => state.language.language);
  const [blog_details, set_blog_details] = useState([]);
  const [loading, set_loading] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getBlogDetails = () => {
    const url = `${configWeb.GET_BLOG_DETAILS(id)}?lang=${language}`;

    simpleGetCall(url)
      .then((res) => {
        if (!res?.error) {
          set_blog_details(res);
        }
      })
      .catch((error) => {
        console.error("Banner failed:", error);
      })
      .finally(() => {
        set_loading(false);
      });
  };
  useEffect(() => {
    getBlogDetails();
  }, [language]);
  function formatDate(isoString) {
    const date = new Date(isoString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options)?.format(date);
  }

  // Generate structured data dynamically based on blog details
  const generateStructuredData = () => {
    return {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": window.location.href
      },
      "headline": blog_details?.title || "",
      "image": blog_details?.image || "", // URL to image
      "author": {
        "@type": "Person",
        "name": blog_details?.author || "Unknown Author"
      },
      "publisher": {
        "@type": "Organization",
        "name": "AutoStrad",
        "logo": {
          "@type": "ImageObject",
          "url": /* carlogo */ "https://www.autostrad.com/assets/images/logo.png"
        }
      },
      "dateModified": "2024-10-23T18:30:00.000Z",
      "datePublished": blog_details?.publish_date || formatDate(new Date()), // Default to today's date if not available
      "description": blog_details?.seo_meta_description || blog_details?.description?.slice(0, 500) 
         };
  };
// Slugify function to convert blog titles into URL-friendly strings
const slugify = (text) => {
  return text
    ?.toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word characters
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
};

// Generate breadcrumb structured data dynamically based on blog details
const generateBreadcrumbsStructuredData = () => {
  return {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": `${configWeb.BASE_WEB_URL}`
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Blogs",
        "item": `${configWeb.BASE_WEB_URL}blogs`
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": blog_details?.title || "Blog Details",
        "item": `${configWeb.BASE_WEB_URL}blogs/${slugify(blog_details?.title)}-${id}`
      }
    ]
  };
};
  
  return (
    // <Helmet title={blog_details?.title}>

<>

{loading ?   <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh", width: "100vw" }}
        >
          {" "}
          <Spinner />
        </div> : 
      <section className="">

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(generateStructuredData()),
        }}
      />
        {/* Structured data for BreadcrumbList */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(generateBreadcrumbsStructuredData()),
          }}
        />
      <MetaHelmet
        title={blog_details?.title}
        description={blog_details?.seo_meta_description || blog_details?.description?.slice(0, 500) }
        keywords={blog_details?.seo_meta_tags || "car rental, affordable cars, rent a car"}
        ogTitle={blog_details?.title} /* "For Those Who Want A Taste Of Adventure" */
        ogDescription={blog_details?.seo_meta_description ||"This is very good blog" } 
        ogImage={blog_details?.image || "https://images.unsplash.com/photo-1533473359331-0135ef1b58bf?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} /* "https://files.staging.autostrad.com/admin/blog/eaee08a6f7bbc001cecbfe6148a8d476.webp" */
        ogUrl= {window.location.href} /* "https://staging.autostrad.com/en/blogs/for-those-who-want-a-taste-of-adventure-1" */
        twitterTitle={blog_details?.title}
        twitterDescription={blog_details?.seo_meta_description || blog_details?.description?.slice(0, 500) }
        twitterImage={blog_details?.image}
        twitterCard="summary_large_image"
        // twitterSite="@YourTwitterHandle"
        // twitterCreator="@AuthorTwitterHandle"
        canonicalUrl = {`${configWeb.BASE_WEB_URL}/en/blogs/${slugify(blog_details?.title)}-${id}`}
        hreflangs={[
          { hreflang: "en", href: `${configWeb.BASE_WEB_URL}/en/blogs/${slugify(blog_details?.title)}-${id}` },
          { hreflang: "ar", href: `${configWeb.BASE_WEB_URL}/ae/blogs/${slugify(blog_details?.title)}-${id}` },
        ]}

      />

        <Container>
          <Row>
            <Col lg="12" md="12">
              <div className="blog__details">
                <img src={blog_details?.image} alt="" className="w-100" />
<div className="blog-padding">
                <h1 className="section__title mt-4">{blog_details?.title}</h1>

                <div className="blog__publisher d-flex align-items-center gap-4 mb-4">
                  <span className="blog__author">
                    <i class="ri-user-line"></i>By {blog_details?.author}
                  </span>

                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i class="ri-calendar-line"></i> {/* {blog_details?.created_at ? formatDate(blog_details?.created_at) : ''} */}Published Date : {blog_details?.publish_date}
                  </span>

                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i class="ri-time-line"></i> {blog_details?.time}
                  </span>
                </div>

            
                <div 
                
                  dangerouslySetInnerHTML={{
                    __html: blog_details?.description,
                  }}
                />
               </div>
              </div>
            </Col>

            {/* <Col lg="4" md="4">
              <div className="recent__post mb-4">
                <h5 className=" fw-bold">Recent Posts</h5>
              </div>
              {blogData?.map((item) => (
                <div className="recent__blog-post mb-4" key={item.id}>
                  <div className="recent__blog-item d-flex gap-3">
                    <img src={item.imgUrl} alt="" className="w-25 rounded-2" />
                    <h6>
                      <Link to={`/blogs/${item.title}`}>{blog?.title}</Link>
                    </h6>
                  </div>
                </div>
              ))}
            </Col> */}
          </Row>
        </Container>
    
      </section>
         
          }   
     </>
    // </Helmet>
  );
  
};



export default BlogDetails;

// reducers/stepperSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedDeliveryEmirate: null, // Initial state value
  };

  const selectedDeliveryEmirateSlice = createSlice({
    name: 'selectedDeliveryEmirate',
    initialState: initialState,
    reducers: {
        setSelectedDeliveryEmirate: (state, action) => {
        state.selectedDeliveryEmirate = action.payload;
      },
    },
  });

  export const { setSelectedDeliveryEmirate } = selectedDeliveryEmirateSlice.actions;
export default selectedDeliveryEmirateSlice.reducer;
import React, { useContext, useEffect, useState } from "react";
import "../../styles/contact.css";
import CommonSection from "./CommonSection";
import { Col, Container } from "react-bootstrap";
import Location from "./Location";
import { useTranslation } from "react-i18next";
import FindCarForm2 from "./FindCarForm2";
import { simpleGetCall } from "../../config.js/SetUp";
import configWeb from "../../config.js/configWeb";
import { useSelector } from "react-redux";
import MetaHelmet from "../Helmet/MetaHelmet";
import { AppContext } from "../../context/AppContext";
const ContectUserPage = () => {
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.language.language);
  const {bookingTypeGlobal} = useContext(AppContext);
  const [emiratesArray, setEmiratesArray] = useState([]);
  const emiratesData = () => {
    const url = `${configWeb.GET_EMIRATES}?lang=${language}`;
    simpleGetCall(url)
      .then((res) => {
        setEmiratesArray(res);
      })
      .catch((errr) => {
        console.log("errr", errr);
      })
      .finally(() => {
        // set_loading(false);
      });
  };
  useEffect(() => {
    emiratesData();
  }, [language]);
  return (
    <>

<MetaHelmet
        title="Contact Us"
        description=""
        keywords="car rental, affordable cars, rent a car"
        canonicalUrl ={`${configWeb.BASE_WEB_URL}/en/ContectUsPage`}
        hreflangs={[
          { hreflang: "en", href: `${configWeb.BASE_WEB_URL}/en/ContectUsPage` },
          { hreflang: "ar", href: `${configWeb.BASE_WEB_URL}/ae/ContectUsPage` },
        ]}

        />
      <section className="pb-1 form-section" /* ref={parentRef} */>
        <h1
          className="pt-2 m-0 text-center text-light booking__tital-dream"
          style={{ fontFamily: "Rubik" }}
        >
          {bookingTypeGlobal ==="daily" ? t("BOOK YOUR DREAM CAR NOW") : t("FREE DELIVERY ON ALL MONTHLY RENTALS")}!
        </h1>
        <Col id="booking-form-container" lg="12" md="12" sm="12">
          <Container>
            <FindCarForm2 emiratesArray={emiratesArray} />
          </Container>
        </Col>
      </section>

      <CommonSection title="CONTACT US" />
      <section className="p-0">
        <Container>
          <div>
            <div className="inner_section_main">
              <div className="contact_us_inner padding-70">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                      <div className="contact_left_section">
                        <div className="contact-dddd">
                          <h3 className="title_serach">Autostrad Rent A Car</h3>

                          <h4 class="company-title">
                            Autostrad International Vehicle Leasing Sole
                            Proprietorship LLC.
                          </h4>
                          <p class="company-address-tel">
                            PO BOX 8279 Abu Dhabi, United Arab Emirates
                            <br />
                            Tel:{" "}
                            <a class="paragraph_text" href="tel:+971 28152700">
                              +971 2 815 2652
                            </a>
                          </p>
                          <h4 class="company-title">
                            Autostrad International Vehicle Leasing LLC.
                          </h4>
                          <p class="company-address-tel">
                            PO BOX 449788 Dubai, United Arab Emirates
                            <br />
                            Tel:{" "}
                            <a class="paragraph_text" href="tel:+971 4 3387171">
                              +971 4 3387171
                            </a>
                          </p>
                          <h5 class="company-title">
                            Toll Free:{" "}
                            <a class="paragraph_text" href="tel:800 2772">
                              800 2772
                            </a>
                          </h5>
                          <h5 class="company-title">EMAIL:</h5>
                          <p class="company-address-email">
                            <a href="mailto:info@autostrad.com">
                              info@autostrad.com
                            </a>
                          </p>
                          <p class="company-address-email">
                            <a
                              href="https://www.autostrad.com/"
                              target="_blank"
                            >
                              <img
                                src="https://www.autostrad.com/assets/images/icon/1.png"
                                alt=""
                                className="img-fluid"
                              />{" "}
                              www.autostrad.com
                            </a>
                          </p>
                          <p class="company-address-email">
                            <a
                              href="https://www.facebook.com/autostradrentacar"
                              target="_blank"
                            >
                              <img
                                src="https://www.autostrad.com/assets/images/icon/2.png"
                                alt=""
                                class="img-fluid"
                              />{" "}
                              Facebook
                            </a>
                          </p>
                          <p class="company-address-email">
                            <a
                              href="https://www.instagram.com/autostradrentacar/"
                              target="_blank"
                            >
                              <img
                                src="https://www.autostrad.com/assets/images/icon/3.png"
                                alt=""
                                class="img-fluid"
                              />{" "}
                              instagram
                            </a>
                          </p>

                          <p class="company-address-email">
                            <a
                              href="https://www.linkedin.com/company/autostradrentacar"
                              target="_blank"
                            >
                              <img
                                src="https://www.autostrad.com/assets/images/icon/5.png"
                                alt=""
                                class="img-fluid"
                              />{" "}
                              Linkedin
                            </a>
                          </p>
                          <p class="company-address-email">
                            <a
                              href="https://www.youtube.com/channel/UC659md7pt-02N63-H9siTPQ"
                              target="_blank"
                            >
                              <img
                                src="https://www.autostrad.com/assets/images/icon/6.png"
                                alt=""
                                class="img-fluid"
                              />{" "}
                              Youtube
                            </a>
                          </p>

                          {/* Company details
                  <h4 className="company-title">Autostrad International Vehicle Leasing Sole Proprietorship LLC.</h4>
                  <p className="company-address-tel">
                    PO BOX 8279 Abu Dhabi, United Arab Emirates<br />
                    Tel: <a className="paragraph_text" href="tel:+971 28152700">+971 28152700</a>
                  </p>

                  <h4 className="company-title">Autostrad International Vehicle Leasing LLC.</h4>
                  <p className="company-address-tel">
                    PO BOX 449788 Dubai, United Arab Emirates<br />
                    Tel: <a className="paragraph_text" href="tel:+971 4 3387171">+971 4 3387171</a>
                  </p>

  
                  <h5 className="company-title">Toll Free: <a className="paragraph_text" href="tel:800 2772">800 2772</a></h5>
                  <h5 className="company-title">EMAIL:</h5>
                  <p className="company-address-email"><a href="mailto:info@autostrad.com">info@autostrad.com</a></p>

                  <p className="company-address-email">
                    <a href="https://www.autostrad.com/" target="_blank">
                      <img src="image_path_1.png" alt="" className="img-fluid" /> www.autostrad.com
                    </a>
                  </p>
                  Repeat the above pattern for other social media links */}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                      <div className="map_section">
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3634.190476357427!2d54.51736211552146!3d24.374676384285344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e40fa313fc963%3A0xcf4e3f8694dc8693!2sFast%20Rent%20A%20Car%20-%20Mussafah%20Branch!5e0!3m2!1sen!2sin!4v1605696831973!5m2!1sen!2sin"
                          width="100%"
                          height="759"
                          frameBorder="0"
                          style={{ border: 0 }}
                          allowFullScreen=""
                          aria-hidden="false"
                          tabIndex="0"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <Location dont_display="yes" />
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default ContectUserPage;

import React from "react";
import { Container } from "reactstrap";
import "../../styles/common-section.css";

const CommonSection = ({ title }) => {
  return (
    <section className="common__section mb-3">
      <Container className="text-center">
        <h1 className="text-light">{title}</h1>
      </Container>
    </section>
  );
};



const CommonSectionForCarDetails = ({ title,backgroundImage }) => {
  const carDetailsSectionStyle = {
    backgroundImage: `linear-gradient(rgba(0, 13, 107, 0.6), rgba(0, 13, 107, 0.6)), url(${backgroundImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    padding: '80px 0px',
  };
  return (
    <section className="common__section--- mb-5" style={carDetailsSectionStyle}>
      <Container className="text-center">
        <h1 className="text-light">{title}</h1>
      </Container>
    </section>
  );
};

export default CommonSection;
export{CommonSectionForCarDetails};

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/account.css";
import Helmet from "../../components/Helmet/Helmet";
import { Link } from "react-router-dom";

import {
  Nav,
  NavItem,
  Tab,
  Accordion,
  Badge,
  Form,
  Button,
  Spinner,
  Modal,
} from "react-bootstrap";
import threedots from "../../assets/all-images/options3dots.svg";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  IconButton,
  Typography,
} from "@mui/material";
import { compose, nanoid } from "@reduxjs/toolkit";
import threedotsvertical from "../../assets/all-images/three-dots-vertical-svgrepo-com.svg";
import carImage from "../../assets/all-images/cars-img/tesla.jpg";
import { red } from "@mui/material/colors";
import dropdownshow from "../../assets/all-images/dropdown-arrow-svgrepo-com.svg";
import { notifyError, notifySuccess } from "../../SharedComponent/notify";
import {
  multipartPostCall,
  simpleGetCall,
  simpleGetCallAuth,
  simplePostCallAuth,
  simplePutCallAuth,
} from "../../config.js/SetUp";
import configWeb from "../../config.js/configWeb";
import { useSelector, useDispatch } from "react-redux";
import ExtendDropOffDateTimePicker from "./ExtendDropOffDateTimePicker";
import CardDetailsModal from "../../components/UI/CardDetails";
import { setEditUserBookingObject } from "../../reducers/Slices/editUserBookingSlice";
import CustomPagination from "../../components/UI/Pagination";
import { setLanguage } from "../../actions/action";

const Booking = ({ user_id }) => {
  const language = useSelector((state) => state.language.language);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showCardButtons, setShowCardButtons] = useState(null);
  const [activeKey, setActiveKey] = useState("coming");
  const [bookingList, setBookingList] = useState([]);
  const [cancelation_reason, set_cancelation_reason] = useState("");
  const [booking_id, set_booking_id] = useState(null);
  const [booking_number, set_booking_number] = useState(null);
  const [dropoff_location_id, set_dropoff_location_id] = useState(null);
  const [dropoff_emirate_id, set_dropoff_emirate_id] = useState(null);
  const [dropoff_date_time, set_dropoff_date_time] = useState(null);
  const [validated, setValidated] = useState(false);
  const [validated_extend, setValidated_extend] = useState(false);
  const [cardModalShow, setCardModalShow] = useState(false);
  const [disable_extend_booking_button , set_disable_extend_booking_button] = useState(true);
  const [extend_booking_message, set_extend_booking_message] = useState({
    id: null,
    booking_number: null,
    message: null,
  });

  const [payment_type, set_payment_type] = useState(null);
  const [cancel_booking_loading, set_cancel_booking_loading] = useState(false);
  const [booking_extend_loading, set_booking_extend_loading] = useState(false);
  const [booking_check_loading, set_booking_check_loading] = useState(false);
  const handleCancelReason = (event) => {
    set_cancelation_reason(event.target.value);
  };

  const [show_cancel_modal, setShow_cancel_modal] = useState(false);
  const [show_extend_modal, setShow_extend_modal] = useState(false);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading_for_booking, set_loading_for_booking] = useState(false);

  const handleClose_cancel_modal = () => setShow_cancel_modal(false);
  const handleClose_extend_modal = () =>{ 
    set_disable_extend_booking_button(true);
    set_extend_booking_message(null);
    setShow_extend_modal(false);}

  const handleShow_cancel_modal = () => setShow_cancel_modal(true);
  const [agreed, setAgreed] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleCheckboxChange = (event) => {
    setAgreed(event.target.checked);
  };

  const handleSelectKey = (key) => {
    setActiveKey(key);
    setCurrentPage(1);
  };

  const getBookingList = () => {
    // const url1 = `${configWeb.GET_USER_BOOKING_DETAILS(user_id)}?language=${language}&page_number=${pageNumber}&page_size=${pageSize}`;

    const url = `${configWeb.GET_BOOKING_LIST(
      activeKey
    )}?lang=${language}&page=${currentPage}&page_size=${recordsPerPage}`;
    set_loading_for_booking(true);
    simpleGetCallAuth(url)
      .then((res) => {
        if (!res?.error) {
          setBookingList(res?.data);
          setTotalRecords(res?.total_records);
        }
      })
      .catch((error) => {
        console.error("Banner failed:", error);
      })
      .finally(() => {
        set_loading_for_booking(false);
      });
  };
  useEffect(() => {
    getBookingList();
  }, [activeKey, currentPage]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      // Call the cancelBooking function only if the form is valid
      await cancelBooking();
      // const cancelBooking = await cancelBooking();
      getBookingList();
    }

    setValidated(true);
  };
  const handleSubmit_extend = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      // Call the cancelBooking function only if the form is valid
      await extendBooking("extend");
      setCardModalShow(true);
      setShow_extend_modal(false);
    }

    setValidated_extend(true);
  };

  const extendBooking = (action_type) => {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        booking_number: booking_number,
        dropoff_date: formattedDate_dropoff,
        dropoff_time: formattedTime_dropoff,
        action_type: action_type,
        booking_source : "web" ,
      });

      const url = configWeb.POST_EXTEND_BOOKING;
      if (action_type === "extend") {
        set_booking_extend_loading(true);
        set_disable_extend_booking_button(true);

      } else {
        set_booking_check_loading(true);
        set_disable_extend_booking_button(false);
      }

      simplePostCallAuth(url, body)
        .then((res) => {
          if (res?.status === "success") {
            set_extend_booking_message(res?.booking);
            set_payment_type(res?.payment_type);
            resolve(true);
           
          } else if (res?.error) {
            notifyError(res?.message);
         
          }
        })
        .catch((error) => {
          console.error("Banner failed:", error);
          notifyError("Something went wrong. Please try again letter.");
          resolve(false);
        })
        .finally(() => {
          set_booking_extend_loading(false);
          set_booking_check_loading(false);
        });
    });
  };

  const cancelBooking = () => {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        booking_id: booking_id,
        cancellation_reason: cancelation_reason,
      });

      const url = configWeb.POST_CANCEL_BOOKING;
      set_cancel_booking_loading(true);
      simplePostCallAuth(url, body)
        .then((res) => {
          if (!res?.error) {
            // setUserDetails(res);
            notifySuccess("Cancelled Successfully");
            resolve(true);
          } else if (res?.error) {
            notifyError(res?.message);
          }
          handleClose_cancel_modal();
        })
        .catch((error) => {
          console.error("Banner failed:", error);
          notifyError("Something went wrong. Please try again letter.");
          resolve(false);
        })
        .finally(() => {
          set_cancel_booking_loading(false);
        });
    });
  };

  const handleCancelBooking = (booking_id) => {
    setShow_cancel_modal(true);
    set_booking_id(booking_id);
  };

  const handleEditBooking = async (booking_number) => {
    await getUserBookingDetailsLatest(booking_number);
    navigate(`/${language}/bookingDetails/${booking_number}`);
  };

  const handleExtendBooking = (
    booking_id,
    dropoff_location_id,
    dropoff_emirate_id,
    dropoff_date_time,
    booking_number
  ) => {
    setShow_extend_modal(true);
    set_booking_id(booking_id);
    set_dropoff_date_time(dropoff_date_time);
    set_booking_number(booking_number);
    if (dropoff_location_id) {
      set_dropoff_location_id(dropoff_location_id);
    } else {
      set_dropoff_emirate_id(dropoff_emirate_id);
    }
  };

  // function formatDate(dateString) {
  //   const options = {
  //     weekday: "long",
  //     year: "numeric",
  //     month: "short",
  //     day: "numeric",
  //     hour: "2-digit",
  //     minute: "2-digit",
  //   };

  //   return new Date(dateString)
  //     .toLocaleDateString("en-US", options)
  //     .replace(",", "");
  // }

  function formatDate(dateString) {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "Asia/Dubai", 
    };
  
    return new Date(dateString)
      .toLocaleString("en-US", options)
      .replace(",", "");
  }
  

  const handleExtendBookingPayment = () => {
    setCardModalShow(true);
  };

  const [loading_for_edit_booking, set_loading_for_edit_booking] = useState(false);
  const getUserBookingDetailsLatest = (booking_number) => {
    return new Promise((resolve, reject) => {
      const url = configWeb.GET_USER_LATEST_BOOKING_DETAILS(booking_number);
      set_loading_for_edit_booking(true);
      simpleGetCallAuth(url)
        .then((res) => {
          if (!res?.error) {
            dispatch(setEditUserBookingObject(res?.data));
            resolve(true);
          }
          resolve(false);
        })
        .catch((error) => {
          console.error("Banner failed:", error);
          resolve(false);
        })
        .finally(() => {
          set_loading_for_edit_booking(false)
        });
    });
  };

  // ////////////////Dropoffdatetimepicker /////////////////////////////////////////
  const [formattedDate_dropoff, setFormattedDate_dropoff] = useState("");
  const [formattedTime_dropoff, setFormattedTime_dropoff] = useState("");
  const handleDateTimeChange_dropoff = (date, time) => {
    setFormattedDate_dropoff(date);
    setFormattedTime_dropoff(time);
  };
  return (
    <>
      {loading_for_edit_booking ? <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh", width: "100vw" }}
        ><Spinner/></div> : 
    
    
  
    <div id="innerTabs">

    
      <Tab.Container defaultActiveKey="coming" onSelect={handleSelectKey}>
        <Nav variant="pills" className="">
          <Nav.Item>
            <Nav.Link eventKey="coming">Upcoming Reservations</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="current" className="h-100">
              Current Reservations
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="past" className="h-100">
              Past Reservations
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="cancelled">Cancelled Reservations</Nav.Link>
          </Nav.Item>
        </Nav>
        {loading_for_booking ? (
          <div className="text-center h-100">
            {" "}
            <Spinner />{" "}
          </div>
        ) : (
          <>
            <Tab.Content>
              <Tab.Pane eventKey="coming">
                <div className="row justify-content-center mb-4">
                  {bookingList?.length > 0 &&
                    bookingList?.map((booking, index) => (
                      <div
                        key={booking?.id}
                        className="col-lg-6 col-12 position-relative "
                      >
                        <div className="p-2 shadow rounded-3">
                          <div className="row reserved-row">
                            <div className="col-lg-5 col-md-5 col-xs-6 d-flex flex-column align-items-start">
                            <img
                                src={booking?.image}
                                className="img-fluid rounded-3"
                                alt={booking?.car_name}
                              />
                            </div>
                            <div className="col-lg-5 col-md-5 col-xs-6 card-body my-auto text-under-small-screen">
                              <span
                                className="badge p-2 mt-1"
                                style={{
                                  backgroundColor: "#6C505E",
                                  fontFamily: "monospace",
                                  fontSize: "18px",
                                }}
                              >
                                {booking?.car_name}
                              </span>
                              <p className="mb-0">
                                <strong>Reservation Number:</strong>{" "}
                                {booking?.booking_number}
                              </p>
                              <p className="mb-0">
                                <strong>Pick Up Location:</strong>{" "}
                                {booking?.pickup_location ||
                                  booking?.pickup_address}
                              </p>
                              <p className="mb-0">
                                <strong>Pick Up Date:</strong>{" "}
                                {/* Wednesday, Aug 14, 2024
                         07:00 PM */}{" "}
                                {formatDate(booking?.pickup_date_time)}
                              </p>
                              <hr className="my-1" />
                              <p className="mb-0">
                                <strong>Drop Off Location:</strong>{" "}
                                {/* Al Jazah Street, Ras
                         Al Khaimah */}{" "}
                                {booking?.dropoff_location ||
                                  booking?.dropoff_address}
                              </p>
                              <p className="mb-0">
                                <strong>Drop Off Date:</strong>{" "}
                                {/* Wednesday, Aug 21, 2024
                         01:00 PM */}{" "}
                                {formatDate(booking?.dropoff_date_time)}
                              </p>
                            </div>
                            <div className="col-lg-1 col-md-1 move-it-right">
                              <img
                                src={threedotsvertical}
                                className="threedots"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  setShowCardButtons(
                                    showCardButtons === index ? null : index
                                  )
                                }
                                alt="Menu"
                              />
                            </div>
                          </div>
                        </div>
                        {showCardButtons === index && (
                          <ul className="list-unstyled make-position-absolute">
                            <li
                              className="text-nowrap"
                              onClick={() =>
                                handleEditBooking(booking?.booking_number)
                              }
                            >
                              Edit Reservation
                            </li>

                            <li
                              className="text-nowrap"
                              onClick={() => handleCancelBooking(booking?.id)}
                            >
                              Cancel Reservation
                            </li>
                          </ul>
                        )}
                      </div>
                    ))}
                  {/* <div className="col-sm-6 col-md-6 col-lg-6 col-12 position-relative ">
                <div className="p-2 shadow rounded-3">
                  <div className="row reserved-row">
                    <div className="col-lg-5 col-md-5 col-xs-6 d-flex flex-column align-items-start">
                      <img
                        src={carImage}
                        className="img-fluid rounded-3"
                        alt="Car"
                      />
                    </div>
                    <div className="col-lg-5 col-md-5 col-xs-6 card-body my-auto text-under-small-screen">
                      <span
                        className="badge p-2 mt-1"
                        style={{
                          backgroundColor: "#6C505E",
                          fontFamily: "monospace",
                          fontSize: "18px",
                        }}
                      >
                        Mitsubishi Attrage
                      </span>
                      <p className="mb-0">
                        <strong>Reservation Number:</strong> ARC14497
                      </p>
                      <p className="mb-0">
                        <strong>Pick Up Location:</strong> Dubai International
                        Airport - Terminal 1 Arrival
                      </p>
                      <p className="mb-0">
                        <strong>Pick Up Date:</strong> Wednesday, Aug 14, 2024
                        07:00 PM
                      </p>
                      <hr className="my-1" />
                      <p className="mb-0">
                        <strong>Drop Off Location:</strong> Al Jazah Street, Ras
                        Al Khaimah
                      </p>
                      <p className="mb-0">
                        <strong>Drop Off Date:</strong> Wednesday, Aug 21, 2024
                        01:00 PM
                      </p>
                    </div>
                    <div className="col-lg-1 col-md-1 move-it-right">
                      <img
                        src={threedotsvertical}
                        className=""
                        onClick={() => setShowCardButtons(!showCardButtons)}
                        alt="Menu"
                      />
                    </div>
                  </div>
                </div>
                {showCardButtons && (
                  <ul className="list-unstyled make-position-absolute">
                    <li>Edit Reservation</li>
                    <li>Cancel Reservation</li>
                  </ul>
                )}
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-12 position-relative">
                <div className="p-2 shadow rounded-3">
                  <div className="row reserved-row">
                    <div className="col-lg-5 col-md-5 col-xs-6 d-flex flex-column align-items-start">
                      <img
                        src={carImage}
                        className="img-fluid rounded-3"
                        alt="Car"
                      />
                    </div>
                    <div className="col-lg-5 col-md-5 col-xs-6 card-body my-auto text-under-small-screen">
                      <span
                        className="badge p-2 mt-1"
                        style={{
                          backgroundColor: "#6C505E",
                          fontFamily: "monospace",
                          fontSize: "18px",
                        }}
                      >
                        Mitsubishi Attrage
                      </span>
                      <p className="mb-0">
                        <strong>Reservation Number:</strong> ARC14497
                      </p>
                      <p className="mb-0">
                        <strong>Pick Up Location:</strong> Dubai International
                        Airport - Terminal 1 Arrival
                      </p>
                      <p className="mb-0">
                        <strong>Pick Up Date:</strong> Wednesday, Aug 14, 2024
                        07:00 PM
                      </p>
                      <hr className="my-1" />
                      <p className="mb-0">
                        <strong>Drop Off Location:</strong> Al Jazah Street, Ras
                        Al Khaimah
                      </p>
                      <p className="mb-0">
                        <strong>Drop Off Date:</strong> Wednesday, Aug 21, 2024
                        01:00 PM
                      </p>
                    </div>
                    <div className="col-lg-1 col-md-1 move-it-right">
                      <img
                        src={threedotsvertical}
                        className=""
                        onClick={() => setShowCardButtons(!showCardButtons)}
                        alt="Menu"
                      />
                    </div>
                  </div>
                </div>
                {showCardButtons && (
                  <ul className="list-unstyled make-position-absolute">
                    <li>Edit Reservation</li>
                    <li>Cancel Reservation</li>
                  </ul>
                )}
              </div> */}
                </div>

                <CustomPagination
                  recordsPerPage={recordsPerPage}
                  totalRecords={totalRecords}
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                />
              </Tab.Pane>
            </Tab.Content>

            <Tab.Content>
              <Tab.Pane eventKey="current">
                <div className="row justify-content-center mb-4">
                  {bookingList?.length > 0 &&
                    bookingList?.map((booking, index) => (
                      <div
                        key={booking?.id}
                        className="col-lg-6 col-12 position-relative "
                      >
                        <div className="p-2 shadow rounded-3">
                          <div className="row reserved-row">
                            <div className="col-lg-5 col-md-5 col-xs-6 d-flex flex-column align-items-start">
                              <img
                                src={booking?.image}
                                className="img-fluid rounded-3"
                                alt={booking?.car_name}
                              />
                            </div>
                            <div className="col-lg-5 col-md-5 col-xs-6 card-body my-auto text-under-small-screen">
                              <span
                                className="badge p-2 mt-1"
                                style={{
                                  backgroundColor: "#6C505E",
                                  fontFamily: "monospace",
                                  fontSize: "18px",
                                }}
                              >
                                {booking?.car_name}
                              </span>
                              <p className="mb-0">
                                <strong>Reservation Number:</strong>{" "}
                                {booking?.booking_number}
                              </p>
                              <p className="mb-0">
                                <strong>Pick Up Location:</strong>{" "}
                                {booking?.pickup_location ||
                                  booking?.pickup_address}
                              </p>
                              <p className="mb-0">
                                <strong>Pick Up Date:</strong>{" "}
                                {/* Wednesday, Aug 14, 2024
                         07:00 PM */}{" "}
                                {formatDate(booking?.pickup_date_time)}
                              </p>
                              <hr className="my-1" />
                              <p className="mb-0">
                                <strong>Drop Off Location:</strong>{" "}
                                {/* Al Jazah Street, Ras
                         Al Khaimah */}{" "}
                                  {booking?.dropoff_location ||
                                  booking?.dropoff_address}
                              </p>
                              <p className="mb-0">
                                <strong>Drop Off Date:</strong>{" "}
                                {/* Wednesday, Aug 21, 2024
                         01:00 PM */}{" "}
                                {formatDate(booking?.dropoff_date_time)}
                              </p>
                            </div>
                            <div className="col-lg-1 col-md-1 move-it-right">
                              <img
                                src={threedotsvertical}
                                className="threedots"
                                onClick={() =>
                                  setShowCardButtons(
                                    showCardButtons === index ? null : index
                                  )
                                }
                                alt="Menu"
                              />
                            </div>
                          </div>
                        </div>
                        {showCardButtons === index && (
                          <ul className="list-unstyled make-position-absolute">
                            <li
                              className="text-nowrap"
                              onClick={() =>
                                handleExtendBooking(
                                  booking?.id,
                                  booking?.dropoff_location_id,
                                  booking?.dropoff_emirate_id,
                                  booking?.dropoff_date_time,
                                  booking?.booking_number
                                )
                              }
                            >
                              Extend Reservation
                            </li>
                            {/* <li  className="text-nowrap" onClick={() => handleCancelBooking(booking?.id)}>
                          Cancel Reservation
                        </li> */}
                          </ul>
                        )}
                      </div>
                    ))}

                  {/* <div className="col-sm-6 col-md-6 col-lg-6 col-12 position-relative ">
                <div className="p-2 shadow rounded-3">
                  <div className="row reserved-row">
                    <div className="col-lg-5 col-md-5 col-xs-6 d-flex flex-column align-items-start">
                      <img
                        src={carImage}
                        className="img-fluid rounded-3"
                        alt="Car"
                      />
                    </div>
                    <div className="col-lg-5 col-md-5 col-xs-6 card-body my-auto text-under-small-screen">
                      <span
                        className="badge p-2 mt-1"
                        style={{
                          backgroundColor: "#6C505E",
                          fontFamily: "monospace",
                          fontSize: "18px",
                        }}
                      >
                        Mitsubishi Attrage
                      </span>
                      <p className="mb-0">
                        <strong>Reservation Number:</strong> ARC14497
                      </p>
                      <p className="mb-0">
                        <strong>Pick Up Location:</strong> Dubai International
                        Airport - Terminal 1 Arrival
                      </p>
                      <p className="mb-0">
                        <strong>Pick Up Date:</strong> Wednesday, Aug 14, 2024
                        07:00 PM
                      </p>
                      <hr className="my-1" />
                      <p className="mb-0">
                        <strong>Drop Off Location:</strong> Al Jazah Street, Ras
                        Al Khaimah
                      </p>
                      <p className="mb-0">
                        <strong>Drop Off Date:</strong> Wednesday, Aug 21, 2024
                        01:00 PM
                      </p>
                    </div>
                    <div className="col-lg-1 col-md-1 move-it-right">
                      <img
                        src={threedotsvertical}
                        className=""
                        onClick={() => setShowCardButtons(!showCardButtons)}
                        alt="Menu"
                      />
                    </div>
                  </div>
                </div>
                {showCardButtons && (
                  <ul className="list-unstyled make-position-absolute">
                    <li>Edit Reservation</li>
                    <li>Cancel Reservation</li>
                  </ul>
                )}
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-12 position-relative ">
                <div className="p-2 shadow rounded-3">
                  <div className="row reserved-row">
                    <div className="col-lg-5 col-md-5 col-xs-6 d-flex flex-column align-items-start">
                      <img
                        src={carImage}
                        className="img-fluid rounded-3"
                        alt="Car"
                      />
                    </div>
                    <div className="col-lg-5 col-md-5 col-xs-6 card-body my-auto text-under-small-screen">
                      <span
                        className="badge p-2 mt-1"
                        style={{
                          backgroundColor: "#6C505E",
                          fontFamily: "monospace",
                          fontSize: "18px",
                        }}
                      >
                        Mitsubishi Attrage
                      </span>
                      <p className="mb-0">
                        <strong>Reservation Number:</strong> ARC14497
                      </p>
                      <p className="mb-0">
                        <strong>Pick Up Location:</strong> Dubai International
                        Airport - Terminal 1 Arrival
                      </p>
                      <p className="mb-0">
                        <strong>Pick Up Date:</strong> Wednesday, Aug 14, 2024
                        07:00 PM
                      </p>
                      <hr className="my-1" />
                      <p className="mb-0">
                        <strong>Drop Off Location:</strong> Al Jazah Street, Ras
                        Al Khaimah
                      </p>
                      <p className="mb-0">
                        <strong>Drop Off Date:</strong> Wednesday, Aug 21, 2024
                        01:00 PM
                      </p>
                    </div>
                    <div className="col-lg-1 col-md-1 move-it-right">
                      <img
                        src={threedotsvertical}
                        className=""
                        onClick={() => setShowCardButtons(!showCardButtons)}
                        alt="Menu"
                      />
                    </div>
                  </div>
                </div>
                {showCardButtons && (
                  <ul className="list-unstyled make-position-absolute">
                    <li>Edit Reservation</li>
                    <li>Cancel Reservation</li>
                  </ul>
                )}
              </div> */}
                </div>
                <CustomPagination
                  recordsPerPage={recordsPerPage}
                  totalRecords={totalRecords}
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                />
              </Tab.Pane>
            </Tab.Content>

            <Tab.Content>
              <Tab.Pane eventKey="past">
                <div className="row justify-content-center mb-4">
                  {bookingList?.length > 0 &&
                    bookingList?.map((booking, index) => (
                      <div
                        key={booking?.id}
                        className=" col-lg-6 col-12 position-relative "
                      >
                        <div className="p-2 shadow rounded-3">
                          <div className="row reserved-row">
                            <div className="col-lg-5 col-md-5 col-xs-6 d-flex flex-column align-items-start">
                            <img
                                src={booking?.image}
                                className="img-fluid rounded-3"
                                alt={booking?.car_name}
                              />
                            </div>
                            <div className="col-lg-5 col-md-5 col-xs-6 card-body my-auto text-under-small-screen">
                              <span
                                className="badge p-2 mt-1"
                                style={{
                                  backgroundColor: "#6C505E",
                                  fontFamily: "monospace",
                                  fontSize: "18px",
                                }}
                              >
                                {booking?.car?.name}
                              </span>
                              <p className="mb-0">
                                <strong>Reservation Number:</strong>{" "}
                                {booking?.booking_number}
                              </p>
                              <p className="mb-0">
                                <strong>Pick Up Location:</strong>{" "}
                                {booking?.pickup_location ||
                                  booking?.pickup_address}
                              </p>
                              <p className="mb-0">
                                <strong>Pick Up Date:</strong>{" "}
                                {/* Wednesday, Aug 14, 2024
                         07:00 PM */}{" "}
                                {formatDate(booking?.pickup_date_time)}
                              </p>
                              <hr className="my-1" />
                              <p className="mb-0">
                                <strong>Drop Off Location:</strong>{" "}
                                {/* Al Jazah Street, Ras
                         Al Khaimah */}{" "}
                                  {booking?.dropoff_location ||
                                  booking?.dropoff_address}
                              </p>
                              <p className="mb-0">
                                <strong>Drop Off Date:</strong>{" "}
                                {/* Wednesday, Aug 21, 2024
                         01:00 PM */}{" "}
                                {formatDate(booking?.dropoff_date_time)}
                              </p>
                            </div>
                            <div className="col-lg-1 col-md-1 move-it-right">
                              <img
                                src={threedotsvertical}
                                className="threedots"
                                onClick={() =>
                                  setShowCardButtons(
                                    showCardButtons === index ? null : index
                                  )
                                }
                                alt="Menu"
                              />
                            </div>
                          </div>
                        </div>
                        {/* {showCardButtons === index && (
                      <ul className="list-unstyled make-position-absolute">
                        <li>Edit Reservation</li>
                        <li onClick={() => handleCancelBooking(booking?.id)}>
                          Cancel Reservation
                        </li>
                      </ul>
                    )} */}
                      </div>
                    ))}

                  {/* <div className="col-sm-6 col-md-6 col-lg-6 col-12 position-relative ">
                <div className="p-2 shadow rounded-3">
                  <div className="row reserved-row">
                    <div className="col-lg-5 col-md-5 col-xs-6 d-flex flex-column align-items-start">
                      <img
                        src={carImage}
                        className="img-fluid rounded-3"
                        alt="Car"
                      />
                    </div>
                    <div className="col-lg-5 col-md-5 col-xs-6 card-body my-auto text-under-small-screen">
                      <span
                        className="badge p-2 mt-1"
                        style={{
                          backgroundColor: "#6C505E",
                          fontFamily: "monospace",
                          fontSize: "18px",
                        }}
                      >
                        Mitsubishi Attrage
                      </span>
                      <p className="mb-0">
                        <strong>Reservation Number:</strong> ARC14497
                      </p>
                      <p className="mb-0">
                        <strong>Pick Up Location:</strong> Dubai International
                        Airport - Terminal 1 Arrival
                      </p>
                      <p className="mb-0">
                        <strong>Pick Up Date:</strong> Wednesday, Aug 14, 2024
                        07:00 PM
                      </p>
                      <hr className="my-1" />
                      <p className="mb-0">
                        <strong>Drop Off Location:</strong> Al Jazah Street, Ras
                        Al Khaimah
                      </p>
                      <p className="mb-0">
                        <strong>Drop Off Date:</strong> Wednesday, Aug 21, 2024
                        01:00 PM
                      </p>
                    </div>
                    <div className="col-lg-1 col-md-1 move-it-right">
                      <img
                        src={threedotsvertical}
                        className=""
                        onClick={() => setShowCardButtons(!showCardButtons)}
                        alt="Menu"
                      />
                    </div>
                  </div>
                </div>
                {showCardButtons && (
                  <ul className="list-unstyled make-position-absolute">
                    <li>Edit Reservation</li>
                    <li>Cancel Reservation</li>
                  </ul>
                )}
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-12 position-relative ">
                <div className="p-2 shadow rounded-3">
                  <div className="row reserved-row">
                    <div className="col-lg-5 col-md-5 col-xs-6 d-flex flex-column align-items-start">
                      <img
                        src={carImage}
                        className="img-fluid rounded-3"
                        alt="Car"
                      />
                    </div>
                    <div className="col-lg-5 col-md-5 col-xs-6 card-body my-auto text-under-small-screen">
                      <span
                        className="badge p-2 mt-1"
                        style={{
                          backgroundColor: "#6C505E",
                          fontFamily: "monospace",
                          fontSize: "18px",
                        }}
                      >
                        Mitsubishi Attrage
                      </span>
                      <p className="mb-0">
                        <strong>Reservation Number:</strong> ARC14497
                      </p>
                      <p className="mb-0">
                        <strong>Pick Up Location:</strong> Dubai International
                        Airport - Terminal 1 Arrival
                      </p>
                      <p className="mb-0">
                        <strong>Pick Up Date:</strong> Wednesday, Aug 14, 2024
                        07:00 PM
                      </p>
                      <hr className="my-1" />
                      <p className="mb-0">
                        <strong>Drop Off Location:</strong> Al Jazah Street, Ras
                        Al Khaimah
                      </p>
                      <p className="mb-0">
                        <strong>Drop Off Date:</strong> Wednesday, Aug 21, 2024
                        01:00 PM
                      </p>
                    </div>
                    <div className="col-lg-1 col-md-1 move-it-right">
                      <img
                        src={threedotsvertical}
                        className=""
                        onClick={() => setShowCardButtons(!showCardButtons)}
                        alt="Menu"
                      />
                    </div>
                  </div>
                </div>
                {showCardButtons && (
                  <ul className="list-unstyled make-position-absolute">
                    <li>Edit Reservation</li>
                    <li>Cancel Reservation</li>
                  </ul>
                )}
              </div> */}
                </div>

                <CustomPagination
                  recordsPerPage={recordsPerPage}
                  totalRecords={totalRecords}
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                />
              </Tab.Pane>
            </Tab.Content>

            <Tab.Content>
              <Tab.Pane eventKey="cancelled">
                <div className="row justify-content-center mb-4">
                  {bookingList?.length > 0 &&
                    bookingList?.map((booking, index) => (
                      <div
                        key={booking?.id}
                        className="col-lg-6 col-12 position-relative "
                      >
                        <div className="p-2 shadow rounded-3">
                          <div className="row reserved-row">
                            <div className="col-lg-5 col-md-5 col-xs-6 d-flex flex-column align-items-start">
                            <img
                                src={booking?.image}
                                className="img-fluid rounded-3"
                                alt={booking?.car_name}
                              />
                            </div>
                            <div className="col-lg-5 col-md-5 col-xs-6 card-body my-auto text-under-small-screen">
                              <span
                                className="badge p-2 mt-1"
                                style={{
                                  backgroundColor: "#6C505E",
                                  fontFamily: "monospace",
                                  fontSize: "18px",
                                }}
                              >
                                {booking?.car?.name}
                              </span>
                              <p className="mb-0">
                                <strong>Reservation Number:</strong>{" "}
                                {booking?.booking_number}
                              </p>
                              <p className="mb-0">
                                <strong>Pick Up Location:</strong>{" "}
                                {booking?.pickup_location ||
                                  booking?.pickup_address}
                              </p>
                              <p className="mb-0">
                                <strong>Pick Up Date:</strong>{" "}
                                {/* Wednesday, Aug 14, 2024
                         07:00 PM */}{" "}
                                {formatDate(booking?.pickup_date_time)}
                              </p>
                              <hr className="my-1" />
                              <p className="mb-0">
                                <strong>Drop Off Location:</strong>{" "}
                                {/* Al Jazah Street, Ras
                         Al Khaimah */}{" "}
                                  {booking?.dropoff_location ||
                                  booking?.dropoff_address}
                              </p>
                              <p className="mb-0">
                                <strong>Drop Off Date:</strong>{" "}
                                {/* Wednesday, Aug 21, 2024
                         01:00 PM */}{" "}
                                {formatDate(booking?.dropoff_date_time)}
                              </p>
                            </div>
                            <div className="col-lg-1 col-md-1 move-it-right">
                              <img
                                src={threedotsvertical}
                                className="threedots"
                                onClick={() =>
                                  setShowCardButtons(
                                    showCardButtons === index ? null : index
                                  )
                                }
                                alt="Menu"
                              />
                            </div>
                          </div>
                        </div>
                        {/* {showCardButtons === index && (
                      <ul className="list-unstyled make-position-absolute">
                        <li>Edit Reservation</li>
                        <li onClick={() => handleCancelBooking(booking?.id)}>
                          Cancel Reservation
                        </li>
                      </ul>
                    )} */}
                      </div>
                    ))}
                  {/* <div className="col-sm-6 col-md-6 col-lg-6 col-12 position-relative ">
                <div className="p-2 shadow rounded-3">
                  <div className="row reserved-row">
                    <div className="col-lg-5 col-md-5 col-xs-6 d-flex flex-column align-items-start">
                      <img
                        src={carImage}
                        className="img-fluid rounded-3"
                        alt="Car"
                      />
                    </div>
                    <div className="col-lg-5 col-md-5 col-xs-6 card-body my-auto text-under-small-screen">
                      <span
                        className="badge p-2 mt-1"
                        style={{
                          backgroundColor: "#6C505E",
                          fontFamily: "monospace",
                          fontSize: "18px",
                        }}
                      >
                        Mitsubishi Attrage
                      </span>
                      <p className="mb-0">
                        <strong>Reservation Number:</strong> ARC14497
                      </p>
                      <p className="mb-0">
                        <strong>Pick Up Location:</strong> Dubai International
                        Airport - Terminal 1 Arrival
                      </p>
                      <p className="mb-0">
                        <strong>Pick Up Date:</strong> Wednesday, Aug 14, 2024
                        07:00 PM
                      </p>
                      <hr className="my-1" />
                      <p className="mb-0">
                        <strong>Drop Off Location:</strong> Al Jazah Street, Ras
                        Al Khaimah
                      </p>
                      <p className="mb-0">
                        <strong>Drop Off Date:</strong> Wednesday, Aug 21, 2024
                        01:00 PM
                      </p>
                    </div>
                    <div className="col-lg-1 col-md-1 move-it-right">
                      <img
                        src={threedotsvertical}
                        className=""
                        onClick={() => setShowCardButtons(!showCardButtons)}
                        alt="Menu"
                      />
                    </div>
                  </div>
                </div>
                {showCardButtons && (
                  <ul className="list-unstyled make-position-absolute">
                    <li>Edit Reservation</li>
                    <li>Cancel Reservation</li>
                  </ul>
                )}
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-12 position-relative ">
                <div className="p-2 shadow rounded-3">
                  <div className="row reserved-row">
                    <div className="col-lg-5 col-md-5 col-xs-6 d-flex flex-column align-items-start">
                      <img
                        src={carImage}
                        className="img-fluid rounded-3"
                        alt="Car"
                      />
                    </div>
                    <div className="col-lg-5 col-md-5 col-xs-6 card-body my-auto text-under-small-screen">
                      <span
                        className="badge p-2 mt-1"
                        style={{
                          backgroundColor: "#6C505E",
                          fontFamily: "monospace",
                          fontSize: "18px",
                        }}
                      >
                        Mitsubishi Attrage
                      </span>
                      <p className="mb-0">
                        <strong>Reservation Number:</strong> ARC14497
                      </p>
                      <p className="mb-0">
                        <strong>Pick Up Location:</strong> Dubai International
                        Airport - Terminal 1 Arrival
                      </p>
                      <p className="mb-0">
                        <strong>Pick Up Date:</strong> Wednesday, Aug 14, 2024
                        07:00 PM
                      </p>
                      <hr className="my-1" />
                      <p className="mb-0">
                        <strong>Drop Off Location:</strong> Al Jazah Street, Ras
                        Al Khaimah
                      </p>
                      <p className="mb-0">
                        <strong>Drop Off Date:</strong> Wednesday, Aug 21, 2024
                        01:00 PM
                      </p>
                    </div>
                    <div className="col-lg-1 col-md-1 move-it-right">
                      <img
                        src={threedotsvertical}
                        className=""
                        onClick={() => setShowCardButtons(!showCardButtons)}
                        alt="Menu"
                      />
                    </div>
                  </div>
                </div>
                {showCardButtons && (
                  <ul className="list-unstyled make-position-absolute">
                    <li>Edit Reservation</li>
                    <li>Cancel Reservation</li>
                  </ul>
                )}
              </div> */}
                </div>

                <CustomPagination
                  recordsPerPage={recordsPerPage}
                  totalRecords={totalRecords}
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                />
              </Tab.Pane>
            </Tab.Content>
          </>
        )}
      </Tab.Container>

      <Modal show={show_cancel_modal} onHide={handleClose_cancel_modal}>
        <Modal.Header closeButton>
          <Modal.Title>CANCEL BOOKING?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Cancellation Reason</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                placeholder="Enter cancellation reasons"
                onChange={handleCancelReason}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a cancellation reason.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="cancellationPolicyCheckbox">
              <Form.Check
                type="checkbox"
                label="I have read and agree to the Cancellation Policy"
                checked={agreed}
                onChange={handleCheckboxChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                You must agree before submitting.
              </Form.Control.Feedback>
            </Form.Group>
            <Modal.Footer>
              <Button
                variant="secondary"
                className="defButton"
                type="button"
                onClick={handleClose_cancel_modal}
              >
                Close
              </Button>
              <Button
                className="defButton"
                variant="secondary"
                type="submit"
                /* onClick={handleSubmit} */ disabled={cancel_booking_loading}
              >
                {cancel_booking_loading ? <Spinner /> : "Cancel Booking"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={show_extend_modal} onHide={handleClose_extend_modal}>
        <Modal.Header closeButton>
          <Modal.Title>EXTEND BOOKING?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validated_extend}
            onSubmit={handleSubmit_extend}
          >
            <ExtendDropOffDateTimePicker
              onDateTimeChange={handleDateTimeChange_dropoff}
              dropoff_location_id={dropoff_location_id}
              dropoff_emirate_id={dropoff_emirate_id}
              dropoff_date_time={dropoff_date_time}
              extendBooking={extendBooking}
              // deliveryOption={deliveryOption}
              // collectOption={collectOption}
              // selectedCollectEmirate={selectedCollectEmirate}
              // selectedDeliveryEmirate={selectedDeliveryEmirate}
              // selectedDropoffLocation={selectedDropoffLocation}
              // selectedPickupLocation={selectedPickupLocation}
              // formattedDate={formattedDate}
              // month={selectedMonthDuration?.value}
              // booking_type={formData?.booking_type}
            />
            <p className="text-dark mt-2 fw-bold">
              {booking_check_loading ? (
                <Spinner />
              ) : (
                extend_booking_message?.message
              )}
            </p>
         

            <Modal.Footer>
              <Button
                variant="secondary"
                className="defButton"
                type="button"
                onClick={handleClose_extend_modal}
              >
                Close
              </Button>
              <Button
                className="defButton"
                variant="secondary"
                type="submit"
                /* onClick={handleSubmit} */ disabled={booking_extend_loading || disable_extend_booking_button} 
              >
                {booking_extend_loading ? <Spinner /> : "Extend Booking"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      <CardDetailsModal
        cardModalShow={cardModalShow}
        setCardModalShow={setCardModalShow}
        confirmBookingData={extend_booking_message}
        confirmBookingPayment={payment_type}
        closeButton={false}
      />
    </div>
}
    </>
  );
};

export default Booking;

import React, { useEffect, useState } from "react";
import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import { Container } from "reactstrap";
import "../styles/careers.css";
import whats from "../assets/all-images/icons8-whatsapp.svg";
import twit from "../assets/all-images/icons8-twitter.svg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  multipartPostCall,
  multipartPostCallWithoutAuth,
  simpleGetCall,
  simplePostCall,
} from "../config.js/SetUp";
import configWeb from "../config.js/configWeb";
import { Button, Col, Form, Spinner, Row, Modal, Image } from "react-bootstrap";
import { notifyError, notifySuccess } from "../SharedComponent/notify";
import FindCarForm2 from "../components/UI/FindCarForm2";
import MetaHelmet from "../components/Helmet/MetaHelmet";
const CareersPage = () => {
  const [show, setShow] = useState(false);
  const [showshare, setshowshare] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseshare = () => setshowshare(false);
  useEffect(()=>{
    window.scroll(0,0);
  },[])
  const handleOpenWindow = (id) => {

  
    setEnquiryState((prevState) => ({
      ...prevState,
      career_job_id: id,
    }));
    setShow(true);
    document.getElementById("popup").style.display = "block";
    document.getElementById("overlay").style.display = "block";
  };
  const { t, i18n } = useTranslation();
  const [tweetUrl, setTweetUrl] = useState("");
  const [whatsappUrl, setWhatsappUrl] = useState("");
  const [gmailUrl, setGmailUrl] = useState("");
  const handleOpenWindowshare = (career) => {
    // Generate share content
    const shareContent = `Check out this job: ${career.title} in ${career.location}. Requires ${career.experience_years} years of experience!`;

    // Twitter share link
    const tweetLink = `https://twitter.com/intent/ttweet?text=${encodeURIComponent(
      shareContent
    )}`;

    // WhatsApp share link
    const whatsappLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      shareContent
    )}`;

    // Gmail share link in the desired format
    const emailSubject = `Job Opportunity: ${career.title}`;
    const emailBody = `Exciting Opportunity Alert!\n\nLooking for a rewarding career?\n${career.title} - Location: ${career.location}\nRequires: ${career.experience_years} years of experience.\n\nFind out more details!`;

    const gmailLink = `https://mail.google.com/mail/u/0/?fs=1&to&su=${encodeURIComponent(
      emailSubject
    )}&body=${encodeURIComponent(emailBody)}&ui=2&tf=cm`;
    // Update state
    setTweetUrl(tweetLink);
    setWhatsappUrl(whatsappLink);
    setGmailUrl(gmailLink);

    setshowshare(true);
  };

  const handleclosewindow = (e) => {
    e.preventDefault();
    document.getElementById("popup").style.display = "none";
    document.getElementById("overlay").style.display = "none";
  };
  const handleclosewindowshare = (e) => {
    e.preventDefault();
    document.getElementById("popup-share").style.display = "none";
    document.getElementById("overlay").style.display = "none";
  };
  const [captchaValue, setCaptchaValue] = useState([]);
  const [enquiryState, setEnquiryState] = useState({
    first_name: "",
    last_name: "",
    phone_code: "",
    phone_number: "",
    email: "",
    career_job_id: 1,
    cv: null,
    captcha: false,
  });
  const [countries, setCountries] = useState([]);
  const [carListArray, setCarListArray] = useState([]);
  const [emiratesArray, setEmiratesArray] = useState([]);
  const [enquiry_loading, set_enquiry_loading] = useState(false);

  const [tweetContent, setTweetContent] = useState("");
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setEnquiryState((prev) => ({
        ...prev,
        [name]: files[0],
      }));
    } else {
      setEnquiryState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  const [validated, setValidated] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false /* || !enquiryState.captcha */) {
      e.stopPropagation();
      setValidated(true);
    } else {
      // Process form data (e.g., send API request)
      const captcha = await postRecaptcha();
      if (captcha) {
        const enquire = await postEnquireMain();

        if (!enquire) setValidated(true);
        else setValidated(false);
      }
    }
    // setValidated(true);
  };

  const [careerList, setCareerList] = useState([]);
  const [loading, setLoading] = useState(true);
  const language = useSelector((state) => state.language.language);
  const getCareerList = () => {
    const url = `${configWeb.GET_CAREER_LIST}?lang=${language}&page=1&page_size=9999999`;
    setLoading(true);
    simpleGetCall(url)
      .then((res) => {
        if (!res?.error) {
          setCareerList(res?.data || []); // Ensure it's always an array
        }
      })
      .catch((error) => {
        console.log("Special offers API failed-->", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCareerList();
    getCountriesData();
  }, [language]);

  const postEnquireMain = () => {
    return new Promise((resolve, reject) => {
      // const body = JSON.stringify({
      //   first_name: enquiryState?.first_name,
      //   last_name: enquiryState?.last_name,
      //   phone_code: enquiryState?.phone_code,
      //   phone_number: enquiryState?.phone_number,
      //   email: enquiryState?.email,
      //   career_job_id: 1,
      //   cv : enquiryState?.cv
      // });
      const formData = new FormData();
      formData.append("first_name", enquiryState?.first_name);
      formData.append("last_name", enquiryState?.last_name);
      formData.append("phone_code", enquiryState?.phone_code);
      formData.append("phone_number", enquiryState?.phone_number);
      formData.append("email", enquiryState?.email);
      formData.append("career_job_id", enquiryState?.career_job_id);
      formData.append("cv", enquiryState?.cv);

      const url = configWeb.POST_CAREER_JOB_APPLICATION;
      set_enquiry_loading(true);
      multipartPostCallWithoutAuth(url, formData)
        .then((res) => {
          if (res?.status === "success") {
            // setUserDetails(res);
            notifySuccess("Enquire Successfully");

            resolve(true);
            setEnquiryState({
              first_name: "",
              last_name: "",
              phone_code: "",
              phone_number: "",
              email: "",
              type: "",
              duration: "",
              car_id: null,
              emirate_id: null,
              detail: "",
              captcha: false,
            });
          } else if (res?.error) {
            notifyError(res?.message[0]);
            resolve(false);
          }
        })
        .catch((error) => {
          console.error("Banner failed:", error);
          notifyError("Something went wrong. Please try again letter.");
          resolve(false);
        })
        .finally(() => {
          set_enquiry_loading(false);
          setCaptchaValue([]);
          window.grecaptcha.reset();
        });
    });
  };
  const postRecaptcha = () => {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        g_recaptcha_response: captchaValue,
      });

      const url = configWeb.POST_CAPTCHA_VERIFY;
      set_enquiry_loading(true);
      simplePostCall(url, body)
        .then((res) => {
          // setUserDetails(res);
          resolve(true);
          if (res?.error) {
            resolve(false);
          }
        })
        .catch((error) => {
          console.error("Banner failed:", error);
          resolve(false);
        })
        .finally(() => {});
    });
  };

  const getCountriesData = () => {
    const url = `${configWeb.GET_COUNTRY_LIST}?lang=${language}&page_size=260`;
    simpleGetCall(url)
      .then((res) => {
        setCountries(res?.data);
      })
      .catch((errr) => {
        console.log("errr", errr);
      })
      .finally(() => {});
  };
  return (
    <Helmet title="Careers">
        <MetaHelmet
        title="Careers"
        description=""
        keywords="car rental, affordable cars, rent a car"
        canonicalUrl = {`${configWeb.BASE_WEB_URL}/en/CareersPage`}
        hreflangs={[
          { hreflang: "en", href: `${configWeb.BASE_WEB_URL}/en/CareersPage` },
          { hreflang: "ar", href: `${configWeb.BASE_WEB_URL}/ae/CareersPage` },
        ]}

        />
     
{loading ?   <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh", width: "100vw" }}
        >
          <Spinner />
        </div> :  <>
        {/* <section className="pb-1 form-section" ref={parentRef}>
        <h1
          className="pt-2 m-0 text-center text-light booking__tital-dream"
          style={{ fontFamily: "Rubik" }}
        >
          {t("BOOK YOUR DREAM CAR NOW")}!
        </h1>
        <Col id="booking-form-container" lg="12" md="12" sm="12">
          <Container>
            <FindCarForm2 emiratesArray={emiratesArray} />
          </Container>
        </Col>
      </section> */}
 <CommonSection title="Careers" />

      <Container fluid className="careers__section">
        <div class="inner_section_main">
          <div id="demo-containe">
            <div class="container">
              <div class="row">
                <div class="col-sm-12">
                  <p class="content">
                    Fuel your drive with a career at Autostrad!
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="blue-containe mb-3">
            <div class="container">
              <div class="row">
                <div class="col-sm-12">
                  <div class="bg-blue">
                    <p class="content">
                      Autostrad Rent A Car is a company where people come first
                      and our team members are empowered to take the lead. We
                      place emphasis on personal growth, overall well-being and
                      career development for all of our employees because we
                      believe in their potential. Come join us in an exciting
                      work environment with opportunities at every turn!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="driver-container">
          {careerList?.length > 0 &&
            careerList?.map((career) => (
              <div id="driver-row" key={career.id}>
                <div id="dr-col">
                  <h2>{career.title}</h2>
                  <h6>
                    Location: {career.location}{" "}
                    <span>
                      <strong>Experience</strong>
                    </span>
                    : {career.experience_years} Years
                  </h6>
                  <div id="content" className="text-dark">
                    {career.description}
                  </div>
                </div>
                <div id="dr-col-btn">
                  <button
                    type="button"
                    id="btn"
                    onClick={() => handleOpenWindow(career.id)}
                  >
                    Apply Now
                  </button>
                  <button
                    type="button"
                    id="btn"
                    onClick={() => handleOpenWindowshare(career)}
                  >
                    Share with a Friend
                  </button>
                </div>
              </div>
            ))}

          {/* <div id="driver-row">
            <div id="dr-col">
              <h2>SCHOOL BUS DRIVER</h2>
              <h6>
                Location: Abu Dhabi <span>Experience</span>: 1-2 years
              </h6>
              <div id="content">
                <p>
                  <strong>Exciting Opportunity Alert!</strong>
                </p>
                <p>Looking for a rewarding career with great benefits?</p>
                <p>
                  Autostrad Rent A Car is searching for qualified School Bus
                  Drivers to join our team!
                </p>
                <h6>Requirements</h6>
                <div id="remove-margin">
                  <p>- CV (Resume)</p>
                  <p>- Holds a valid UAE Heavy Driving License</p>
                  <p>- Have a valid ITC Permit </p>
                  <p>- Emirates ID holder</p>
                </div>
                <p>
                  We offer an <strong>attractive package including</strong> a
                  competitive salary (AED 2700+) + Visa sponsorship + Health
                  insurance plus airfare, accommodation, and other benefits!
                </p>
                <p>
                  <strong>
                    Ready to join the team? We're hosting walk-in interviews!
                  </strong>
                </p>
                <div id="remove-margin">
                  <p>Dates: July 16th - 20th, 2024</p>
                  <p>Time: 9:00 AM - 5:00 PM</p>
                  <p>
                    Location: Autostrad Rent A Car Musaffah - M2 (Behind ABB) -
                    Abu Dhabi
                  </p>
                </div>
                <p>
                  Don't miss out! Share this post with anyone who might be
                  interested!
                </p>
              </div>
            </div>
            <div id="dr-col-btn">
              <button type="button" id="btn" onClick={handleOpenWindow}>
                Apply Now
              </button>
              <button type="button" id="btn" onClick={handleOpenWindowshare}>
                Share with a Friend
              </button>
            </div>
          </div>

          <div id="driver-row">
            <div id="dr-col">
              <h2>HR OFFICER</h2>
              <h6>
                Location: Abu Dhabi <span>Experience</span>: 5 years (UAE
                experience)
              </h6>
              <div id="content">
                <p>
                  <strong>
                    Do you thrive in a dynamic environment and have a passion
                    for building a world-class team?
                  </strong>
                </p>
                <p>
                  Autostrad Rent a Car seeks a driven HR Officer to join our
                  growing family. You'll be instrumental in attracting top
                  talent, fostering a positive work culture,
                  <br /> and ensuring a smooth employee experience from
                  onboarding to development.
                </p>
                <h6>Requirements</h6>
                <div id="remove-margin">
                  <p>- Administer the exit process </p>
                  <p>
                    - Coordinate final settlements and manage check-in/out.{" "}
                  </p>
                  <p>
                    - Process repatriation tickets, visa renewals, staff
                    letters, and medical insurance.{" "}
                  </p>
                  <p>
                    - Manage uniforms, personal files, and company IDs, as well
                    as prepare invoices.
                  </p>
                  <p>- Perform other HR tasks as assigned</p>
                </div>

                <h6>Ideal Candidate: </h6>
                <div id="remove-margin">
                  <p>
                    - Bachelor's degree in HR or Business Administration or
                    equivalent.
                  </p>
                  <p>- 5+ years of UAE experience.</p>
                  <p>
                    - Excellent communication, interpersonal, and organizational
                    skills, with the ability to build strong relationships with
                    employees at all levels.
                  </p>
                  <p>
                    - A passion for building positive employee relations and
                    fostering a strong company culture with a proactive employee
                    engagement and development approach.
                  </p>
                </div>

                <p>
                  <strong>
                    Ready to embark on a rewarding career journey?
                  </strong>
                </p>
                <p>
                  Competitive salary &amp; benefits. If you have the skills and
                  enthusiasm to excel in this dynamic role, please submit your
                  application by email to <br />
                  <strong>
                    recruitment@autostrad.com with "Graphic Designer"
                  </strong>
                  in the subject line.
                </p>
                <p>
                  <strong>
                    Join Autostrad, and let's embark on this exciting journey
                    together!
                  </strong>
                </p>
              </div>
            </div>
            <div id="dr-col-btn">
              <button type="button" id="btn" onClick={handleOpenWindow}>
                Apply Now
              </button>
              <button type="button" id="btn" onClick={handleOpenWindowshare}>
                Share with a Friend
              </button>
            </div>
          </div> */}
          <div id="overlay"></div>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Apply Now</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="">
                  <Col md={12} xs={12} className="mb-2">
                    <Form.Group controlId="first_name">
                      <Form.Label>
                        First Name <span className="text-danger">&#8727;</span>
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="first_name"
                        value={enquiryState.first_name}
                        onChange={handleChange}
                        placeholder="Enter your first name"
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter your first name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={12} xs={12} className="mb-2">
                    <Form.Group controlId="last_name">
                      <Form.Label>
                        Last Name <span className="text-danger">&#8727;</span>
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="last_name"
                        value={enquiryState.last_name}
                        onChange={handleChange}
                        placeholder="Enter your last name"
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter your last name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={12} xs={12} className="mb-2">
                    <Form.Group controlId="phone_code">
                      <Form.Label>
                        Phone Code <span className="text-danger">&#8727;</span>
                      </Form.Label>

                      <Form.Select
                        name="phone_code"
                        value={enquiryState.phone_code}
                        onChange={handleChange}
                        placeholder="Enter phone code"
                        required
                      >
                        <option value="">Code</option>
                        {countries?.map((country) => (
                          <option key={country.id} value={country.phone_code}>
                            {country.code} {country.phone_code}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please enter your phone code.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={12} xs={12} className="mb-2">
                    <Form.Group controlId="phone_number">
                      <Form.Label>
                        Phone Number{" "}
                        <span className="text-danger">&#8727;</span>
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="phone_number"
                        value={enquiryState.phone_number}
                        onChange={handleChange}
                        placeholder="Enter phone number"
                        maxLength={10}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid 10-digit phone number.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={12} xs={12} className="mb-2">
                    <Form.Group controlId="email">
                      <Form.Label>
                        Email <span className="text-danger">&#8727;</span>
                      </Form.Label>
                      <Form.Control
                        required
                        type="email"
                        name="email"
                        value={enquiryState.email}
                        onChange={handleChange}
                        placeholder="Enter your email"
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid email.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={12} xs={12} className="mb-2">
                    <Form.Group controlId="formFile">
                      <Form.Label>Upload Resume</Form.Label>
                      <Form.Control
                        type="file"
                        name="cv"
                        onChange={handleChange}
                        accept="application/pdf"
                        required
                      />
                      <Form.Text className="text-muted">
                        Note: under 2MB pdf
                      </Form.Text>
                      <Form.Control.Feedback type="invalid">
                        Please upload resume.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col xs={12} className="mt-3 ">
                    <Button
                      type="submit"
                      variant="#1E5486"
                      style={{ backgroundColor: "#4078ab", color: "white" }}
                      className="w-100 btn btn-secondry"
                      disabled={enquiry_loading ? true : false}
                    >
                      {enquiry_loading ? <Spinner /> : "Submit"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
          </Modal>

          <Modal show={showshare} onHide={handleCloseshare}>
            <Modal.Header closeButton>
              <Modal.Title>Share</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <div id="share-img" className="d-flex justify-content-around">
                  <a
                    href={whatsappUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image src={whats} alt="WhatsApp" />
                  </a>
                  <a href={tweetUrl} target="_blank" rel="noopener noreferrer">
                    <Image src={twit} alt="Twitter" />
                  </a>
                  <a href={gmailUrl} target="_blank" rel="noopener noreferrer">
                    {/* <Image src={twit} alt="Twitter" /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="29"
                      height="29"
                      fill="currentColor"
                      className="bi bi-google mt-2 text-danger"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.545 6.558a9.4 9.4 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.7 7.7 0 0 1 5.352 2.082l-2.284 2.284A4.35 4.35 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.8 4.8 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.7 3.7 0 0 0 1.599-2.431H8v-3.08z" />
                    </svg>
                  </a>
                  <a href={gmailUrl} target="_blank" rel="noopener noreferrer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="29"
                      height="29"
                      fill="currentColor"
                      className="bi bi-envelope-fill mt-2 text-dark"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
                    </svg>
                  </a>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      </Container>
      </>
}
    </Helmet>
  );
};

export default CareersPage;

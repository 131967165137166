import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { simpleGetCall, simplePostCall } from "../../config.js/SetUp";
import configWeb from "../../config.js/configWeb";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  DateTimePicker as MuiDateTimePicker,
  // TimePickerComponentProps,
} from "@mui/x-date-pickers/DateTimePicker";
// import textField, { TextField } from "@mui/material";
// import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import dayjs from "dayjs";
import "./CustomDateTimePicker3.css";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { AppContext } from "../../context/AppContext";

const ProSpan = styled("span")({
  display: "inline-block",
  height: "100px",
  width: "100px",
  verticalAlign: "middle",
  marginLeft: "0.3em",
  marginBottom: "0.08em",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundImage: "url(https://mui.com/static/x/pro.svg)",
});

function Label({ componentName, valueType, isProOnly }) {
  const content = (
    <span>
      <strong>{componentName}</strong> for {valueType} editing
    </span>
  );

  if (isProOnly) {
    return (
      <Stack direction="row" spacing={0.5} component="span">
        <Tooltip title="Included on Pro package">
          <a
            href="https://mui.com/x/introduction/licensing/#pro-plan"
            aria-label="Included on Pro package"
          >
            <ProSpan />
          </a>
        </Tooltip>
        {content}
      </Stack>
    );
  }

  return content;
}


export default function CommonlyUsedComponents(props) {
  const { clickonMapAddressSelectionFlag,setClickonMapAddressSelectionFlag } = useContext(AppContext);
  // Destructure props
  const {
    booking_type,
    selectedDeliveryEmirate,
    deliveryOption,
    selectedPickupLocation,
    onDateTimeChange,
  } = props;
  const pickupEmirate = useSelector(
    (state) => state.pickupEmirate.pickupEmirate
  );
  // States
  const [value, setValue] = useState(null); // Selected date and time
  const [shifts, setShifts] = useState([]); // Available shifts
  const [open, setOpen] = useState(false); // Picker open state
  const [formattedTime, setFormattedTime] = useState(""); // Formatted time
  const [formattedDate, setFormattedDate] = useState(""); // Formatted date
  const [dayOfWeek, setDayOfWeek] = useState(null); // Day of the week
  const [locationID, setLocationID] = useState(null);
  const [fetchApiFlag, setFetchApiFlag] = useState(true);
  const [useEffectFlag, setUseEffectFlag] = useState(true);

  useEffect(() => {
    if (deliveryOption === "deliver_to_me") {
      setLocationID(selectedDeliveryEmirate?.value);
    } else {
      setLocationID(selectedPickupLocation?.value);
    }
  }, [deliveryOption]);

  // Determine day of the week from formatted date
  useEffect(() => {
    if (formattedDate) {
      const date = new Date(formattedDate);
      const day2 = date.getDay();
      const datePlus = day2 + 1 * 1;
      // console.log
      setDayOfWeek(datePlus);
      // setDayOfWeek(date.getDay());
    }
  }, [formattedDate]);

  const handleDateChange = (newValue) => {
    setFetchApiFlag(false);
    setValue(newValue);
    setFormattedDate(dayjs(newValue).format("YYYY-MM-DD"));
    setFormattedTime(dayjs(newValue).format("HH:mm"));
  };

  // Fetch shifts based on pickup location and day
  const fetchShifts = async (locationId, dayNumber) => {
    setFetchApiFlag(true);
    if (selectedPickupLocation || selectedDeliveryEmirate) {
      const url =
        deliveryOption === "deliver_to_me"
          ? configWeb.GET_EMIRATE_LOCATION_HOURS(locationId, dayNumber)
          : configWeb.GET_PICKUP_LOCATION_HOURS(locationId, dayNumber);

      try {
        const response = await simpleGetCall(url);
        if (!response?.error) {
          setShifts(response);
          return response;
        }
      } catch (error) {
        console.error("Failed to fetch shifts:", error)
      } finally {
        // setFetchApiFlag(false);
      }
      return [];
    }
  };

  // Add buffer hours and minutes to current date and return a new date object
  const getBufferedTime = (bufferHours = 0) => {
    const currentDate = dayjs();
    const updatedDate = currentDate.add(bufferHours, "hour").add(15, "minute");

    return updatedDate
      .minute(Math.ceil(updatedDate.minute() / 15) * 15)
      .second(0);
    // return currentDate
    //   .add(bufferHours, "hour")
    //   .add(15, "minute")
    //   .minute(Math.ceil(currentDate.minute() / 15) * 15)
    //   .second(0);

    // const updatedDate = dayjs()
    // .add(bufferHours, "hour") // Add buffer hours
    // .add(15, "minute") // Add 15 minutes

    // // Round minutes to the nearest 15-minute interval
    // .minute(Math.ceil(dayjs().add(bufferHours, "hour").minute() / 15) * 15)
    // .second(0); // Set seconds to 0 for consistency
  };

  // Validate if a given time falls within available shifts
  const isTimeWithinShifts = (time, shiftList) => {
    const selectedHour = time.hour();
    return Array.isArray(shiftList) && shiftList?.length > 0 && shiftList?.some(({ from_hours, to_hours }) => {
      if (to_hours === 24) return selectedHour >= from_hours && selectedHour <= 23;
      if (from_hours <= to_hours) return selectedHour >= from_hours && selectedHour < to_hours;
      return selectedHour >= from_hours || selectedHour < to_hours;
    });
  };

  const setNextValidTime = async (locationId, startDay) => {
    if (selectedPickupLocation || selectedDeliveryEmirate) {
      let dayNumber = startDay; // Start from the given day
      let daysChecked = 1; // Counter to ensure we only check 7 days

      while (daysChecked < 8) {
        const nextShifts = await fetchShifts(locationId, dayNumber);

        if (Array.isArray(nextShifts) && nextShifts?.length > 0) {
          // Filter out closed shifts where both `from_hours` and `to_hours` are 0
          const validShifts = nextShifts?.filter(
            (shift) => !(shift.from_hours === 0 && shift.to_hours === 0)
          );

          if (Array.isArray(validShifts) && validShifts?.length > 0) {
            // Use the first valid shift
            const firstValidShift = validShifts[0];
            const nextValidTime = dayjs()
              .add(daysChecked, "day") // Add the days manually
              .hour(firstValidShift?.from_hours)
              .minute(0)
              .second(0);

            setValue(nextValidTime);

            return; // Stop the loop as we've found a valid shift
          }
        }

        // Move to the next day, wrapping around if necessary
        dayNumber = (dayNumber % 7) + 1;
        daysChecked++;
      }

      console.warn("No valid shifts found within 7 days.");
    }
  };
    
  const initializeDateTime = async () => {
    const locationId =
      deliveryOption === "deliver_to_me"
        ? selectedDeliveryEmirate?.value
        : selectedPickupLocation?.value;
    const currentDay = (dayjs().day() + 1) % 7 || 7; // Add 1 since Sunday = 1
    const bufferHours = Number(
      selectedPickupLocation
        ? selectedPickupLocation?.buffer_hours || 0
        : selectedDeliveryEmirate?.buffer_hours || 0
    );

    // Fetch today's shifts
    const todayShifts =
      selectedPickupLocation || selectedDeliveryEmirate
        ? await fetchShifts(locationId, currentDay)
        : null;
    // Add buffer to current time
    const bufferedTime = getBufferedTime(bufferHours);

    // Check if buffered time is within today's shifts
    if (isTimeWithinShifts(bufferedTime, todayShifts)) {
      setValue(bufferedTime);
    } else if (
      !isTimeWithinShifts(bufferedTime, todayShifts) &&
      selectedPickupLocation
    ) {
      // If not valid, fetch next day's shifts

      await setNextValidTime(locationId, currentDay + 1);
    }
  };
 
  useEffect(() => {


    if (!useEffectFlag && (selectedPickupLocation || selectedDeliveryEmirate)) {
      initializeDateTime();
    }

    setUseEffectFlag(false);
  }
  
  , [
     selectedPickupLocation,  /* selectedDeliveryEmirate, */
  ]);

  useEffect(()=>{
if(clickonMapAddressSelectionFlag){
  initializeDateTime();
}
setClickonMapAddressSelectionFlag(false);
  },[clickonMapAddressSelectionFlag])

  // Trigger callback when value changes
  useEffect(() => {
    if (value) {
      const formattedDate = dayjs(value).format("YYYY-MM-DD");
      const formattedTime = dayjs(value).format("HH:mm");
      setFormattedDate(formattedDate);
      onDateTimeChange(formattedDate, formattedTime);
    }
  }, [value]);

  // Disable invalid times in the picker
  const shouldDisableTime = (time, view) => {
    if (!dayjs.isDayjs(time)) return false;
    return !isTimeWithinShifts(time, shifts);
  };


  useEffect(() => {
    if ( !fetchApiFlag &&
      formattedDate &&
      dayOfWeek &&
      (selectedPickupLocation || selectedDeliveryEmirate) 
    ) {
      fetchShifts(
        deliveryOption === "deliver_to_me"
          ? selectedDeliveryEmirate?.value
          : selectedPickupLocation?.value,
        dayOfWeek
      );
    }
  }, [dayOfWeek /* , formattedDate */]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DateTimePicker"]}>
        <DemoItem>
          <Form.Group controlId="formGridAddress1">
            <Form.Label className="label-name mb-1-">
              Pick-up Date / Time
            </Form.Label>
            <MuiDateTimePicker
              open={open}
              onOpen={() => setOpen(true)}
              // onClose={handleClose}
              onClose={() => setOpen(false)}
              value={value}
              onChange={handleDateChange}
              disablePast={true}
              timeSteps={{ hours: 1, minutes: 15 }}
              ampm={false}
              format="YYYY-MM-DD HH:mm"
              shouldDisableTime={shouldDisableTime}
              slotProps={{
                textField: {
                  onClick: () => setOpen(true),
                },
              }}
            />
          </Form.Group>
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
}

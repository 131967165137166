// reducers/stepperSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    dropOffEmirate: null, // Initial state value
  };

  const dropOffEmirateSlice = createSlice({
    name: 'dropOffEmirate',
    initialState: initialState,
    reducers: {
        setDropOffEmirate: (state, action) => {
        state.dropOffEmirate = action.payload;
      },
    },
  });

  export const { setDropOffEmirate } = dropOffEmirateSlice.actions;
export default dropOffEmirateSlice.reducer;
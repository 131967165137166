// reducers/stepperSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedCollectEmirate: null, // Initial state value
  };

  const selectedCollectEmirateSlice = createSlice({
    name: 'selectedCollectEmirate',
    initialState: initialState,
    reducers: {
        setSelectedCollectEmirate: (state, action) => {
        state.selectedCollectEmirate = action.payload;
      },
    },
  });

  export const { setSelectedCollectEmirate } = selectedCollectEmirateSlice.actions;
export default selectedCollectEmirateSlice.reducer;
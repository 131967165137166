import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  /* Form, */ FormGroup,
  Input,
  Modal,
} from "reactstrap";
import Helmet from "../../components/Helmet/Helmet";
import CommonSection from "../../components/UI/CommonSection";

import "../../styles/contact.css";
import { Button, FloatingLabel, Form, Spinner } from "react-bootstrap";
import configWeb from "../../config.js/configWeb";
import { simpleGetCall, simplePostCall } from "../../config.js/SetUp";
import { useSelector } from "react-redux";
import { notifyError, notifySuccess } from "../../SharedComponent/notify";
import ReCAPTCHA from "react-google-recaptcha";
import { colors } from "@mui/material";
import { useTranslation } from "react-i18next";
import FindCarForm2 from "./FindCarForm2";
import MetaHelmet from "../Helmet/MetaHelmet";

const socialLinks = [
  {
    url: "#",
    icon: "ri-facebook-line",
  },
  {
    url: "#",
    icon: "ri-instagram-line",
  },
  {
    url: "#",
    icon: "ri-linkedin-line",
  },
  {
    url: "#",
    icon: "ri-twitter-line",
  },
];

const Contact = () => {
  const [enquiryState, setEnquiryState] = useState({
    first_name: "",
    last_name: "",
    phone_code: "",
    phone_number: "",
    email: "",
    type: "",
    duration: "",
    car_id: null,
    emirate_id: null,
    detail: "",
    captcha: false,
    feedback_source: 1,
    user_feedback_service_id: 1,
    product_knowledge_rating_id: null,
    friendliness_rating_id: null,
    timely_response_rating_id: null,
    reliability_rating_id: null,
    professionalism_rating_id: null,
    cleanliness_rating_id: null,
    overall_rating_id: 1,
    revert_reason_id: 1,
  });
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.language.language);
  const [validated, setValidated] = useState(false);
  const [countries, setCountries] = useState([]);
  const [emiratesArray, setEmiratesArray] = useState([]);
  const [captchaValue, setCaptchaValue] = useState([]);
  const [carListArray, setCarListArray] = useState([]);
  const [feedback_source, set_feedback_source] = useState([]);
  const [feedback_rating, set_feedback_rating] = useState([]);
  const [feedback_revert_reason, set_feedback_revert_reason] = useState([]);
  const [feedback_service_category, set_feedback_service_category] = useState(
    []
  );
  const [feedback_overall_rating, set_feedback_overall_rating] = useState([]);
  const [enquiry_loading, set_enquiry_loading] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEnquiryState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const getCountriesData = () => {
    const url = `${configWeb.GET_COUNTRY_LIST}?lang=${language}&page_size=260`;
    simpleGetCall(url)
      .then((res) => {
        setCountries(res?.data);
      })
      .catch((errr) => {
        console.log("errr", errr);
      })
      .finally(() => {});
  };

  const emiratesData = () => {
    const url = `${configWeb.GET_EMIRATES}?lang=${language}`;
    simpleGetCall(url)
      .then((res) => {
        setEmiratesArray(res);
      })
      .catch((errr) => {
        console.log("errr", errr);
      })
      .finally(() => {
        // set_loading(false);
      });
  };
  useEffect(() => {
    emiratesData();
    getCountriesData();
  }, [language]);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [error, setError] = useState({
    emirate: "",
    product_knowledge: "",

    professionalism: "",
    friendliness: "",
    timelyResponse: "",
    reliability: "",
    cleanliness: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!enquiryState?.emirate_id) {
      setError((prevState) => ({
        ...prevState,
        emirate: "Please Select Emirate.",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        emirate: "",
      }));
    }

    if (!enquiryState.product_knowledge_rating_id) {
      setError((prevState) => ({
        ...prevState,
        product_knowledge: "Please Select.",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        product_knowledge: "",
      }));
    }
    if (!enquiryState.professionalism_rating_id) {
      setError((prevState) => ({
        ...prevState,
        professionalism: "Please Select.",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        professionalism: "",
      }));
    }
    if (!enquiryState.friendliness_rating_id) {
      setError((prevState) => ({
        ...prevState,
        friendliness: "Please Select.",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        friendliness: "",
      }));
    }
    if (!enquiryState.timely_response_rating_id) {
      setError((prevState) => ({
        ...prevState,
        timelyResponse: "Please Select.",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        timelyResponse: "",
      }));
    }
    if (!enquiryState.reliability_rating_id) {
      setError((prevState) => ({
        ...prevState,
        reliability: "Please Select.",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        reliability: "",
      }));
    }
    if (!enquiryState.cleanliness_rating_id) {
      setError((prevState) => ({
        ...prevState,
        cleanliness: "Please Select.",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        cleanliness: "",
      }));
    }
    const form = e.currentTarget;
    if (
      form.checkValidity() === false ||
      !enquiryState.emirate_id ||
      !enquiryState?.cleanliness_rating_id ||
      !enquiryState.friendliness_rating_id ||
      !enquiryState.product_knowledge_rating_id ||
      !enquiryState.professionalism_rating_id ||
      !enquiryState.reliability_rating_id ||
      !enquiryState.timely_response_rating_id /* || !enquiryState.captcha */
    ) {
      e.stopPropagation();
      setValidated(true);
      setError((prevState) => ({
        ...prevState,
        emirate: "Please Select Emirate.",
        product_knowledge: "Please Select",

        professionalism: "Please Select",
        friendliness: "Please Select",
        timelyResponse: "Please Select",
        reliability: "Please Select",
        cleanliness: "Please Select",
      }));
    } else {
      // Process form data (e.g., send API request)
      const captcha = await postRecaptcha();
      if (captcha) {
        const enquire = await postEnquireMain();

        if (!enquire) {
          setValidated(true);
          setError((prevState) => ({
            ...prevState,
            emirate: "Please Select Emirate.",
            product_knowledge: "Please Select",

            professionalism: "Please Select",
            friendliness: "Please Select",
            timelyResponse: "Please Select",
            reliability: "Please Select",
            cleanliness: "Please Select",
          }));
        } else {
          setValidated(false);
          setError((prevState) => ({
            ...prevState,
            emirate: "",
            product_knowledge: "",

            professionalism: "",
            friendliness: "",
            timelyResponse: "",
            reliability: "",
            cleanliness: "",
          }));
        }
      }
    }
    // setValidated(true);
  };

  const token = "";
  const postEnquireMain = () => {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        first_name: enquiryState?.first_name,
        last_name: enquiryState?.last_name,
        phone_code: enquiryState?.phone_code,
        phone_number: enquiryState?.phone_number,
        email: enquiryState?.email,
        emirate_id: enquiryState?.emirate_id,
        user_feedback_source_id: enquiryState?.feedback_source,
        /* extra */ user_feedback_service_id:
          enquiryState?.user_feedback_service_id,
        user_feedback_service_category_id:
          enquiryState?.user_feedback_service_id,
        product_knowledge_rating_id: enquiryState?.product_knowledge_rating_id,
        professionalism_rating_id: enquiryState?.professionalism_rating_id,
        friendliness_rating_id: enquiryState?.friendliness_rating_id,
        timely_response_rating_id: enquiryState?.timely_response_rating_id,
        reliability_rating_id: enquiryState?.reliability_rating_id,
        cleanliness_rating_id: enquiryState?.cleanliness_rating_id,
        overall_rating_id: enquiryState?.overall_rating_id,
        revert_reason_id: enquiryState?.revert_reason_id,
        detail: enquiryState?.detail,
      });

      const url = configWeb.POST_FEEDBACK;
      set_enquiry_loading(true);
      simplePostCall(url, body)
        .then((res) => {
          if (res?.status === "success") {
            // setUserDetails(res);
            notifySuccess("Enquire Successfully");

            resolve(true);
            setEnquiryState({
              first_name: "",
              last_name: "",
              phone_code: "",
              phone_number: "",
              email: "",
              type: "",
              duration: "",
              car_id: 0,
              emirate_id: 0,
              detail: "",
              captcha: false,
              feedback_source: 1,
              user_feedback_service_id: 1,
              product_knowledge_rating_id: null,
              friendliness_rating_id: null,
              timely_response_rating_id: null,
              reliability_rating_id: null,
              cleanliness_rating_id: null,
              overall_rating_id: 1,
              revert_reason_id: 1,
            });
          } else if (res?.error) {
            notifyError(res?.message[0]);
            resolve(false);
          }
        })
        .catch((error) => {
          console.error("Banner failed:", error);
          notifyError("Something went wrong. Please try again letter.");
          resolve(false);
        })
        .finally(() => {
          set_enquiry_loading(false);
          setCaptchaValue([]);
          window.grecaptcha.reset();
        });
    });
  };
  const postRecaptcha = () => {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        g_recaptcha_response: captchaValue,
      });

      const url = configWeb.POST_CAPTCHA_VERIFY;
      set_enquiry_loading(true);
      simplePostCall(url, body)
        .then((res) => {
          // setUserDetails(res);
          if(res?.success === true){
            resolve(true);
            }else{
              resolve(false);
              notifyError("Wrong Captcha")
            }
          if (res?.error) {
            resolve(false);
          }
        })
        .catch((error) => {
          console.error("Banner failed:", error);
          resolve(false);
        })
        .finally(() => {});
    });
  };
  // const onChangeRecaptcha = (value) => {
  //   // token = value;
  //   console.log("onChangeRecaptcha", JSON.stringify(value));
  // };

  function onChange(value) {
    setCaptchaValue(value);
  }
  const [selectedValue, setSelectedValue] = useState("");

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const [ratings, setRatings] = useState({
    productKnowledge: "",
    professionalism: "",
    friendliness: "",
    timelyResponse: "",
  });

  // Handler to update ratings
  const handleRatingChange = (category, value) => {
    setRatings({ ...ratings, [category]: value });
  };
  const [ratingss, setRatingss] = useState({
    reliability: "",
    cleanliness: "",
  });

  // Handler to update ratings
  const handleRatingChangee = (category, value) => {
    setRatingss({ ...ratingss, [category]: value });
  };

  const getFeedbackSource = () => {
    const url = `${configWeb.GET_FEEDBACK_SOURCE}?lang=${language}`;
    simpleGetCall(url)
      .then((res) => {
        if (!res?.error) {
          set_feedback_source(res?.data || []); // Ensure it's always an array
        }
      })
      .catch((error) => {
        console.log("Special offers API failed-->", error);
      })
      .finally(() => {});
  };
  const getFeedbackRating = () => {
    const url = `${configWeb.GET_FEEDBACK_RATING}?lang=${language}`;
    simpleGetCall(url)
      .then((res) => {
        if (!res?.error) {
          set_feedback_rating(res?.data || []); // Ensure it's always an array
        }
      })
      .catch((error) => {
        console.log("Special offers API failed-->", error);
      })
      .finally(() => {});
  };
  const getFeedbackOverallRating = () => {
    const url = `${configWeb.GET_FEEDBACK_OVERALL_RATING}?lang=${language}`;
    simpleGetCall(url)
      .then((res) => {
        if (!res?.error) {
          set_feedback_overall_rating(res?.data || []); // Ensure it's always an array
        }
      })
      .catch((error) => {
        console.log("Special offers API failed-->", error);
      })
      .finally(() => {});
  };
  const getFeebackRevertReason = () => {
    const url = `${configWeb.GET_FEEDBACK_REVERT_REASON}?lang=${language}`;
    simpleGetCall(url)
      .then((res) => {
        if (!res?.error) {
          set_feedback_revert_reason(res?.data || []); // Ensure it's always an array
        }
      })
      .catch((error) => {
        console.log("Special offers API failed-->", error);
      })
      .finally(() => {});
  };
  const getFeebackServiceCategory = () => {
    const url = `${configWeb.GET_FEEDBACK_SERVICE_CATEGORY}?lang=${language}`;
    simpleGetCall(url)
      .then((res) => {
        if (!res?.error) {
          set_feedback_service_category(res?.data || []); // Ensure it's always an array
        }
      })
      .catch((error) => {
        console.log("Special offers API failed-->", error);
      })
      .finally(() => {});
  };

  useEffect(() => {
    getFeedbackSource();
    getFeedbackRating();
    getFeedbackOverallRating();
    getFeebackRevertReason();
    getFeebackServiceCategory();
  }, [language]);

  return (
    // <Helmet title="Contact">
    <Helmet title="customer-feedback">
        <MetaHelmet
        title="Customer Feedback"
        description=""
        keywords="car rental, affordable cars, rent a car"
        canonicalUrl = {`${configWeb.BASE_WEB_URL}/en/customer-feedback`}
        hreflangs={[
          { hreflang: "en", href: `${configWeb.BASE_WEB_URL}/en/customer-feedback` },
          { hreflang: "ar", href: `${configWeb.BASE_WEB_URL}/ae/customer-feedback` },
        ]}

        />
       {/* <section className="pb-1 form-section">
        <h1
          className="pt-2 m-0 text-center text-light booking__tital-dream"
          style={{ fontFamily: "Rubik" }}
        >
          {t("BOOK YOUR DREAM CAR NOW")}!
        </h1>
        <Col id="booking-form-container" lg="12" md="12" sm="12">
          <Container>
            <FindCarForm2 emiratesArray={emiratesArray} />
          </Container>
        </Col>
      </section> */}
      <CommonSection title="Customer Feedback" />
      <section>
        <Container>
          {/* <Row>
            <Col lg="7" md="7">
              <h6 className="fw-bold mb-4">Get In Touch</h6>

              <Form>
                <FormGroup className="contact__form">
                  <Input placeholder="Your Name" type="text" />
                </FormGroup>
                <FormGroup className="contact__form">
                  <Input placeholder="Email" type="email" />
                </FormGroup>
                <FormGroup className="contact__form">
                  <textarea
                    rows="5"
                    placeholder="Message"
                    className="textarea"
                  ></textarea>
                </FormGroup>

                <button className=" contact__btn" type="submit">
                  Send Message
                </button>
              </Form>
            </Col>

            <Col lg="5" md="5">
              <div className="contact__info">
                <h6 className="fw-bold">Contact Information</h6>
                <p className="section__description mb-0">
                  Abu Dhabi, UAE
                </p>
                <div className=" d-flex align-items-center gap-2">
                  <h6 className="fs-6 mb-0">Phone:</h6>
                  <p className="section__description mb-0">+88683896366</p>
                </div>

                <div className=" d-flex align-items-center gap-2">
                  <h6 className="mb-0 fs-6">Email:</h6>
                  <p className="section__description mb-0">example@gmail.com</p>
                </div>

                <h6 className="fw-bold mt-4">Follow Us</h6>

                <div className=" d-flex align-items-center gap-4 mt-3">
                  {socialLinks.map((item, index) => (
                    <Link
                      to={item.url}
                      key={index}
                      className="social__link-icon"
                    >
                      <i class={item.icon}></i>
                    </Link>
                  ))}
                </div>
              </div>
            </Col>
          </Row> */}
          {/* <p className="text-center">
            If you like to book on your own, please book directly now or put in
            your request for a quotation{" "}
          </p> */}
          <div className="col-auto mx-2 rounded-3 p-2 p-lg-4 add-color">
            {/* <div className="mb-3">
              {" "2
              <h2 className="section__title">QUOTATION REQUEST</h2>{" "}
            </div> */}
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-6 col-sm-6 my-3">
                  <Form.Group>
                    <p>How did you know about Autostrad Rent a Car?</p>
                    <Form.Select
                      name="feedback_source"
                      value={enquiryState.feedback_source}
                      onChange={handleChange}
                      placeholder="Enter phone code"
                      required
                    >
                      {/* <option value="select" disabled selected>
                        select
                      </option> */}
                      {feedback_source?.map((source) => (
                        <option key={source.id} value={source.id}>
                          {source.name}
                        </option>
                      ))}
                      {/* <option value="Newspaper">Newspaper</option>
                      <option value="Magazine">Magazine</option>
                      <option value="Internet">Internet</option>
                      <option value="Social Media">Social Media</option>
                      <option value="Friends & colleagues">
                        Friends & colleagues
                      </option> */}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-lg-6 col-sm-6 my-3 ">
                  <Form.Group>
                    <p>Please choose the category of the service</p>
                    <Form.Select
                      name="user_feedback_service_id"
                      value={enquiryState.user_feedback_service_id}
                      onChange={handleChange}
                      placeholder="Enter phone code"
                      required
                    >
                      {/* <option value="select" disabled selected>
                        select
                      </option> */}
                      {feedback_service_category?.map((source) => (
                        <option key={source.id} value={source.id}>
                          {source.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-12">
                  <div className="p-2 borderr rounded-4">
                    <Form.Group>
                      <p>Please tell us the location of your rental</p>
                      <div className="d-flex justify-content-between making-colums-flow">
                        {emiratesArray?.length > 0 &&
                          emiratesArray?.map((emirate) => (
                            <Form.Label
                              className="d-flex align-items-center"
                              key={emirate.id}
                            >
                              <Form.Check
                                className="mx-1"
                                type="radio"
                                //  value={emirate.id}
                                //  checked={enquiryState.emirate_id === emirate.id}
                                //  onChange={handleRadioChange}
                                name="emirate_id"
                                id={emirate.id}
                                value={emirate.id}
                                onChange={handleChange}
                                placeholder="Enter emirate ID"
                                required
                              />
                              <span>{emirate.name}</span>
                            </Form.Label>
                          ))}
                      </div>
                    </Form.Group>
                  </div>
                  {!enquiryState?.emirate_id && (
                    <div className="text-center mt-1">
                      {" "}
                      <span className="text-danger">{error.emirate}</span>{" "}
                    </div>
                  )}
                </div>
                <div className="row mb-3 mt-4 mx-auto">
                  <p>
                    What are your thoughts about the representative who attended
                    you? He/She has:
                  </p>
                  <div className="col-lg-6 col-sm-12">
                    <div className="p-2 borderr rounded-4 ">
                      <Form.Group>
                        <p>Product Knowledge</p>
                        <Row className="making-colums-flow">
                          {/* {["Excellent", "Good", "Average", "Fair", "Poor"].map( */}
                          {feedback_rating?.map((rating) => (
                            <Col style={{ width: "3rem" }} key={rating.id}>
                              <Form.Label className="d-flex align-items-center">
                                <Form.Check
                                  type="radio"
                                  className="mx-1"
                                  name="product_knowledge_rating_id"
                                  value={rating.id}
                                  // checked={
                                  //   ratings.productKnowledge === rating.id
                                  // }
                                  // onChange={() =>
                                  //   handleRatingChange(
                                  //     "productKnowledge",
                                  //     rating.id
                                  //   )
                                  // }
                                  onChange={handleChange}
                                />
                                <span>{rating.name}</span>
                              </Form.Label>
                            </Col>
                          ))}
                        </Row>
                      </Form.Group>
                    </div>
                    {!enquiryState?.product_knowledge_rating_id && (
                      <div className="text-center mt-1">
                        {" "}
                        <span className="text-danger">
                          {error.product_knowledge}
                        </span>{" "}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="p-2 borderr rounded-4">
                      <Form.Group>
                        <p>Professionalism</p>
                        <Row className="making-colums-flow">
                          {/* {["Excellent", "Good", "Average", "Fair", "Poor"].map( */}
                          {feedback_rating?.map((rating) => (
                            <Col style={{ width: "3rem" }} key={rating.id}>
                              <Form.Label className="d-flex align-items-center">
                                <Form.Check
                                  type="radio"
                                  className="mx-1"
                                  name="professionalism_rating_id"
                                  value={rating.id}
                                  // checked={ratings.professionalism === rating.id}
                                  // onChange={() =>
                                  //   handleRatingChange(
                                  //     "professionalism",
                                  //     rating.id
                                  //   )
                                  // }
                                  onChange={handleChange}
                                />
                                <span>{rating.name}</span>
                              </Form.Label>
                            </Col>
                          ))}
                        </Row>
                      </Form.Group>
                    </div>
                    {!enquiryState?.professionalism_rating_id && (
                      <div className="text-center mt-1">
                        {" "}
                        <span className="text-danger">
                          {error.professionalism}
                        </span>{" "}
                      </div>
                    )}
                  </div>

                  <div className="col-lg-6 col-sm-12">
                    <div className="p-2 borderr rounded-4">
                      <Form.Group>
                        <p>Friendliness</p>
                        <Row className="making-colums-flow">
                          {/* {["Excellent", "Good", "Average", "Fair", "Poor"].map( */}
                          {feedback_rating?.map((rating) => (
                            <Col style={{ width: "3rem" }} key={rating.id}>
                              <Form.Label className="d-flex align-items-center">
                                <Form.Check
                                  type="radio"
                                  className="mx-1"
                                  name="friendliness_rating_id"
                                  value={rating.id}
                                  // checked={ratings.friendliness === rating.id}
                                  // onChange={() =>
                                  //   handleRatingChange("friendliness", rating.id)
                                  // }
                                  onChange={handleChange}
                                />
                                <span>{rating.name}</span>
                              </Form.Label>
                            </Col>
                          ))}
                        </Row>
                      </Form.Group>
                    </div>
                    {!enquiryState?.friendliness_rating_id && (
                      <div className="text-center mt-1">
                        {" "}
                        <span className="text-danger">
                          {error.friendliness}
                        </span>{" "}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="p-2 borderr rounded-4">
                      <Form.Group>
                        <p>Timely Response</p>
                        <Row className="making-colums-flow">
                          {/* {["Excellent", "Good", "Average", "Fair", "Poor"].map( */}
                          {feedback_rating?.map((rating) => (
                            <Col style={{ width: "3rem" }} key={rating.id}>
                              <Form.Label className="d-flex align-items-center">
                                <Form.Check
                                  type="radio"
                                  className="mx-1"
                                  name="timely_response_rating_id"
                                  value={rating.id}
                                  // checked={ratings.timely_response_rating_id === rating.id}
                                  // onChange={() =>
                                  //   handleRatingChange(
                                  //     "timely_response_rating_id",
                                  //     rating.id
                                  //   )
                                  // }
                                  onChange={handleChange}
                                />
                                <span>{rating.name}</span>
                              </Form.Label>
                            </Col>
                          ))}
                        </Row>
                      </Form.Group>
                    </div>
                    {!enquiryState?.timely_response_rating_id && (
                      <div className="text-center mt-1">
                        {" "}
                        <span className="text-danger">
                          {error.timelyResponse}
                        </span>{" "}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mt-3">
                  <p>How would you rate the vehicle provided by us?</p>
                  <div className="col-lg-6 col-sm-12">
                    <div className="p-2 borderr rounded-4">
                      <Form.Group>
                        <p>Reliability</p>
                        <Row className="making-colums-flow">
                          {/* {["Excellent", "Good", "Average", "Fair", "Poor"].map( */}
                          {feedback_rating?.map((rating) => (
                            <Col style={{ width: "3rem" }} key={rating.id}>
                              <Form.Label className="d-flex align-items-center">
                                <Form.Check
                                  type="radio"
                                  className="mx-1"
                                  name="reliability_rating_id"
                                  value={rating.id}
                                  // checked={ratingss.reliability_rating_id === rating.id}
                                  // onChange={() =>
                                  //   handleRatingChangee("reliability_rating_id", rating.id)
                                  // }
                                  onChange={handleChange}
                                />
                                <span>{rating.name}</span>
                              </Form.Label>
                            </Col>
                          ))}
                        </Row>
                      </Form.Group>
                    </div>
                    {!enquiryState?.reliability_rating_id && (
                      <div className="text-center mt-1">
                        {" "}
                        <span className="text-danger">
                          {error.reliability}
                        </span>{" "}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="p-2 borderr rounded-4">
                      <Form.Group>
                        <p>Cleanliness</p>
                        <Row className="making-colums-flow">
                          {/* {["Excellent", "Good", "Average", "Fair", "Poor"].map( */}
                          {feedback_rating?.map((rating) => (
                            <Col style={{ width: "3rem" }} key={rating.id}>
                              <Form.Label className="d-flex align-items-center">
                                <Form.Check
                                  type="radio"
                                  className="mx-1"
                                  name="cleanliness_rating_id"
                                  value={rating.id}
                                  // checked={ratingss.cleanliness_rating_id === rating.id}
                                  // onChange={() =>
                                  //   handleRatingChangee("cleanliness_rating_id", rating.id)
                                  // }
                                  onChange={handleChange}
                                />
                                <span>{rating.name}</span>
                              </Form.Label>
                            </Col>
                          ))}
                        </Row>
                      </Form.Group>
                    </div>
                    {!enquiryState?.cleanliness_rating_id && (
                      <div className="text-center mt-1">
                        {" "}
                        <span className="text-danger">
                          {error.cleanliness}
                        </span>{" "}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 my-3">
                  <Form.Group>
                    <p className="another-fontsize">
                      How would you rate Autostrad Rent A Car compared to other
                      car rental companies?
                    </p>
                    <Form.Select
                      name="overall_rating_id"
                      value={enquiryState.overall_rating_id}
                      onChange={handleChange}
                      placeholder="Enter emirate ID"
                      required
                    >
                      {/* <option value="select" disabled selected>
                        select
                      </option> */}
                      {feedback_overall_rating?.map((source) => (
                        <option key={source.id} value={source.id}>
                          {source.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-lg-6 col-sm-12 my-3">
                  <Form.Group>
                    <p className="another-fontsize">
                      How likely you would rent again from Autostrad Rent A Car?
                    </p>
                    <Form.Select
                      name="revert_reason_id"
                      value={enquiryState.revert_reason_id}
                      onChange={handleChange}
                      placeholder="Enter emirate ID"
                      required
                    >
                      {/* <option value="select" disabled selected>
                        select
                      </option> */}
                      {feedback_revert_reason?.map((reason) => (
                        <option key={reason.id} value={reason.id}>
                          {reason.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-lg-12 col-sm-12 my-3">
                  {/* <Form.Group>
                    <FloatingLabel label="Any suggestion on how could we improve your experience next time?">
                      <Form.Control
                        style={{ height: "100px" }}
                        as="textarea"
                        name="detail"
                        value={enquiryState.detail}
                        onChange={handleChange}
                        placeholder="Enter details"
                      ></Form.Control>
                    </FloatingLabel>
                  </Form.Group> */}
                  <Form.Group controlId="detail">
                    <Form.Label>
                      Any suggestion on how could we improve your experience
                      next time? <span className="text-danger">&#8727;</span>
                    </Form.Label>
                    <Form.Control
                      required
                      as="textarea"
                      name="detail"
                      value={enquiryState.detail}
                      onChange={handleChange}
                      placeholder="Enter details"
                      rows={3}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide some details.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <Row className="">
                <Col md={6} xs={12} className="mb-3">
                  <Form.Group controlId="first_name">
                    <p>
                      Name <span className="text-danger">&#8727;</span>
                    </p>
                    <Form.Control
                      required
                      type="text"
                      name="first_name"
                      value={enquiryState.first_name}
                      onChange={handleChange}
                      placeholder="Enter your first name"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter your first name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={6} xs={12} className="mb-3">
                  <Form.Group controlId="last_name">
                    <p>
                      Last Name <span className="text-danger">&#8727;</span>
                    </p>
                    <Form.Control
                      required
                      type="text"
                      name="last_name"
                      value={enquiryState.last_name}
                      onChange={handleChange}
                      placeholder="Enter your last name"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter your last name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={6} xs={12} className="mb-3">
                  <Form.Group controlId="phone_code">
                    <p>
                      Phone Code <span className="text-danger">&#8727;</span>
                    </p>

                    <Form.Select
                      name="phone_code"
                      value={enquiryState.phone_code}
                      onChange={handleChange}
                      placeholder="Enter phone code"
                      required
                    >
                      <option value="">Code</option>
                      {countries?.map((country) => (
                        <option key={country.id} value={country.phone_code}>
                          {country.code} {country.phone_code}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please enter your phone code.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={6} xs={12} className="mb-3">
                  <Form.Group controlId="phone_number">
                    <p>
                      Phone Number <span className="text-danger">&#8727;</span>
                    </p>
                    <Form.Control
                      required
                      type="text"
                      name="phone_number"
                      value={enquiryState.phone_number}
                      onChange={handleChange}
                      placeholder="Enter phone number"
                      maxLength={10}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid 10-digit phone number.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={6} xs={12} className="mb-3">
                  <Form.Group controlId="email">
                    <p>
                      Email <span className="text-danger">&#8727;</span>
                    </p>
                    <Form.Control
                      required
                      type="email"
                      name="email"
                      value={enquiryState.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid email.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                {/* <Col md={6} xs={12} className="mb-3">
                  <Form.Group controlId="emirate_id">
                    <p>
                      Emirate <span className="text-danger">&#8727;</span>
                    </p>

                    <Form.Select
                      name="emirate_id"
                      value={enquiryState.emirate_id}
                      onChange={handleChange}
                      placeholder="Enter emirate ID"
                      required
                    >
                      <option value="">Select Emirates</option>
                      {emiratesArray?.map((emirate) => (
                        <option key={emirate.id} value={emirate.id}>
                          {emirate.name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please Select an emirate ID.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col> */}

                {/* <Col md={6} xs={12} className="mb-3">
                  <Form.Group controlId="type">
                    <p>
                      Enquiry Type <span className="text-danger">&#8727;</span>
                    </p>
                    <Form.Select
                      required
                      as="select"
                      name="type"
                      value={enquiryState.type}
                      onChange={handleChange}
                    >
                      <option value="" disabled selected>
                        Select type
                      </option>
                      <option value="individual">Individual</option>
                      <option value="corporate">Corporate</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please select a type.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col> */}

                {/* <Col md={6} xs={12} className="mb-3">
                  <Form.Group controlId="duration">
                    <p>
                      Duration <span className="text-danger">&#8727;</span>
                    </p>
                    <Form.Select
                      required
                      as="select"
                      name="duration"
                      value={enquiryState.duration}
                      onChange={handleChange}
                    >
                      <option value="" selected disabled>
                        Select duration
                      </option>
                      <option value="daily">Daily</option>
                      <option value="weekly">Weekly</option>
                      <option value="monthly">Monthly</option>
                      <option value="yearly">Yearly</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please select a duration.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col> */}

                {/* <Col xs={12} className="mb-3">
                  <Form.Group controlId="detail">
                    <p>
                      Detail <span className="text-danger">&#8727;</span>
                    </p>
                    <Form.Control
                      required
                      as="textarea"
                      name="detail"
                      value={enquiryState.detail}
                      onChange={handleChange}
                      placeholder="Enter details"
                      rows={3}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide some details.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col> */}

                <Col xs={12} className="mt-3 mb-3">
                  {/* <Form.Group controlId="captcha">
            <Form.Check
              required
              type="checkbox"
              name="captcha"
              label="I'm not a robot"
              checked={enquiryState.captcha}
              onChange={(e) =>
                setEnquiryState((prev) => ({
                  ...prev,
                  captcha: e.target.checked,
                }))
              }
              feedback="Please verify that you're not a robot."
              feedbackType="invalid"
            />
          </Form.Group> */}
                  <div className="captcha-col">
                    <ReCAPTCHA
                      onChange={onChange}
                      // sitekey="6Lc7SOseAAAAAHBh-joEdg_fmL4wiJXVW8h3lUIU"
                      sitekey="6Lfp_nwpAAAAAOR7LqsSxWWZtt3vrTfroNRFqxlp"
                      // sitekey="6Lcs8HwpAAAAADfOTHdgj-6Ljv-W1dWKOWBykalo"
                    />
                  </div>
                </Col>

                <Col xs={12} className="mt-3 ">
                  <Button
                    type="submit"
                    variant="#1E5486"
                    style={{ backgroundColor: "#4078ab", color: "white" }}
                    className="w-100 btn btn-secondry"
                    disabled={enquiry_loading}
                  >
                    {enquiry_loading ? <Spinner /> : "Submit"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Container>
      </section>
    </Helmet>
  );
};

export default Contact;

import React from "react";
import GoogleMapReact from 'google-map-react';
import {
  GoogleMap,
  useLoadScript,
  useJsApiLoader,
  Marker,
  MarkerF,
  AdvancedMarkerElement,
} from "@react-google-maps/api";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function MapView({locationCoordinates}){
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627
    },
    zoom: 11
  };
  const GEOLOCATION_API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
  
  const mapContainerStyle = {
    /*  width: '30rem',
       height: '30rem' */
    height: "60vh",
    width: "100%",
  };
  const defaultCenter = {
    lat: 25.2048, // default latitude
    // lat: 23.4241, // default latitude
    lng: 55.2708, // default longitude
    // lng:  53.8478, // default longitude
  };
  const markerPositions = locationCoordinates

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GEOLOCATION_API_KEY,
    // libraries: libraries, // Add 'places' library here
    
  })

  return (
  
    <>
    {isLoaded && (
     <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={7}
      center={defaultCenter} // The center of the map
    >
      {/* Map over markerPositions array to render multiple markers */}
      {markerPositions.map((position, index) => (
        <MarkerF
          key={index} // Each marker must have a unique key
          position={{
            lat: position.lat, // Latitude of the marker
            lng: position.lng, // Longitude of the marker
          }}
        />
      ))}
    </GoogleMap>
    )}
    </>
  );
}
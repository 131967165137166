// const BASE_URL = "https://www.autostrad.com/"
// const BASE_URL = "http://localhost:3001/api/v1/"
const BASE_URL = process.env.REACT_APP_NODE_HOST;

const BASE_WEB_URL = process.env.REACT_APP_FILE_SERVER;

const configWeb = {
  BASE_URL: BASE_URL,
  BASE_WEB_URL: BASE_WEB_URL,
  GET_COUNTRY_LIST: BASE_URL + "country",
  POST_REGISTER: BASE_URL + "user/register",
  POST_REGISTER_CLASSIC: BASE_URL + "user/register/classic",
  POST_REGISTER_OTP: BASE_URL + "user/active/otp",

  POST_LOGIN: BASE_URL + "user/login",
  POST_FORGOT_PASSWORD: BASE_URL + "user/forgot/password",
  POST_RESET_PASSWORD: BASE_URL + "user/reset/password",
  GET_EMIRATES: BASE_URL + "emirate",
  GET_CAR_LIST: BASE_URL + "car",
  GET_EMIRATES_CAR_LIST: BASE_URL + "home/rates",
  // GET_HOMEPAGE_BANNER : BASE_URL + "home/banner",
  GET_HOMEPAGE_BANNER: (type) => `${BASE_URL}home/banner/${type}`,
  GET_EMIRATES_PAGE: (id) => `${BASE_URL}emirate/page/${id}`,
  GET_BLOGS: BASE_URL + "blog",
  GET_BLOG_DETAILS: (id) => `${BASE_URL}blog/${id}`,
  GET_CAR_BRANDS: BASE_URL + "car-brand",
  POST_NEWSLETTER: BASE_URL + "newsletter/subscription",

  /////////////////////////FindCarForm Apis/////////////////////////////////
  GET_PICKUP_LOCATION: (type) => `${BASE_URL}location/${type}`,
  GET_PICKUP_LOCATION_HOURS: (id, day) =>
    `${BASE_URL}location/hours/${id}/${day}`,
  GET_EMIRATE_LOCATION_HOURS: (id, day) =>
    `${BASE_URL}emirate/hours/${id}/${day}`,
  POST_VLIDATE_PICKUP_LOCATION: BASE_URL + "booking/form/validate_pickup",
  POST_VLIDATE_DROPOFF: BASE_URL + "booking/form/validate_dropoff",
  POST_VLIDATE_COUPON: BASE_URL + "booking/form/validate_coupon",
  /////////////////////////////////////////////////////////////////////////////////
  POST_CAR_SEARCH: BASE_URL + "booking/form/car/search",
  GET_CAR_CATEGORY: BASE_URL + "car-category",
  POST_CAR_EXTRA: BASE_URL + "booking/form/car/extra",
  POST_CONFIRM_BOOKING: BASE_URL + "confirm/booking",
  POST_BOOKING_PAYMENT: BASE_URL + "booking/payment",
  POST_MONTHLY_INSTALLMENT: BASE_URL + "booking/monthly/installment",
  // GET_KM_PLAN: BASE_URL + "booking/monthly/km/plan/[group-id]",

  GET_KM_PLAN: (rate_id) =>
    `${BASE_URL}booking/monthly/upgrade/extra/km/${rate_id}`,
  GET_MONTHLY_UPGRADE_MILEAGE_PLAN: (car_id, emirate_id, months) =>
    `${BASE_URL}booking/monthly/upgrade/mileage/plans/${car_id}/${emirate_id}/${months}`,

  /////////////////////////////////////////Account section apis/////////////////////////////////
  GET_USER_DETAILS: (id) => `${BASE_URL}user/profile/details/${id}`,
  PUT_USER_DETAILS: (id) => `${BASE_URL}user/profile/details/${id}`,

  GET_USER_ADDRESS: (id) => `${BASE_URL}user/profile/address/${id}`,
  PUT_USER_ADDRESS: (id) => `${BASE_URL}user/profile/address/${id}`,
  PUT_RESET_PASSWORD: (id) => `${BASE_URL}user/profile/password/${id}`,
  POST_USER_DOCUMENTS: BASE_URL + "user/document",
  POST_USER_DRIVER_DOCUMENTS: BASE_URL + "user/driver/document",
  GET_DOCUMENTS_SET_TYPE: (set_type) => `${BASE_URL}user/documents/${set_type}`,
  GET_DOCUMENTS_DOC_TYPE: (doc_type) => `${BASE_URL}user/documents/${doc_type}`,
  GET_USER_DOCUMENTS: (doc_set_type, user_id) =>
    `${BASE_URL}user/document/${doc_set_type}/${user_id}`,
  GET_USER_DRIVER_DOCUMENTS: (doc_set_type, user_driver_id) =>
    `${BASE_URL}user/driver/document/${doc_set_type}/${user_driver_id}`,

  GET_BOOKING_LIST: (type) => `${BASE_URL}user/booking/listing/${type}`,
  POST_CANCEL_BOOKING: BASE_URL + "cancel/booking",
  POST_EXTEND_BOOKING: BASE_URL + "extend/booking",
  POST_EDIT_BOOKING: BASE_URL + "edit/booking",
  GET_USER_DRIVER: BASE_URL + "user/driver",
  PUT_USER_DRIVER: (id) => `${BASE_URL}user/driver/${id}`,
  DELETE_USER_DRIVER: (id) => `${BASE_URL}user/driver/${id}`,
  GET_USER_DRIVER_DETAILS: (id) => `${BASE_URL}user/driver/${id}`,

  GET_USER_LATEST_BOOKING_DETAILS: (booking_number) =>
    `${BASE_URL}user/booking/latest/${booking_number}`,
  POST_ADDITIONAL_DRIVER: BASE_URL + "user/driver",

  /////////////////emirate pages////////////////////////////

  GET_EMIARTES_PAGES: (type, id) => `${BASE_URL}emirate/page/${type}/${id}`,

  //////Car Details Page///////////////////////
  GET_CAR_DETAILS: (id) => `${BASE_URL}car/${id}`,

  ////////offer page//////////////////////////////////
  GET_SPECIAL_OFFER: BASE_URL + "offer",
  GET_SPECIAL_OFFER_DETAILS: (id) => `${BASE_URL}offer/${id}`,
  POST_OFFER_ENQUIRE: BASE_URL + "offer/enquiry",

  /////enquiry page////////////
  POST_ENQUIRE: BASE_URL + "enquiry",
  POST_CHAUFFEUR_ENQUIRE: BASE_URL + "chauffeur/enquiry",
  POST_CAPTCHA_VERIFY: BASE_URL + "google/recaptcha/verify",

  ///Pages///
  GET_PRIVACY_POLICY: (type) => `${BASE_URL}page/${type}`,

  //FAQ//////////
  GET_FAQ_CATEGORIES: BASE_URL + "faq/category",
  GET_FAQ: BASE_URL + "faq",

  ///User feedback page//////////
  GET_FEEDBACK_SOURCE: BASE_URL + "user/feedback/source",
  GET_FEEDBACK_RATING: BASE_URL + "user/feedback/rating",
  GET_FEEDBACK_OVERALL_RATING: BASE_URL + "user/feedback/overall/rating",
  GET_FEEDBACK_REVERT_REASON: BASE_URL + "user/feedback/revert/reason",
  GET_FEEDBACK_SERVICE_CATEGORY: BASE_URL + "user/feedback/service/category",
  POST_FEEDBACK: BASE_URL + "user/feedback",

  ///lost and found////////////
  POST_LOST_AND_FOUND: BASE_URL + "lost/found/request",

  /////awards and certificate page/////

  GET_AWARD_AND_CERTIFICATE: BASE_URL + "award/certificate",

  ///career page////////////
  GET_CAREER_LIST: BASE_URL + "career/job",
  POST_CAREER_JOB_APPLICATION: BASE_URL + "career/job/application",

  /////payment success page/////
  GET_BOOKING_DETAILS: (id) => `${BASE_URL}user/booking/detail/${id}`,
};
export default configWeb;

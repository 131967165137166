import React, { useContext, useEffect, useState } from 'react';
import Helmet from '../Helmet/Helmet';
import CommonSection from './CommonSection';
import { Col, Container, Row , Spinner} from 'react-bootstrap';
import BlogList from './BlogList';
import { useSelector } from 'react-redux';
import configWeb from '../../config.js/configWeb';
import { simpleGetCall } from '../../config.js/SetUp';
import CustomPagination from './Pagination';
import { useTranslation } from "react-i18next";
import FindCarForm2 from './FindCarForm2';
import MetaHelmet from '../Helmet/MetaHelmet';
import { AppContext } from '../../context/AppContext';

const AboutUae = () => {
   
      const language = useSelector((state) => state.language.language);
      const {bookingTypeGlobal} = useContext(AppContext);
      const [blog_list_array, set_blog_list_array] = useState([]);
      const { t, i18n } = useTranslation();
      const [emiratesArray, setEmiratesArray] = useState([]);
      const [currentPage, setCurrentPage] = useState(1);
      const [total_records, set_total_records] = useState(null);
      const [loading, set_loading] = useState(true);
      const page_size =10
      const getBlogsList = () => {
        const url = `${configWeb.GET_BLOGS}?lang=${language}&page=${currentPage}&page_size=${page_size}&featured=0&type=about_uae`; //emirate_id
        set_loading(true);
        simpleGetCall(url)
          .then((res) => {
            if (!res?.error) {
              set_blog_list_array(res?.data);
              set_total_records(res?.total_records);
            }
          })
          .catch((error) => {
            console.log("Blog list api failed-->", error);
          })
          .finally(() => {
            set_loading(false)
          });
      };
    
      useEffect(()=>{
        getBlogsList();
      },[language, currentPage])
      const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scroll(0,0);
      };
      useEffect(()=>{
        window.scroll(0,0);
      },[])


      const emiratesData = () => {
        const url = `${configWeb.GET_EMIRATES}?lang=${language}`;
        simpleGetCall(url)
          .then((res) => {
            setEmiratesArray(res);
          })
          .catch((errr) => {
          
          })
          .finally(() => {
            // set_loading(false);
          });
      };
      useEffect(()=>{
        emiratesData();
      },[language])
    return (
        <Helmet title="About UAE">
            <MetaHelmet
        title="Blogs About UAE"
        description=""
        keywords="car rental, affordable cars, rent a car"
        canonicalUrl =  {`${configWeb.BASE_WEB_URL}/en/AboutUae`} 
        hreflangs={[
          { hreflang: "en", href: `${configWeb.BASE_WEB_URL}/en/AboutUae` },
          { hreflang: "ar", href: `${configWeb.BASE_WEB_URL}/ae/AboutUae` },
        ]}

        />

{loading ?  <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh", width: "100vw" }}
        >
        
          <Spinner />
        </div>  : 
        <div>
           <section className="pb-1 form-section" /* ref={parentRef} */>
        <h1
          className="pt-2 m-0 text-center text-light booking__tital-dream"
          style={{ fontFamily: "Rubik" }}
        >
          {bookingTypeGlobal ==="daily" ? t("BOOK YOUR DREAM CAR NOW") : t("FREE DELIVERY ON ALL MONTHLY RENTALS")}!
        </h1>
        <Col id="booking-form-container" lg="12" md="12" sm="12">
          <Container>
            <FindCarForm2 emiratesArray={emiratesArray} />
          </Container>
        </Col>
      </section>
        <CommonSection title="About UAE" />
        <section className="p-0">
        <Container>
          <div className="">
            <Row>
             

            <BlogList blog_list_array={blog_list_array}  />
            </Row>
            <CustomPagination
          recordsPerPage={page_size}
          totalRecords={total_records}
          onPageChange={handlePageChange}
          currentPage={currentPage}
        />
          </div>
        </Container>
      </section>
        </div>
}
        </Helmet>
    );
};

export default AboutUae;
import React, { useState } from "react";
import "../styles/register.css";
import { Form, Spinner, Container } from "react-bootstrap";
import CommonSection from "../components/UI/CommonSection";
import DatePicker from "react-datepicker";

import ReCAPTCHA from "react-google-recaptcha";
import dayjs from "dayjs";
import {
  getWithAuthCallWithErrorResponse,
  simpleGetCall,
  simplePostCall_New,
  simplePostCall,
} from "../config.js/SetUp";
import configWeb from "../config.js/configWeb";
import { useEffect } from "react";
import { notifyError, notifySuccess } from "../SharedComponent/notify";
import { Link, useNavigate } from "react-router-dom";
import { UseSelector, useDispatch, useSelector } from "react-redux";
import { pixelLeadEvent } from "../actions/facebookPixelEvents";
// import Flatpickr from "react-flatpickr";
import "react-datepicker/dist/react-datepicker.css";

const Register = () => {
  const language = useSelector((state) => state.language.language);
  const [validated, setValidated] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const navigate = useNavigate();
  const [loading, set_loading] = useState(false);
  const [dobError, setDobError] = useState("");

  // State variables for each input field
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [nationality, setNationality] = useState("");
  const [emirates, setEmirates] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [countryID, setCountryID] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [countries, setCountries] = useState([]);
  const [emiratesArray, setEmiratesArray] = useState([]);
  const [otp_flag, set_otp_flag] = useState(false);
  const [loading_otp, set_loading_otp] = useState(false);

  // Handler functions to update the state
  const handleFirstNameChange = (e) => setFirstName(e.target.value);
  const handleLastNameChange = (e) => setLastName(e.target.value);
  const handleDateOfBirthChange = (e) => setDateOfBirth(e.target.value);
  // const handleNationalityChange = (e) => setNationality(e.target.value);

  const handleNationalityChange = (e) => {
    const selectedCountry = countries.find(
      (country) => country.name === e.target.value
    );
    setNationality(e.target.value);
    setCountryCode(selectedCountry ? selectedCountry.phone_code : "");
    setCountryID(selectedCountry ? selectedCountry.id : "");
  };
  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value);
  };
  const handleEmiratesChange = (e) => {
    setEmirates(e.target.value);
  };
  // const handleCountryCodeChange = (e) => setCountryCode(e.target.value);
  const handleContactNumberChange = (e) => setContactNumber(e.target.value);
  const handleGenderChange = (e) => setGender(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);

  const registerUser = async (userData) => {
    const url = configWeb.POST_REGISTER;

    try {
      const response = await simplePostCall_New(url, userData);
      return response;
    } catch (error) {
      console.error("Error during registration:", error);
      throw error;
    }
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;

    event.preventDefault();
    if(!isChecked){
      setAgreementError("Please agree in order to register");
      
          }
    if (!dateOfBirth) {
      setDobError("Please provide birth date.");
    }
    if (
      form.checkValidity() === false ||
      password !== confirmPassword ||
      !dateOfBirth
    ) {
      event.stopPropagation();
      setValidated(true);
    } else {
      // Form submission logic here
      // handleRegister();
      setDobError("");
      const captcha = await postRecaptcha();
      if (captcha) {
        Registration_OLD();
      }
    }
  };

  const Registration_OLD = () => {
    return new Promise((resolve, reject) => {
      let body = {
        first_name: firstName,
        last_name: lastName,
        dob: dateOfBirth,
        gender: gender,
        country_id: countryID,

        phone_code: countryCode,
        phone_number: contactNumber,
        email: email,
        password: password,
        confirm_password: confirmPassword,
      };
      if (countryCode === "971") {
        body.emirate_id = emirates;
      }

      set_loading(true);
      simplePostCall(configWeb.POST_REGISTER_CLASSIC, JSON.stringify(body))
        .then((res) => {
          if (res.status === "success") {
            resolve(true);
            notifySuccess(res.status);
            pixelLeadEvent("Register");
            // navigate("/login");
            set_otp_flag(true);
          } else {
            resolve(false);
            notifyError(res.message[0]);
          }
        })
        .catch((errr) => {
          console.log("errr", errr);
          resolve(false);
        })
        .finally(() => {
          set_loading(false);
          setAgreementError("")
          setCaptchaValue([]);
          window.grecaptcha.reset();
        });
    });
  };

  const fetchCountryData = async () => {
    const url = `${configWeb.GET_COUNTRY_LIST}?lang=${language}&page_size=260`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }
    return response.json();
  };
  const emiratesData = () => {
    const url = `${configWeb.GET_EMIRATES}?lang=${language}`;
    simpleGetCall(url)
      .then((res) => {
        setEmiratesArray(res);
      })
      .catch((errr) => {
        console.log("errr", errr);
      })
      .finally(() => {
        set_loading(false);
      });
  };

  useEffect(() => {
    fetchCountryData()
      .then((data) => {
        setCountries(data?.data);
      })
      .catch((err) => {});
    emiratesData();
  }, [language]);

  ///////////////////////////////////////////////////RESET PASSWORD STATES AND METHODS ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // const [validated, setValidated] = useState(false);
  const [otp, setOtp] = useState("");
  // const [loading, set_loading] = useState(false);

  const [errMsg, setErrMsg] = useState({ email: "", password: "" });
  const [timer, setTimer] = useState(10);
  const [isResendDisabled, setIsResendDisabled] = useState(false);

  const [isChecked, setIsChecked] = useState(false); // State to store checkbox value
  const [agreementError, setAgreementError] = useState('');
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked); // Update the state based on checkbox value
   
  };
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setIsResendDisabled(false);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleResendOTP = () => {
    setIsResendDisabled(true);
    setTimer(10); // Set timer for 60 seconds
    // Add logic to resend OTP here
    let body = {
      email: email,
    };

    // set_loading(true);
    simplePostCall(configWeb.POST_FORGOT_PASSWORD, JSON.stringify(body))
      .then((res) => {
        if (!res?.error) {
          // localStorage.setItem('access_token', res?.access_token)
          notifySuccess("Success. We have sent you OTP via email.");
          //   navigate('/ResetPassword')
        } else {
          notifyError(res.message[0]);
        }
      })
      .catch((errr) => {
        notifyError("Something went wrong, please try again");
      })
      .finally(() => {
        // set_loading(false);
      });
  };
  const registerOTP = () => {
    let body = {
      email: email,
      register_otp: otp,
    };

    set_loading(true);
    simplePostCall(configWeb.POST_REGISTER_OTP, JSON.stringify(body))
      .then((res) => {
        if (!res?.error) {
          // localStorage.setItem('access_token', res?.access_token)
          notifySuccess("You are registered successfully.");
          set_otp_flag(false);
          navigate(`/${language}/Login`);
        } else {
          if (Array.isArray(res?.message)) {
            notifyError(res.message[0]);
          }

          notifyError(res.message);
        }
      })
      .catch((errr) => {
        notifyError("Something went wrong, please try again");
      })
      .finally(() => {
        set_loading(false);
      });
  };

  // const handleSubmit2 = (event) => {
  //   const form = event.currentTarget;
  //   event.preventDefault();

  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   } else {
  //     registerOTP();
  //   }

  //   setValidated(true);
  // };

  const SubmitOTP = () => {
    registerOTP();
  };

  const [captchaValue, setCaptchaValue] = useState([]);
  const postRecaptcha = () => {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        g_recaptcha_response: captchaValue,
      });

      const url = configWeb.POST_CAPTCHA_VERIFY;
      set_loading(true);
      simplePostCall(url, body)
        .then((res) => {
          // setUserDetails(res);
          if (res?.success === true) {
            resolve(true);
          } else {
            resolve(false);
            notifyError("Wrong Captcha");
          }
          if (res?.error) {
            resolve(false);
          }
        })
        .catch((error) => {
          console.error("Banner failed:", error);
          resolve(false);
        })
        .finally(() => {
          set_loading(false);
        });
    });
  };
  function onChange(value) {
    setCaptchaValue(value);
  }

  const handleDateChange = (date) => {
    const formatDate = dayjs(date).format("YYYY-MM-DD");

    setDateOfBirth(formatDate);
  };
  const [value, setValue] = React.useState(dayjs("2022-04-17"));
  return (
    <>
      <div className="registerMain">
        <CommonSection title="Register" />

        <div className="row registerRow">
          <div className="col-lg-6 col-md-12">
            <div className="leftCOntent">
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12 headBox">
                    <h1 className="headingTxt">Your personal details:</h1>
                    {!otp_flag ? (
                      <label className="labelIn">
                        Whether you have previously rented from Autostrad Rent a
                        Car or not, take advantage of the many special services
                        and join now without any obligation.
                      </label>
                    ) : (
                      <label className="labelIn">
                        Please Enter the OTP provided to you via email.
                      </label>
                    )}
                  </div>
                  {!otp_flag ? (
                    <>
                      <div className="col-lg-6 col-md-12 formBox">
                        <Form.Group controlId="validationFirstName">
                          <Form.Control
                            type="text"
                            placeholder="First Name*"
                            value={firstName}
                            onChange={handleFirstNameChange}
                            required
                            className="form-control borderedInp"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide a first name.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      <div className="col-lg-6 col-md-12 formBox">
                        <Form.Group controlId="validationLastName">
                          <Form.Control
                            type="text"
                            required
                            placeholder="Last Name"
                            value={lastName}
                            onChange={handleLastNameChange}
                            className="form-control borderedInp"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide a last name.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      <div className="col-lg-6 col-md-12 formBox">
                        <Form.Group controlId="validationLastName">
                          {/* <Form.Control
                            type="date"
                            required
                            // placeholder="Date of Birth"
                            value={dateOfBirth}
                            onChange={handleDateOfBirthChange}
                            className="form-control borderedInp"
                          /> */}

                          <DatePicker
                            // className="form-control is-invalid borderedInp"
                            className={`form-control borderedInp ${
                              dobError && !dateOfBirth ? "is-invalid" : "borderedInp"
                            }`}
                            selected={dateOfBirth}
                            // onChange={(date) => setStartDate(date)}
                            onChange={handleDateChange}
                            placeholderText="Select Date Of Birth..."
                            dateFormat="yyyy/MM/dd"
                            showYearDropdown
                            yearDropdownItemNumber={50}
                            scrollableYearDropdown
                          />

                       

                          {dobError && !dateOfBirth && (
                            <span className="invalid_error ">
                              {dobError}
                            </span>
                          )}
                          <Form.Control.Feedback type="invalid">
                            Please provide date of birth.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      <div className="col-lg-6 col-md-12 formBox">
                        <Form.Group controlId="validationNationality">
                          <Form.Select
                            value={nationality}
                            onChange={handleNationalityChange}
                            required
                            className="form-select borderedInp"
                          >
                            <option value="">Nationality</option>
                            {countries?.map((country) => (
                              <option key={country.id} value={country.name}>
                                {country?.name}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            Please select a nationality.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      <div className="col-lg-6 col-md-12 formBox">
                        <Form.Group controlId="validationCountryCode">
                          <Form.Select
                            value={countryCode}
                            onChange={handleCountryCodeChange}
                            required
                            className="form-select borderedInp"
                          >
                            <option value="">Country Code</option>
                            {countries
                              // ?.filter(
                              //   (country) => country.name === nationality
                              // )
                              ?.map((country) => (
                                <option
                                  key={country.id}
                                  value={country.phone_code}
                                >
                                  {country.name} {country.phone_code}
                                </option>
                              ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            Please select a country code.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      {countryCode === "971" && (
                        <div className="col-lg-6 col-md-12 formBox">
                          <Form.Group controlId="validationCountryCode">
                            <Form.Select
                              value={emirates}
                              onChange={handleEmiratesChange}
                              required
                              className="form-select borderedInp"
                            >
                              <option value="">Select Emirates</option>
                              {emiratesArray
                                // ?.filter((emirate) => emirate.name === nationality)
                                ?.map((emirate) => (
                                  <option key={emirate.id} value={emirate.id}>
                                    {emirate.name}
                                  </option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Please select a country code.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                      )}

                      <div className="col-lg-6 col-md-12 formBox">
                        <Form.Group controlId="validationContactNumber">
                          <Form.Control
                            type="number"
                            placeholder="Contact Number"
                            value={contactNumber}
                            onChange={handleContactNumberChange}
                            required
                            className="form-control borderedInp"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide a contact number.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      <div className="col-lg-6 col-md-12 formBox">
                        <Form.Group controlId="validationPassword">
                          <Form.Control
                            type="email"
                            placeholder="Enter Email"
                            value={email}
                            onChange={handleEmailChange}
                            required
                            className="form-control borderedInp"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide email.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      <div className="col-lg-6 col-md-12 formBox">
                        <Form.Group controlId="validationPassword">
                          <Form.Control
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={handlePasswordChange}
                            required
                            className="form-control borderedInp"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide a password.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      <div className="col-lg-6 col-md-12 formBox">
                        <Form.Group controlId="validationConfirmPassword">
                          <Form.Control
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            required
                            className="form-control borderedInp"
                            isInvalid={password !== confirmPassword}
                          />
                          <Form.Control.Feedback type="invalid">
                            Passwords do not match.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      <div className="col-md-12">
                        <Form.Group controlId="validationGender">
                          <div className="checFlex">
                            <div className="form-check">
                              <Form.Check
                                type="radio"
                                label="Male"
                                name="gender"
                                id="male"
                                value="male"
                                checked={gender === "male"}
                                onChange={handleGenderChange}
                                required
                              />
                            </div>
                            <div className="form-check">
                              <Form.Check
                                type="radio"
                                label="Female"
                                name="gender"
                                id="female"
                                value="female"
                                checked={gender === "female"}
                                onChange={handleGenderChange}
                                required
                              />
                            </div>
                          </div>
                          <Form.Control.Feedback type="invalid">
                            Please select a gender.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      <div className="col-lg-6 col-md-12 formBox">
                        <div className="captcha-col">
                          <ReCAPTCHA
                            onChange={onChange}
                            sitekey="6Lfp_nwpAAAAAOR7LqsSxWWZtt3vrTfroNRFqxlp"
                          />
                        </div>
                      </div>
                      <div className="col-12" style={{ display: "flex", alignItems: "center", margin: "10px 0" }}>
      <input
      className="formBox"
required
        type="checkbox"
        id="terms-checkbox"
        checked={isChecked} // Controlled component
        onChange={handleCheckboxChange} // Handle changes
        style={{ marginRight: "8px", height:'2rem', width:'2rem' }}
      />
      <label htmlFor="terms-checkbox" className="labelIn">
        By selecting this option, you agree to our{" "}
        <Link to= {`/${language}/TermsCondition`} target="_blank" rel="noopener noreferrer">
          Terms and Conditions
        </Link>{" "}
        and acknowledge that you have read our{" "}
        <Link to={`/${language}/PrivacyPolicy`} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </Link>
        .
      </label>
    </div>
    <div>
     {agreementError && !isChecked && <p className="invalid-feedback-custom">{agreementError}</p> } 
    </div>

                      <div className="col-md-12 btn-auth">
                        <button
                          className="submitBtn---,  filled-btn"
                          disabled={loading ? true : false}
                        >
                          {loading ? (
                            <Spinner animation="border" variant="#1D288E" />
                          ) : (
                            "SUBMIT"
                          )}
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="mb-2">
                        <Form.Label className="common-labels">OTP</Form.Label>
                        <Form.Control
                          required
                          type="number"
                          placeholder="Enter OTP"
                          value={otp}
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, otp: "" });

                            setOtp(e.target.value);
                          }}
                        />

                        <Form.Control.Feedback type="invalid">
                          Please OTP.
                        </Form.Control.Feedback>
                      </div>

                      {/* <div className="form-check setp2_field mb-2" style={{}}>
                        <span className="mr-1"> Not received OTP? </span>
                        <button
                          className="fw-semibold"
                          onClick={handleResendOTP}
                          disabled={isResendDisabled}
                          id="forgotpasswordresendotp_href"
                          style={{
                            background: "none",
                            border: "none",
                            color: "blue",
                            cursor: "pointer",
                            padding: 0,
                            display: timer > 0 && "none",
                          }}
                        >
                          Resend OTP
                        </button>
                        <span
                          id="forgotpasswordresendotp_timer"
                          style={{
                            color: "rgb(2, 37, 76)",
                            fontSize: "18px",
                            display: timer > 0 ? "inline" : "none",
                          }}
                        >
                          {timer}s
                        </span>
                      </div> */}
 


                      <div className="col-md-12 btn-auth mt-2">
                        <button
                          className="submitBtn---,  filled-btn "
                          type="button"
                          onClick={SubmitOTP}
                          disabled={loading || !otp ? true : false}
                        >
                          {loading ? (
                            <Spinner animation="border" variant="#1D288E" />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </Form>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="rightLayer">
              <div className="wrapper"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;

import React, { useContext } from "react";

import { Card, Col, Container, Nav, Row } from "react-bootstrap";
import MapView from "./MapView";
import "../../styles/location.css";
import CommonSection from "./CommonSection";
import configWeb from "../../config.js/configWeb";
import { useState } from "react";
import { useSelector } from "react-redux";
import { simpleGetCall } from "../../config.js/SetUp";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import FindCarForm2 from "./FindCarForm2";
import MetaHelmet from "../Helmet/MetaHelmet";
import { AppContext } from "../../context/AppContext";

const Location = ({dont_display}) => {
 

  const language = useSelector((state) => state.language.language);
  const {bookingTypeGlobal} = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const [pickupLocationArray, setPickupLocationArray] = useState([]);
  const [emiratesArray, setEmiratesArray] = useState([]);
  const [emirate_id, set_emirate_id] = useState(null);
  const [locationCoordinates, setLocationCoordinates] = useState([]);
  

  const getPickupLocation = () => {
    const BaseUrl = `${configWeb.GET_PICKUP_LOCATION("pickup")}?lang=${language}`;
    const url = emirate_id ? `${BaseUrl}&emirate_id=${emirate_id}` : BaseUrl
    simpleGetCall(url)
      .then((res) => {
        if (!res?.error) {
          setPickupLocationArray(res);
        }
      })
      .catch((error) => {
        console.error("Location failed:", error);
      })
      .finally(() => {});
  };
  const emiratesCategories = () => {
    const url = `${configWeb.GET_EMIRATES}?lang=${language}`;
    simpleGetCall(url)
      .then((res) => {
        if (!res?.error) {
          setEmiratesArray(res);
        }
      })
      .catch((error) => {})
      .finally(() => {});
  };
  useEffect(()=>{
    emiratesCategories();
  },[language])
  useEffect(() => {
    getPickupLocation();
   
  }, [language, emirate_id]);

  useEffect(() => {
    if (pickupLocationArray) {
      const locationCooordinates = pickupLocationArray?.map((location) => ({
        lat: parseFloat(location.lat),
        lng: parseFloat(location.long),
      }));
      setLocationCoordinates(locationCooordinates);
    }
  }, [pickupLocationArray]);

  const handleNavClick = (id) => {
    set_emirate_id(id);
  };

  return (
    <div>
        <MetaHelmet
        title="Locations"
        description=""
        keywords="car rental, affordable cars, rent a car"
        canonicalUrl = {`${configWeb.BASE_WEB_URL}/en/Location`}

        hreflangs={[
          { hreflang: "en", href: `${configWeb.BASE_WEB_URL}/en/Location` },
          { hreflang: "ar", href:`${configWeb.BASE_WEB_URL}/ae/Location` },
        ]}

        />
   
      { dont_display === 'yes' ? null : 
      <>
      {/* <section className="pb-1 form-section" ref={parentRef}>
        <h1
          className="pt-2 m-0 text-center text-light booking__tital-dream"
          style={{ fontFamily: "Rubik" }}
        >
          {bookingTypeGlobal ==="daily" ? t("BOOK YOUR DREAM CAR NOW") : t("FREE DELIVERY ON ALL MONTHLY RENTALS")}!
      
        </h1>
        <Col id="booking-form-container" lg="12" md="12" sm="12">
          <Container>
            <FindCarForm2 emiratesArray={emiratesArray} />
          </Container>
        </Col>
      </section> */}
      
      <CommonSection title="Location" />
      </>  }
      
      <section className="offer__section">
        <Container>
          <div>
            <Row>
              <Col lg="12" md="12" sm="12">
                <h6 className="section__title">OUR LOCATIONS</h6>
              </Col>
              <Col lg="12" md="12" sm="12" className="my-3">
                <Nav
                  variant="pills"
                  defaultActiveKey="link-0"
                  className="nav-custom"
                >
                  <Nav.Item>
                    <Nav.Link
                      className="px-4 py-2"
                      eventKey="link-0"
                      onClick={() => handleNavClick(0)}
                    >
                      All UAE
                    </Nav.Link>
                  </Nav.Item>
                  {emiratesArray &&
                    emiratesArray?.map((emirate) => (
                      <Nav.Item key={emirate.id}>
                        <Nav.Link
                          className="px-2 py-2"
                          eventKey={`link-${emirate.id}`}
                          onClick={() => handleNavClick(emirate.id)}
                        >
                          {emirate.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                </Nav>
              </Col>
            </Row>
            <Row style={{margin:'auto 20px'}}>
              <MapView locationCoordinates={locationCoordinates} />
            </Row>

            <div className="location-card">
              <Container className="mt-5">
                <Row>
                  {/* {pickupLocationArray &&
                    pickupLocationArray.length > 0 &&
                    pickupLocationArray.map((location) => (
                      <Col
                        md={4}
                        sm={6}
                        className="mb-4 h-100 d-flex flex-wrap"
                        key={location.id}
                      >
                        <Card className="location-card-overView h-100 d-flex flex-column">
                          <Card.Body className="d-flex flex-column">
                            <Card.Title className="mt-3 mb-3 location__card-title">
                              {location.name}
                            </Card.Title>
                            <Card.Text className="flex-grow-1">
                              <p>{location.address}</p>
                             
                              <div className="d-flex align-items-center">
                                <i className="fas fa-phone text-black me-3"></i>
                                <p className="mb-0">+971521192399</p>
                              </div>
                              <div className="d-flex align-items-center mt-2">
                                <i className="fas fa-envelope text-black me-3"></i>
                                <p className="mb-0">
                                  gamal.fahmi@autostrad.com
                                </p>
                              </div>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))} */}

{pickupLocationArray && pickupLocationArray?.length>0 && pickupLocationArray?.map((location)=>(
                     <Col md={4} sm={6} className="mb-4" key={location.id}>
                     <Card className="location-card-overView" >
                       <Card.Body>
                         <Card.Title className="mt-3 mb-3 location__card-title">
                          {location.name}
                         </Card.Title>
                         <Card.Text>
                           <p>{location.address}</p>
                           <div className="d-flex justify-content-between">
                             <p>{location.timing_detail}</p>
                             {/* <button className="btn btn-primary location-side-tital">
                               All Week
                             </button> */}
                           </div>
 
                           <div className="d-flex align-items-center">
                             <i className="fas fa-phone text-black me-3"></i>
                             <p className="mb-0">{location.contact_number}</p>
                           </div>
                           <div className="d-flex align-items-center mt-2">
                             <i className="fas fa-envelope text-black me-3"></i>
                             <p className="mb-0 emails-rec">{location.recipients[0]}</p>
                           </div>
                         </Card.Text>
                       </Card.Body>
                     </Card>
                   </Col>
                  ))}
                  {/* <Col md={4} sm={6} className="mb-4">
                    <Card className="location-card-overView" >
                      <Card.Body>
                        <Card.Title className="mt-3 mb-3 location__card-title">
                          Abu Dhabi International Airport - Meet & Greet
                        </Card.Title>
                        <Card.Text>
                          <p>AUH Airport - Terminal A Car Rental Parking</p>
                          <div className="d-flex justify-content-between">
                            <p>08:00 AM to 11:00 PM</p>
                            <button className="btn btn-primary location-side-tital">
                              All Week
                            </button>
                          </div>

                          <div className="d-flex align-items-center">
                            <i className="fas fa-phone text-black me-3"></i>
                            <p className="mb-0">+971521192399</p>
                          </div>
                          <div className="d-flex align-items-center mt-2">
                            <i className="fas fa-envelope text-black me-3"></i>
                            <p className="mb-0">gamal.fahmi@autostrad.com</p>
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4} sm={6} className="mb-4">
                    <Card className="location-card-overView">
                      <Card.Body>
                        <Card.Title className="mt-3 mb-3 location__card-title">
                          Abu Dhabi International Airport - Meet & Greet
                        </Card.Title>
                        <Card.Text>
                          <p>AUH Airport - Terminal A Car Rental Parking</p>
                          <div className="d-flex justify-content-between">
                            <p>08:00 AM to 11:00 PM</p>
                            <button className="btn btn-primary location-side-tital">
                              All Week
                            </button>
                          </div>

                          <div className="d-flex align-items-center">
                            <i className="fas fa-phone text-black me-3"></i>
                            <p className="mb-0">+971521192399</p>
                          </div>
                          <div className="d-flex align-items-center mt-2">
                            <i className="fas fa-envelope text-black me-3"></i>
                            <p className="mb-0">gamal.fahmi@autostrad.com</p>
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4} sm={6} className="mb-4">
                    <Card className="location-card-overView">
                      <Card.Body>
                        <Card.Title className="mt-3 mb-3 location__card-title">
                          Abu Dhabi International Airport - Meet & Greet
                        </Card.Title>
                        <Card.Text>
                          <p>AUH Airport - Terminal A Car Rental Parking</p>
                          <div className="d-flex justify-content-between">
                            <p>08:00 AM to 11:00 PM</p>
                            <button className="btn btn-primary location-side-tital">
                              All Week
                            </button>
                          </div>

                          <div className="d-flex align-items-center">
                            <i className="fas fa-phone text-black me-3"></i>
                            <p className="mb-0">+971521192399</p>
                          </div>
                          <div className="d-flex align-items-center mt-2">
                            <i className="fas fa-envelope text-black me-3"></i>
                            <p className="mb-0">gamal.fahmi@autostrad.com</p>
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col> */}
                </Row>
              </Container>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Location;

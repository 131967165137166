

export const isDifferenceGreaterThanDays = (date1, date1Time, date2, date2Time, days) => {
  const dateTime1 = new Date(`${date1}T${date1Time}:00`); 
  const dateTime2 = new Date(`${date2}T${date2Time}:00`); 

  const diffInTime = Math.abs(dateTime2 - dateTime1);

  const diffInDays = diffInTime / (1000 * 60 * 60 * 24);

  return diffInDays > days;
};

export function getFullMonthDifference(date1, date2) {
  // Convert strings to Date objects
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  // Calculate the difference in years and months
  let yearDiff = d2.getFullYear() - d1.getFullYear();
  let monthDiff = d2.getMonth() - d1.getMonth();

  // Total full months difference
  let totalMonths = yearDiff * 12 + monthDiff;

  // Check if the end date's day is before the start date's day
  if (d2.getDate() < d1.getDate()) {
    totalMonths--; // Subtract 1 if a full month hasn't passed
  }

  return totalMonths > 0 ? totalMonths : 1; // Ensure non-negative result
}

export const extractEmirate = (googleApiResponse) => {
  if (!Array.isArray(googleApiResponse) || googleApiResponse.length === 0) return null;

  const addressComponents = googleApiResponse[0]?.address_components;
  if (!Array.isArray(addressComponents)) return null;

 
  const emirateComponent = addressComponents.find((component) =>
    component.types.includes("administrative_area_level_1")
  );

  return emirateComponent ? emirateComponent.long_name : null;
};

export const filterEmirateArrayByLabel = (emirateArray, label)=>{
  if(!Array.isArray(emirateArray) || typeof label !=="string") return null;
  const foundItem = emirateArray.find((item)=>item.name === label);
  return foundItem ? {value : foundItem.id , label : foundItem.name} : null ;
}

export const UAE_BOUNDS = {
  north: 26.5,
  south: 22.5,
  west: 51.5,
  east: 57.5,
};

import Layout from "./components/Layout/Layout";
import "../src/styles/responsive.css"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './assets/style/main.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { useEffect } from "react";
import FreshChat from 'react-freshchat'
import Freshchat from "react-freshchat";


function App() {
  // const TRACKING_ID = "G-NQXBGR67Q6";
  // ReactGA.initialize('YOUR_MEASUREMENT_ID');
//   useEffect(() => {

//     ReactGA.initialize(TRACKING_ID);
//     // Send pageview with a custom path
//     ReactGA.send({ hitType: "pageview", page: "/", title: "Landing Page" });
// }, [])
const location = useLocation();
useEffect(() => {
  // Track pageview when the route changes
  ReactGA.send({ hitType: "pageview", page: location.pathname });
}, [location]);

const freshchatConfig = {
  token: "WEB_CHAT_TOKEN", // Replace with your Freshchat token
  host: "https://wchat.freshchat.com" // Default Freshchat host
};
const handleInit = (widget) => {
  // Use the widget instance for further configuration
  widget.user.setProperties({
    email: "user@example.com",
    first_name: "John",
    last_name: "Doe",
    phone: "+1234567890",
  });
  // console.log("Freshchat widget initialized:", widget);
};
  return (
  <>
     <FreshChat
        token="fbec8b12-0e71-4c62-9a76-6afecc526681" // Replace with your Freshchat token
        email="user@example.com"
        first_name="John"
        onInit={handleInit}
      />

{/* <Freshchat config={freshchatConfig} /> */}
  <ToastContainer/>
    <Layout />
    </>);
}

export default App;

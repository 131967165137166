import React from "react";
import { Routes, Route, Navigate, useParams } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import CarListing from "../pages/CarListing";
import CarDetails from "../pages/CarDetails";
import Blog from "../pages/Blog";
import BlogDetails from "../pages/BlogDetails";
import NotFound from "../pages/NotFound";
import Contact from "../pages/Contact";
import Awards from "../pages/Awards";
import BookingDetails from "../pages/BookingDetails";
import Login from "../pages/login";
import ForgetPassword from "../pages/ForgetPassword";
import Offerspage from "../components/UI/Offerspage";
import OffersDetails from "../components/UI/OffersDetails";
import { BookingDetailsTwo } from "../pages/BookingDetailsTwo";
import Register from "../pages/Register";
import Location from "../components/UI/Location";
import OurFleet from "../components/UI/OurFleet";
import OurFleetList from "../components/UI/OurFleetList";
import CarEnquriry from "../components/UI/CarEnquriry";
import EmirateService from "../components/UI/EmiratesDetalis";
import ECarList from "../components/UI/ECarList";
import CareersPage from "../pages/CareersPage";
import LeasingCorporatePage from "../components/UI/LeasingCorporatePage";
import CommercialVehicles from "../components/UI/CommercialVehicles";
import Allblog from "../components/UI/Allblog";
import AboutUae from "../components/UI/AboutUae";
import OtherBlog from "../components/UI/OtherBlog";
import FqaPage from "../components/UI/FqaPage";
import Lostandfound from "../components/UI/Lostandfound";
import ContectUserPage from "../components/UI/ContectUserPage";
import CustomerFeedback from "../components/UI/CustomerFeedback";
import TermsCondition from "../components/UI/TermsCondition";
import PrivacyPolicy from "../components/UI/PrivacyPolicy";
import YourAccount from "../pages/MyAccount/YourAccount";
import ResetPassword from "../pages/ResetPassword";
// import CarsAndDetails from "../components/UI/CarsAndDetails";
import PaymentSuccess from "../components/UI/PaymentSuccess";
import PaymentFailed from "../components/UI/PaymentFail";
import LandmarkDetailsPage from "../components/UI/LandmarkDetailsPage";
import LandMarkDetailsPage from "../components/UI/LandmarkDetailsPage";
import NeighbourhoodDetailsPage from "../components/UI/NeighbourhoodDetailsPage";
import DriverDocuments from "../pages/MyAccount/DriverDocuments";
import Header from "../components/Header/Header";
import NewOurServices from "../components/UI/NewOurServices/NewOurServices";
import ChauffeurServices from "../components/UI/ChauffeurServices/ChauffeurServices";
import ChauffeurEnquiry from "../components/UI/ChauffeurServices/ChauffeurEnquiry";

const Routers = () => {
  const { lang } = useParams();

  return (
    <>
      <Routes>
        <Route path="/not-found" element={<NotFound />} />

        <Route path="/home" element={<Navigate to="/" />} />
        <Route path="/:lang/" element={<Navigate to="/" />} />
        <Route path="/:lang/home" element={<Navigate to="/" />} />
        <Route path="/" element={<Home />} />
        <Route path="/:lang/login" element={<Login />} />
        <Route path="/:lang/forgetPassword" element={<ForgetPassword />} />
        <Route path="/:lang/resetPassword" element={<ResetPassword />} />
        <Route path="/:lang/register" element={<Register />} />
        <Route path="/:lang/about" element={<About />} />
        <Route path="/:lang/cars" element={<CarListing />} />
        {/* <Route path="/:lang/cars/:slug" element={<CarDetails />} /> */}
        <Route path="/:lang/cars/:car_id" element={<CarDetails />} />
        <Route path="/:lang/blogs" element={<Blog />} />
        {/* <Route path="/:lang/blogs/:slug" element={<BlogDetails />} /> */}
        <Route path="/:lang/blogs/:slug-:id" element={<BlogDetails />} />
        <Route path="/blogs/:slug-:id" element={<BlogDetails />} />
        <Route path="/:lang/bookingDetails" element={<BookingDetails />} />
        <Route
          path="/:lang/bookingDetails/:booking_number"
          element={<BookingDetails />}
        />
        <Route
          path="/:lang/bookingDetailsTwo/:booking_number"
          element={<BookingDetailsTwo />}
        />
        <Route
          path="/:lang/bookingDetailsTwo"
          element={<BookingDetailsTwo />}
        />
        <Route path="/:lang/contact" element={<Contact />} />
        <Route path="/:lang/awards-and-accreditations" element={<Awards />} />
        <Route path="/:lang/carEnquriry" element={<CarEnquriry />} />
        <Route path="/:lang/offersPage" element={<Offerspage />} />
        <Route
          path="/:lang/offersDetails/:slug/:id"
          element={<OffersDetails />}
        />
        <Route path="/:lang/location" element={<Location />} />
        <Route path="/:lang/ourFleet" element={<OurFleet />} />
        <Route path="/:lang/ourFleetList" element={<OurFleetList />} />
        <Route path="/:lang/eCarList" element={<ECarList />} />
        <Route path="/:lang/careersPage" element={<CareersPage />} />
        <Route
          path="/:lang/corporate-leasing"
          element={<LeasingCorporatePage />}
        />
        <Route
          path="/:lang/commercialVehicles"
          element={<CommercialVehicles />}
        />
        <Route path="/:lang/allblog" element={<Allblog />} />
        <Route path="/:lang/aboutUae" element={<AboutUae />} />
        <Route path="/:lang/otherBlog" element={<OtherBlog />} />
        <Route path="/:lang/fqaPage" element={<FqaPage />} />
        <Route path="/:lang/lostandFound" element={<Lostandfound />} />
        <Route path="/:lang/ContectUsPage" element={<ContectUserPage />} />
        <Route path="/:lang/customerFeedback" element={<CustomerFeedback />} />
        <Route path="/:lang/termsCondition" element={<TermsCondition />} />
        <Route path="/:lang/our-services" element={<NewOurServices />} />
        <Route path="/:lang/chauffeur-services" element={<ChauffeurServices />} />
        <Route path="/:lang/enquire-chauffeur-services" element={<ChauffeurEnquiry />} />
        <Route path="/:lang/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/:lang/MyAccount/:type" element={<YourAccount />} />
        <Route path="/:lang/MyAccount" element={<YourAccount />} />
        <Route
          path="/:lang/MyAccount/DiverDocuments/:id"
          element={<DriverDocuments />}
        />
        <Route path="/:lang/CarDetails" element={<CarDetails />} />
        <Route path="/:lang/PaymentSuccess" element={<PaymentSuccess />} />
        <Route path="/PaymentSuccess" element={<PaymentSuccess />} />
        <Route path="/:lang/PaymentFailed" element={<PaymentFailed />} />
        <Route path="/PaymentFailed" element={<PaymentFailed />} />

        <Route
          path="/:lang/EmirateService/rent-a-car-in-:slug-:id"
          element={<EmirateService />}
        />
        <Route
          path="/:lang/EmirateLandmark/:slug/:id/:place_id"
          element={<LandMarkDetailsPage />}
        />
        <Route
          path="/:lang/EmirateNeighbourhood/:slug/:id/:place_id"
          element={<NeighbourhoodDetailsPage />}
        />
        <Route path="/:lang/customer-feedback" element={<CustomerFeedback />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default Routers;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "remixicon/fonts/remixicon.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-stepper-js/dist/index.css'
import AppState from "../src/context/AppState";
import "./components/MultiLang/i18n.js"
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store/store'; // Ensure correct import path
import ReactGA from 'react-ga4';
import { BrowserRouter as Router } from "react-router-dom";

// Initialize Google Analytics with your Measurement ID
ReactGA.initialize(process.env.REACT_APP_GOOGLE_MEASUREMENT_ID); // Replace with your actual Measurement ID

const root = ReactDOM.createRoot(document.getElementById("root"));
//  //HERE ->
//  document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
root.render(
  // <React.StrictMode>
    <Router>
    <AppState>
      <Provider store={store} >
      <PersistGate loading={null} persistor={persistor}>
    
      <App />
      </PersistGate>
      </Provider>
      </AppState>
    </Router>
    // </React.StrictMode>
 

);

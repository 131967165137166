import React, { useContext, useState } from "react";
import { Carousel, Tab, Tabs } from "react-bootstrap";
// import Stepper from "react-stepper-js";
import icon1 from "../../assets/all-images/cars-img/Layer_1.svg";
import "../../styles/bookingstepper.css";
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useSelector, useDispatch } from "react-redux";
import "./stepper.css";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import { toggle_got_to_edit } from "../../reducers/Slices/goToEditSlice"; //import action
import Slider from "react-slick";

const BookingStepper = (props) => {
  const language = useSelector((state) => state.language.language);
  const { /* set_edit_edit_form, edit_edit_form */} =
  useContext(AppContext);
  const {
    carCategories,
    handleCarCategorySelection,
    selectedCarCategory,
    set_edit_edit_form,
  } = props;
  const { booking_number } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stepperPage = useSelector((state) => state.stepper.stepperPage);
  const got_to_edit = useSelector((state) => state.got_to_edit.got_to_edit);
  const steps = ["Edit Itinerary", "Choose a Car", "Choose Extras"];
  const settings = {
    dots: true,
    infinite: false,
    arrows : false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 330,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#784af4",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#784af4",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  const handleStepClick = (label) => {
    if (label === "Edit Itinerary") {
      dispatch(toggle_got_to_edit());
      set_edit_edit_form((prevState) => !prevState);
    } else if (label === "Choose a Car") {
      if (booking_number) {
        navigate(`/${language}/bookingDetails/${booking_number}`);
      } else {
        navigate(`/${language}/bookingDetails`);
      }
    }
  };

  return (
    <div>
      {/* <Stepper
        color="#342978"
        fontSize="1rem"
        fontColor="#342978"
        fontWeight={700}
      
        steps={[
          { label: "Edit Itinerary" },
          { label: "Choose a Car"},
          { label: "Choose Extras" },
        ]}
        currentStep={ location?.pathname === '/bookingDetails' ? 2 : (location?.pathname === '/bookingDetailsTwo' ? 3 : 1)}
        
    /> */}

      <Box sx={{ width: "100%", marginTop: "2rem" }}>
        <Stepper
          // activeStep={
          //   location.pathname === "/bookingDetails"
          //     ? 1
          //     : location.pathname === "/bookingDetailsTwo"
          //     ? 2
          //     : 0
          // }
          activeStep={
            location.pathname ===
            (booking_number
              ? `/${language}/bookingDetails/${booking_number}`
              : `/${language}/bookingDetails`)
              ? 1
              : location.pathname ===
                (booking_number
                  ? `/${language}/bookingDetailsTwo/${booking_number}`
                  : `/${language}/bookingDetailsTwo`)
              ? 2
              : 0
          }
          alternativeLabel
          connector={
            <QontoConnector />
          } /*  connector={<CustomStepConnector activeStep={activeStep} />} */
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                onClick={() => handleStepClick(label)}
                style={{ cursor: "pointer" }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      {/*below is f's responsive  */}

      {(location.pathname === `/${language}/bookingDetails` ||
        booking_number) && (
          // <div className={`my-5 car_category__section d-block d-md-none category-item ${selectedCarCategory === category?.id ? "active" : ""}`}>
        <div className="  my-5 car_category__section d-block d-md-none">
          {carCategories && carCategories?.length > 0 && (
          <Slider {...settings}>
            {Array.isArray(carCategories) &&
              carCategories?.map((category) => (
                <div
                  key={category?.id}
                  onClick={() => handleCarCategorySelection(category?.id)}
                  className={`category-item ${selectedCarCategory === category?.id ? "active" : ""}`}
                >
                  <span style={{ display: "inline-block" }}>
                    <img className="my-1 category_img category_img-car-suv" src={category?.image} alt={`${category?.name} Image`} />
                    {category?.name}
                  </span>
                
                
                
                </div>
              ))}
          </Slider>
          )}
        </div>
      )}

      {/* below is original mine */}
      {(location.pathname === `/${language}/bookingDetails` ||
        booking_number) && (
        <div className="my-5 car_category__section  d-none d-md-block">
          <Tabs
            activeKey={selectedCarCategory}
            id="uncontrolled-tab-example"
            className="justify-content-evenly mb-3 gap-2"
            onSelect={handleCarCategorySelection}
          >
            {Array.isArray(carCategories) &&
              carCategories?.map((category) => (
                <Tab
                  key={category?.id}
                  eventKey={category?.id}
                  title={
                    <div className="category_img-wrapper">
                    <span style={{ display: "inline-block" }}>
                      <img className="my-1 category_img"  src={category?.image} alt=  {category?.name} />
                     
                    </span>
                       <span style={{ display: "inline-block" }}>
                         {category?.name}
                       </span>
                       </div>
                  }
                />
              ))}
            {/* <Tab
              eventKey="sedan"
              title={
                <span>
                  <img className="my-1 category_img" src={icon1} alt="" />
                  Sedan
                </span>
              }
            />
            <Tab
              eventKey="electric"
              title={
                <span>
                  <img className="my-1 category_img" src={icon2} alt="" />
                  Electric
                </span>
              }
            />
            <Tab
              eventKey="pickup"
              title={
                <span>
                  <img className="my-1 category_img" src={icon3} alt="" />
                  pickup
                </span>
              }
            />
            <Tab
              eventKey="sports"
              title={
                <span>
                  <img className="my-1 category_img" src={icon4} alt="" />
                  Sports Coupe
                </span>
              }
            />
            <Tab
              eventKey="sports"
              title={
                <span>
                  <img className="my-1 category_img" src={icon4} alt="" />
                  Sports Coupe
                </span>
              }
            />
            <Tab
              eventKey="sports"
              title={
                <span>
                  <img className="my-1 category_img" src={icon4} alt="" />
                  Sports Coupe
                </span>
              }
            />
            <Tab
              eventKey="sports"
              title={
                <span>
                  <img className="my-1 category_img" src={icon4} alt="" />
                  Sports Coupe
                </span>
              }
            /> */}
          </Tabs>
        </div>
      )}
    </div>
  );
};

export default BookingStepper;

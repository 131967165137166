import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Helmet from "../Helmet/Helmet";
import CommonSection from "./CommonSection";
import { Container } from "react-bootstrap";

const CarEnquiry = () => {
  return (
    <Helmet title="Enquiry">
      <CommonSection title="Contact" />
      <section>
        <Container>
      
        <h5 className="fw-bold mb-4 text-center">If you like to book on your own, please book directly now or put in your request for a quotation</h5>
          <Card className="w-75 mx-auto border  shadow-lg">
   

            {/* Adjust the width using Bootstrap utility classes */}
            <Card.Body className="p-4">
            <h6 className="fw-bold mb-4 ">QUOTATION REQUEST</h6>
              <Form>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter First Name"
                      className="w-100" // Make the input width 100%
                    />
                  </Form.Group>

                  <Form.Group as={Col} >
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      className="w-100" // Make the input width 100%
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    placeholder="Email Address"
                    className="w-100" // Make the input width 100%
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Emirate </Form.Label>
                  <Form.Control
                    placeholder="Emirate "
                    className="w-100" // Make the input width 100%
                  />
                </Form.Group>
                </Row>
                <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridState">
                    <Form.Label>Enquiry Type</Form.Label>
                    <Form.Select
                      defaultValue="Choose..."
                      className="w-100" // Make the select width 100%
                    >
                      <option>Choose...</option>
                      <option>...</option>
                    </Form.Select>
                  </Form.Group>
                  

                  <Form.Group as={Col} controlId="formGridState">
                    <Form.Label>Duration</Form.Label>
                    <Form.Select
                      defaultValue="Duration..."
                      className="w-100" // Make the select width 100%
                    >
                      <option>Choose...</option>
                      <option>...</option>
                    </Form.Select>
                  </Form.Group>

               
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                  <Form.Label>Select the car that you want to enquire for (optional)</Form.Label>
                  <Form.Control
                
                    className="w-100" // Make the input width 100%
                  />
                </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                  <Form.Label>Additional Comments</Form.Label>
                  <Form.Control
                
                    className="w-100" // Make the input width 100%
                  />
                </Form.Group>
                </Row>
                <Button variant="primary" type="submit" className="btn">
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </Helmet>
  );
};

export default CarEnquiry;

import React ,{useContext, useEffect}from "react";
import { Container, Row, Col, Badge } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import { useSelector } from 'react-redux';
import { setSessionStorage, getSessionStorage } from "./sessionStorage";
import useMediaQuery from "../../SharedComponent/useMediaQueryHook";
const BookingAddressSection = (props) => {
  // const {carArray} = props;
  const language = useSelector((state)=>state.language.language);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const carArray = useSelector((state) => state.carArray.carArray);
  const selectedPickupLocation = useSelector((state)=>state.selectedPickupLocation.selectedPickupLocation);
  const selectedDropoffLocation = useSelector((state)=>state.selectedDropoffLocation.selectedDropoffLocation);
  const inputValue = useSelector((state)=>state.inputValue.inputValue);
  const inputValueDropoff = useSelector((state)=>state.inputValueDropoff.inputValueDropoff);
  const {/* selectedPickupLocation, */ /* selectedDropoffLocation, */ /* inputValue, */ /* inputValueDropoff */ } = useContext(AppContext)
const requestBody_dropoff = useSelector((state) => state.requestBody_dropoff.requestBody_dropoff);
const requestBody_pickup = useSelector((state) => state.requestBody_pickup.requestBody_pickup);
// const selectedPickupLocation = useSelector((state) => state.selectedPickupLocation.selectedPickupLocation);

// Function to format the date
const formatDate = (dateString) => {
  // Check if the dateString is valid
  if (!dateString) return '';

  // Create a Date object from the date string
  const date = new Date(dateString);

  // Check if the date object is valid
  if (isNaN(date.getTime())) {
    return 'Invalid Date';
  }

  // Define options for formatting the date
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  if(!isMobile){
    options.weekday = 'long'
  }

  // Format the date using Intl.DateTimeFormat
  return new Intl.DateTimeFormat('en-US', options).format(date);
};
// Function to convert 24-hour time to 12-hour format with AM/PM
const convertTimeTo12Hour = (timeString) => {
  const [hours, minutes] = timeString?.split(':');
  const hour = parseInt(hours, 10);
  const period = hour >= 12 ? 'PM' : 'AM';
  const adjustedHour = hour % 12 || 12; // Adjust hour to 12-hour format and handle midnight (0)
  return `${adjustedHour}:${minutes} ${period}`;
};

  return (
    <div className="display-none-in-small-screen">
      {/* <h1 className="pt-2 m-0 text-center text-light">Your booking Deatils</h1> */}
  
      <Container className="my-2 p-sm-0">
        <div className="location__details px-3">
          <Row className="text-center mobile-address-grid">
            <Col  className="p-0 pickup__details" md="4">
              <h5 className="location__header m-0">Pick Up Location</h5>
              <div className="address__section p-1">
                <h6 className="pickup__address pt-0 pt-md-3">
               
{ requestBody_pickup?.pickup_type === 'delivery' ?  <p>{inputValue}</p> : <p>{isMobile ? selectedPickupLocation?.label : selectedPickupLocation?.address }</p> }
  
              
                </h6>
                <p className="pickup__datetime pt-3-">
                  {formatDate(requestBody_pickup?.pickup_date)}{" "}{requestBody_pickup?.pickup_time} {/* {convertTimeTo12Hour(requestBody_pickup?.pickup_time)} */}

                </p>
              </div>
            
            </Col>
            <Col  md={4} className="p-0 mobile-grid-child-duration">
              {" "}
              <h5 className="location__header m-0">&nbsp;</h5>
              <div className="address__section your-plan-flex p-1 ">
                <Badge className="to__badge mt-2 display-none-mobile">To</Badge>
                <h6 className="mt-4- pickup__datetime">
  Your plan for {requestBody_pickup?.booking_type === 'monthly' ?  carArray?.[0]?.booking_months   : carArray?.[0]?.booking_days} {requestBody_pickup?.booking_type === 'monthly' ? Number(carArray?.[0]?.booking_months) === 1 ? 'month':'months' : Number(carArray?.[0]?.booking_days) === 1 ? 'day' :  'days' }
   {requestBody_pickup?.booking_type === 'monthly' && 
  Number(carArray?.[0]?.flexi_days) >0 && 
  ` & ${carArray?.[0]?.flexi_days} ${Number(carArray?.[0]?.flexi_days) === 1 ? 'day' : 'days'}`
}
</h6>
              </div>
             </Col>
            <Col  className="p-0" md="4">
              <h5 className="location__header m-0">Drop Off Location</h5>
              <div className="address__section p-1">
                <h6 className="pickup__address pt-0 pt-md-3">
                  { requestBody_dropoff?.dropoff_type === 'collection' ? ( <p>{inputValueDropoff ? inputValueDropoff : inputValue}</p>) : (<p>{isMobile ? (selectedDropoffLocation?.label ?  selectedDropoffLocation?.label : selectedPickupLocation?.label) : (selectedDropoffLocation?.address ?  selectedDropoffLocation?.address : selectedPickupLocation?.address) }</p>) }
                </h6>
                <p className="pickup__datetime pt-3-">
                  {formatDate(requestBody_dropoff?.dropoff_date)}{" "}{requestBody_dropoff?.dropoff_time}  {/* {convertTimeTo12Hour(requestBody_dropoff?.dropoff_time)} */}
                </p>
              </div>
            
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default BookingAddressSection;

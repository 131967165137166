import React, { useContext, useEffect, useState } from "react";

// import logo from "../../assets/images/Web-Application-Logo.svg";
import { Form, Spinner, Container, Col } from "react-bootstrap";

import { Link } from "react-router-dom";
import { PostCallWithErrorResponse, simpleGetCall } from "../config.js/SetUp";
import configWeb from "../config.js/configWeb";
import logo from "../assets/new-logo/logo.png";
import { simplePostCall } from "../config.js/SetUp";
import { notifyError, notifySuccess } from "../SharedComponent/notify";
import "../styles/login.css";
import CommonSection from "../components/UI/CommonSection";
import CarImg from "../../src/assets/all-images/loginCar.svg";
import { useTranslation } from "react-i18next";
import FindCarForm2 from "../components/UI/FindCarForm2";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoginFromRegister } from "../reducers/Slices/isLoginFromRegisterSlice";
import { pixelLeadEvent } from "../actions/facebookPixelEvents";

const Login = () => {
  const { t, i18n } = useTranslation();
  const [emiratesArray, setEmiratesArray] = useState([]);
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const language = useSelector((state) => state.language.language);

const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, set_loading] = useState(false);

  const [errMsg, setErrMsg] = useState({ email: "", password: "" });
useEffect(()=>{
  window.scroll(0,0);
},[])
  

  const Login = () => {
    let body = {
      email: email,

      password: password,
    };

    set_loading(true);
    simplePostCall(configWeb.POST_LOGIN, JSON.stringify(body))
      .then((res) => {
        if (!res?.error) {
          const now = new Date();
          const token_item = {
            access_token: res?.access_token,
            user_id: res?.user_id,
            expiry: now.getTime() + 2 * 60 * 60 * 1000, //2 hours from now
            // expiry: now.getTime() +  60 * 1000, // 1 minutes from now
          };
          localStorage.setItem("token", JSON.stringify(token_item));
          notifySuccess("Login Successfull.");
          pixelLeadEvent("Login");
          navigate(`/`);
          dispatch(setIsLoginFromRegister(false));
        } else {
          notifyError(res.message);
        }
      })
      .catch((errr) => {
        notifyError("Something went wrong, please try again");
      })
      .finally(() => {
        set_loading(false);
      });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      Login();
    }

    setValidated(true);
  };

  const emiratesData = () => {
    const url = `${configWeb.GET_EMIRATES}?lang=${language}`;
    simpleGetCall(url)
      .then((res) => {
        setEmiratesArray(res);
      })
      .catch((errr) => {
        console.log("errr", errr);
      })
      .finally(() => {
        // set_loading(false);
      });
  };
  useEffect(()=>{
    // emiratesData();
  },[language])

  return (
    <>  
      {/* <section className="pb-1 form-section" >
    <h1
      className="pt-2 m-0 text-center text-light booking__tital-dream"
      style={{ fontFamily: "Rubik" }}
    >
      {t("BOOK YOUR DREAM CAR NOW")}!
    </h1>
    <Col id="booking-form-container" lg="12" md="12" sm="12">
      <Container>
        <FindCarForm2 emiratesArray={emiratesArray} />
      </Container>
    </Col>
  </section> */}
    <div className="main-auth my-4 ">
      
      <CommonSection title="Login" />
      <Container>
        <div className="login-wrapper">
          <div className="row height-style">
            <div className="col-lg-6 col-md-12 left">
              <div className="bg-img">
                <h3 className="mt-5 mb-0">
                  A New Way To Track ! <br /> Your Vehicle
                </h3>
                <img src={CarImg} className="carImg" alt="" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12 right cx-relative">
              <div className="wrapper row">
                <div>
                  <div className="top-logo">
                    <img src={logo} alt="" />
                  </div>
                  <h3>Welcome Back !</h3>
                  <div className="auth-form">
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <div className="mb-4">
                        <Form.Label className="common-labels">
                          Email ID / Mobile Number
                        </Form.Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder="Enter Your Email ID / Mobile Number"
                          value={email}
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, email: "" });

                            setEmail(e.target.value);
                          }}
                        />

                        <Form.Control.Feedback type="invalid">
                          Please Enter Email ID / Mobile Number.
                        </Form.Control.Feedback>
                      </div>
                      <div className="mb-2">
                        <Form.Label className="common-labels">
                          Password
                        </Form.Label>
                        <Form.Control
                          required
                          type="password"
                          placeholder="Enter your password "
                          value={password}
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, password: "" });
                            setPassword(e.target.value);
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter password.
                        </Form.Control.Feedback>
                      </div>
                      <div className="forgot-link">
                        <Link to={`/${language}/ForgetPassword`}>Forgot Password ?</Link>
                      </div>
                      <div className="btn-auth">
                        {/* <Link to="/"> */}

                        <button
                          type="submit"
                          className="filled-btn"
                          //   onClick={Userlogin}
                          disabled={loading ? true : false}
                        >
                          {loading ? (
                            <Spinner animation="border" variant="#1D288E" />
                          ) : (
                            "Log In"
                          )}
                          {/* {loading && (
                            <ColorRing
                              visible={true}
                              height="30"
                              width="30"
                              ariaLabel="blocks-loading"
                              wrapperStyle={{}}
                              wrapperClass="blocks-wrapper"
                              colors={[
                                "#e15b64",
                                "#f47e60",
                                "#f8b26a",
                                "#abbd81",
                                "#849b87",
                              ]}
                            />
                          )} */}
                        </button>
                        {/* </Link> */}
                        <div className="or-section-main"></div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
    </>
 );
};

export default Login;

import React, { useContext, useEffect, useState } from "react";

// import logo from "../../assets/images/Web-Application-Logo.svg";
import { Form, Spinner, Container } from "react-bootstrap";

import { Link } from "react-router-dom";
// import {PostCallWithErrorResponse} from '../config.js/SetUp'
import configWeb from "../../config.js/configWeb";
import logo from "../../assets/new-logo/logo.png";
import { simplePostCall } from "../../config.js/SetUp";
import { notifyError, notifySuccess } from "../../SharedComponent/notify";
import "../../styles/login.css";
import CommonSection from "../../components/UI/CommonSection";

import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./LoginModal.css";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoginFromRegister } from "../../reducers/Slices/isLoginFromRegisterSlice";
import { pixelLeadEvent } from "../../actions/facebookPixelEvents";
import ExistingCustomerRadio from "../../SharedComponent/AreYouExistingUser/ExistingCustomerRadio";

function LoginModal({
  loginModalShow,
  setLoginModalShow,
  setRegisterModalShow,
  setForgetPasswordModalShow,
  setCardModalShow,
  isExistingCustomer,
  handleSelectionChange,
  setIsExistingCustomer
}) {
  // const [loginModalShow, setLoginModalShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useSelector((state) => state.language.language);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, set_loading] = useState(false);

  const [errMsg, setErrMsg] = useState({ email: "", password: "" });
  // const [isExistingCustomer, setIsExistingCustomer] = useState("yes");

  // const handleSelectionChange = (value) => {
  //   if (value === "no") {
  //     setLoginModalShow(false);
  //     setRegisterModalShow(true);
  //   }
  //   setIsExistingCustomer(value);
  // };
  
  const Login = () => {
    let body = {
      email: email,

      password: password,
    };

    set_loading(true);
    simplePostCall(configWeb.POST_LOGIN, JSON.stringify(body))
      .then((res) => {
        if (!res?.error) {
          
          const now = new Date();
          const token_item = {
            access_token: res?.access_token,
            user_id: res?.user_id,
            expiry: now.getTime() + 3 * 60 * 60 * 1000, //3 hours from now
            // expiry: now.getTime() + 1 * 60 * 1000, // 5 minutes from now
          };
          localStorage.setItem("token", JSON.stringify(token_item));
          notifySuccess("Login Successfull.");
          pixelLeadEvent("Login");
          setLoginModalShow(false);
          dispatch(setIsLoginFromRegister(false));
          // setCardModalShow(true);
        } else {
          notifyError(res.message);
        }
      })
      .catch((errr) => {
        notifyError("Something went wrong, please try again");
      })
      .finally(() => {
        set_loading(false);
      });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      Login();
    }

    setValidated(true);
  };

  const handleRegisterClick = () => {
    setRegisterModalShow(true);
    // setForgetPasswordModalShow(true);
    setLoginModalShow(false);
  };
  const handleForgetPasswordClick = () => {
    setForgetPasswordModalShow(true);
    setLoginModalShow(false);
  };

  return (
    <>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={loginModalShow}
        onHide={() => setLoginModalShow(false)}
        className="custom-modal-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
      
       </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="wrapper row">
            <div>
              {/* <div className="d-flex justify-content-center">
                <div className="top-logo mb-4">
                  <img src={logo} alt="" width={400} />
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <h3>Welcome Back !</h3>
              </div> */}
              <div className="custom-radio-component">
                  <ExistingCustomerRadio
        value={isExistingCustomer}
        onSelectionChange={handleSelectionChange}
      /> 
      </div>

              <div className="auth-form">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <Form.Label className="common-labels">
                      Email ID / Mobile Number
                    </Form.Label>
                    <Form.Control
                      required
                      type="email"
                      placeholder="Enter Your Email ID / Mobile Number"
                      value={email}
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, email: "" });
                        setEmail(e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Email ID / Mobile Number.
                    </Form.Control.Feedback>
                  </div>
                  <div className="mb-2">
                    <Form.Label className="common-labels">Password</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      placeholder="Enter your password "
                      value={password}
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, password: "" });
                        setPassword(e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter password.
                    </Form.Control.Feedback>
                  </div>
                  <div className="forgot-link-- d-flex justify-content-between my-4">
                    {/* <Link
                      to="#"
                      className="forgot-password"
                      onClick={handleRegisterClick}
                    >
                      Register
                    </Link> */}
                    <Link
                     to="#" /*  to={`/${language}/ForgetPassword`}  */
                      className="forgot-password"
                      onClick={handleForgetPasswordClick}
                    >
                      Forgot Password ?
                    </Link>
                {/* <div  className="forgot-password"
                      onClick={handleForgetPasswordClick}>
                  Forgot taleeb
                </div> */}
                  </div>
                  <div className="btn-auth">
                    <button
                      type="submit"
                      className="filled-btn"
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner animation="border" variant="#1D288E" />
                      ) : (
                        "Login & Continue"
                      )}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="close-button"
            onClick={() => setLoginModalShow(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LoginModal;

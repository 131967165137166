import React, { useEffect, useState } from "react";
import Helmet from "../Helmet/Helmet";
import FindCarForm2 from "./FindCarForm2";
import CommonSection from "./CommonSection";
import "../../styles/corporate.css";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import configWeb from "../../config.js/configWeb";
import { simpleGetCall } from "../../config.js/SetUp";
import carlogo from "../../assets/new-logo/logo.png";

import { decode } from "html-entities";
import { useTranslation } from "react-i18next";


import { Spinner } from "reactstrap";
import MetaHelmet from "../Helmet/MetaHelmet";
import EnquireNowButton from "../../SharedComponent/EnquireNowButton/EnquireNowButton";
const LeasingCorporatePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.language.language);
  const [corporateLeasing, setCorporateLeasing] = useState([]);
  const [loading, setLoading] = useState(true);
  const [emiratesArray, setEmiratesArray] = useState([]);
  const emiratesData = () => {
    const url = `${configWeb.GET_EMIRATES}?lang=${language}`;
    simpleGetCall(url)
      .then((res) => {
        setEmiratesArray(res);
      })
      .catch((errr) => {
        console.log("errr", errr);
      })
      .finally(() => {
        // set_loading(false);
      });
  };
  useEffect(()=>{
    emiratesData();
  },[])
  const getCorporateLeasing = () => {
    const url = `${configWeb.GET_PRIVACY_POLICY(
      "corporate_leasing"
    )}?lang=${language}`;
    simpleGetCall(url)
      .then((res) => {
        if (!res?.error) {
          setCorporateLeasing(res || []); // Ensure it"s always an array
        }
      })
      .catch((error) => {
        console.log("Special offers API failed-->", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getCorporateLeasing();
  }, [language]);

  const processHTMLContent2 = (htmlContent) => {
    // Decode HTML entities
    const decodedContent = decode(htmlContent);

    // Replace \n with actual new lines (if needed)
    return decodedContent.replace(/\\n/g, " ");
  };

  const processHTMLContent = (htmlContent) => {
    // Parse the string into a DOM structure
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    // Return the cleaned up outerHTML
    return doc.body.innerHTML;
  };

  const generateStructuredData = () => {
    return {
      "@context": "https://schema.org",
      "@type": "Article",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": window.location.href,
      },
      headline: corporateLeasing?.title || "CORPORATE LEASING",
      description: corporateLeasing?.seo_meta_description,
      image: corporateLeasing?.image,
      author: {
        "@type": "Organization",
        name: "Autostrad",
      },
      publisher: {
        "@type": "Organization",
        name: "Autostrad",
        logo: {
          "@type": "ImageObject",
          url: carlogo,
        },
      },
      datePublished: "2024-10-11",
      dateModified: "2024-10-12",
    };
  };

  return (
    // <Helmet title="CORPORATE LEASING & FLEET MANAGEMENT">
    <>
 <MetaHelmet
        title={corporateLeasing?.title}
        description={corporateLeasing?.seo_meta_description}
        keywords={corporateLeasing?.seo_meta_tags || "car rental, affordable cars, rent a car"}
        ogTitle={corporateLeasing?.title}
        ogDescription={corporateLeasing?.seo_meta_description}
        ogImage={corporateLeasing?.image}
        ogUrl= {window.location.href}
        twitterTitle={corporateLeasing?.title}
        twitterDescription={corporateLeasing?.seo_meta_description}
        twitterImage={corporateLeasing?.image}
        twitterCard="summary_large_image"
        // twitterSite="@YourTwitterHandle"
        // twitterCreator="@AuthorTwitterHandle"
        canonicalUrl = {`${configWeb.BASE_WEB_URL}/en/corporate-leasing`}
        hreflangs={[
          { hreflang: "en", href: `${configWeb.BASE_WEB_URL}/en/corporate-leasing` },
          { hreflang: "ar", href: `${configWeb.BASE_WEB_URL}/ae/corporate-leasing` },
        ]}

      />
      
{loading ?  <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh", width: "100vw" }}
        >
        
          <Spinner />
        </div> :  <>
        {/* <section className="pb-1 form-section" >
        <h1
          className="pt-2 m-0 text-center text-light booking__tital-dream"
          style={{ fontFamily: "Rubik" }}
        >
          {t("BOOK YOUR DREAM CAR NOW")}!
        </h1>
        <Col id="booking-form-container" lg="12" md="12" sm="12">
          <Container>
            <FindCarForm2 emiratesArray={emiratesArray} />
          </Container>
        </Col>
      </section> */}
      <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(generateStructuredData()),
            }}
          />

      <CommonSection title="CORPORATE LEASING & FLEET MANAGEMENT" />
    
      {/* <div id="main-section" class="">

     

<div class="inner_section_main about section-gap-24px" id="sub-main-sec-1">

  <div class="container" id="my-container-1">

    <div class="row" id="my-row-2">

      <div class="blog_section padding-70">

        <div class="col-lg-12 col-md-12 col-sm-12">

          <h5 class="mb-2 font-size-25">

            <strong>Who is it right for?</strong>

          </h5>

          <ul class="text-justify content">

            <li>

              Are you a business or a company looking for vehicles to

              transport your goods, materials or to simply provide an

              option to commute your employees?

            </li>

            <li>Does your enterprise require many vehicles?</li>

            <li>

              Do you want to avoid paying lump sum amounts to purchase

              your own vehicles?

            </li>

            <li>

              Are you looking for a professional fleet management

              service at a fixed cost, to free up your time and

              resources so you can focus on running your business?

            </li>

          </ul>

          <p>

            <b>

              If the answer to any or all of these questions is yes then

              you and your company will definitely benefit from leasing

              your corporate fleet from Autostrad Rent a Car.

            </b>

          </p>

        </div>

      </div>

    </div>

  </div>

</div>

<div class="inner_section_main about section-gap-24px">

  <div class="our-values">
     <div class="enquire-now-button-in-corporate-div">
  <a href="https://staging.autostrad.com/en/Contact" >
 <button class='enquire-now-button-in-corporate'>
 Enquire Now
 </button>
 </a>
</div>

    <div class="container">

      <div class="row">

        <div class="col-sm-12">

          <div class="center image-title">

            <h5 class="mb-2 font-size-25 ">

            <strong>  Why choose Autostrad Rent A Car?</strong>

            </h5>

            <p class="content">

              Autostrad Rent A Car has become one of the leading

              transport solution providers in the UAE market for

              corporate leasing and fleet management solutions. We

              provide our services with proven expertise and experience

              in managing lease operations.

            </p>

          </div>

        </div>

      </div>

    </div>

  </div>

</div>



  <div class="our-heritage section-gap-24px">

    <div class="container" id="my-container-3" >

     <div style={{display:"flex", justifyContent:"space-between" ,flexWrap:"wrap"}} id="my-row-3">

      <div id="my-col-3-1">

          <div class id="my-col-3-1.1" >

            <h4 class="mb-4 font-size-25 mb-3">

              Sectors we cater to

            </h4>

            <ul

              class="content list-with-dots--"

              style={{ paddingLeft: "18px" }}

            >

              <li>

                Vehicles for company executives including senior

                management

              </li>

              <li>Sales and Field force vehicle requirement</li>

              <li>

                Distribution of perishable food and non-food items

              </li>

              <li>

                Construction & project-related movement of goods and

                manpower

              </li>

              <li>

                Hotels and travel sector requirements for guest movement

              </li>

              <li>Logistics sector requirements</li>

              <li>Oil & Gas sector field movements</li>

            </ul>

          </div>

        </div>

        <div id="my-col-3-2">

          <div class="side-img" id="my-col-3-2-img-1">

            <img

              src="https://files.staging.autostrad.com/admin/pages/corporate_leasing/68a7b76bc370e48211b36464543383f4.webp"

              alt="Side Image"

              class="img-fluid"

            />

          </div>

        </div>

      </div>

   </div>

  </div>



<div class="inner_section_main section-gap-24px " id="main-sec-2">

  <div class="blog_section padding-100" id="main-sec-2-1">

    <div class="container" id="main-sec-2-container">

      <div id="main-sec-2-row">

        <div class="col-lg-7 col-sm-12 " id="main-sec-2-col-1">

          <h5 class="mb-2 font-size-25">

            <strong>End to End solutions we provide</strong>

          </h5>

          <ul

            class="text-justify content3 list-with-dots--"

            style={{ paddingLeft: "18px" }}

          >

            <li>

              Complete fleet life cycle management services including

              analysis and advisory of fleet usage

            </li>

         

    

            <li>MIS & Reports provided</li>

            <li>

              Dedicated lease experts and coordinators for large fleet

              support

            </li>

            <li>

              Network in 5 Emirates to ensure accessibility & faster

              service delivery

            </li>

            <li>

              Complete freedom to choose vehicle of choice from any

              manufacturer

            </li>

            <li>

              Customized vehicle solutions completely tailored to your

              business needs

            </li>

            <li>Flexible lease options with no hidden costs</li>

            <li>

              Single monthly bill covering entire vehicle usage activity

            </li>

            <li>Pick up & Drop facility for fleet services</li>

            <li>

              With our strong fleet replacement vehicles will be

              provided to ensure uninterrupted mobility

            </li>

            <li>

              Paper less approach to vehicle delivery & services

            </li>

            <li>

              World Class customer service, with 24/7 breakdown and

              recovery service

            </li>

            <li>Minimum administration procedures</li>

            <li>

              Customer portal access to coordinators and drivers/users

            </li>

            <li>Vehicle tracking and smart diagnostic services</li>

            <li>

              Health & Safety assessment with accident history

              tracking, analysis & reporting

            </li>

            <li>

              Optional Services like Project Management &

              Value-Added Services

            </li>

            <li>Easy Exit facility</li>

          </ul>

        </div>

        <div class="col-lg-5 col-md-5 col-sm-12 " id="main-sec-2-col-2">

          <div class="side-img mt-52"id="main-sec-2-col-2-img1-div" >

            <img id="main-sec-2-col-2-img1"

              src="https://files.staging.autostrad.com/admin/pages/corporate_leasing/c2d6ce0c45d4e9afa98966e846625a6d.webp"

              class="img-fluid w-100"

              alt="Side Image 1"

            />

          </div>

          <div class="side-img" id="main-sec-2-col-2-img2-div">

            <img  id="main-sec-2-col-2-img2"

              src="https://files.staging.autostrad.com/admin/pages/corporate_leasing/719b86e0bdc7fb92c4cde4ef99195a20.webp"

              class="img-fluid w-100"

              alt="Side Image 2"

            />

          </div>

        </div>

      </div>

    </div>

  </div>

</div>



<div class="our-heritage section-gap-24px">

  <div class="container">

    <div class="profile-div pb-100">

      <div class="row">

        <div class="col-sm-12 ">

        <h5 class="mb-2 font-size-25">
<strong>
              Advantages of leasing a fleet of vehicles over owning a

            fleet for your corporate fleet requirements
</strong>
          </h5>

          <div class="profile-holder">

            <div class="table-responsive ">

              <table class="table02">

                <tbody>

                  <tr class="hed-table">

                    <td class="hed-table-title">Criteria</td>

                    <td class="hed-table-title">

                      Company Owned Fleet

                    </td>

                    <td class="hed-table-title">

                      Leasing From Autostrad Rent a Car

                    </td>

                  </tr>

                  <tr class="hed-table-title-p">

                    <td>Best Vehicle purchase pricing</td>

                    <td>You need to negotiate for each vehicle</td>

                    <td>

                      Managed for you along with best prices as a fleet

                      buyer

                    </td>

                  </tr>

                  <tr class="hed-table-title-p">

                    <td>Insurance</td>

                    <td>

                      You will need cover as per business requirement

                    </td>

                    <td>

                      Managed for all kinds of business requirements at

                      best possible price

                    </td>

                  </tr>

                  <tr class="hed-table-title-p">

                    <td>Salik & Traffic fines management</td>

                    <td>Keep track of payment & details</td>

                    <td>

                      Managed for you and payments to be made on monthly

                      basis

                    </td>

                  </tr>

                  <tr class="hed-table-title-p">

                    <td>Vehicle routine service tracking</td>

                    <td>

                      Need to keep track of service intervals and

                      coordinate with various garages depending on

                      make/model of vehicle

                    </td>

                    <td>Managed completely by Autostrad Rent A Car </td>

                  </tr>

                  <tr class="hed-table-title-p">

                    <td>Vehicle break down</td>

                    <td>

                      Arrange break down assistance and get alternate

                      mobility arranged

                    </td>

                    <td>Managed completely by Autsotrad Rent A Car</td>

                  </tr>

                  <tr class="hed-table-title-p">

                    <td>Repairs, tyres and battery replacement</td>

                    <td>

                      Get correct diagnosis for repairs, best prices for

                      tyres and timely battery replacements

                    </td>

                    <td>Managed completely by Autostrad Rent A Car</td>

                  </tr>

                  <tr class="hed-table-title-p">

                    <td>Accident management</td>

                    <td>

                      Manage insurance claims, coordinate with garages

                      for timely and quality work

                    </td>

                    <td>Managed completely by Autsotrad Rent A Car</td>

                  </tr>

                  <tr class="hed-table-title-p">

                    <td>Insurance renewal, registration renewal</td>

                    <td>

                      Keep track of timely renewals, cost of renewal

                      negotiations and ensure vehicle registration

                      renewal is done within timeline after due

                      technical passing of vehicle from the authorities

                    </td>

                    <td>

                      Completely managed with no interruption and hassle

                      to the customer

                    </td>

                  </tr>

                  <tr class="hed-table-title-p">

                    <td>Replacement vehicle requirement</td>

                    <td>

                      Need to arrange alternate vehicle due to issues

                      such as service, repairs, or break down to ensure

                      mobility

                    </td>

                    <td>Uninterrupted mobility is assured</td>

                  </tr>

                  <tr class="hed-table-title-p">

                    <td>Cost budgeting</td>

                    <td>Unknown and uncertain</td>

                    <td>Pre-budgeted with no surprises</td>

                  </tr>

                  <tr class="hed-table-title-p">

                    <td>Sale of vehicle after useful life</td>

                    <td>

                      Get correct re-sale value and transfer safely

                    </td>

                    <td>

                      No risk as taken care by the leasing company

                    </td>

                  </tr>

                </tbody>

              </table>

            </div>

          </div>

        </div>

      </div>

    </div>

  </div>

</div>

<div class="blog_section padding-100 leasing-process section-gap-24px">

<div class="container">

<div class="row">

  <div class="col-lg-12 col-sm-12">

    <h5 class="mb-2 font-size-25">

      <strong>The Autostrad Rent a Car Leasing Process</strong>

    </h5>

    <p>

      At Autostrad Rent A Car, we make leasing as easy as we can with minimal paperwork. After receiving an initial sales inquiry, we set up a meeting to assess your requirements and understand any further needs that you may have as we believe in providing tailor-made solutions to suit our client’s needs. Based on this, we then present to you a solution proposal. Upon agreement to this proposal, we only require a few documents:

    </p>

    <ul class="content list-with-dots--">

      <li>

        A valid trade license copy

      </li>

      <li>

        A valid tax certificate copy

      </li>

      <li>

        A valid copy of passport, VISA, EID of the authorized signatory and his power of attorney

      </li>

    </ul>

    <p>

      The above is then submitted for document verification and approval. Once cleared, all we require is a signature from the Hirer on the VLC (Vehicle Leasing Contract) and you’re good to go!

      <br/>

      <b>What are you waiting for? <a href="https://staging.autostrad.com/en/Contact">Click here</a> to choose from a variety of lease options with Autostrad Rent A Car!</b>

    </p>

  </div>

</div>

</div>

</div>

</div> */}

<Container>
{/* <section className="px-2- px-lg-5- py-0">
<EnquireNowButton buttonText="Enquire Now" link={`/${language}/contact`} />
</section> */}
       <section className="px-2- px-lg-5-"
              dangerouslySetInnerHTML={{
                __html: processHTMLContent(corporateLeasing?.content),
              }}
            />
         </Container>
            </> }
    {/* </Helmet> */} </>
  
  
  );
};

export default LeasingCorporatePage;

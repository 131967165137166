import React, { useContext, useEffect, useState } from "react";

// import logo from "../../assets/images/Web-Application-Logo.svg";
import {Form, Spinner,Container} from "react-bootstrap";

import { Link } from "react-router-dom";
import {PostCallWithErrorResponse} from '../config.js/SetUp'
import configWeb from "../config.js/configWeb";
import logo from "../assets/new-logo/logo.png";
import { simplePostCall } from "../config.js/SetUp";
import { notifyError , notifySuccess} from "../SharedComponent/notify";
import "../styles/login.css";
import CommonSection from "../components/UI/CommonSection";
import CarImg from "../../src/assets/all-images/loginCar.svg";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ForgetPassword = () => {
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const language = useSelector((state) => state.language.language);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, set_loading] = useState(false);

  const [errMsg, setErrMsg] = useState({ email: "", password: "" });

 


  const Login = ( ) => {

    let body = {
      email : email,
    
  };

  set_loading(true);
  simplePostCall(
    configWeb.POST_FORGOT_PASSWORD, 
    JSON.stringify(body)
  )
    .then((res) => {
      if (!res?.error) {
        // localStorage.setItem('access_token', res?.access_token)
        notifySuccess('Success. We have sent you OTP via email.');
        navigate(`/${language}/ResetPassword`)
      } else {
        notifyError(res.message[0]);
       

        
      }
    })
    .catch((errr) => {
      notifyError('Something went wrong, please try again');
    })
    .finally(() => {
      set_loading(false);
    });

  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
  
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      Login();

    
    }


    setValidated(true);
  };

  

  return (
    <div className="main-auth my-4 ">
      <CommonSection title="Forgot Password" />
      <Container>
        <div className="login-wrapper">
          <div className="row height-style">
            <div className="col-lg-6 col-md-12 left">
              <div className="bg-img">
                <h3 className="mt-5 mb-0">
                  A New Way To Track ! <br /> Your Vehicle
                </h3>
                <img src={CarImg} className="carImg" alt="" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12 right cx-relative">
              <div className="wrapper row">
                <div>
                  <div className="top-logo">
                    <img src={logo} alt="" />
                  </div>
                  <h3 style={{color:'#1B3D6B'}}>Forgot Password</h3>
                  <div className="auth-form">
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <div className="mb-4">
                        <Form.Label className="common-labels">
                          Email ID
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Enter Your Email ID"
                          value={email}
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, email: "" });
                           
                            setEmail(e.target.value);
                          }}
                        />
                      
                        <Form.Control.Feedback type="invalid">
                          Please Enter Email ID.
                        </Form.Control.Feedback>
                      </div>
                    
                      <div className="btn-auth">
                        {/* <Link to="/"> */}
                       
                          <button
                            type="submit"
                            className="filled-btn"
                            //   onClick={Userlogin}
                              disabled={loading ? true : false}
                          >
                          { loading ? <Spinner animation="border" variant="#1D288E" /> :   "Submit" }
                            {/* {loading && (
                            <ColorRing
                              visible={true}
                              height="30"
                              width="30"
                              ariaLabel="blocks-loading"
                              wrapperStyle={{}}
                              wrapperClass="blocks-wrapper"
                              colors={[
                                "#e15b64",
                                "#f47e60",
                                "#f8b26a",
                                "#abbd81",
                                "#849b87",
                              ]}
                            />
                          )} */}
                          </button>
                        {/* </Link> */}
                        <div className="or-section-main"></div>
                      </div>
                    
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ForgetPassword;

import React, { useContext, useEffect, useState } from "react";
import BookingAddressSection from "../components/UI/BookingAddressSection";

import {
  Badge,
  Button,
  Card,
  Container,
  Figure,
  Form,
  Nav,
  Tab,
  Table,
  Tabs,
  Spinner,
  Carousel,
  Col,
  Row,
} from "react-bootstrap";
import BookingStepper from "../components/UI/BookingStepper";
import img1 from "../assets/all-images/car-parts/fig-1.png.webp";
import img from "../assets/all-images/cars-img/View.png";
import infoIcon from "../assets/all-images/detailsPage-icons/info-circle.svg"
import configWeb from "../config.js/configWeb";
import {
  simpleGetCall,
  simplePostCall,
  simplePostCallAuth,
} from "../config.js/SetUp";
import Slider from "react-slick";
import proImg1 from "../assets/all-images/detailsPage-icons/proimg1.svg";
import proImg2 from "../assets/all-images/detailsPage-icons/proimg2.svg";
import proImg3 from "../assets/all-images/detailsPage-icons/proimg3.svg";
import proImg4 from "../assets/all-images/detailsPage-icons/proimg4.svg";
import proImg5 from "../assets/all-images/detailsPage-icons/proimg5.svg";
import proImg6 from "../assets/all-images/detailsPage-icons/proimg6.svg";
import exampleCarimg from "../assets/all-images/cars-img/exampleCarimg.jpg"
import { useSelector, useDispatch } from "react-redux";
import LoginModal from "../components/UI/LoginModal";
import "../styles/bookingDetails.css";
import { AppContext } from "../context/AppContext";
import { setSelectedCar } from "../reducers/Slices/selectedCarSlice";
import { setCarExtraArray } from "../reducers/Slices/carExtraArray";
import { setFilterCarArray } from "../reducers/Slices/filterCarArraySlice";
import CardDetailsModal from "../components/UI/CardDetails";
import { setAddProtection } from "../reducers/Slices/addProtectionSlice";
import { useNavigate, useParams } from "react-router-dom";
import RegisterModal from "../components/UI/RegisterModal";
import { notifyError } from "../SharedComponent/notify";
import MetaHelmet from "../components/Helmet/MetaHelmet";
import {
  pixelInitiateCheckoutEvent,
  pixelViewContentEvent,
} from "../actions/facebookPixelEvents";
import ForgetPasswordModal from "../components/UI/ForgetPasswordModa";
import ResetPasswordModal from "../components/UI/ResetPasswordModal";

export const BookingDetailsTwo = () => {
  const { booking_number } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editUserBookingObject = useSelector(
    (state) => state.editUserBookingObject.editUserBookingObject
  );
  //  const [carExtraArray, setCarExtraArray] = useState([]);
  const { subscriptionPlan } = useContext(AppContext);
  const [loading_confirmBookin, setLoading_confirmBookin] = useState(false);
  const [installmentTableLoading, setInstallmentTableLoading] = useState(false);
  const [km_object, set_km_object] = useState(null);
  const [mileage_plan_array, set_mileage_plan_array] = useState(null);
  const [monthlyInstallmentArray, setMonthlyInstallmentArray] = useState([]);
  const [edit_edit_form, set_edit_edit_form] = useState(true);
  const [extra_enabled_check, set_extra_enabled_check] = useState(false);
  const [enabledExtras, setEnabledExtras] = useState([]);
  const [skipEvent, setSkipEvent] = useState(false);
  const [isExistingCustomer, setIsExistingCustomer] = useState("yes");
  const handleSelectionChange = (value) => {
    setIsExistingCustomer(value);

    if (value === "no") {
      setLoginModalShow(false);
      setRegisterModalShow(true);
    } else if (value === "yes") {
      setRegisterModalShow(false);
      setLoginModalShow(true);
    }
  };

  // State to track the selected mileage item
  const [selectedMileage, setSelectedMileage] = useState(3000);

  // Function to handle click on a mileage item
  const handleSelectMileage = (mileage) => {
    if (selectedMileage === mileage) {
      // Deselect if the same item is clicked again
      setSelectedMileage(3000);
    } else {
      // Select the clicked item
      setSelectedMileage(mileage);
    }
  };

  // const [activeKM, setActiveKM] = useState(null);
  const settings = {
    // dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const language = useSelector((state) => state.language.language);
  const carExtraArray = useSelector(
    (state) => state.carExtraArray.carExtraArray
  );
  const filterCarArray = useSelector(
    (state) => state.filterCarArray.filterCarArray
  );

  const requestBody_dropoff = useSelector(
    (state) => state.requestBody_dropoff.requestBody_dropoff
  );
  const requestBody_pickup = useSelector(
    (state) => state.requestBody_pickup.requestBody_pickup
  );
  const emiratesArray = useSelector(
    (state) => state.emiratesArray.emiratesArray
  );
  const stepperPage = useSelector((state) => state.stepper.stepperPage);
  const carArray = useSelector((state) => state.carArray.carArray);
  const selectedCar = useSelector((state) => state.selectedCar.selectedCar);
  const addProtection = useSelector(
    (state) => state.addProtection.addProtection
  );

  // State to track if GA event has been fired
  const [hasFiredEvent, setHasFiredEvent] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [carArrayTemp, setCarArrayTem] = useState([]);
  const [babyDriver, setBabyDriver] = useState({ baby_seat: 1, driver: 1 });
  const [loginModalShow, setLoginModalShow] = useState(false);
  const [registerModalShow, setRegisterModalShow] = useState(false);
  const [forgetPasswordModalShow, setForgetPasswordModalShow] = useState(false);
  const [resetPasswordModalShow, setResetPasswordModalShow] = useState(false);
  const [cardModalShow, setCardModalShow] = useState(false);
  const [confirmBookingData, setConfirmBookingData] = useState([]);
  const [confirmBookingPayment, setConfirmBookingPayment] = useState(null);
  const [loading_for_car_rate_table, set_loading_for_car_rate_table] =
    useState(false);
  // const [addProtection, setAddProtection] = useState({
  //   pai: false,
  //   cdw: false,
  //   scdw: false,
  //   baby_seat: false,
  //   gps: false,
  //   driver: false,
  // });
  const handleMouseEnter = (index, event) => {
    const rect = event.target.getBoundingClientRect();
    setTooltipPosition({
      top: rect.bottom + window.scrollY + 5, // Adjust for tooltip spacing
      left: rect.left + window.scrollX,
    });
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  // Reset `isExistingCustomer` to "yes" when the login modal is closed
  useEffect(() => {
    if (!loginModalShow && !registerModalShow) {
      setIsExistingCustomer("yes");
    }
  }, [loginModalShow, registerModalShow]);
  //function to handle KM plan
  const handle_km_plan_click = (kmValue, extraKM) => {
    if (addProtection?.activeKM === kmValue) {
      dispatch(
        setAddProtection({
          activeKM: null,
          extra_km: null,
        })
      );
    } else {
      // setActiveKM(kmValue);
      dispatch(
        setAddProtection({
          activeKM: kmValue,
          extra_km: extraKM,
        })
      );
    }
  };

  const getEnabledExtras = (updatedProtection) => {
    return Object.keys(updatedProtection)
      .filter((key) => updatedProtection[key])
      .map((extra) => {
        const carExtra = carExtraArray?.find(
          (item) => item[extra] !== undefined
        );
        return {
          item_id: `extra_${extra}`,
          item_name: extra,
          price: carExtra ? carExtra[extra] : 0,
          quantity:
            extra === "baby_seat"
              ? 1 * (babyDriver?.baby_seat ?? 1)
              : extra === "driver"
              ? 1 * (babyDriver?.driver ?? 1)
              : 1,
        };
      });
  };
  const handleAddProtecionChange = (e) => {
    const { name, checked } = e.target;

    // Update the addProtection state directly with the new value
    const updatedProtection = { ...addProtection, [name]: checked };
    dispatch(setAddProtection(updatedProtection));
    set_extra_enabled_check(checked);

    const recentlyModifiedExtra = {
      item_id: `extra_${name}`,
      item_name: name,
      price:
        carExtraArray?.find((item) => item[name] !== undefined)?.[name] || 0, // Use price if found, else default to 0
    };

    //   setEnabledExtras(enabledExtras)
    const updatedEnabledExtras = getEnabledExtras(updatedProtection);
    setEnabledExtras(updatedEnabledExtras);

    // Determine whether to add or remove from cart
    if (checked /* || Number(babyDriver?.baby_seat) > 1 */) {
      // Trigger `add_to_cart` event
      setSkipEvent(true);
      window.gtag("event", "add_to_cart", {
        currency: "AED",
        value:
          requestBody_pickup?.booking_type === "monthly"
            ? parseFloat(selectedCar?.car_rate_total || 0)
            : selectedCar?.payment_method === "pay_now"
            ? parseFloat(selectedCar?.pay_now || 0)
            : parseFloat(selectedCar?.pay_later || 0), // Adjust the value as needed
        items: [
          {
            item_id: selectedCar?.id,
            item_name: selectedCar?.car_name,
            affiliation: "Autostrad Rent A Car",
            price:
              requestBody_pickup?.booking_type === "monthly"
                ? parseFloat(selectedCar?.car_rate_total || 0)
                : selectedCar?.payment_method === "pay_now"
                ? parseFloat(selectedCar?.pay_now || 0)
                : parseFloat(selectedCar?.pay_later || 0),
            quantity: 1,
            category: selectedCar?.category,
            // discount: selectedCar?.discount,
            emirate_id: selectedCar?.emirate_id,
          },
          // ...enabledExtras, // Include all enabled extras
          ...updatedEnabledExtras,
        ],
      });

      window.gtag("event", "view_item", {
        currency: "AED",
        value:
          requestBody_pickup?.booking_type === "monthly"
            ? parseFloat(selectedCar?.car_rate_total || 0)
            : selectedCar?.payment_method === "pay_now"
            ? parseFloat(selectedCar?.pay_now || 0)
            : parseFloat(selectedCar?.pay_later || 0), // Adjust   the value as needed
        items: [
          {
            item_id: selectedCar?.id,
            item_name: selectedCar?.car_name,
            affiliation: "Autostrad Rent A Car",
            price:
              requestBody_pickup?.booking_type === "monthly"
                ? parseFloat(selectedCar?.car_rate_total || 0)
                : selectedCar?.payment_method === "pay_now"
                ? parseFloat(selectedCar?.pay_now || 0)
                : parseFloat(selectedCar?.pay_later || 0),
            quantity: 1,
            category: selectedCar?.category,
            discount: selectedCar?.discount,
            emirate_id: selectedCar?.emirate_id,
          },
          // ...enabledExtras, // Include all enabled extras
          ...updatedEnabledExtras,
        ],
      });
    } else {
      // Trigger `remove_from_cart` event with only the recently deselected extra
      window.gtag("event", "remove_from_cart", {
        currency: "AED",
        value: parseFloat(recentlyModifiedExtra?.price || 0),

        items: [
          recentlyModifiedExtra, // Include only recently removed extra
        ],
      });
    }
  };

  // Run gtag events whenever babyDriver changes
  useEffect(() => {
    if (Number(babyDriver?.baby_seat > 1) || Number(babyDriver?.driver > 1)) {
      const updatedEnabledExtras = getEnabledExtras(addProtection);
      setEnabledExtras(updatedEnabledExtras);

      window.gtag("event", "add_to_cart", {
        currency: "AED",
        value:
          requestBody_pickup?.booking_type === "monthly"
            ? parseFloat(selectedCar?.car_rate_total || 0)
            : selectedCar?.payment_method === "pay_now"
            ? parseFloat(selectedCar?.pay_now || 0)
            : parseFloat(selectedCar?.pay_later || 0),
        items: [
          {
            item_id: selectedCar?.id,
            item_name: selectedCar?.car_name,
            affiliation: "Autostrad Rent A Car",
            price:
              requestBody_pickup?.booking_type === "monthly"
                ? parseFloat(selectedCar?.car_rate_total || 0)
                : selectedCar?.payment_method === "pay_now"
                ? parseFloat(selectedCar?.pay_now || 0)
                : parseFloat(selectedCar?.pay_later || 0),
            quantity: 1,
            category: selectedCar?.category,
            // discount: selectedCar?.discount,
            emirate_id: selectedCar?.emirate_id,
          },
          ...updatedEnabledExtras,
        ],
      });

      window.gtag("event", "view_item", {
        currency: "AED",
        value:
          requestBody_pickup?.booking_type === "monthly"
            ? parseFloat(selectedCar?.car_rate_total || 0)
            : selectedCar?.payment_method === "pay_now"
            ? parseFloat(selectedCar?.pay_now || 0)
            : parseFloat(selectedCar?.pay_later || 0),
        items: [
          {
            item_id: selectedCar?.id,
            item_name: selectedCar?.car_name,
            affiliation: "Autostrad Rent A Car",
            price:
              requestBody_pickup?.booking_type === "monthly"
                ? parseFloat(selectedCar?.car_rate_total || 0)
                : selectedCar?.payment_method === "pay_now"
                ? parseFloat(selectedCar?.pay_now || 0)
                : parseFloat(selectedCar?.pay_later || 0),
            quantity: 1,
            category: selectedCar?.category,
            // discount: selectedCar?.discount,
            emirate_id: selectedCar?.emirate_id,
          },
          ...updatedEnabledExtras,
        ],
      });
    }
  }, [babyDriver]);
  // Function to handle input change
  const babySeatAndDriver = (e) => {
    const { name, value } = e.target;
    setBabyDriver((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    // if(Number(babyDriver?.baby_seat) > 1 || babyDriver?.driver > 1){
    if (!skipEvent) return;
    window.gtag("event", "add_to_cart", {
      currency: "AED",
      value:
        requestBody_pickup?.booking_type === "monthly"
          ? parseFloat(selectedCar?.car_rate_total || 0)
          : selectedCar?.payment_method === "pay_now"
          ? parseFloat(selectedCar?.pay_now || 0)
          : parseFloat(selectedCar?.pay_later || 0), // Adjust the value as needed
      items: [
        {
          item_id: selectedCar?.id,
          item_name: selectedCar?.car_name,
          affiliation: "Autostrad Rent A Car",
          price:
            requestBody_pickup?.booking_type === "monthly"
              ? parseFloat(selectedCar?.car_rate_total || 0)
              : selectedCar?.payment_method === "pay_now"
              ? parseFloat(selectedCar?.pay_now || 0)
              : parseFloat(selectedCar?.pay_later || 0),
          quantity: 1,
          category: selectedCar?.category,
          // discount: selectedCar?.discount,
          emirate_id: selectedCar?.emirate_id,
        },
        ...enabledExtras, // Include all enabled extras
      ],
    });
    setSkipEvent(false);
  }, [babyDriver]);

  // Function to handle input validation on blur
  const babySeatAndDriverBlur = (e) => {
    const { name, value } = e.target;
    if (value < 1) {
      setBabyDriver((prevState) => ({
        ...prevState,
        [name]: 1,
      }));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // setStepperpage(3);
  }, []);
  // const [filterCarArray, setFilterCarArray] = useState([]);

  const filterCars = (carArrayTemp, selectedCar) => {
    if (selectedCar?.payment_method === "pay_later") {
      return carArrayTemp?.filter(
        (car) => car.pay_later > selectedCar?.pay_later
      );
    } else if (selectedCar?.payment_method === "pay_now") {
      return carArrayTemp?.filter((car) => car?.pay_now > selectedCar?.pay_now);
    } else if (
      selectedCar?.payment_method === "pay_monthly_now" ||
      selectedCar?.payment_method === "pay_monthly_later"
    ) {
      return carArrayTemp?.filter(
        (car) => car?.car_rate_total > selectedCar?.car_rate_total
      );
    } else {
      return carArrayTemp; //return the original array
    }
  };

  const carSearch = async () => {
    const body = {
      booking_type: requestBody_dropoff?.booking_type,
      pickup_type: requestBody_pickup?.pickup_type,
      pickup_date: requestBody_dropoff?.pickup_date,
      pickup_time: requestBody_dropoff?.pickup_time,
      dropoff_type: requestBody_dropoff?.dropoff_type,
      dropoff_date: requestBody_dropoff?.dropoff_date,
      dropoff_time: requestBody_dropoff?.dropoff_time,
      booking_source: "web",

      // discount_coupon: ""
    };

    if (requestBody_pickup?.pickup_type === "self") {
      body.pickup_location_id = requestBody_pickup?.pickup_location_id;
    }
    if (requestBody_pickup?.pickup_type === "delivery") {
      body.pickup_emirate_id = requestBody_pickup?.pickup_emirate_id;
      body.pickup_coordinates = requestBody_pickup?.pickup_coordinates;
      body.pickup_address = requestBody_pickup?.pickup_address;
    }

    if (requestBody_dropoff?.dropoff_type === "collection") {
      body.dropoff_emirate_id = requestBody_dropoff?.dropoff_emirate_id;
      body.dropoff_coordinates = requestBody_dropoff?.dropoff_coordinates;
      body.dropoff_address = requestBody_dropoff?.dropoff_address;
    }
    if (requestBody_dropoff?.dropoff_type === "self") {
      body.dropoff_location_id = requestBody_dropoff?.dropoff_location_id;
    }

    if (requestBody_pickup?.booking_type === "monthly") {
      body.booking_months = requestBody_pickup?.booking_months;
    }
    if (requestBody_dropoff?.discount_coupon) {
      body.discount_coupon = requestBody_dropoff?.discount_coupon;
    }

    const baseUrl = `${configWeb.POST_CAR_SEARCH}?lang=${language}&page=1&page_size=100&sort=ASC`; //car_id=1

    const url = selectedCar?.car_category
      ? `${baseUrl}&category_id=${selectedCar?.car_category}`
      : baseUrl;
    set_loading_for_car_rate_table(true);
    simplePostCall(url, JSON.stringify(body))
      .then((res) => {
        if (!res?.error) {
          // dispatch(setCarArray(res?.data));
          setCarArrayTem(res?.data);
          // setTotalRecords(res?.total_records )
          // dispatch(setSelectedCar(null));
        }

        if (res?.status === "success") {
          //  notifySuccess(res?.message)
        }
        if (res?.error) {
          // dispatch(setSelectedCar(null))
          //   if(Array.isArray(res?.message)){
          //     notifyError(res?.message[0]);
          //   } else{
          //   notifyError(res?.message);
          //   }
        }
      })
      .catch((error) => {
        console.log("validate pickuplocation api failed-->", error);
        // notifyError('Something went wwrong, please try again letter')
      })
      .finally(() => {
        set_loading_for_car_rate_table(false);
      });
  };

  useEffect(() => {
    if (
      selectedCar?.payment_method === "pay_now" ||
      selectedCar?.payment_method === "pay_later"
    ) {
      carSearch();
    }
  }, [selectedCar?.payment_method]);
  useEffect(() => {
    if (selectedMileage) {
      carSearchForTotalRateAfterMileAge();
    }
  }, [selectedMileage]);

  const carSearchForTotalRateAfterMileAge = async () => {
    const body = {
      booking_type: requestBody_dropoff?.booking_type,
      pickup_type: requestBody_pickup?.pickup_type,
      pickup_date: requestBody_dropoff?.pickup_date,
      pickup_time: requestBody_dropoff?.pickup_time,
      dropoff_type: requestBody_dropoff?.dropoff_type,
      dropoff_date: requestBody_dropoff?.dropoff_date,
      dropoff_time: requestBody_dropoff?.dropoff_time,
      booking_source: "web",
      // discount_coupon: ""
    };

    if (requestBody_pickup?.pickup_type === "self") {
      body.pickup_location_id = requestBody_pickup?.pickup_location_id;
    }
    if (requestBody_pickup?.pickup_type === "delivery") {
      body.pickup_emirate_id = requestBody_pickup?.pickup_emirate_id;
      body.pickup_coordinates = requestBody_pickup?.pickup_coordinates;
      body.pickup_address = requestBody_pickup?.pickup_address;
    }

    if (requestBody_dropoff?.dropoff_type === "collection") {
      body.dropoff_emirate_id = requestBody_dropoff?.dropoff_emirate_id;
      body.dropoff_coordinates = requestBody_dropoff?.dropoff_coordinates;
      body.dropoff_address = requestBody_dropoff?.dropoff_address;
    }
    if (requestBody_dropoff?.dropoff_type === "self") {
      body.dropoff_location_id = requestBody_dropoff?.dropoff_location_id;
    }

    if (requestBody_pickup?.booking_type === "monthly") {
      body.booking_months = requestBody_pickup?.booking_months;
    }
    if (requestBody_dropoff?.discount_coupon) {
      body.discount_coupon = requestBody_dropoff?.discount_coupon;
    }

    const baseUrl = `${configWeb.POST_CAR_SEARCH}?lang=${language}&page=1&page_size=100&sort=ASC&car_id=${selectedCar?.id}&monthly_mileage=${selectedMileage}`; //car_id=1

    const url = selectedCar?.car_category
      ? `${baseUrl}&category_id=${selectedCar?.car_category}`
      : baseUrl;
    set_loading_for_car_rate_table(true);
    simplePostCall(url, JSON.stringify(body))
      .then((res) => {
        if (!res?.error) {
          // dispatch(setCarArray(res?.data));

          // setTotalRecords(res?.total_records )
          // dispatch(setSelectedCar(null));
          mergeCarProperties(res?.data[0]);
        }

        if (res?.status === "success") {
          //  notifySuccess(res?.message)
        }
        if (res?.error) {
          // dispatch(setSelectedCar(null))
          //   if(Array.isArray(res?.message)){
          //     notifyError(res?.message[0]);
          //   } else{
          //   notifyError(res?.message);
          //   }
        }
      })
      .catch((error) => {
        console.log("validate pickuplocation api failed-->", error);
        // notifyError('Something went wwrong, please try again letter')
      })
      .finally(() => {
        set_loading_for_car_rate_table(false);
      });
  };
  const monthlyInstallment = async () => {
    const car_extras = [];

    if (addProtection.cdw) {
      car_extras.push({ type: "cdw" });
    }
    if (addProtection.driver) {
      car_extras.push({
        type: "driver",
        quantity: Number(babyDriver.driver),
      });
    }
    if (addProtection.scdw) {
      car_extras.push({ type: "scdw" });
    }
    if (addProtection.baby_seat) {
      car_extras.push({
        type: "baby_seat",
        quantity: Number(babyDriver.baby_seat),
      });
    }
    if (addProtection.pai) {
      car_extras.push({ type: "pai" });
    }
    if (addProtection.gps) {
      car_extras.push({ type: "gps" });
    }
    const body = {
      booking_type: requestBody_dropoff?.booking_type,
      car_id: selectedCar?.id,
      pickup_type: requestBody_pickup?.pickup_type,
      pickup_date: requestBody_dropoff?.pickup_date,
      pickup_time: requestBody_dropoff?.pickup_time,
      dropoff_type: requestBody_dropoff?.dropoff_type,
      dropoff_date: requestBody_dropoff?.dropoff_date,
      dropoff_time: requestBody_dropoff?.dropoff_time,
      payment_type: selectedCar?.payment_method === "pay_monthly_later" ? "later" : "now",
      car_extras: car_extras,
      booking_source: "web",
      // discount_coupon: ""
    };

    if (requestBody_pickup?.pickup_type === "self") {
      body.pickup_location_id = requestBody_pickup?.pickup_location_id;
    }
    if (requestBody_pickup?.pickup_type === "delivery") {
      body.pickup_emirate_id = requestBody_pickup?.pickup_emirate_id;
      body.pickup_coordinates = requestBody_pickup?.pickup_coordinates;
      body.pickup_address = requestBody_pickup?.pickup_address;
    }

    if (requestBody_dropoff?.dropoff_type === "collection") {
      body.dropoff_emirate_id = requestBody_dropoff?.dropoff_emirate_id;
      body.dropoff_coordinates = requestBody_dropoff?.dropoff_coordinates;
      body.dropoff_address = requestBody_dropoff?.dropoff_address;
    }
    if (requestBody_dropoff?.dropoff_type === "self") {
      body.dropoff_location_id = requestBody_dropoff?.dropoff_location_id;
    }

    if (requestBody_pickup?.booking_type === "monthly") {
      body.booking_months = requestBody_pickup?.booking_months;
    }
    if (requestBody_pickup?.booking_type === "monthly") {
      body.monthly_mileage = selectedMileage;
    }
    if (
      requestBody_pickup?.booking_type === "monthly" &&
      addProtection?.extra_km
    ) {
      body.extra_kms = addProtection?.extra_km;
    }
    if (requestBody_dropoff?.discount_coupon) {
      body.discount_coupon = requestBody_dropoff?.discount_coupon;
    }

    const baseUrl = configWeb.POST_MONTHLY_INSTALLMENT;

    setInstallmentTableLoading(true);
    simplePostCall(baseUrl, JSON.stringify(body))
      .then((res) => {
        if (!res?.error) {
          // mergeCarProperties(res?.data[0]);
          setMonthlyInstallmentArray(res || []);
        }

        if (res?.status === "success") {
          //  notifySuccess(res?.message)
        }
        if (res?.error) {
          // dispatch(setSelectedCar(null))
          //   if(Array.isArray(res?.message)){
          //     notifyError(res?.message[0]);
          //   } else{
          //   notifyError(res?.message);
          //   }
        }
      })
      .catch((error) => {
        console.log("validate pickuplocation api failed-->", error);
        // notifyError('Something went wwrong, please try again letter')
      })
      .finally(() => {
        setInstallmentTableLoading(false);
      });
  };

  useEffect(() => {
    if (requestBody_pickup?.booking_type === "monthly") monthlyInstallment();
  }, [selectedMileage, addProtection, babyDriver]);

  useEffect(() => {
    if (carArrayTemp) {
      const filtereCarArrayTemp = filterCars(carArrayTemp, selectedCar);
      dispatch(setFilterCarArray(filtereCarArrayTemp));
    }
  }, [carArrayTemp, selectedCar]);

  const carExtra = () => {
    const body = {
      booking_type: requestBody_pickup?.booking_type,
      pickup_type: requestBody_pickup?.pickup_type,
      ...(requestBody_pickup?.pickup_type === "self" && {
        pickup_location_id: requestBody_pickup?.pickup_location_id,
        booking_source: "web",
      }),

      // "pickup_emirate_id": "required for pickup_type delivery",
      ...(requestBody_pickup?.pickup_type === "delivery" && {
        pickup_emirate_id: requestBody_pickup?.pickup_emirate_id,
      }),
      // "pickup_coordinates": "Custom location required for delivery",
      ...(requestBody_pickup?.pickup_type === "delivery" && {
        pickup_coordinates: requestBody_pickup?.pickup_coordinates,
      }),
      ...(requestBody_pickup?.pickup_type === "delivery" && {
        pickup_address: requestBody_pickup?.pickup_address,
      }),

      pickup_date: requestBody_pickup?.pickup_date,
      pickup_time: requestBody_pickup?.pickup_time,
      dropoff_type: requestBody_dropoff?.dropoff_type,
      dropoff_date: requestBody_dropoff?.dropoff_date,
      dropoff_time: requestBody_dropoff?.dropoff_time,
      car_id: selectedCar?.id,
      // "dropoff_location_id": "dropoff location id",
      ...(requestBody_dropoff?.dropoff_type === "self" && {
        dropoff_location_id: requestBody_dropoff?.dropoff_location_id,
      }),

      // "dropoff_emirate_id": "dropoff emirate id",
      ...(requestBody_dropoff?.dropoff_type === "collection" && {
        dropoff_emirate_id: requestBody_dropoff?.dropoff_emirate_id,
      }),

      // "dropoff_coordinates": "Custom location required for collection",
      ...(requestBody_dropoff?.dropoff_type === "collection" && {
        dropoff_coordinates: requestBody_dropoff?.dropoff_coordinates,
      }),
      ...(requestBody_dropoff?.dropoff_type === "collection" && {
        dropoff_address: requestBody_dropoff?.dropoff_address,
      }),

      // "months": "required for booking_type monthly",
      // ...(requestBody_dropoff?.booking_type === 'monthly' && { booking_months: requestBody_dropoff?.months }),
    };

    if (requestBody_pickup?.booking_type === "monthly") {
      body.booking_months = requestBody_pickup?.booking_months;
    }
    if (requestBody_pickup?.booking_type === "monthly") {
      body.monthly_mileage = selectedMileage;
    }
    if (requestBody_dropoff?.discount_coupon) {
      body.discount_coupon = requestBody_dropoff?.discount_coupon;
    }

    simplePostCall(configWeb.POST_CAR_EXTRA, JSON.stringify(body))
      .then((res) => {
        if (res?.status === "success") {
          //  notifySuccess(res?.message)
        }
        if (!res?.error) {
          dispatch(setCarExtraArray(res));
        }
        if (res?.error) {
          if (Array.isArray(res?.message)) {
            // notifyError(res?.message[0]);
          } else {
            // notifyError(res?.message);
          }
        }
      })
      .catch((error) => {
        console.log("validate pickuplocation api failed-->", error);
        // notifyError('Something went wwrong, please try again letter')
      })
      .finally(() => {});
  };
  useEffect(() => {
    if (selectedCar) {
      carExtra();
    }
  }, [selectedCar?.id]);

  const handleBookingClick = async () => {
    if (!localStorage.getItem("token")) {
      setLoginModalShow(true);
    } else if (selectedCar?.payment_method === "pay_monthly_now") {
      confirmBooking();
      // navigate(`/PaymentSuccess?id=${confirmBookingData?.id}`);
    } else if (
      selectedCar?.payment_method === "pay_monthly_later" ||
      selectedCar?.payment_method === "pay_later"
    ) {
      confirmBooking();
    } else {
      confirmBooking();
    }
  };

  useEffect(() => {
    if (booking_number) {
      if (
        confirmBookingData?.id &&
        (confirmBookingPayment === "later" ||
          (
            Number(total) - Number(editUserBookingObject?.total_amount)
          )?.toFixed(2) < 1)
      ) {
        navigate(`/PaymentSuccess?id=${confirmBookingData?.id}`);
      }

      // New condition for 'now' payment type and monthly booking
      if (
        confirmBookingData?.id &&
        // confirmBookingPayment === "now" &&
        requestBody_pickup?.booking_type === "monthly" &&
        Number(monthlyInstallmentArray?.installments?.[0]?.total_amount) -
          Number(
            editUserBookingObject?.monthly_installments?.[0]?.actual_amount
          ) <
          1
      ) {
        navigate(`/PaymentSuccess?id=${confirmBookingData?.id}`);
      }
    } else if (confirmBookingData?.id && confirmBookingPayment === "later") {
      navigate(`/PaymentSuccess?id=${confirmBookingData?.id}`);
    }

    return () => {};
  }, [confirmBookingData?.id]);

  const mergeCarProperties = (car) => {
    // Create a copy of selectedCar
    const updatedSelectedCar = { ...selectedCar };

    // Loop through the properties of the car object and update the selectedCar object
    for (const key in car) {
      if (
        car?.hasOwnProperty(key) &&
        key !== "payment_method" &&
        key !== "car_category"
      ) {
        updatedSelectedCar[key] = car[key];
      }
    }
    dispatch(setSelectedCar(updatedSelectedCar));

    // Return the updated selectedCar object
    // return updatedSelectedCar;
  };
  const findMatchingKey = (km_object, activeKM) => {
    for (let key in km_object) {
      if (km_object[key] === activeKM) {
        return key;
      }
    }
    return null; // Return null if no match is found
  };

  const calculateSubTotal = (
    selectedCar,
    addProtection,
    carExtraArray,
    babyDriver
  ) => {
    let total = 0;

    // Add Car Rate
    total +=
      selectedCar?.payment_method === "pay_monthly_now" ||
      selectedCar?.payment_method === "pay_monthly_later"
        ? selectedCar?.car_rate_total
        : selectedCar?.payment_method === "pay_later"
        ? selectedCar?.pay_later
        : selectedCar?.pay_now;

    // Add conditional values
    if (addProtection.pai) {
      total += Number(carExtraArray?.[0]?.pai);
    }
    if (addProtection.cdw) {
      total += Number(carExtraArray?.[0]?.cdw);
    }
    if (addProtection.scdw) {
      total += Number(carExtraArray?.[0]?.scdw);
    }
    if (addProtection.baby_seat) {
      total +=
        Number(carExtraArray?.[0]?.baby_seat) * Number(babyDriver.baby_seat);
    }
    if (addProtection.gps) {
      total += Number(carExtraArray?.[0]?.gps);
    }
    if (addProtection.driver) {
      total += Number(carExtraArray?.[0]?.driver) * Number(babyDriver.driver);
    }
    if (addProtection?.activeKM) {
      total +=
        Number(addProtection?.activeKM) * Number(selectedCar?.booking_months);
    }

    // Add fixed values
    total +=
      selectedCar?.pickup_parking_charges !== "0" &&
      selectedCar?.pickup_parking_charges !== 0
        ? Number(selectedCar?.pickup_parking_charges)
        : 0;
    total +=
      selectedCar?.dropoff_parking_charges !== "0" &&
      selectedCar?.dropoff_parking_charges !== 0
        ? Number(selectedCar?.dropoff_parking_charges)
        : 0;
    total +=
      selectedCar?.delivery_charges !== "0" &&
      selectedCar?.delivery_charges !== 0
        ? Number(selectedCar?.delivery_charges)
        : 0;
    total +=
      selectedCar?.collection_charges !== "0" &&
      selectedCar?.collection_charges !== 0
        ? Number(selectedCar?.collection_charges)
        : 0;
    total +=
      selectedCar?.inter_emirates_charges !== "0" &&
      selectedCar?.inter_emirates_charges !== 0
        ? Number(selectedCar?.inter_emirates_charges)
        : 0;
    total +=
      selectedCar?.vmd_charges !== "0" && selectedCar?.vmd_charges !== 0
        ? Number(selectedCar?.vmd_charges)
        : 0;
    // total -=
    //   selectedCar?.discount !== "0" && selectedCar?.discount !== 0
    //     ? Number(selectedCar?.discount)
    //     : 0;

    return total.toFixed(2); // Return total with two decimal places
  };

  const calculateVAT = (subtotal) => {
    return (Number(subtotal) * 0.05).toFixed(2);
  };

  const subTotal = calculateSubTotal(
    selectedCar,
    addProtection,
    carExtraArray,
    babyDriver
  );
  const vat = calculateVAT(subTotal);
  const calculateTotal = (subtotal, vat) => {
    return (Number(subtotal) + Number(vat)).toFixed(2);
  };
  const total = calculateTotal(subTotal, vat);

  const confirmBooking = async () => {
    return new Promise((resolve, reject) => {
      const car_extras = [];

      if (addProtection.cdw) {
        car_extras.push({ type: "cdw" });
      }
      if (addProtection.driver) {
        car_extras.push({
          type: "driver",
          quantity: Number(babyDriver.driver),
        });
      }
      if (addProtection.scdw) {
        car_extras.push({ type: "scdw" });
      }
      if (addProtection.baby_seat) {
        car_extras.push({
          type: "baby_seat",
          quantity: Number(babyDriver.baby_seat),
        });
      }
      if (addProtection.pai) {
        car_extras.push({ type: "pai" });
      }
      if (addProtection.gps) {
        car_extras.push({ type: "gps" });
      }
      const body = {
        booking_type: requestBody_dropoff?.booking_type,
        car_id: selectedCar?.id,
        pickup_type: requestBody_pickup?.pickup_type,
        pickup_date: requestBody_dropoff?.pickup_date,
        pickup_time: requestBody_dropoff?.pickup_time,
        dropoff_type: requestBody_dropoff?.dropoff_type,
        dropoff_date: requestBody_dropoff?.dropoff_date,
        dropoff_time: requestBody_dropoff?.dropoff_time,
        booking_source: "web",
        payment_type:
          selectedCar?.payment_method === "pay_now" ||
          selectedCar?.payment_method === "pay_monthly_now"
            ? "now"
            : selectedCar?.payment_method === "pay_later" ||
              selectedCar?.payment_method === "pay_monthly_later"
            ? "later"
            : "now",

        // discount_coupon: ""

        car_extras: car_extras,
      };

      if (requestBody_pickup?.pickup_type === "self") {
        body.pickup_location_id = requestBody_pickup?.pickup_location_id;
      }
      if (requestBody_pickup?.pickup_type === "delivery") {
        body.pickup_emirate_id = requestBody_pickup?.pickup_emirate_id;
        body.pickup_coordinates = requestBody_pickup?.pickup_coordinates;
        body.pickup_address = requestBody_pickup?.pickup_address;
      }

      if (requestBody_dropoff?.dropoff_type === "collection") {
        body.dropoff_emirate_id = requestBody_dropoff?.dropoff_emirate_id;
        body.dropoff_coordinates = requestBody_dropoff?.dropoff_coordinates;
        body.dropoff_address = requestBody_dropoff?.dropoff_address;
      }
      if (requestBody_dropoff?.dropoff_type === "self") {
        body.dropoff_location_id = requestBody_dropoff?.dropoff_location_id;
      }

      if (requestBody_pickup?.booking_type === "monthly") {
        body.booking_months = requestBody_pickup?.booking_months;
      }
      if (requestBody_pickup?.booking_type === "monthly") {
        body.monthly_mileage = selectedMileage;
      }
      if (requestBody_dropoff?.discount_coupon) {
        body.discount_coupon = requestBody_dropoff?.discount_coupon;
      }
      if (booking_number) {
        body.booking_number = booking_number;
      }

      if (
        requestBody_pickup?.booking_type === "monthly" &&
        addProtection?.activeKM
      ) {
        // const matchingKey = findMatchingKey(km_object, addProtection?.activeKM);
        body.extra_kms = addProtection?.extra_km;
      }
      const baseUrl = `${configWeb.POST_CONFIRM_BOOKING}?lang=${language}&page=1&page_size=100&sort=ASC`; //car_id=1

      const url = selectedCar?.car_category
        ? `${baseUrl}&category_id=${selectedCar?.car_category}`
        : baseUrl;
      setLoading_confirmBookin(true);
      simplePostCallAuth(
        booking_number
          ? configWeb.POST_EDIT_BOOKING
          : configWeb.POST_CONFIRM_BOOKING,
        JSON.stringify(body)
      )
        .then((res) => {
          if (!res?.error) {
          }

          if (res?.status === "success") {
            //  notifySuccess(res?.message)

            // if (
            //               !(selectedCar?.payment_method === "pay_later") ||
            //   !(selectedCar?.payment_method === "pay_monthly_later")

            // ) {
            //   setCardModalShow(true);
            // }
            // ReactGA.event({
            //   category: 'Booking',
            //   action: 'booking_confirm',
            //   label: 'user has confirm the booking',
            // });

            window.gtag("event", "select_item", {
              item_list_id: `${
                requestBody_pickup?.pickup_location_id
                  ? requestBody_pickup?.pickup_location_id
                  : `delivery_${requestBody_pickup?.pickup_emirate_id}`
              } + ${
                requestBody_dropoff?.dropoff_location_id
                  ? requestBody_dropoff?.dropoff_location_id
                  : `collection_${requestBody_dropoff?.dropoff_emirate_id}`
              }`,
              // item_list_name: "Related products",
              items: [
                {
                  item_id: selectedCar?.id,
                  item_name: selectedCar?.car_name,
                  affiliation: "Autostrad",
                  coupon: selectedCar?.coupon,
                  discount: selectedCar?.discount,
                  index: 0,
                  item_brand: selectedCar?.brand,
                  item_category: selectedCar?.category,
                  // item_category2: "Adult",
                  // item_category3: "Shirts",
                  // item_category4: "Crew",
                  // item_category5: "Short sleeve",
                  item_list_id: `${
                    requestBody_pickup?.pickup_location_id
                      ? requestBody_pickup?.pickup_location_id
                      : `delivery_${requestBody_pickup?.pickup_emirate_id}`
                  } + ${
                    requestBody_dropoff?.dropoff_location_id
                      ? requestBody_dropoff?.dropoff_location_id
                      : `collection_${requestBody_dropoff?.dropoff_emirate_id}`
                  }`,
                  // item_list_name: "Related Products",
                  item_variant: selectedCar?.payment_method,
                  location_id: selectedCar?.emirate_id,
                  price:
                    requestBody_pickup?.booking_type === "daily"
                      ? parseFloat(total || 0)
                      : parseFloat(
                          monthlyInstallmentArray?.installments?.[0]
                            ?.total_amount || 0
                        ),
                  quantity: 1,
                },
                ...enabledExtras,
              ],
            });

            window.gtag("event", "view_cart", {
              currency: "AED",
              value:
                requestBody_pickup?.booking_type === "daily"
                  ? parseFloat(total || 0)
                  : parseFloat(
                      monthlyInstallmentArray?.installments?.[0]
                        ?.total_amount || 0
                    ),

              items: [
                {
                  item_id: selectedCar?.id,
                  item_name: selectedCar?.car_name,
                  affiliation: "Autostrad",
                  coupon: selectedCar?.coupon,
                  discount: selectedCar?.discount,
                  index: 0,
                  item_brand: selectedCar?.brand,
                  item_category: selectedCar?.category,
                  // item_category2: "Adult",
                  // item_category3: "Shirts",
                  // item_category4: "Crew",
                  // item_category5: "Short sleeve",
                  item_list_id: `${
                    requestBody_pickup?.pickup_location_id
                      ? requestBody_pickup?.pickup_location_id
                      : `delivery_${requestBody_pickup?.pickup_emirate_id}`
                  } + ${
                    requestBody_dropoff?.dropoff_location_id
                      ? requestBody_dropoff?.dropoff_location_id
                      : `collection_${requestBody_dropoff?.dropoff_emirate_id}`
                  }`,
                  // item_list_name: "Related Products",
                  item_variant: selectedCar?.payment_method,
                  location_id: selectedCar?.emirate_id,
                  price:
                    requestBody_pickup?.booking_type === "daily"
                      ? parseFloat(total || 0)
                      : parseFloat(
                          monthlyInstallmentArray?.installments?.[0]
                            ?.total_amount || 0
                        ),
                  quantity: 1,
                },
                ...enabledExtras,
              ],
            });

            const carExtras41 = enabledExtras?.map((item) => ({
              id: item.item_id,
              quantity: item.quantity,
            }));

            const contents = [
              {
                id: selectedCar?.id,
                quantity: 1,
              },
              ...carExtras41,
            ];

            const numItem = contents?.length;
            const value =
              requestBody_pickup?.booking_type === "daily"
                ? total
                : monthlyInstallmentArray?.installments?.[0]?.total_amount;

            const contentIds = contents?.map((item) => item?.id);

            pixelInitiateCheckoutEvent(
              contentIds,
              contents,
              value,
              "AED",
              numItem
            );

            if (selectedCar?.payment_method === "pay_later") {
              window.gtag("event", "add_payment_info", {
                currency: "AED",
                value:
                  requestBody_pickup?.booking_type === "daily"
                    ? parseFloat(total || 0)
                    : parseFloat(
                        monthlyInstallmentArray?.installments?.[0]
                          ?.total_amount || 0
                      ),

                coupon: selectedCar?.coupon,
                payment_type:
                  requestBody_pickup?.booking_type === "daily"
                    ? selectedCar?.payment_method === "pay_now"
                      ? "Credit Card"
                      : "Cash On Delivery"
                    : selectedCar?.payment_method === "pay_monthly_now"
                    ? "Credit Card"
                    : "Cash On Delivery",
                items: [
                  {
                    item_id: selectedCar?.id,
                    item_name: selectedCar?.car_name,
                    affiliation: "Autostrad",
                    coupon: selectedCar?.coupon,
                    discount: selectedCar?.discount,
                    index: 0,
                    item_brand: selectedCar?.brand,
                    item_category: selectedCar?.category,
                    // item_category2: "Adult",
                    // item_category3: "Shirts",
                    // item_category4: "Crew",
                    // item_category5: "Short sleeve",
                    item_list_id: `${
                      requestBody_pickup?.pickup_location_id
                        ? requestBody_pickup?.pickup_location_id
                        : `delivery_${requestBody_pickup?.pickup_emirate_id}`
                    } + ${
                      requestBody_dropoff?.dropoff_location_id
                        ? requestBody_dropoff?.dropoff_location_id
                        : `collection_${requestBody_dropoff?.dropoff_emirate_id}`
                    }`,
                    // item_list_name: "Related Products",
                    item_variant: selectedCar?.payment_method,
                    location_id: selectedCar?.emirate_id,
                    price:
                      requestBody_pickup?.booking_type === "daily"
                        ? parseFloat(total || 0)
                        : parseFloat(
                            monthlyInstallmentArray?.installments?.[0]
                              ?.total_amount || 0
                          ),
                    quantity: 1,
                  },
                  ...enabledExtras,
                ],
              });

              window.gtag("event", "begin_checkout", {
                currency: "AED",
                value:
                  requestBody_pickup?.booking_type === "daily"
                    ? parseFloat(total || 0)
                    : parseFloat(
                        monthlyInstallmentArray?.installments?.[0]
                          ?.total_amount || 0
                      ),

                coupon: selectedCar?.coupon,
                items: [
                  {
                    item_id: selectedCar?.id,
                    item_name: selectedCar?.car_name,
                    affiliation: "Autostrad",
                    coupon: selectedCar?.coupon,
                    discount: selectedCar?.discount,
                    index: 0,
                    item_brand: selectedCar?.brand,
                    item_category: selectedCar?.category,
                    // item_category2: "Adult",
                    // item_category3: "Shirts",
                    // item_category4: "Crew",
                    // item_category5: "Short sleeve",
                    item_list_id: `${
                      requestBody_pickup?.pickup_location_id
                        ? requestBody_pickup?.pickup_location_id
                        : `delivery_${requestBody_pickup?.pickup_emirate_id}`
                    } + ${
                      requestBody_dropoff?.dropoff_location_id
                        ? requestBody_dropoff?.dropoff_location_id
                        : `collection_${requestBody_dropoff?.dropoff_emirate_id}`
                    }`,
                    // item_list_name: "Related Products",
                    item_variant: selectedCar?.payment_method,
                    location_id: selectedCar?.emirate_id,
                    price:
                      requestBody_pickup?.booking_type === "daily"
                        ? parseFloat(total || 0)
                        : parseFloat(
                            monthlyInstallmentArray?.installments?.[0]
                              ?.total_amount || 0
                          ),
                    quantity: 1,
                  },
                  ...enabledExtras,
                ],
              });
            }
            if (booking_number) {
              if (requestBody_pickup?.booking_type === "monthly") {
                const amountToPay =
                  Number(
                    monthlyInstallmentArray?.installments?.[0]?.total_amount
                  ) -
                  Number(
                    editUserBookingObject?.monthly_installments?.[0]
                      ?.actual_amount
                  );
                const shouldShowCardModal =
                  amountToPay > 0 &&
                  selectedCar?.payment_method === "pay_monthly_now";
                if (shouldShowCardModal) {
                  setCardModalShow(true);
                }
              } else {
                const amountToPay =
                  Number(total) - Number(editUserBookingObject?.total_amount);
                const shouldShowCardModal =
                  amountToPay > 0 &&
                  (!(selectedCar?.payment_method === "pay_later") ||
                    !(selectedCar?.payment_method === "pay_monthly_later"));

                if (shouldShowCardModal) {
                  setCardModalShow(true);
                }
              }
            } else {
              // If booking_number doesn't exist, keep the original conditions
              if (
                selectedCar?.payment_method === "pay_now" ||
                selectedCar?.payment_method === "pay_monthly_now"
              ) {
                setCardModalShow(true);
              }
            }

            setConfirmBookingData(res?.booking);
            setConfirmBookingPayment(res?.payment_type);
            resolve(true);
          } else {
            resolve(false);
            notifyError(res?.message);
          }
          // notifyError(res?.message);
        })
        .catch((error) => {
          console.log("validate pickuplocation api failed-->", error);
          // notifyError('Something went wwrong, please try again letter')
          notifyError("Something went wrong please try again later.");

          resolve(false);
        })
        .finally(() => {
          setLoading_confirmBookin(false);
        });
    });
  };

  const KM_PLAN = () => {
    const url = configWeb.GET_KM_PLAN(selectedCar?.rate_id);
    simpleGetCall(url)
      .then((res) => {
        if (!res?.error) {
          set_km_object(res);
        }
      })
      .catch((error) => {
        console.error("Banner failed:", error);
      })
      .finally(() => {});
  };
  const GET_MONTHLY_UPGRADE_MILEAGE_PLAN = () => {
    const url = configWeb.GET_MONTHLY_UPGRADE_MILEAGE_PLAN(
      selectedCar?.id,
      selectedCar?.emirate_id,
      selectedCar?.booking_months
    );
    // const url = configWeb.GET_MONTHLY_UPGRADE_MILEAGE_PLAN(61,1,3);
    simpleGetCall(url)
      .then((res) => {
        if (!res?.error) {
          set_mileage_plan_array(res?.data);
          // const tempArray = [
          //   {
          //     id: 10,
          //     mileage: 4000,
          //     rate: 5250,
          //   },
          //   {
          //     id: 11,
          //     mileage: 5000,
          //     rate: 5500,
          //   },
          // ];
          // set_mileage_plan_array(tempArray);
        }
      })
      .catch((error) => {
        console.error("Banner failed:", error);
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (
      (selectedCar?.payment_method === "pay_monthly_now" ||
        selectedCar?.payment_method === "pay_monthly_later") &&
      /* selectedCar?.group_id */ selectedCar?.rate_id
    ) {
      // KM_PLAN();
    }
  }, [selectedCar?.rate_id]);

  useEffect(() => {
    if (
      (selectedCar?.payment_method === "pay_monthly_now" ||
        selectedCar?.payment_method === "pay_monthly_later") &&
      selectedCar?.group_id
    ) {
      GET_MONTHLY_UPGRADE_MILEAGE_PLAN();
    }
  }, [selectedCar?.id]);

  useEffect(() => {
    window.gtag("event", "view_item", {
      currency: "AED",
      value:
        requestBody_pickup?.booking_type === "monthly"
          ? parseFloat(selectedCar?.car_rate_total || 0)
          : selectedCar?.payment_method === "pay_now"
          ? parseFloat(selectedCar?.pay_now || 0)
          : parseFloat(selectedCar?.pay_later || 0),
      items: [
        {
          item_id: selectedCar?.id,
          item_name: selectedCar?.car_name,
          affiliation: "AutoStrad",
          coupon: selectedCar?.coupon_code,
          discount: selectedCar?.discount,
          index: 0,
          item_brand: selectedCar?.brand,
          item_category: selectedCar?.category,
          // item_category2: "Adult",
          // item_category3: "Shirts",
          // item_category4: "Crew",
          // item_category5: "Short sleeve",
          // item_list_id: `${requestBody_pickup?.pickup_location_id ? requestBody_pickup?.pickup_location_id  : `delivery_${requestBody_pickup?.pickup_emirate_id}` } + ${requestBody_dropoff?.dropoff_location_id ? requestBody_dropoff?.dropoff_location_id : `collection_${requestBody_dropoff?.dropoff_emirate_id}`}`,
          // item_list_name: "Related Products",
          item_variant: selectedCar?.payment_method,
          location_id: selectedCar?.emirate_id,
          price:
            requestBody_pickup?.booking_type === "monthly"
              ? parseFloat(selectedCar?.car_rate_total || 0)
              : selectedCar?.payment_method === "pay_now"
              ? parseFloat(selectedCar?.pay_now || 0)
              : parseFloat(selectedCar?.pay_later || 0),
          quantity: 1,
        },
      ],
    });
  }, []);

  useEffect(() => {
    if (carExtraArray) {
      const items =
        carExtraArray &&
        carExtraArray?.flatMap((extra, index) => [
          {
            item_id: "pai",
            item_name: "pai",
            index: 0,
            item_category: "car extra",
            price: parseFloat(extra.pai),
            quantity: 1,
          },
          {
            item_id: "cdw",
            item_name: "cdw",
            index: 1,
            item_category: "car extra",
            price: parseFloat(extra.cdw),
            quantity: 1,
          },
          {
            item_id: "scdw",
            item_name: "scdw",
            index: 2,
            item_category: "car extra",
            price: parseFloat(extra.scdw),
            quantity: 1,
          },
          {
            item_id: "baby_seat",
            item_name: "baby_seat",
            index: 3,
            item_category: "car extra",
            price: parseFloat(extra.baby_seat),
            quantity: 1,
          },
          {
            item_id: "gps",
            item_name: "gps",
            index: 4,
            item_category: "car extra",
            price: parseFloat(extra.gps),
            quantity: 1,
          },
          {
            item_id: "driver",
            item_name: "driver",
            index: 5,
            item_category: "car extra",
            price: parseFloat(extra.driver),
            quantity: 1,
          },
        ]);

      window.gtag("event", "view_item_list", {
        item_list_id: `${
          requestBody_pickup?.pickup_location_id
            ? requestBody_pickup?.pickup_location_id
            : `delivery_${requestBody_pickup?.pickup_emirate_id}`
        } + ${
          requestBody_dropoff?.dropoff_location_id
            ? requestBody_dropoff?.dropoff_location_id
            : `collection_${requestBody_dropoff?.dropoff_emirate_id}`
        }`,
        // item_list_name: "Car Extra",
        items: items,
      });
    }
  }, [carExtraArray]);

  useEffect(() => {
    if (selectedCar.id) {
      const contentType = "Product";
      const contentIds = selectedCar?.id;
      const value =
        requestBody_pickup?.booking_type === "monthly"
          ? selectedCar?.car_rate_total
          : selectedCar?.payment_method === "pay_now"
          ? selectedCar?.pay_now
          : selectedCar?.pay_later;

      pixelViewContentEvent(contentType, contentIds, value);
    }
  }, [selectedCar.id]);

  return (
    <div className="booking__detailsTwo__section">
      <MetaHelmet
        title="Booking Details Two"
        description=""
        keywords="car rental, affordable cars, rent a car"
        canonicalUrl={`${configWeb.BASE_WEB_URL}/en/bookingDetailsTwo`}
        hreflangs={[
          {
            hreflang: "en",
            href: `${configWeb.BASE_WEB_URL}/en/bookingDetailsTwo`,
          },
          {
            hreflang: "ar",
            href: `${configWeb.BASE_WEB_URL}/ae/bookingDetailsTwo`,
          },
        ]}
      />
      {/* <section className="form-section">
        <BookingAddressSection />
      </section> */}
      <section className="p-1 form-section bg-dange">
        <Col lg="12" md="12" sm="12">
          <section className="form-section">
            <BookingAddressSection />
          </section>
        </Col>
      </section>
      <section>
        <Container>
          <BookingStepper
          //  set_edit_edit_form={set_edit_edit_form}
          />
        </Container>
      </section>
      <Container>
<Row className="g-md-0 ">
<Col lg="6" md="12" sm="12">
<Col lg="12" md="12" sm="12">
    
      
      <section className="car__details__section pb-0">
        <Container>
        
          <Card className="mt-1- shadow border-0 min-height-mileage-box">
            <Card.Header className="car__details__header">
              Add Protection
            </Card.Header>
            <Card.Body>
              <div className="container px-sm-4 px-md-2">
                <div className="row- gx-sm-5- gx-md-0-">
                  <div className="col">
                    <div className="p-3-">
                      <div className="d-flex-  align-items-center mb-1 row px-0 ">
                        <div className="img-div d-flex gap-2 align-items-center col-8">
                          <img src={proImg1} alt="PAI" />
                          <label className="Protectin-add-on">
                            Personal Accident Insurance (PAI)
                          </label>
                        </div>
                        {/* <div className="protection__details__div"> */}
                          {/* <label className="Protectin-add-on col-6">
                            Personal Accident Insurance (PAI)
                          </label> */}
                          <p className="title-price mb-0 col-2">
                            AED{" "}
                            {requestBody_pickup?.booking_type === "daily"
                              ? carExtraArray?.[0]?.pai
                              : carExtraArray?.[0]?.pai_per_month_rate}
                          </p>
                          <div className="d-flex justify-content-end col-2">
                            {/* <label>View Details</label> */}
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              name="pai"
                              // checked={setAddProtection.pai}
                              checked={addProtection.pai}
                              onChange={handleAddProtecionChange}
                              disabled={
                                carExtraArray?.[0]?.pai == 0 ||
                                !carExtraArray?.[0]?.pai
                              }
                            />
                          </div>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="p-sm-3 p-md-0">
                      <div className="d-flex- px-0 align-items-center mb-1 row">
                        <div className="img-div d-flex gap-2 align-items-center col-8">
                          <img src={proImg2} alt="PAI" />
                          <label className="Protectin-add-on">
                            Collision Damage Waiver (CDW)
                          </label>
                        </div>
                        {/* <div className="protection__details__div no-wrap"> */}
                         
                          <p className="title-price mb-0 col-2">
                            AED{" "}
                            {requestBody_pickup?.booking_type === "daily"
                              ? carExtraArray?.[0]?.cdw
                              : carExtraArray?.[0]?.cdw_per_month_rate}
                          </p>
                          <div className="d-flex justify-content-end col-2">
                            {/* <label>View Details</label> */}
                            <Form.Check // prettier-ignore
                              type="switch"
                              id="custom-switch"
                              name="cdw"
                              // checked={setAddProtection.cdw}
                              checked={addProtection.cdw}
                              onChange={handleAddProtecionChange}
                              disabled={
                                carExtraArray?.[0]?.cdw == 0 ||
                                !carExtraArray?.[0]?.cdw
                              }
                            />
                          </div>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="p-sm-3 p-md-0">
                      <div className="d-flex- px-0 align-items-center mb-1 row">
                        <div className="img-div  d-flex gap-2 align-items-center col-8">
                          <img src={proImg3} alt="PAI" />
                          <label className="Protectin-add-on">
                            Super Collision Damage Waiver (SCDW)
                          </label>
                        </div>
                        {/* <div className="protection__details__div no-wrap"> */}
                       
                          <p className="title-price mb-0 col-2">
                            AED{" "}
                            {requestBody_pickup?.booking_type === "daily"
                              ? carExtraArray?.[0]?.scdw
                              : carExtraArray?.[0]?.scdw_per_month_rate}
                          </p>
                          <div className="d-flex justify-content-end col-2">
                            {/* <label>View Details</label> */}
                            <Form.Check // prettier-ignore
                              type="switch"
                              id="custom-switch"
                              name="scdw"
                              // checked={setAddProtection.scdw}
                              checked={addProtection.scdw}
                              onChange={handleAddProtecionChange}
                              disabled={
                                carExtraArray?.[0]?.scdw == 0 ||
                                !carExtraArray?.[0]?.scdw
                              }
                            />
                          </div>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>

</Container>
</section>


    
      </Col>

      <Col lg="12" md="12" sm="12">
<section className="car__details__section pt-0 mt-4">
<Container>

          <Card className="mt-5- shadow border-0">
            <Card.Header className="car__details__header">
              Rental Add-ons
            </Card.Header>
            <Card.Body>
              <div className="container px-sm-4 px-md-2">
                <div className="row- gx-5- ">
                  <div className="col">
                    <div className="p-3-">
                      <div className="d-flex- px-0 align-items-center mb-1 row">
                        <div className="img-div d-flex gap-2 align-items-center col-6">
                          <img src={proImg4} alt="PAI" />
                          <label className="Protectin-add-on  no-wrap">
                                Baby Seat
                              </label>
                        </div>
                        {/* <div className="protection__details__div "> */}
                          {/* <div className="d-flex justify-content-between"> */}
                            {/* <div> */}
                             
                              <p className="title-price mb-0 col">
                                AED{" "}
                                {requestBody_pickup?.booking_type === "daily"
                                  ? carExtraArray?.[0]?.baby_seat
                                  : carExtraArray?.[0]
                                      ?.baby_seat_per_month_rate}
                              </p>
                            {/* </div> */}
                            <Form.Control
                              type="number"
                              // disabled
                              name="baby_seat"
                              className="text-center  col-1"
                              placeholder="0"
                              style={{ height: "max-content", width: "15%" }}
                              value={babyDriver.baby_seat}
                              onChange={babySeatAndDriver}
                              // onBlur={babySeatAndDriverBlur}
                              min="1" // Ensure the input field UI prevents entering values less than 1
                              max="3"
                              disabled={
                                !addProtection.baby_seat ||
                                carExtraArray?.[0]?.baby_seat == 0 ||
                                !carExtraArray?.[0]?.baby_seat
                              }
                            />
                          {/* </div> */}

                          <div className="d-flex justify-content-end col">
                            {/* <label>View Details</label> */}
                            <Form.Check // prettier-ignore
                              type="switch"
                              id="custom-switch"
                              name="baby_seat"
                              // checked={setAddProtection.baby_seat}
                              checked={addProtection.baby_seat}
                              onChange={handleAddProtecionChange}
                              disabled={
                                carExtraArray?.[0]?.baby_seat == 0 ||
                                !carExtraArray?.[0]?.baby_seat
                              }
                            />
                          </div>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="p-sm-3 p-md-0">
                      <div className="d-flex- px-0 align-items-center mb-1 row">
                        <div className="img-div d-flex gap-2 align-items-center col-6">
                          <img src={proImg5} alt="PAI" />
                          <label className="Protectin-add-on">
                            Navigation System
                          </label>
                        </div>
                        {/* <div className="protection__details__div no-wrap"> */}
                        
                          <p className="title-price mb-0 col">
                            AED{" "}
                            {requestBody_pickup?.booking_type === "daily"
                              ? carExtraArray?.[0]?.gps
                              : carExtraArray?.[0]?.gps_per_month_rate}
                          </p>
                          <div className="col"></div>
                          <div className="d-flex justify-content-end col">
                            {/* <label>View Details</label> */}
                            <Form.Check // prettier-ignore
                              type="switch"
                              id="custom-switch"
                              name="gps"
                              // checked={setAddProtection.gps}
                              checked={addProtection.gps}
                              onChange={handleAddProtecionChange}
                              disabled={
                                carExtraArray?.[0]?.gps == 0 ||
                                !carExtraArray?.[0]?.gps
                              }
                            />
                          </div>
                          
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="p-sm-3 p-md-0">
                      <div className="d-flex- px-0 align-items-center mb-1 row">
                        <div className="img-div d-flex gap-2 align-items-center col-6">
                          <img src={proImg6} alt="PAI" />
                          <label className="Protectin-add-on no-wrap col-4">
                                Additional Driver
                              </label>
                        </div>
                        {/* <div className="protection__details__div no-wrap"> */}
                          {/* <div className="d-flex justify-content-between"> */}
                           
                             
                              <p className="title-price mb-0 col">
                                AED{" "}
                                {requestBody_pickup?.booking_type === "daily"
                                  ? carExtraArray?.[0]?.driver
                                  : carExtraArray?.[0]?.driver_per_month_rate}
                              </p>
                          
                            <Form.Control
                              type="number"
                              name="driver"
                              className="text-center  col-1"
                              placeholder="0"
                              style={{ height: "max-content", width: "15%" }}
                              value={babyDriver.driver}
                              onChange={babySeatAndDriver}
                              // onBlur={babySeatAndDriverBlur}
                              min="1"
                              max="3"
                              disabled={
                                !addProtection.driver ||
                                carExtraArray?.[0]?.driver == 0 ||
                                !carExtraArray?.[0]?.driver
                              }
                            />
                          {/* </div> */}
                          <div className="d-flex justify-content-end col">
                            {/* <label>View Details</label> */}
                            <Form.Check // prettier-ignore
                              type="switch"
                              id="custom-switch"
                              name="driver"
                              // checked={setAddProtection.driver}
                              checked={addProtection.driver}
                              onChange={handleAddProtecionChange}
                              disabled={
                                carExtraArray?.[0]?.driver == 0 ||
                                !carExtraArray?.[0]?.driver
                              }
                            />
                          </div>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
      
        </Container>
        </section>
        </Col>  

      </Col>


      
<Col lg="6" md="12" sm="12">
      <section  className={`section-mobile ${requestBody_pickup?.booking_type === "monthly" ? "mt-2" : ""}`}>
        <Container>
          <div className="row g-2 car__details__section">
            {/* <div className="col-lg-12 col-sm-12">
              <div className="">
                <Figure>
                  <Figure.Image alt="171x180" src={selectedCar?.image} />
                  <Figure.Image alt="why" src={exampleCarimg} />
                </Figure>
              </div>
            </div> */}

            <div className="col-lg-12  col-sm-12 mt-0">
              <div className="">
                {
                  /* subscriptionPlan === "monthly"   */ true ? (
                    <>
                      {mileage_plan_array?.length > 0 && (
                        <Card className="shadow border-0 min-height-mileage-box">
                          <Card.Header className="car__details__header">
                            {/* Monthly Subscription */} Mileage Plan
                          </Card.Header>
                          <Card.Body className="car__details__body-2">
                            <ul className="nav nav-tabs justify-content-evenly border-0">
                              {/* <li
                                   className={`nav-item rent_tabs  default-mileage-plan ${
                                    selectedMileage === 3000
                                      ? "selected"
                                      : ""
                                  }`}
                                 
                                    onClick={() =>
                                      handleSelectMileage(3000)
                                    }
                                  >
                                    <a className="nav-link d-block">
                                      <p className="rent_duration">
                                       3000 KM
                                      </p>
                                      <p className="rent_rate mb-0">
                                        NA AED
                                      </p>
                                      <p className="rent_per_duration m-0 ps-3">
                                        Per Month
                                      </p>
                                    </a>
                                  </li> */}
                              {mileage_plan_array?.length > 0 &&
                                mileage_plan_array?.map((item) => (
                                  // <li
                                  //   className="nav-item rent_tabs"
                                  //   key={item.id}
                                  // >
                                  //   <a className="nav-link d-block">
                                  //     <p className="rent_duration">
                                  //       {item.mileage} KM
                                  //     </p>
                                  //     <p className="rent_rate mb-0">
                                  //       {item.rate} AED
                                  //     </p>
                                  //     <p className="rent_per_duration m-0 ps-3">
                                  //       Per Month
                                  //     </p>
                                  //   </a>
                                  // </li>

                                  <li
                                    className={`nav-item rent_tabs ${
                                      selectedMileage === item.mileage
                                        ? "selected"
                                        : ""
                                    }`}
                                    key={item.id}
                                    onClick={() =>
                                      handleSelectMileage(item.mileage)
                                    }
                                  >
                                    <a className="nav-link d-block">
                                      <p className="rent_duration">
                                        {item.mileage} KM
                                      </p>
                                      <p className="rent_rate mb-0">
                                        {item.rate} AED
                                      </p>
                                      <p className="rent_per_duration m-0 ps-3">
                                        Per Month
                                      </p>
                                    </a>
                                  </li>
                                ))}

                              {/* <li className="nav-item rent_tabs">
                              <a className="nav-link d-block" href="#">
                                <p className="rent_duration">1 Month</p>
                                <p className="rent_rate mb-0">1,499 AED</p>
                                <p className="rent_per_duration m-0">
                                  Per Month
                                </p>
                              </a>
                            </li>
                            <li className="nav-item rent_tabs">
                              <a className="nav-link d-block" href="#">
                                <p className="rent_duration">3 Months</p>
                                <p className="rent_rate mb-0">2,499 AED</p>
                                <p className="rent_per_duration m-0">
                                  Per Month
                                </p>
                              </a>
                            </li>
                            <li className="nav-item rent_tabs">
                              <a className="nav-link d-block" href="#">
                                <p className="rent_duration">6 Months</p>
                                <p className="rent_rate mb-0">17,499 AED</p>
                                <p className="rent_per_duration m-0">
                                  Per Month
                                </p>
                              </a>
                            </li> */}
                            </ul>
                            {mileage_plan_array?.length > 0 && (
                            <div className="col mt-3 mb-2">
                      
                         </div>
                         )}
                          </Card.Body>
                        </Card>
                      )}
                    </>
                  ) : (
                    <></>
                  )
                }

                {requestBody_pickup?.booking_type === "daily" && (
                  <Card className="mt-2 shadow border-0">
                    <Card.Header className="car__details__header">
                      Car Details
                    </Card.Header>
                    <Card.Body className="car__details__body">
                      <Card.Title className="car__details__title m-0">
                        {/* TOYOTA COROLLA */}
                        {selectedCar?.car_name}
                      </Card.Title>
                      <Card.Text className="car__details__compare m-0">
                        or Similar | {selectedCar?.category}
                      </Card.Text>
                      <hr className="my-2" />
                      {loading_for_car_rate_table ? (
                        <div className="text-center h-100">
                          <Spinner />{" "}
                        </div>
                      ) : (
                        <div className="row">
                        <div className="col-lg-7  col-sm-7">
                        <Table borderless className="">
                          <tbody className="text-start ">
                            <tr>
                              <td>Car Rate</td>
                              <td>
                                AED{" "}
                                {
                                  selectedCar?.payment_method ===
                                    "pay_monthly_now" ||
                                  selectedCar?.payment_method ===
                                    "pay_monthly_later"
                                    ? Number(selectedCar?.car_rate_total) /* +
                                    Number(selectedCar?.discount) */
                                    : selectedCar?.payment_method ===
                                      "pay_later"
                                    ? Number(selectedCar?.pay_later) /* +
                                    Number(selectedCar?.discount) */
                                    : Number(selectedCar?.pay_now) /* +
                                    Number(selectedCar?.discount) */
                                }
                              </td>
                            </tr>

                            {addProtection.pai && (
                              <tr>
                                <td>PAI</td>
                                <td>AED {carExtraArray?.[0]?.pai}</td>
                              </tr>
                            )}
                            {addProtection.cdw && (
                              <tr>
                                <td>CDW</td>
                                <td>AED {carExtraArray?.[0]?.cdw}</td>
                              </tr>
                            )}
                            {addProtection.scdw && (
                              <tr>
                                <td>SCDW</td>
                                <td>AED {carExtraArray?.[0]?.scdw}</td>
                              </tr>
                            )}
                            {addProtection.baby_seat && (
                              <tr>
                                <td>Baby Seater</td>
                                <td>
                                  AED{" "}
                                  {Number(carExtraArray?.[0]?.baby_seat) *
                                    Number(babyDriver.baby_seat)}{" "}
                                </td>
                              </tr>
                            )}
                            {addProtection.gps && (
                              <tr>
                                <td>GPS</td>
                                <td>AED {carExtraArray?.[0]?.gps} </td>
                              </tr>
                            )}
                            {addProtection.driver && (
                              <tr>
                                <td>Driver</td>
                                <td>
                                  AED{" "}
                                  {Number(carExtraArray?.[0]?.driver) *
                                    Number(babyDriver.driver)}{" "}
                                </td>
                              </tr>
                            )}

                            {selectedCar?.discount !== "0" &&
                              selectedCar?.discount !== 0 && (
                                <tr>
                                  <td>Promo Code Discount</td>
                                  <td>
                                    {" "}
                                    <span className="text-danger">
                                      AED {selectedCar?.discount}
                                    </span>
                                  </td>
                                </tr>
                              )}

                            {selectedCar?.pickup_parking_charges !== "0" &&
                              selectedCar?.pickup_parking_charges !== 0 && (
                                <tr>
                                  <td>Pickup Parking Charges</td>
                                  <td>
                                    AED {selectedCar?.pickup_parking_charges}
                                  </td>
                                </tr>
                              )}
                            {selectedCar?.dropoff_parking_charges !== "0" &&
                              selectedCar?.dropoff_parking_charges !== 0 && (
                                <tr>
                                  <td>Dropoff Parking Charges</td>
                                  <td>
                                    AED {selectedCar?.dropoff_parking_charges}
                                  </td>
                                </tr>
                              )}
                            {selectedCar?.delivery_charges !== "0" &&
                              selectedCar?.delivery_charges !== 0 && (
                                <tr>
                                  <td>Delivery Charges</td>
                                  <td>AED {selectedCar?.delivery_charges}</td>
                                </tr>
                              )}
                            {selectedCar?.collection_charges !== "0" &&
                              selectedCar?.collection_charges !== 0 && (
                                <tr>
                                  <td>Collection Charges</td>
                                  <td>AED {selectedCar?.collection_charges}</td>
                                </tr>
                              )}
                            {selectedCar?.inter_emirates_charges !== "0" &&
                              selectedCar?.inter_emirates_charges !== 0 && (
                                <tr>
                                  <td>Inter Emirate Charges</td>
                                  <td>
                                    AED {selectedCar?.inter_emirates_charges}
                                  </td>
                                </tr>
                              )}
                            {selectedCar?.vmd_charges !== "0" &&
                              selectedCar?.vmd_charges !== 0 && (
                                <tr>
                                  <td>VMD Charges</td>
                                  <td>AED {selectedCar?.vmd_charges}</td>
                                </tr>
                              )}
                            {addProtection?.activeKM && (
                              <tr>
                                <td>KM Allowance</td>
                                <td>
                                  AED{" "}
                                  {Number(addProtection?.activeKM) *
                                    Number(selectedCar?.booking_months)}
                                </td>
                              </tr>
                            )}

                            <tr>
                              <td>Sub Total</td>
                              <td>AED {subTotal}</td>
                            </tr>
                            <tr>
                              <td>VAT (5%)</td>
                              <td>AED {vat} </td>
                            </tr>

                            <tr>
                              <td>Total</td>
                              <td>AED {total}</td>
                            </tr>

                            {booking_number &&
                              editUserBookingObject?.payment_type === "now" && (
                                <>
                                  <tr>
                                    <td>Previously Paid</td>
                                    <td>
                                      AED {editUserBookingObject?.total_amount}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {Number(total) -
                                        Number(
                                          editUserBookingObject?.total_amount
                                        ) <
                                      0
                                        ? "You will be refunded"
                                        : "Need to Pay Now"}
                                    </td>
                                    <td>
                                      AED{" "}
                                      {Math.abs(
                                        Number(total) -
                                          Number(
                                            editUserBookingObject?.total_amount
                                          )
                                      )?.toFixed(2)}
                                    </td>
                                  </tr>
                                </>
                              )}
                          </tbody>
                        </Table>
                        </div>

                         <div className="col-lg-5 col-sm-5">
                         <div className="">
                           <Figure>
                             <Figure.Image alt={selectedCar?.car_name} src={selectedCar?.image} />
                          
                           </Figure>
                         </div>
                       </div>
                       </div>
                      )}

                      <Card.Text className="car__details__feature m-0 text-center">
                        PAYMENT METHOD:{" "}
                        {selectedCar?.payment_method === "pay_later" ||
                        selectedCar?.payment_method === "pay_monthly_later"
                          ? "PAY LATER"
                          : "PAY NOW"}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                )}
                {requestBody_pickup?.booking_type === "monthly" && (
                  <Card className="mt-4 shadow border-0">
                    <Card.Header className="car__details__header">
                      {monthlyInstallmentArray?.installments?.length === 1
                        ? "Payment Details"
                        : "Installments"}{" "}
                      {/* taleeb */}
                    </Card.Header>
                    {installmentTableLoading ? (
                      <div className="w-100 h-100 text-center my-4 py-4">
                        <Spinner />
                      </div>
                    ) : (
                      <Card.Body className="car__details__body">
                        <div className="my-2 row">
                          <div className="col-md-7">
                          <Table
                            striped
                            bordered
                            hover
                          
                            
                            className="installment_table "
                          >
                            <thead>
                              <tr>
                                {monthlyInstallmentArray?.installments?.length >
                                  1 && <th>#</th>}
                                <th>Sub Amount</th>
                                <th>VAT Amount</th>

                                <th>Total Amount</th>
                                {booking_number &&
                                  editUserBookingObject?.payment_type !==
                                    "later" && (
                                    <>
                                      {" "}
                                      <th>Previously Paid</th>
                                      <th>
                                        {Number(
                                          monthlyInstallmentArray
                                            ?.installments?.[0]?.total_amount
                                        ) -
                                          Number(
                                            editUserBookingObject
                                              ?.monthly_installments?.[0]
                                              ?.actual_amount
                                          ) >
                                        0
                                          ? "Amount To Be Paid"
                                          : "Refund Amount"}
                                      </th>
                                    </>
                                  )}
                              </tr>
                            </thead>

                            <tbody className="">
                              {monthlyInstallmentArray &&
                                monthlyInstallmentArray?.installments?.length >
                                  0 &&
                                monthlyInstallmentArray?.installments?.map(
                                  (item, index) => (
                                    <tr key={item.installment_no}>
                                      {monthlyInstallmentArray?.installments
                                        ?.length > 1 && (
                                        <td>{item.installment_no}</td>
                                      )}
                                      <td>
                                        {Number(item.sub_amount)?.toFixed(2)}{" "}
                                        AED
                                        <span
                                            onMouseEnter={(e) => handleMouseEnter(index, e)}
                                            onMouseLeave={handleMouseLeave}
                                            className="tooltip-info"
                                          >
                                          <img src={infoIcon} className="info-icon" /> 
                                          </span>
                                          {hoveredIndex === index && (
                                            <div
                                              className="installment-tooltip"
                                            
                                              onMouseEnter={(e) => handleMouseEnter(index, e)}
                                              onMouseLeave={handleMouseLeave}
                                            >
                                              <strong>Amount Breakdown:</strong>
                                              <table className="table table-sm table-borderless mb-0">
                                                <tbody>
                                                  {Object.entries(item.details).map(([key, value]) => {
                                                    if (key === "cat" || value == 0) return null; // Skip the "cat" key
                                                    return (
                                                      <tr key={key}>
                                                        <td>{key}</td>
                                                        <td>{Number(value)?.toFixed(2)}</td>
                                                      </tr>
                                                    );
                                                  })}
                                                </tbody>
                                              </table>
                                            </div>
                                          )}
                                      </td>
                                      <td>
                                        {Number(item.vat_amount)?.toFixed(2)}{" "}
                                        AED
                                      </td>
                                      <td>
                                        {Number(item.total_amount)?.toFixed(2)}{" "}
                                        AED
                                      </td>
                                      {/* Check if it's the first row */}
                                      {booking_number &&
                                        editUserBookingObject?.payment_type !==
                                          "later" &&
                                        (index === 0 ? (
                                          <>
                                            {/* Only show Previously Paid in the first row */}

                                            <td>
                                              {Number(
                                                editUserBookingObject
                                                  ?.monthly_installments?.[0]
                                                  ?.actual_amount
                                              )?.toFixed(2)}{" "}
                                              AED
                                            </td>
                                            {/* Show adjusted Total Amount in the first row */}
                                            <td>
                                              {Number(
                                                Math.abs(
                                                  Number(item.total_amount) -
                                                    Number(
                                                      editUserBookingObject
                                                        ?.monthly_installments?.[0]
                                                        ?.actual_amount
                                                    )
                                                )
                                              )?.toFixed(2)}{" "}
                                              AED
                                            </td>
                                          </>
                                        ) : (
                                          <>
                                            <td></td>
                                            <td></td>
                                          </>
                                        ))}
                                    </tr>
                                  )
                                )}

                              {/* <tr>
                            <td>1</td>
                            <td>28-12-2023 00:23</td>
                            <td>27-01-2024 00:29</td>
                            <td>30</td>
                            
                          </tr>
                          <tr>
                            <td></td>
                            <td colSpan={2}>Total</td>
                            <td>183</td>
                          </tr> */}
                            </tbody>
                          </Table>
                          </div>
                          <div className=" col-md-5">
                          <Figure>
                             <Figure.Image alt={selectedCar?.car_name} src={selectedCar?.image} />
                           
                           </Figure>
                          </div>
                        </div>
                      </Card.Body>
                    )}
                  </Card>
                )}
              </div>
            </div>

            

            {/* <div className="col-lg-6  col-sm-12"></div> */}
            <div className="text-center col-lg-12 col-sm-12  book-button-container my-2">
              {/* <Button
                className="booking__btn text-nowrap mt-2"
                onClick={handleBookingClick}
                disabled={loading_confirmBookin}
              >
                {loading_confirmBookin ? (
                  <Spinner />
                ) : (
                  <>
                    <span className="text-nowrap">
                      {
                        booking_number &&
                        editUserBookingObject?.payment_type !== "later" &&
                        editUserBookingObject?.type === "daily"
                          ? Number(total) -
                              Number(editUserBookingObject?.total_amount) >
                            0
                            ? `AED  ${(
                                Number(total) -
                                Number(editUserBookingObject?.total_amount)
                              )?.toFixed(2)}`
                            : `You will be refunded  ${Math.abs(
                                Number(total) -
                                  Number(editUserBookingObject?.total_amount)
                              )?.toFixed(2)}` // This renders nothing when the amount to pay now is negative
                          : booking_number &&
                            requestBody_pickup?.booking_type === "monthly" &&
                            editUserBookingObject?.payment_type !== "later" // New condition for booking_number and booking_type
                          ? `${
                              Number(
                                monthlyInstallmentArray?.installments?.[0]
                                  ?.total_amount
                              ) -
                                Number(
                                  editUserBookingObject
                                    ?.monthly_installments?.[0]?.actual_amount
                                ) <
                              0
                                ? "You will be refunded  "
                                : " Pay "
                            }AED ${Math.abs(
                              Number(
                                monthlyInstallmentArray?.installments?.[0]
                                  ?.total_amount
                              ) -
                                Number(
                                  editUserBookingObject
                                    ?.monthly_installments?.[0]?.actual_amount
                                )
                            ).toFixed(2)}`
                          : requestBody_pickup?.booking_type === "monthly"
                          ? `AED ${monthlyInstallmentArray?.installments?.[0]?.total_amount}` // Display installment amount for monthly booking
                          : `AED ${total}` // Show total if not monthly booking
                      }
                      <br />
                      {}
                      Book For{" "}
                      {selectedCar?.payment_method === "pay_monthly_now" ||
                      selectedCar?.payment_method === "pay_monthly_later"
                        ? Number(monthlyInstallmentArray?.booking_months) === 1
                          ? monthlyInstallmentArray?.installments?.length > 1
                            ? `${selectedCar?.booking_months} Month By Paying 1st Installment`
                            : `${selectedCar?.booking_months} Month`
                          : `${selectedCar?.booking_months} Months By Paying 1st Installment`
                        : Number(selectedCar?.booking_days) === 1
                        ? `${selectedCar?.booking_days} Day`
                        : `${selectedCar?.booking_days} Days`}
                    </span>
                  </>
                )}
              </Button> */}
              <Button
                className="booking__btn text-nowrap mt-2"
                onClick={handleBookingClick}
                disabled={loading_confirmBookin}
              >
              
              {(() => {
    if (loading_confirmBookin) {
      return <Spinner />;
    } else if (!localStorage.getItem("token") && (selectedCar?.payment_method === "pay_now" || selectedCar?.payment_method === "pay_monthly_now") ) {
      return "Login or continue as guest";
    } else if (localStorage.getItem("token") && requestBody_pickup?.booking_type === "daily" && selectedCar?.payment_method === "pay_now" ){
      return "Continue to payment"
    } else if (localStorage.getItem("token") && requestBody_pickup?.booking_type === "monthly" && selectedCar?.payment_method === "pay_monthly_now" ){
      return "Continue to pay 1st Installment"
    }
    
    else if (!localStorage.getItem("token") && (selectedCar?.payment_method === "pay_later" || selectedCar?.payment_method === "pay_monthly_later") ){
      return "Login or continue as guest"
    } else if (localStorage.getItem("token") && (selectedCar?.payment_method === "pay_later" || selectedCar?.payment_method === "pay_monthly_later")){
      return "Confirm booking"
    }
  })()}
              
                </Button>

            </div>

         

            {/* {console.log("km_object-->", km_object)}
            {(selectedCar?.payment_method === "pay_monthly_now" ||
              selectedCar?.payment_method === "pay_monthly_later") &&
              km_object?.extra_1000_km_rate && (
                <div className="col mt-2">
                  <Card className="shadow border-0">
                    <Card.Header className="car__details__header">
                      Should you exceed your KM allowance the cost x extra KM is
                      AED 1 x KM
                    </Card.Header>
                 
                    <Card.Body>
                      <div className="container p-2">
                        <div className="row  d-flex justify-content-center ">
                          <ul className="list-unstyled nav nav-tabs service_slider border-0 custom-slider">
                            <div className="row justify-content-around mx-auto">
                              <li className="col-lg-3 col-md-3 col-sm-4 nav-item km_allowance_tabs">
                                <a
                                  className={`nav-link ${
                                    addProtection?.activeKM ===
                                    km_object?.extra_1000_km_rate
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handle_km_plan_click(
                                      km_object?.extra_1000_km_rate,
                                      1000
                                    )
                                  }
                                  aria-current="page"
                                >
                                  1000 km / {km_object?.extra_1000_km_rate} AED
                                  <br /> Per Month
                                </a>
                              </li>
                              <li className="col-lg-3 col-md-3 col-sm-4 nav-item km_allowance_tabs">
                                <a
                                  className={`nav-link ${
                                    addProtection?.activeKM ===
                                    km_object?.extra_2000_km_rate
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handle_km_plan_click(
                                      km_object?.extra_2000_km_rate,
                                      2000
                                    )
                                  }
                                >
                                  2000 km / {km_object?.extra_2000_km_rate} AED
                                  <br /> Per Month
                                </a>
                              </li>

                              <li className="col-lg-3 col-md-3 col-sm-4 nav-item km_allowance_tabs">
                                <a
                                  className={`nav-link ${
                                    addProtection?.activeKM ===
                                    km_object?.extra_3000_km_rate
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handle_km_plan_click(
                                      km_object?.extra_3000_km_rate,
                                      3000
                                    )
                                  }
                                >
                                  3000 km / {km_object?.extra_3000_km_rate} AED
                                  <br /> Per Month
                                </a>
                              </li>
                            </div>
                          </ul>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              )} */}
          </div>
        </Container>
      </section>
      </Col>
      </Row>
      </Container>
      <Container>
              {filterCarArray?.length > 0 &&
                (selectedCar?.payment_method === "pay_now" ||
                  selectedCar?.payment_method === "pay_later") && (
                  <Card className="shadow border-0 mt-4">
                    <Card.Header className="car__details__header">
                      Upgrade Options
                    </Card.Header>
                    <Card.Body>
                      <div class="container px-4 text-center   ">
                        <div class="row  gx-5 p-1">
                          <>
                            {filterCarArray?.length > 3 ? (
                              /*    <>
                          <Slider className="service_slider" {...settings}>
                            {filterCarArray &&
                              filterCarArray?.map((car) => (
                                <div class="col " key={car.id}>
                                  <div
                                    class="p-3 book-card-wrap"
                                    style={{ height: "300px" }}
                                  >
                                    <div className="p-2 ">
                                      <Card.Img
                                        variant="top "
                                        src={car?.image}
                                        style={{
                                          height: "15vh",
                                          objectFit: "cover",
                                        }}
                                      />
                                      <Card.Body className="text-center">
                                        <Card.Title className="title-upgrade">
                                          {car?.car_name}
                                        </Card.Title>
                                        <Card.Text className="title-price">
                                          AED{" "}
                                          {selectedCar?.payment_method ===
                                            "pay_monthly_now" ||
                                          selectedCar?.payment_method ===
                                            "pay_monthly_later"
                                            ? (
                                                Number(car.car_rate_total) -
                                                Number(
                                                  selectedCar?.car_rate_total
                                                )
                                              ).toFixed(2)
                                            : selectedCar?.payment_method ===
                                              "pay_now"
                                            ? (
                                                Number(car.pay_now) -
                                                Number(selectedCar?.pay_now)
                                              ).toFixed(2)
                                            : (
                                                Number(car.pay_later) -
                                                Number(selectedCar?.pay_later)
                                              ).toFixed(2)}
                                        </Card.Text>
                                        <Button
                                          className="btn-upgrade"
                                          variant="primary rounded-0"
                                          onClick={() =>
                                            mergeCarProperties(car)
                                          }
                                        >
                                          Book{" "}
                                        </Button>
                                      </Card.Body>
                                    </div>
                                  </div>
                                </div>
                              ))}

                          
                          </Slider>
                        </> */
                              <>
                                <Slider
                                  className="service_slider"
                                  {...settings}
                                >
                                  {filterCarArray &&
                                    filterCarArray?.map((car) => (
                                      <div className="row justify-content-center">
                                        <div class="col " key={car.id}>
                                          <div
                                            class="w-100 book-card-wrap"
                                            // style={{ height: "300px" }}
                                          >
                                            <div className="p-2 w-100">
                                              <Card.Img
                                                className="card-img-comparing"
                                                variant="top"
                                                src={car?.image}
                                                // style={{
                                                //   height: "20vh",
                                                //   width: "auto",
                                                //   textAlign: "center",
                                                //   marginInline: "auto",
                                                // }}
                                              />
                                              <Card.Body className="text-center">
                                                <Card.Title className="title-upgrade text-nowrap mx-auto">
                                                  {car?.car_name}
                                                </Card.Title>
                                                <Card.Text className="title-price">
                                                  {/* taleeb */}
                                                  <p className="text-nowrap upgrade-text-fs">
                                                    <span className="title-upgrade text-nowrap upgrade-text-fs">
                                                      Pay additional{" "}
                                                    </span>{" "}
                                                    <span className=" upgrade-text-fs-price">
                                                      {" "}
                                                      AED{" "}
                                                      {selectedCar?.payment_method ===
                                                        "pay_monthly_now" ||
                                                      selectedCar?.payment_method ===
                                                        "pay_monthly_later"
                                                        ? (
                                                            Number(
                                                              car.car_rate_total
                                                            ) -
                                                            Number(
                                                              selectedCar?.car_rate_total
                                                            )
                                                          ).toFixed(2)
                                                        : selectedCar?.payment_method ===
                                                          "pay_now"
                                                        ? (
                                                            Number(
                                                              car.pay_now
                                                            ) -
                                                            Number(
                                                              selectedCar?.pay_now
                                                            )
                                                          ).toFixed(2)
                                                        : (
                                                            Number(
                                                              car.pay_later
                                                            ) -
                                                            Number(
                                                              selectedCar?.pay_later
                                                            )
                                                          ).toFixed(2)}{" "}
                                                    </span>
                                                    <br />
                                                    <span className="title-upgrade text-nowrap upgrade-text-fs">
                                                      {" "}
                                                      for this amazing upgrade{" "}
                                                    </span>
                                                  </p>
                                                </Card.Text>
                                                <Button
                                                  className="btn-upgrade"
                                                  variant="primary rounded-0"
                                                  onClick={() =>
                                                    mergeCarProperties(car)
                                                  }
                                                >
                                                  Upgrade Now{" "}
                                                </Button>
                                              </Card.Body>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}

                                  {/* <div class="col ">
                    <div class="p-3 book-card-wrap">
                      <div className="p-2 ">
                        <Card.Img variant="top" src={img1} />
                        <Card.Body className="text-center">
                          <Card.Title className="title-upgrade">
                            Toyota Camry SE 350
                          </Card.Title>
                          <Card.Text className="title-price">
                            AED 50{" "}
                          </Card.Text>
                          <Button
                            className="btn-upgrade"
                            variant="primary rounded-0"
                          >
                            Book{" "}
                          </Button>
                        </Card.Body>
                      </div>
                    </div>
                  </div>{" "}
                  <div class="col ">
                    <div class="p-3 book-card-wrap">
                      <div className="p-2 ">
                        <Card.Img variant="top" src={img1} />
                        <Card.Body className="text-center">
                          <Card.Title className="title-upgrade">
                            Toyota Camry SE 350
                          </Card.Title>
                          <Card.Text className="title-price">
                            AED 50{" "}
                          </Card.Text>
                          <Button
                            className="btn-upgrade"
                            variant="primary rounded-0"
                          >
                            Book{" "}
                          </Button>
                        </Card.Body>
                      </div>
                    </div>
                  </div>{" "}  
                  <div class="col ">
                    <div class="p-3 book-card-wrap">
                      <div className="p-2 ">
                        <Card.Img variant="top" src={img1} />
                        <Card.Body className="text-center">
                          <Card.Title className="title-upgrade">
                            Toyota Camry SE 350
                          </Card.Title>
                          <Card.Text className="title-price">
                            AED 50{" "}
                          </Card.Text>
                          <Button
                            className="btn-upgrade"
                            variant="primary rounded-0"
                          >
                            Book{" "}
                          </Button>
                        </Card.Body>
                      </div>
                    </div>
                  </div>{" "}
                  <div class="col ">
                    <div class="p-3 book-card-wrap">
                      <div className="p-2 ">
                        <Card.Img variant="top" src={img1} />
                        <Card.Body className="text-center">
                          <Card.Title className="title-upgrade">
                            Toyota Camry SE 350
                          </Card.Title>
                          <Card.Text className="title-price">
                            AED 50{" "}
                          </Card.Text>
                          <Button
                            className="btn-upgrade"
                            variant="primary rounded-0"
                          >
                            Book{" "}
                          </Button>
                        </Card.Body>
                      </div>
                    </div>
                  </div> */}
                                </Slider>
                              </>
                            ) : (
                              <>
                                {" "}
                                {/* {filterCarArray &&
                            filterCarArray.length < 4&&
                            filterCarArray?.map((car) => (
                              <div
                                className="col-sm-12 col-md-6 col-lg-4"
                                key={car.id}
                              >
                                <div
                                  className="p-3 w-100 book-card-wrap col-12"
                                  style={{ height: "300px" }}
                                >
                                  <div className="p-2 w-100 ">
                                    <Card.Img
                                      variant="top "
                                      src={car?.image}
                                      style={{
                                        height: "15vh",
                                        objectFit: "cover",
                                      }}
                                    />
                                    <Card.Body className="text-center">
                                      <Card.Title className="title-upgrade">
                                        {car?.car_name}
                                      </Card.Title>
                                      <Card.Text className="title-price">
                                        AED{" "}
                                        {selectedCar?.payment_method ===
                                          "pay_monthly_now" ||
                                        selectedCar?.payment_method ===
                                          "pay_monthly_later"
                                          ? (
                                              Number(car.car_rate_total) -
                                              Number(
                                                selectedCar?.car_rate_total
                                              )
                                            ).toFixed(2)
                                          : selectedCar?.payment_method ===
                                            "pay_now"
                                          ? (
                                              Number(car.pay_now) -
                                              Number(selectedCar?.pay_now)
                                            ).toFixed(2)
                                          : (
                                              Number(car.pay_later) -
                                              Number(selectedCar?.pay_later)
                                            ).toFixed(2)}
                                      </Card.Text>
                                      <Button
                                        className="btn-upgrade"
                                        variant="primary rounded-0"
                                        onClick={() => mergeCarProperties(car)}
                                      >
                                        Book{" "}
                                      </Button>
                                    </Card.Body>
                                  </div>
                                </div>
                              </div>
                            ))} */}
                                {filterCarArray &&
                                  filterCarArray?.length < 4 && (
                                    <>
                                      <div className="d-block d-md-none">
                                        <Carousel>
                                          {filterCarArray.map((car) => (
                                            <Carousel.Item key={car.id}>
                                              <div className="col-sm-12 col-md-4 col-lg-4">
                                                <div
                                                  className="p-3 book-card-wrap"
                                                  // style={{ height: "300px" }}
                                                >
                                                  <div className="p-2 w-100">
                                                    <Card.Img
                                                      variant="top"
                                                      src={car?.image}
                                                      className="card-img-lessthanthree"
                                                      // style={{
                                                      //   height: "15vh",
                                                      //   objectFit: "cover",
                                                      // }}
                                                    />
                                                    <Card.Body className="text-center">
                                                      <Card.Title className="title-upgrade ">
                                                        {car?.car_name}
                                                      </Card.Title>
                                                      <Card.Text className="title-price">
                                                        AED{" "}
                                                        {selectedCar?.payment_method ===
                                                          "pay_monthly_now" ||
                                                        selectedCar?.payment_method ===
                                                          "pay_monthly_later"
                                                          ? (
                                                              Number(
                                                                car.car_rate_total
                                                              ) -
                                                              Number(
                                                                selectedCar?.car_rate_total
                                                              )
                                                            ).toFixed(2)
                                                          : selectedCar?.payment_method ===
                                                            "pay_now"
                                                          ? (
                                                              Number(
                                                                car.pay_now
                                                              ) -
                                                              Number(
                                                                selectedCar?.pay_now
                                                              )
                                                            ).toFixed(2)
                                                          : (
                                                              Number(
                                                                car.pay_later
                                                              ) -
                                                              Number(
                                                                selectedCar?.pay_later
                                                              )
                                                            ).toFixed(2)}
                                                      </Card.Text>
                                                      <Button
                                                        className="btn-upgrade"
                                                        variant="primary rounded-0"
                                                        onClick={() =>
                                                          mergeCarProperties(
                                                            car
                                                          )
                                                        }
                                                      >
                                                        Book
                                                      </Button>
                                                    </Card.Body>
                                                  </div>
                                                </div>
                                              </div>
                                            </Carousel.Item>
                                          ))}
                                        </Carousel>
                                      </div>

                                      <div className="d-none d-md-block">
                                        <div className="row justify-content-center">
                                          {filterCarArray.map((car) => (
                                            <div
                                              className="col-sm-12 col-md-4 col-lg-3"
                                              key={car.id}
                                            >
                                              <div
                                                className="p-3 w-100 book-card-wrap col-12"
                                                // style={{ height: "300px" }}
                                              >
                                                <div className="p-2 w-100">
                                                  <Card.Img
                                                    variant="top"
                                                    src={car?.image}
                                                    // style={{
                                                    //   height: "15vh",
                                                    //   objectFit: "cover",
                                                    // }}
                                                  />
                                                  <Card.Body className="text-center">
                                                    <Card.Title className="title-upgrade">
                                                      {car?.car_name}
                                                    </Card.Title>
                                                    <Card.Text className="title-price">
                                                      AED{" "}
                                                      {selectedCar?.payment_method ===
                                                        "pay_monthly_now" ||
                                                      selectedCar?.payment_method ===
                                                        "pay_monthly_later"
                                                        ? (
                                                            Number(
                                                              car.car_rate_total
                                                            ) -
                                                            Number(
                                                              selectedCar?.car_rate_total
                                                            )
                                                          ).toFixed(2)
                                                        : selectedCar?.payment_method ===
                                                          "pay_now"
                                                        ? (
                                                            Number(
                                                              car.pay_now
                                                            ) -
                                                            Number(
                                                              selectedCar?.pay_now
                                                            )
                                                          ).toFixed(2)
                                                        : (
                                                            Number(
                                                              car.pay_later
                                                            ) -
                                                            Number(
                                                              selectedCar?.pay_later
                                                            )
                                                          ).toFixed(2)}
                                                    </Card.Text>
                                                    <Button
                                                      className="btn-upgrade"
                                                      variant="primary rounded-0"
                                                      onClick={() =>
                                                        mergeCarProperties(car)
                                                      }
                                                    >
                                                      Book
                                                    </Button>
                                                  </Card.Body>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </>
                                  )}
                              </>
                            )}
                          </>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                )}
            </Container>
      
      {/* <div className="col-lg-6  col-sm-12">

      </div>
      <div className="col-lg-6  col-sm-12">

      </div> */}
      {/* <div className="text-center--  book-button-container my-2">
        

        <Button
          className="booking__btn text-nowrap"
          onClick={handleBookingClick}
          disabled={loading_confirmBookin}
        >
          {loading_confirmBookin ? (
            <Spinner />
          ) : (
            <>
              <span className="text-nowrap">
                {
                  booking_number &&
                  editUserBookingObject?.payment_type !== "later" &&
                  editUserBookingObject?.type === "daily"
                    ? Number(total) -
                        Number(editUserBookingObject?.total_amount) >
                      0
                      ? `AED  ${(
                          Number(total) -
                          Number(editUserBookingObject?.total_amount)
                        )?.toFixed(2)}`
                      : `You will be refunded  ${Math.abs(
                          Number(total) -
                            Number(editUserBookingObject?.total_amount)
                        )?.toFixed(2)}` // This renders nothing when the amount to pay now is negative
                    : booking_number &&
                      requestBody_pickup?.booking_type === "monthly" // New condition for booking_number and booking_type
                    ? `${
                        Number(
                          monthlyInstallmentArray?.installments?.[0]
                            ?.total_amount
                        ) -
                          Number(
                            editUserBookingObject?.monthly_installments?.[0]
                              ?.actual_amount
                          ) <
                        0
                          ? "You will be refunded  "
                          : " Pay "
                      }AED ${Math.abs(
                        Number(
                          monthlyInstallmentArray?.installments?.[0]
                            ?.total_amount
                        ) -
                          Number(
                            editUserBookingObject?.monthly_installments?.[0]
                              ?.actual_amount
                          )
                      ).toFixed(2)}`
                    : requestBody_pickup?.booking_type === "monthly"
                    ? `AED ${monthlyInstallmentArray?.installments?.[0]?.total_amount}` // Display installment amount for monthly booking
                    : `AED ${total}` // Show total if not monthly booking
                }
                <br />
                Book For{" "}
                {selectedCar?.payment_method === "pay_monthly_now" ||
                selectedCar?.payment_method === "pay_monthly_later"
                  ? monthlyInstallmentArray?.installments?.length === 1 ? `${selectedCar?.booking_months} Month` :  `${selectedCar?.booking_months} Months By Paying 1st Installment`
                  : Number(selectedCar?.booking_days) === 1 ?   `${selectedCar?.booking_days} Day` : `${selectedCar?.booking_days} Days` }
              </span>
            </>
          )}
        </Button>
      
      </div> */}
      <LoginModal
        loginModalShow={loginModalShow}
        setLoginModalShow={setLoginModalShow}
        registerModalShow={registerModalShow}
        setRegisterModalShow={setRegisterModalShow}
        setCardModalShow={setCardModalShow}
        setForgetPasswordModalShow={setForgetPasswordModalShow}
        isExistingCustomer={isExistingCustomer}
        handleSelectionChange={handleSelectionChange}
      />
      <RegisterModal
        registerModalShow={registerModalShow}
        setRegisterModalShow={setRegisterModalShow}
        setLoginModalShow={setLoginModalShow}
        isExistingCustomer={isExistingCustomer}
        handleSelectionChange={handleSelectionChange}
      />
      <ForgetPasswordModal
        forgetPasswordModalShow={forgetPasswordModalShow}
        setForgetPasswordModalShow={setForgetPasswordModalShow}
        setResetPasswordModalShow={setResetPasswordModalShow}
        setLoginModalShow={setLoginModalShow}
      />
      <ResetPasswordModal
        resetPasswordModalShow={resetPasswordModalShow}
        setResetPasswordModalShow={setResetPasswordModalShow}
        setLoginModalShow={setLoginModalShow}
      />
      <CardDetailsModal
        cardModalShow={cardModalShow}
        setCardModalShow={setCardModalShow}
        confirmBookingData={confirmBookingData}
        confirmBookingPayment={confirmBookingPayment}
        monthlyInstallmentArray={monthlyInstallmentArray}
        total={total}
        enabledExtras={enabledExtras}
      />
    </div>
  );
};

//// validation addd code
import React, { useContext, useEffect, useRef, useState } from "react";
import "../../styles/find-car-form.css";
import "./FindCarForm.css";
import { useTranslation } from "react-i18next";
import { simpleGetCall, simplePostCall } from "../../config.js/SetUp";
import { notifyError, notifySuccess } from "../../SharedComponent/notify";

import "bootstrap/dist/css/bootstrap.min.css";

import { setSelectedPickupLocation } from "../../reducers"; // Import the action
import { setSelectedDropoffLocation } from "../../reducers/Slices/selectedDropoffLocationSlice"; // Import the action
import {
  Button,
  Col,
  Form,
  Row,
  Nav,
  ButtonGroup,
  ToggleButton,
  Modal,
  Spinner,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import configWeb from "../../config.js/configWeb";
import { AppContext } from "../../context/AppContext";
import { setCarArray } from "../../reducers/Slices/carArraySlice";
import usePlacesAutocomplete, {
  // getGeocode,
  getLatLng,
} from "use-places-autocomplete";
// import TimePicker from "./TimePicker";
import ChooseDeliverToMePopup from "./ChooseDeliverToMePopup";
import CollectFromMePopup from "./CollectFromMePopup";
import CommonlyUsedComponents from "./CustomDateTimePicker3";
import DropoffDateTimePicker from "./DropoffDateTimePicker";
import { useSelector, useDispatch } from "react-redux";
import { setRequestBody_dropoff, setRequestBody_pickup } from "../../reducers";
import { setPickupEmirate } from "../../reducers/Slices/pickupEmirateSlice";
import { setDropOffEmirate } from "../../reducers/Slices/dropoffEmirateSlice";
import {
  transformEmirateArray,
  filteredPickUpLocationArray,
  transformedPickupLocationArray,
} from "../../SharedComponent/ReUseableFunctions";
import { setSelectedDeliveryEmirate } from "../../reducers/Slices/selectedDeliveryEmirateSlice";
import { getFullMonthDifference, isDifferenceGreaterThanDays } from "../../SharedComponent/reusableFunctions";
import { setSelectedCollectEmirate } from "../../reducers/Slices/selectedCollectEmirateSlice";

const defaultCenter = {
  lat: 25.2048, // default latitude
  lng: 55.2708, // default longitude
};

const customStyles = {
  groupHeading: (provided) => ({
    ...provided,
    color: "#333", // Change text color
    fontSize: "14px", // Adjust font size
    fontWeight: "bold", // Make the text bold
    backgroundColor: "#f3f4f6", // Add a background color
    padding: "10px", // Add some padding
  }),
  control: (provided, state) => ({
    ...provided,
    background: "#fff",
    // borderColor: '#9e9e9e',
    minHeight: "30px",
    height: "45px",
    boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "200px", // Adjust the max height as needed
    overflowY: "auto",
  }),
};
const FindCarForm = (props) => {
  const language = useSelector((state) => state.language.language);
  const [allCarsArray, setAllCarsArray] = useState([]);

  const dispatch = useDispatch();

  const selectedPickupLocation = useSelector(
    (state) => state.selectedPickupLocation.selectedPickupLocation
  );
  const dropOffEmirate = useSelector(
    (state) => state.dropOffEmirate.dropOffEmirate
  );
  const pickupEmirate = useSelector(
    (state) => state.pickupEmirate.pickupEmirate
  );
  const savedCar = useSelector((state) => state.savedCar.savedCar);
  const selectedDropoffLocation = useSelector(
    (state) => state.selectedDropoffLocation.selectedDropoffLocation
  );
  const inputValue = useSelector((state) => state.inputValue.inputValue);
  const inputValueDropoff = useSelector(
    (state) => state.inputValueDropoff.inputValueDropoff
  );
  const selectedDeliveryEmirate = useSelector(
    (state) => state.selectedDeliveryEmirate.selectedDeliveryEmirate
  );
  const selectedCollectEmirate = useSelector(
    (state) => state.selectedCollectEmirate.selectedCollectEmirate
  );

  const { emiratesArray } = props;
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const {
    setSubscription /* selectedPickupLocation, */ /* setSelectedPickupLocation, */ /* selectedDropoffLocation, setSelectedDropoffLocation */,
    setBookingTypeGlobal,
    bookingTypeGlobal,
    setClickonMapAddressSelectionFlag,
    setClickonMapAddressSelectionFlagForDropoff
  } = useContext(AppContext);
  // const [validated, setValidated] = useState(false);
  const [error, setError] = useState({
    selectedPickupLocation: "",
    pickup_date: "",
    pickupTime: "",
  });
  const [radioValue_dropoff_location, setRadioValue_dropoff_location] =
    useState("same_dropoff_location");
  const [deliveryOption, setDeliveryOption] = useState("pickup_location");
  const [collectOption, setCollectOption] = useState("dropoff_location");
  const [showPopup, setShowPopup] = useState(false);
  const [dropOffEmirateChangeFlag, setDropOffEmirateChangeFlag] =
    useState(false);
  const [showPopupDropoff, setShowPopupDropoff] = useState(false);
  const [pickupLocationArray, setPickupLocationArray] = useState([]);
  const [pickupOptions, setPickupOptions] = useState([]);
  const [selectedMonthDuration, setSelectedMonthDuration] = useState(null);
  const [monthDurationSelectedFlag, setMonthDurationSelectedFlag] =
    useState(false);

  const [formattedDate, setFormattedDate] = useState("");
  const [formattedTime, setFormattedTime] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [collectAddress, setCollectAddress] = useState("");
  const [emirateError, setEmirateError] = useState("");
  const [loading_for_valdate_pickup, set_loading_for_valdate_pickup] =
    useState(false);

  const [deliveryAddressPopup_flag, setDeliveryAddressPopup_flag] =
    useState(true);
  const [collectFromMePopup_flag, setCollectFromMePopup_flag] = useState(true);
  const [radioValue_age, setRadioValue_age] = useState(null);
  const radios_age = [
    { name: "Yes", value: "1" },
    { name: "No", value: "2" },
  ];

  const [formData, setFormData] = useState({
    pickup_location_id: null, // Done
    booking_type: "daily", //Done
    pickup_type: "self", //Done
    month_time: "", //Done
    pickup_location_name: "", //Done
    pickup_emirate_id: "",
    pickup_date: "", //Done
    pickup_time: "8:00", // Done not geting
    dropoff_location_id: null, //Done
    dropoff_type: "self", //Done
    dropoff_location_name: "", //Done
    dropoff_emirate_id: 3,
    dropoff_date: "", //Done
    dropoff_time: "18:00", // Done not geting
    coupon_code: "", //Done
    agetermsaccepte: 0,
    user_age: "",
  });


  const handleDeliveryAddressChange = (address) => {
    setDeliveryAddress(address);
  };
  const handleCollectAddressChange = (address) => {
    setCollectAddress(address);
  };
  const handleSelectAddressDelivery = () => {
    if (!selectedDeliveryEmirate) {
      setEmirateError("Select Emirate.");
    }
    if (selectedDeliveryEmirate) {
      setDeliveryOption("deliver_to_me");
      setDeliveryAddressPopup_flag(false);
    }
  };
  const handleSelectAddressCollect = () => {
    setCollectOption("collect_from_me");
    setCollectFromMePopup_flag(false);
  };
  const handle_deliver_to_me_click = () => {
    // if (formData?.pickup_type === "delivery") {
    //   setDeliveryOption("pickup_location");
    //   setFormData((prevData) => ({
    //     ...prevData,
    //     pickup_type: "self",
    //   }));
    // } else {
    //   setDeliveryOption("deliver_to_me");
    //   setFormData((prevData) => ({
    //     ...prevData,
    //     pickup_type: "delivery",
    //   }));
    //   setDeliveryAddressPopup_flag(true);
    // }

    //original code
    setDeliveryOption("deliver_to_me");
    setDeliveryAddressPopup_flag(true);
  };
  const handle_collect_from_me_click = () => {
    // if (formData?.dropoff_type === "collection") {
    //   setCollectOption("dropoff_location");
    //   setFormData((prevData) => ({
    //     ...prevData,
    //     dropoff_type: "self",
    //   }));
    // } else {
    //   setCollectOption("collect_from_me");
    //   setFormData((prevData) => ({
    //     ...prevData,
    //     dropoff_type: "collection",
    //   }));
    //   setCollectFromMePopup_flag(true);
    // }

    //original code
    setCollectOption("collect_from_me");
    setCollectFromMePopup_flag(true);
  };
  const handleDateTimeChange = (date, time) => {
    setFormattedDate(date);
    setFormattedTime(time);
  };
  const [formattedDate_dropoff, setFormattedDate_dropoff] = useState("");
  const [formattedTime_dropoff, setFormattedTime_dropoff] = useState("");

  const handleDateTimeChange_dropoff = (date, time) => {
    setFormattedDate_dropoff(date);
    setFormattedTime_dropoff(time);
  };
  const handleCloseButton = () => {
    setClickonMapAddressSelectionFlag(true);
    setDeliveryOption("pickup_location");
    setDeliveryAddressPopup_flag(true);
  };
  const handleCloseButtonForCollect = () => {
    setClickonMapAddressSelectionFlagForDropoff(true);
    setCollectOption("dropoff_location");
  };
  const popupRef = useRef(null);
  const handleOutsideClick = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      handleCloseButton(); // Close the popup
      handleCloseButtonForCollect();
    }
  };

  useEffect(() => {
    if (deliveryAddressPopup_flag || collectFromMePopup_flag) {
      document.addEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [deliveryAddressPopup_flag, collectFromMePopup_flag]);
  const handlePickupChange = (selectedOption) => {
    dispatch(setSelectedPickupLocation(selectedOption)); //this is for redux
  };
  const handlePickupEmirateChange = (selectedOption) => {
    dispatch(setPickupEmirate(selectedOption));
     dispatch(setSelectedDeliveryEmirate(selectedOption))
  };

  useEffect(() => {
    if (pickupEmirate && !dropOffEmirateChangeFlag) {
      dispatch(setDropOffEmirate(pickupEmirate));
    }
  }, [pickupEmirate]);

  const handleDropoffEmirateChange = (selectedOption) => {
    dispatch(setDropOffEmirate(selectedOption));
    dispatch(setSelectedCollectEmirate(selectedOption));
    setDropOffEmirateChangeFlag(true);
  };
  const handleDurationChange = (selectedOption) => {
    setSelectedMonthDuration(selectedOption);
    setMonthDurationSelectedFlag(true);
  };
  const [dropoffLocationArray, setDropoffLocationArray] = useState([]);
  const [dropOffOptions, setDropOffOptions] = useState([]);
  const handleDropoffChange = (selectedOption) => {
    dispatch(setSelectedDropoffLocation(selectedOption)); //this is for redux
  };

  const {
    // ready,
    // value,
    suggestions: { status, data },
    // setValue,
    // clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => defaultCenter.lat, lng: () => defaultCenter.lng },
      radius: 200 * 1000,
    },
  });
  const durationOptions = Array.from({ length: 12 }, (_, i) => ({
    value: i + 1,
    label: `${i + 1} Month${i + 1 > 1 ? "s" : ""}`,
  }));
  const [radioValue, setRadioValue] = useState("collect");
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState("self_pickup");
  const getPickupLocation = () => {
    const url = configWeb.GET_PICKUP_LOCATION(/* location_type */ "pickup");
    simpleGetCall(url)
      .then((res) => {
        if (!res?.error) {
          setPickupLocationArray(res);
        }
      })
      .catch((error) => {
        console.error("Location failed:", error);
      })
      .finally(() => {});
  };
  useEffect(() => {
    getPickupLocation();
    getDropoffLocation();
  }, []);

  useEffect(() => {
    if (
      Array.isArray(pickupLocationArray) &&
      pickupLocationArray?.length > 0 &&
      pickupEmirate
    ) {
      // const groupedOptions = Object.values(
      //   pickupLocationArray.reduce((acc, location) => {
      //     const emirateName = location.emirate.name;

      //     if (!acc[emirateName]) {
      //       acc[emirateName] = {
      //         label: emirateName,
      //         options: [],
      //       };
      //     }

      //     acc[emirateName].options.push({
      //       value: location.id,
      //       label: location.name,
      //       // Keep all other details as part of the object
      //       address: location.address,
      //       buffer_hours: location.buffer_hours,
      //       recipients: location.recipients,
      //       lat: location.lat,
      //       long: location.long,
      //       contact_number: location.contact_number,
      //       timing_detail: location.timing_detail,
      //       emirate_id: location.emirate_id,
      //     });

      //     return acc;
      //   }, {})
      // );
      // setPickupOptions(groupedOptions);

      const filteredArray = filteredPickUpLocationArray(
        pickupLocationArray,
        pickupEmirate.value * 1
      );
      const transformedArray = transformedPickupLocationArray(filteredArray);

      setPickupOptions(transformedArray);
      dispatch(setSelectedPickupLocation(transformedArray[0]));
    }
  }, [pickupLocationArray, pickupEmirate]);

  useEffect(() => {
    if (
      Array.isArray(dropoffLocationArray) &&
      dropoffLocationArray?.length > 0 &&
      dropOffEmirate
    ) {
      // const groupedOptions = Object.values(
      //   dropoffLocationArray.reduce((acc, location) => {
      //     const emirateName = location.emirate.name;

      //     if (!acc[emirateName]) {
      //       acc[emirateName] = {
      //         label: emirateName,
      //         options: [],
      //       };
      //     }

      //     acc[emirateName].options.push({
      //       value: location.id,
      //       label: location.name,
      //       // Keep all other details as part of the object
      //       address: location.address,
      //       buffer_hours: location.buffer_hours,
      //       recipients: location.recipients,
      //       lat: location.lat,
      //       long: location.long,
      //       contact_number: location.contact_number,
      //       timing_detail: location.timing_detail,
      //       emirate_id: location.emirate_id,
      //     });

      //     return acc;
      //   }, {})
      // );
      // setDropOffOptions(groupedOptions);
      const filteredArray = filteredPickUpLocationArray(
        dropoffLocationArray,
        dropOffEmirate.value * 1
      );
      const transformedArray = transformedPickupLocationArray(filteredArray);

      setDropOffOptions(transformedArray);
      dispatch(setSelectedDropoffLocation(transformedArray[0]));
    }
  }, [dropoffLocationArray, dropOffEmirate]);

  const getDropoffLocation = () => {
    const url = configWeb.GET_PICKUP_LOCATION(/* location_type */ "dropoff");
    simpleGetCall(url)
      .then((res) => {
        if (!res?.error) {
          setDropoffLocationArray(res);
        }
      })
      .catch((error) => {
        console.error("Location failed:", error);
      })
      .finally(() => {});
  };

  // useEffect(() => {
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     pickup_type: currentTab,
  //   }));
  // }, [currentTab]);

  useEffect(() => {
    if (bookingTypeGlobal) {
      setFormData((prevData) => ({
        ...prevData,
        booking_type: bookingTypeGlobal,
      }));
    }
  }, [bookingTypeGlobal]);

  const handleChange = (key, value) => {
    if (key === "dropoff_type_t") {
      setRadioValue_dropoff_location(value);
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: value,
    }));
  };

  const handleClosePopup = () => {
    // Close the popup
    setShowPopup(false);
    setShowPopupDropoff(false);
  };

  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    // Validate pick-up location
    if (deliveryOption === "deliver_to_me") {
      if (!deliveryAddress) {
        formIsValid = false;
        errors.deliveryAddress = "Delivery Address is required";
      }
    } else {
      if (!selectedPickupLocation) {
        formIsValid = false;
        errors.selectedPickupLocation = "Pick-up location is required";
      }
    }

    if (collectOption === "collect_from_me") {
      if (!collectAddress && !deliveryAddress && !selectedPickupLocation) {
        formIsValid = false;
        errors.collectAddress = "Collect Address is required";
      }
    } else {
      if (!selectedDropoffLocation && !selectedPickupLocation && !inputValue) {
        formIsValid = false;
        errors.selectedDropoffLocation = "Drop off location is required";
      }
    }

    if (!formattedDate) {
      formIsValid = false;
      errors.formattedDate = "Pick-up Date and Time is required";
    }
    if (!formattedDate_dropoff) {
      formIsValid = false;
      errors.formattedDate_dropoff = "Drop-off Date and Time is required";
    }

    if (!radioValue_age) {
      formIsValid = false;
      errors.age = "Age is required";
    } else if (radioValue_age == "2") {
      {
        formIsValid = false;
        errors.age = "Age must be above 21";
      }
    }

    if (formData?.booking_type === "monthly") {
      if (!selectedMonthDuration) {
        formIsValid = false;
        errors.selectedMonthDuration = "Duration is required";
      }
    }

    // Set all errors at once
    setError(errors);
    return formIsValid;
  };

  const handleTabSelect = (key) => {
    if (key === "link-0") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        booking_type: "daily",
        agetermsaccepte: 0,
      }));
      setSubscription("daily"); // Daily Rentals
      setBookingTypeGlobal("daily");
    } else if (key === "link-1") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        booking_type: "monthly",
        agetermsaccepte: 1,
      }));
      setBookingTypeGlobal("monthly");
      setSubscription("monthly");
    }
  };

  const handleSubmit = async (e) => {
    const form = e.currentTarget;

    e.preventDefault();

    if (validateForm()) {
      const isCarSearchValid = await carSearch();
      if (isCarSearchValid) {
        navigate(`/${language}/bookingDetails`);
      }
    } else {
      e.stopPropagation();
    }
  };
  useEffect(() => {
    setBookingTypeGlobal("daily");
  }, []);

  const [markerPosition, setMarkerPosition] = useState(defaultCenter);
  const handleMarkerPositionChange = (position) => {
    setMarkerPosition(position);
  };
  const [showMapPopupDropoff, setShowMapPopupDropoff] = useState(false);
  const [markerPositionDropoff, setMarkerPositionDropoff] =
    useState(defaultCenter);
  const handleMarkerPositionChange_dropoff = (position) => {
    setMarkerPositionDropoff(position);
  };
  const {
    ready: readyDropoff,
    value: valueDropoff,
    suggestions: { status: statusDropoff, data: dataDropoff },
    setValue: setValueDropoff,
    clearSuggestions: clearSuggestionsDropoff,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => defaultCenter.lat, lng: () => defaultCenter.lng },
      radius: 200 * 1000,
    },
  });
  const handleCloseMapPopupDropoff = () => {
    setShowMapPopupDropoff(false);
  };

  const carSearch = async (page_size = 10, setAllCars = false) => {
    return new Promise((resolve, reject) => {
      var c =
        deliveryOption === "deliver_to_me" &&
        collectOption !== "collect_from_me" &&
        !selectedDropoffLocation
          ? "c"
          : null;
          console.log("diff->",isDifferenceGreaterThanDays(formattedDate, formattedTime, formattedDate_dropoff, formattedTime_dropoff, 29))
      const body = {
       
        booking_type: isDifferenceGreaterThanDays(formattedDate, formattedTime, formattedDate_dropoff, formattedTime_dropoff, 29) ? "monthly" : formData?.booking_type,
        pickup_type: deliveryOption === "deliver_to_me" ? "delivery" : "self",
        pickup_date: formattedDate,
        pickup_time: formattedTime,
        dropoff_type:
          c || collectOption === "collect_from_me" ? "collection" : "self",
        dropoff_date: formattedDate_dropoff,
        dropoff_time: formattedTime_dropoff,
        booking_source: "web",
      };

      if (body?.pickup_type === "self") {
        body.pickup_location_id = selectedPickupLocation?.value;
      }
      if (body?.pickup_type === "delivery") {
        body.pickup_emirate_id = selectedDeliveryEmirate?.value;
        body.pickup_coordinates = [
          Number(markerPosition.lat),
          Number(markerPosition.lng),
        ];
        body.pickup_address = inputValue;
      }

      if (body?.dropoff_type === "collection") {
        body.dropoff_emirate_id = selectedCollectEmirate?.value
          ? selectedCollectEmirate?.value
          : selectedDeliveryEmirate?.value;
        body.dropoff_coordinates = [
          Number(markerPositionDropoff?.lat),
          Number(markerPositionDropoff?.lng),
        ];
        body.dropoff_address = inputValueDropoff;
      }
      if (body?.dropoff_type === "self") {
        body.dropoff_location_id = selectedDropoffLocation
          ? selectedDropoffLocation?.value
          : selectedPickupLocation?.value;
      }

      if (body?.booking_type === "monthly") {
        body.booking_months = isDifferenceGreaterThanDays(formattedDate, formattedTime, formattedDate_dropoff, formattedTime_dropoff, 29) ? getFullMonthDifference(formattedDate, formattedDate_dropoff) : selectedMonthDuration?.value ;
      }

      if (formData?.coupon_code) {
        body.discount_coupon = formData?.coupon_code;
      }

      const baseUrl = `${configWeb.POST_CAR_SEARCH}?lang=${language}&page=1&page_size=10&sort=ASC`;

      setLoading(true);
      simplePostCall(baseUrl, JSON.stringify(body))
        .then((res) => {
          if (!res?.error) {
            resolve(true);
            if (setAllCars) {
              // Store in allCarsArray if fetching all records
              dispatch(setAllCarsArray(res?.data));
              // dispatch(setCarArray(res?.data));
            } else {
              // Store in carArray for paginated results
              // dispatch(setCarArray(res?.data));
              if (savedCar) {
                const updatedCarArray = res?.data.filter(
                  (item) => item.id * 1 !== savedCar * 1
                );
                dispatch(setCarArray(updatedCarArray));
              } else {
                dispatch(setCarArray(res?.data));
                body.total_records = res?.total_records || 0;
                dispatch(setRequestBody_dropoff(body));
                dispatch(setRequestBody_pickup(body));
              }
            }
            // setTotalRecords(res?.total_records);
            if (!savedCar) {
              // dispatch(setSelectedCar(null));
            }
          }

          if (res?.status === "success") {
            // notifySuccess(res?.message)
          }
          if (res?.error) {
            // dispatch(setSelectedCar(null));
            resolve(false);
            if (Array.isArray(res?.message)) {
              notifyError(res?.message[0]);
            } else {
              notifyError(res?.message);
            }
          }
        })
        .catch((error) => {
          resolve(false);
          console.log("validate pickuplocation api failed-->", error);
          notifyError("Something went wrong, please try again later");
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const transformedEmirateArray = transformEmirateArray(emiratesArray);
  useEffect(()=>{
    if (formattedDate && formattedDate_dropoff && !monthDurationSelectedFlag ){
    
    if(isDifferenceGreaterThanDays(formattedDate, formattedTime, formattedDate_dropoff, formattedTime_dropoff, 29)){
      const months = getFullMonthDifference(formattedDate, formattedDate_dropoff) || 1;
      setSelectedMonthDuration({label : `${months > 1 ? `${months} Months` : `${months} Month` }`, value : months});
    } 
    } else   if (formattedDate && !monthDurationSelectedFlag) {
     
          
      setSelectedMonthDuration({ label: "1 Month", value: 1 });
    }
      },[formattedDate, formattedDate_dropoff])

  return (
    <div className="p-1--">
      <Nav
        className="subscription__tabs form-tabs"
        variant="pills"
        defaultActiveKey="link-0"
        activeKey={bookingTypeGlobal === "daily" ? "link-0" : "link-1"}
        onSelect={handleTabSelect}
      >
        <Nav.Item>
          <Nav.Link
            className="subscription__tab  form-tab "
            id="daily-rentals"
            eventKey="link-0"
            style={{ borderRadius: "10px 10px 0px 0px" }}
          >
            {t("Make A Booking")}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            id="daily-rentals"
            style={{ borderRadius: "10px 10px 0px 0px" }}
            className="subscription__tab-- form-tab ms-1"
            eventKey="link-1"
          >
            {t("Flexi Monthly")}
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <div className="form">
        {/* <Tabs
          defaultActiveKey="self_pickup"
          transition={false}
          id="noanim-tab-example"
          className="mb-1 justify-content-end border-0 tab-resposives"
          onSelect={(selectedKey) => setCurrentTab(`${selectedKey}`)}
        > */}

        {/* <Tab eventKey="self_pickup" title={t("Self Pick Up")} > */}
        <Form
          onSubmit={handleSubmit}
          /* validated={validated} */ style={{ padding: "5px 0 0px 0" }}
        >
          <Row className=" sm-6 ">
            <Col
              lg={12}
              md={12}
              sm={12}
              className="d-flex justify-content-end "
            >
              {/* <div className="checkbox-wrapper-35">
      <input
        value="private"
        name="switch"
        id="switch"
        type="checkbox"
        className="switch"
        checked={isChecked}
        onChange={handleChangeChecked}
      />
     
      <label htmlFor="switch">
        <span className="switch-x-text">Drop off location is </span>
        <span className="switch-x-toggletext">
          <span className="switch-x-unchecked">
            <span className="switch-x-hiddenlabel">Unchecked: </span>same.
          </span>
          <span className="switch-x-checked" >
            <span className="switch-x-hiddenlabel">Checked: </span>different.
          </span>
        </span>
      </label>
    </div> */}

              {/* <Form.Group className="d-flex justify-content-between align-items-center mb-2">
                <Form.Label className="label-name">Different Drop-off Location?</Form.Label>
                <ButtonGroup className="ms-1">
                  {radios_dropoff_location.map((radio, idx) => (
                    <ToggleButton
                      key={idx}
                      id={`radio_1-${idx}`}
                      type="radio"
                      // variant={
                      //   idx % 2 ? "outline-success" : "outline-danger"
                      // }
                      className="d-flex align-items-center justify-content-center"
                      style={{ height: "40px", width: "50px" }}
                      name="radio_1"
                      value={radio.value}
                      checked={radioValue_dropoff_location === radio.value}
                      // onChange={(e) => setRadioValue(e.currentTarget.value)}
                      onChange={(e) =>
                        handleChange("dropoff_type_t", e.currentTarget.value)
                      }
                    >
                      {radio.name}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
              </Form.Group> */}
            </Col>
          </Row>
          <div className="booking-form-field-wrapper">
            <Row className=" sm-6 ">
              <>
                <Col
                  style={{ paddingBottom: "0px" }}
                  lg={true}
                  md={true}
                  sm={12}
                  className="mb-sm-2 mb-lg-0"
                >
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="label-name ">
                      Pick-up Emirate
                    </Form.Label>
                    <Select
                      value={pickupEmirate}
                      onChange={handlePickupEmirateChange}
                      styles={customStyles}
                      options={transformedEmirateArray || []}
                      placeholder="Select Pickup Emirate..."
                      required
                    />
                  </Form.Group>
                  {!pickupEmirate && (
                    <span className="text-danger">
                      {error?.selectedPickupLocation}{" "}
                    </span>
                  )}{" "}
                </Col>
                <Col style={{ paddingBottom: "0px" }} lg={true} md={true} sm={12}>
                  {deliveryOption === "pickup_location" ? (
                    <>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label className="label-name mb-1-">
                          Pick-up Location
                        </Form.Label>
                        <Select
                          value={selectedPickupLocation}
                          onChange={handlePickupChange}
                          styles={customStyles}
                          options={pickupOptions}
                          placeholder="Select Pickup Location..."
                        />
                      </Form.Group>
                      {!selectedPickupLocation && (
                        <span className="text-danger">
                          {error?.selectedPickupLocation}{" "}
                        </span>
                      )}{" "}
                    </>
                  ) : (
                    <>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label className="label-name mb-1">
                          {/* Select Delivery Emirate */}
                          Delivery Address
                        </Form.Label>
                        <Form.Control
                          //  required
                          value={deliveryAddress}
                          //  onChange={handleDeliverEmirateChange}
                          readOnly
                          placeholder={t("Select...")}
                        />
                      </Form.Group>
                      {!deliveryAddress && (
                        <span className="text-danger">
                          {error?.deliveryAddress}{" "}
                        </span>
                      )}
                    </>
                  )}

                  {/* <div className="mt-1 mb-0">
            Deliver to me? 
            <div className="form-check form-check-inline ms-1">
  <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="deliver_to_me" checked={deliveryOption === 'deliver_to_me'} onChange={(e) => deliveryOptionChange(e.target.value)}  />
  <label className="form-check-label" for="inlineCheckbox1">Yes</label>
</div>
<div className="form-check form-check-inline">
  <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="pickup_location" checked={deliveryOption === 'pickup_location'} onChange={(e) => deliveryOptionChange(e.target.value)}/>
  <label className="form-check-label" for="inlineCheckbox2">No</label>
</div>
</div> */}

                  <div
                    className="delivery-collect-text inline-block "
                    style={{ cursor: "pointer" }}
                    onClick={handle_deliver_to_me_click}
                  >
                    Deliver to me?
                  </div>

                  {deliveryOption === "deliver_to_me" &&
                    deliveryAddressPopup_flag && (
                      <div ref={popupRef}>
                        <ChooseDeliverToMePopup
                          emiratesArray={emiratesArray}
                          handleCloseButton={handleCloseButton}
                          handleDeliveryAddressChange={
                            handleDeliveryAddressChange
                          }
                          handleSelectAddressDelivery={
                            handleSelectAddressDelivery
                          }
                          emirateError={emirateError}
                          /* handleDeliverEmirateChange_psuedo={handleDeliverEmirateChange} */ onMarkerPositionChange={
                            handleMarkerPositionChange
                          }
                        />
                      </div>
                    )}
                </Col>
              </>

              <Col
                // lg={formData.booking_type === "monthly" ? 2 : 3}
                lg={true}
                // md={formData.booking_type === "monthly" ? 4 : 6}
                md={true}
                sm={12}
                // style={{ paddingBottom: "0px", marginTop: "-13px" }}
                className="mb-sm-2 mb-lg-0"
              >
                {/* <Form.Group controlId="formGridAddress1">
                    <Form.Label className="label-name">{t("Pick-up Date / Time")} </Form.Label> */}
                <CommonlyUsedComponents
                  onDateTimeChange={handleDateTimeChange}
                  deliveryOption={deliveryOption}
                  selectedDeliveryEmirate={selectedDeliveryEmirate}
                  // pickupEmirate={pickupEmirate}
                  selectedPickupLocation={selectedPickupLocation}
                  booking_type={formData?.booking_type}
                />
                {!formattedDate && (
                  <span className="text-danger">{error?.formattedDate} </span>
                )}
              </Col>
            </Row>
            <Row>
              <Col
                style={{ paddingBottom: "0px" }}
                lg={true}
                md={true}
                sm={12}
                className="mb-sm-2 mb-lg-0"
              >
                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="label-name mb-1-">
                    Drop-off Emirate
                  </Form.Label>
                  <Select
                    value={dropOffEmirate}
                    onChange={handleDropoffEmirateChange}
                    styles={customStyles}
                    options={transformedEmirateArray || []}
                    placeholder="Select Dropoff Emirate..."
                    required
                  />
                </Form.Group>
                {!dropOffEmirate && (
                  <span className="text-danger">
                    {error?.selectedPickupLocation}{" "}
                  </span>
                )}{" "}
              </Col>

              <Col
                className={`${formData.booking_type === "monthly" ? "d-flex justify-content-between gap-2" : ""}`}
              
                lg={true}
                md={true}
                sm={12}
              >
                <div className={`${formData.booking_type === "monthly" ? "flex-grow-1" : ""}`}>
                {collectOption === "dropoff_location" ? (
                  <>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="label-name mb-1-">
                        Drop-off Location
                      </Form.Label>
                      <Select
                        className="find-my-car-select"
                        value={
                          selectedDropoffLocation || selectedPickupLocation
                        }
                        onChange={handleDropoffChange}
                        options={dropOffOptions}
                        styles={customStyles}
                        menuPortalTarget={document.body}
                        placeholder="Same as pick-up location"
                      />
                    </Form.Group>
                    {!selectedDropoffLocation && !selectedPickupLocation && (
                      <span className="text-danger">
                        {error?.selectedDropoffLocation}{" "}
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    {" "}
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="label-name mb-1-">
                        {/* Select Delivery Emirate */}
                        Collect Address
                      </Form.Label>
                      <Form.Control
                        //  required
                        value={collectAddress || deliveryAddress}
                        //  onChange={handleDeliverEmirateChange}
                        readOnly
                        placeholder="Same as delivery address."
                      />
                    </Form.Group>
                    {!collectAddress && !deliveryAddress && (
                      <span className="text-danger">
                        {error?.collectAddress}{" "}
                      </span>
                    )}
                  </>
                )}
              

                <div
                  className="delivery-collect-text inline-block "
                  style={{ cursor: "pointer" }}
                  onClick={handle_collect_from_me_click}
                >
                  Collect from me?
                </div>
                {collectOption === "collect_from_me" &&
                  collectFromMePopup_flag && (
                    <div ref={popupRef}>
                      <CollectFromMePopup
                        emiratesArray={emiratesArray}
                        handleCloseButtonForCollect={
                          handleCloseButtonForCollect
                        }
                        handleSelectAddressCollect={handleSelectAddressCollect}
                        handleCollectAddressChange={handleCollectAddressChange}
                        /* handleCollectEmirateChange_pseudo ={handleCollectEmirateChange} */ onMarkerPositionChange={
                          handleMarkerPositionChange_dropoff
                        }
                      />
                    </div>
                  )}
                    </div>
                     {/* <Col lg={radioValue === "self_return" ? 4 : 3} md={3} sm={12} style={{paddingBottom : '0px'}}> */}
              {formData.booking_type === "monthly" && (
              
              <div className=" flex-grow-1-">

                  <Form.Group
                    controlId="formBasicEmail"
                    style={{
                      marginBottom: "0.35rem",
                    }}
                  >
                    <Form.Label className="label-name mb-2-">
                      Duration
                    </Form.Label>
                    <Select
                      value={selectedMonthDuration}
                      onChange={handleDurationChange}
                      styles={customStyles}
                      options={durationOptions}
                      placeholder={t("Select...")}
                    />
                  </Form.Group>
                  {!selectedMonthDuration && (
                    <span className="text-danger">
                      {error?.selectedMonthDuration}{" "}
                    </span>
                  )}
              
              </div>
              )}
                  
              </Col>

              {radioValue === "self_return" ? <></> : <></>}

             

              <Col
                className="dropff-date-time"
                // lg={formData.booking_type === "monthly" ? 2 : 3}
                lg={true}
                // md={formData.booking_type === "monthly" ? 4 : 6}
                md={true}
                sm={12}
              >
                <DropoffDateTimePicker
                  onDateTimeChange={handleDateTimeChange_dropoff}
                  deliveryOption={deliveryOption}
                  collectOption={collectOption}
                  selectedCollectEmirate={selectedCollectEmirate}
                  selectedDeliveryEmirate={selectedDeliveryEmirate}
                  selectedDropoffLocation={selectedDropoffLocation}
                  selectedPickupLocation={selectedPickupLocation}
                  formattedDate={formattedDate}
                  formattedTime={formattedTime}
                  month={selectedMonthDuration?.value * 30}
                  booking_type={formData?.booking_type}
                />
                {!formattedDate_dropoff && (
                  <span className="text-danger">
                    {error?.formattedDate_dropoff}{" "}
                  </span>
                )}
              </Col>
            </Row>

            <Row>
              <Col lg={4} md={4} sm={12}>
                <Form.Group
                  controlId="formGridAddress1"
                  className="coupon-code-mobile"
                >
                  {/* <Form.Label>Coupon Code</Form.Label> */}
                  <Form.Control
                    // required
                    placeholder={t("Enter Coupon Code")}
                    value={formData.coupon_code}
                    onChange={(e) =>
                      handleChange("coupon_code", e.target.value)
                    }
                    // onBlur={validateCoupon}
                  />
                </Form.Group>
                <div className="promo-coupon-msg"></div>
              </Col>
              <Col lg={4} md={4} sm={12} className="mb-sm-2 mb-lg-0 age-find-car-flex-box">
                <Form.Group className="d-flex justify-content-start gap-2 align-items-center">
                  <Form.Label className="label-name">
                    {t("Age Above 21 ?")}{" "}
                  </Form.Label>
                  <div className="d-flex flex-column">
                    <ButtonGroup>
                      {radios_age.map((radio, idx) => (
                        <ToggleButton
                          className="mt-1"
                          key={idx}
                          id={`radio-${idx}`}
                          type="radio"
                          // variant={idx % 2 ? 'outline-success' : 'outline-danger'}
                          variant={idx % 2 ? "outline-dark" : "outline-dark"}
                          name="radio"
                          value={radio.value}
                          checked={radioValue_age === radio.value}
                          onChange={(e) =>
                            setRadioValue_age(e.currentTarget.value)
                          }
                        >
                          {radio.name}
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                    {(!radioValue_age || radioValue_age == "2") && (
                      <span className="text-danger ms-5-">{error?.age}</span>
                    )}
                  </div>
                </Form.Group>
                <div className="find-my-card-btn ">
                  <Button type="submit" className="findBtn" disabled={loading}>
                    {loading ? <Spinner /> : "Find My Car"}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Form>
        {/* </Tab> */}

        {/* </Tabs> */}
        <Modal show={showPopup} onHide={handleClosePopup}>
          <Modal.Header closeButton>
            <Modal.Title>Location Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{selectedPickupLocation?.label}</p>
            <p>{selectedPickupLocation?.address}</p>
            <p>{selectedPickupLocation?.timing_detail}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClosePopup}>
              CHOOSE THIS LOCATION
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showPopupDropoff} onHide={handleClosePopup}>
          <Modal.Header closeButton>
            <Modal.Title>Drop off Location Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{selectedDropoffLocation?.label}</p>
            <p>{selectedDropoffLocation?.address}</p>
            <p>{selectedDropoffLocation?.timing_detail}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClosePopup}>
              CHOOSE THIS LOCATION
            </Button>
          </Modal.Footer>
        </Modal>

        {/* <Modal show={showMapPopup} onHide={handleCloseMapPopup} 
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton > 
        <Modal.Title id="contained-modal-title-vcenter">
         Select your address 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body >
    
        <div className="w-100 h-100">
        <FormControl
            // value={value}
            value={inputValue}
            onChange={handleInput}
            disabled={!ready}
            placeholder="Search a location"
            className="mb-2"
          />
          {status === 'OK' && <ul style={{cursor:'pointer'}}>{renderSuggestions()}</ul>}
        <GoogleMap 
        mapContainerStyle={mapContainerStyle}
        zoom={13}
        center={center}
     
      >
        
        <MarkerF position={markerPosition}  draggable={true}
              onDragEnd={handleMarkerDragEnd} />

       
      </GoogleMap>
      </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleCloseMapPopup}>OK</Button>
      </Modal.Footer>
    </Modal> */}
        {/* Dropoff Address Modal */}
        <Modal
          show={showMapPopupDropoff}
          onHide={handleCloseMapPopupDropoff}
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          {/* <Modal.Body>
          <div className="w-100 h-100">
            <FormControl
              value={inputValueDropoff}
              onChange={handleInputDropoff}
              disabled={!readyDropoff}
              placeholder="Search a location"
              className="mb-2"
            />
            {statusDropoff === 'OK' && <ul style={{ cursor: 'pointer' }}>{renderSuggestionsDropoff()}</ul>}
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              zoom={13}
              center={centerDropoff}
            >
              <MarkerF position={markerPositionDropoff} draggable={true} onDragEnd={handleMarkerDragEndDropoff} />
            </GoogleMap>
          </div>
        </Modal.Body> */}
          <Modal.Footer>
            <Button onClick={handleCloseMapPopupDropoff}>OK</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default FindCarForm;

import React, { useEffect, useState } from "react";
import "./PaymentSuccess.css"; // Import the CSS file for styles
import { Button } from "react-bootstrap";
import { Link, useSearchParams , useNavigate, useLocation} from "react-router-dom";

// import { persistor } from "../../store/store";
import {persistor} from "../../store/store"
import { resetState } from "../../reducers/Slices/resetSlice";
import {  useDispatch, useSelector } from "react-redux";
import {
  setRequestBody_dropoff,
  setRequestBody_pickup,
  setSelectedPickupLocation,
} from "../../reducers";
import { setStepperPage } from "../../reducers/Slices/stepperSlice";
import { setSelectedDropoffLocation } from "../../reducers/Slices/selectedDropoffLocationSlice";
import { setInputValue } from "../../reducers/Slices/inputValueSlice";
import { setInputValueDropoff } from "../../reducers/Slices/inputValueDropoffSlice";
import { setCarArray } from "../../reducers/Slices/carArraySlice";
import { setCarExtraArray } from "../../reducers/Slices/carExtraArray";
import { setFilterCarArray } from "../../reducers/Slices/filterCarArraySlice";
import { setSelectedCar } from "../../reducers/Slices/selectedCarSlice";
import { resetAddProtection } from "../../reducers/Slices/addProtectionSlice";
import { setSavedCar } from "../../reducers/Slices/savedCarSlice";
import configWeb from "../../config.js/configWeb";
import { simpleGetCallAuth } from "../../config.js/SetUp";
import car_booked from "../../assets/all-images/car_booked.png"
import { Spinner } from "reactstrap";
import { pixelPurchaseEvent } from "../../actions/facebookPixelEvents";
import { setSelectedDeliveryEmirate } from "../../reducers/Slices/selectedDeliveryEmirateSlice";
import { setIsValidAddressCollection } from "../../reducers/Slices/isValidAddressCollection";
import { setIsValidAddressDelivery } from "../../reducers/Slices/isValidAddressDeliverySlice";
import { setErrorAddressCollection } from "../../reducers/Slices/errorAddressCollection";
import { setErrorAddressDelivery } from "../../reducers/Slices/errorAddressDelivery";
import { setIsLoginFromRegister } from "../../reducers/Slices/isLoginFromRegisterSlice";

const PaymentSuccess = () => {
  const dispatch = useDispatch();
  const [searchparams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  // Combine pathname and search so that query parameters are preserved.
  const currentUrl = location?.pathname + location?.search; // e.g. "/PaymentSuccess?id=882"

  const booking_id = searchparams.get("id");
  const language = useSelector((state) => state.language.language);
const [bookingDetails, setBookingDetails] = useState([]);
const [selectedCarTemp, setSelectedCarTemp] = useState(null)
const [requestBody_pickupTemp, set_requestBody_pickupTemp] = useState(null)
const [loading, setLoading] = useState(true);
const [purchaseEventFlag, setPurchaseEventFlag] = useState(false);

const selectedCar = useSelector((state) => state.selectedCar.selectedCar);
const isLoginFromRegister = useSelector((state) => state.isLoginFromRegister.isLoginFromRegister);
const requestBody_pickup = useSelector(
  (state) => state.requestBody_pickup.requestBody_pickup
);

  const handleClearStorage = () => {
    persistor.purge().then(() => {
      dispatch(setRequestBody_dropoff(null));
      dispatch(setRequestBody_pickup(null));
      dispatch(setSelectedPickupLocation(null));
      dispatch(setStepperPage(null));
      dispatch(setSelectedDropoffLocation(null));
      dispatch(setInputValue(""));
      dispatch(setInputValueDropoff(""));
      dispatch(setCarArray(null));
      dispatch(setCarExtraArray(null));
      dispatch(setFilterCarArray(null));
      dispatch(setSelectedCar(null));
      dispatch(resetAddProtection());
      dispatch(setSavedCar(null));
      dispatch(setIsValidAddressCollection(null));
      dispatch(setIsValidAddressDelivery(null));
      dispatch(setErrorAddressCollection(null));
      dispatch(setErrorAddressDelivery(null));

      // Optionally dispatch any actions to reset your Redux state
    });
  };

  useEffect(()=>{
 window.scrollTo(0, 0);
 dispatch(setSelectedPickupLocation(null));
 dispatch(setSelectedDeliveryEmirate(null));


 return () => {
 } 
  },[])

 
  
  useEffect(() => {
   
  const selectedCarTemp = selectedCar
  setSelectedCarTemp(selectedCarTemp)
  const requestBody_pickupTemp = requestBody_pickup;
  set_requestBody_pickupTemp(requestBody_pickupTemp);

  return () => {
    
    handleClearStorage();
  } 
  }, []);

  // useEffect(()=>{
  //   if(selectedCarTemp && requestBody_pickupTemp){
  //     handleClearStorage();
  //   }
  // },[selectedCarTemp,requestBody_pickupTemp])


  useEffect(()=>{
if(bookingDetails && purchaseEventFlag){

  const formattedExtras = bookingDetails?.car_extras?.map((extra) => ({
    item_id: `extra_${extra.type}`,
    item_name: extra.type,
    price: extra.rate, // Use the rate as the price
    quantity: extra.quantity,
  }));

  window.gtag("event", "purchase", {
    transaction_id: bookingDetails?.booking_log_number,
    // Sum of (price * quantity) for all items.
    // value: parseFloat(bookingDetails?.total_amount || 0),
    value: (parseFloat(bookingDetails?.total_amount || 0)).toFixed(2),
    tax: (parseFloat(bookingDetails?.vat_amount  || 0)).toFixed(2),
    // shipping: 5.99,
    currency: "AED",
    coupon:bookingDetails?.coupon_code,
    items: [
      {
        item_id: bookingDetails?.id,
        item_name: bookingDetails?.car?.name,
        affiliation: "Autostrad",
        coupon:bookingDetails?.coupon_code,
        discount: bookingDetails?.discount_total,
        index: 0,
        item_brand: selectedCarTemp?.brand,
        // item_category: selectedCar?.category,
        // item_category2: "Adult",
        // item_category3: "Shirts",
        // item_category4: "Crew",
        // item_category5: "Short sleeve",
        item_list_id:`${bookingDetails?.pickup_location_id ? bookingDetails?.pickup_location_id : `delivery_${bookingDetails?.pickup_emirate_id}` } + ${bookingDetails?.dropoff_location_id ? bookingDetails?.dropoff_location_id : `collection_${bookingDetails?.dropoff_emirate_id}`}`,
        // item_list_name: "Related Products",
        item_variant: bookingDetails?.payment_type,
        location_id: bookingDetails?.pickup_location_id ||  bookingDetails?.pickup_emirate_id ,
        // price:(parseFloat(bookingDetails?.total_amount || 0)).toFixed(2),
        price:
        requestBody_pickup?.booking_type === "monthly"
        ? parseFloat(selectedCar?.car_rate_total || 0)
        : selectedCar?.payment_method === "pay_now"
        ? parseFloat(selectedCar?.pay_now || 0)
        : parseFloat(selectedCar?.pay_later || 0),
        quantity: 1,
        // car_extras: car_extras,
      },
      ...formattedExtras,
    ],
  });
}

if(bookingDetails && purchaseEventFlag){
const formattedExtras = bookingDetails?.car_extras?.map((extra) => ({
  id: `extra_${extra.type}`,
  // item_name: extra.type,
  // price: extra.rate, // Use the rate as the price
  quantity: extra.quantity,
}));


const contentType ="Product";
const contents = [{
  id : bookingDetails?.id,
  quantity : 1,
  
},
...formattedExtras,
]
const value=bookingDetails?.total_amount;
const currency = "AED";

pixelPurchaseEvent(contentType, contents, value, currency)

}
  },[bookingDetails])
  const getBookingDetails = () => {
    // const url1 = `${configWeb.GET_USER_BOOKING_DETAILS(user_id)}?language=${language}&page_number=${pageNumber}&page_size=${pageSize}`;

    const url = `${configWeb.GET_BOOKING_DETAILS(
      booking_id
    )}?lang=${language}&page=1&page_size=10`;
    // setLoading(true);
    simpleGetCallAuth(url)
      .then((res) => {
        if (!res?.error) {
          setBookingDetails(res?.data);
        
        }
      })
      .catch((error) => {
        console.error("Banner failed:", error);
      })
      .finally(() => {
        setLoading(false);
        setPurchaseEventFlag(true);
      });
  };

  useEffect(()=>{
if(booking_id){
  getBookingDetails()
}
  },[booking_id])


  function formatDate(dateString) {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "Asia/Dubai", 
    };
  
    return new Date(dateString)
      ?.toLocaleString("en-US", options)
      ?.replace(",", "");
  }


  useEffect(() => {
    // Push '/home' as a fake previous entry and then push the current URL.
    // This sets up the history stack so that clicking back lands the user on '/home'.
    window.history.pushState(null, '', '/home');
    window.history.pushState(null, '', currentUrl);

    const handlePopState = () => {
      // When the user presses the back button, redirect to '/home'.
      navigate('/home', { replace: true });
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate, currentUrl]);
    
  return (
    <div className="parent">
      <div className="printer-top"></div>
      <div className="paper-container">
        <div className="printer-bottom"></div>
        <div className="paper">
          <div className="main-contents">
            {/* <div className="success-icon">&#10004;</div> */}
            <div className="text-center">
            <img
                                src={car_booked}
                                className="img-fluid rounded-3 car-animation"
                                alt="Car"
                              />
            </div>
            <div className="success-title">Booking Completed</div>
            <div className="success-description">
              Thank you for completing the booking! You will shortly receive an
              email of booking details.
            </div>
            {loading ? <div className="h-100 w-100 text-center "><Spinner/> </div> : 
            <div className="order-details">
              {/* <div className="order-number-label">Autostrad Booking Number</div> */}
              <div className="order-number-label">Your Booking Number Is</div>
              <div className="order-number">
                <h3>
                  {/* <strong>ARC20240801945431422</strong> */}
                  <strong>{bookingDetails?.booking_number}</strong>
                </h3>
              </div>
              <div className="complement mb-4">Thank You!</div>{" "}

              <div
                      
                        className="col-lg-12 col-12 position-relative "
                      >
                        <div className="py-3 shadow rounded-3">
                          <div className="row reserved-row">
                            <div className="col-lg-4 col-md-4 col-xs-6 d-flex flex-column align-items-start">
                              <img
                                src={bookingDetails?.car?.image}
                                className="img-fluid rounded-3 "
                                alt="Car"
                              />
                            </div>
                            <div className="col-lg-8 col-md-8 col-xs-6 card-body my-auto text-under-small-screen px-4">
                            <div className="text-start mb-2">
                              <span
                                className="badge p-2 mb-1"
                                style={{
                                  backgroundColor: "#6C505E",
                                  fontFamily: "monospace",
                                  fontSize: "18px",
                                }}
                              >
                                
                                {bookingDetails?.car?.name}
                              </span>
                              </div>
                              <div className="text-start">
                              <p className="mb-0">
                                <strong>Reservation Number:</strong>{" "}
                                {bookingDetails?.booking_number}
                              </p>
                              <p className="mb-0">
                                <strong>Pick Up Location:</strong>{" "}
                                {bookingDetails?.pickup_location?.name ||
                                  bookingDetails?.pickup_address}
                              </p>
                              <p className="mb-0">
                                <strong>Pick Up Date:</strong>{" "}
                            
                                {formatDate(bookingDetails?.pickup_date_time)}
                              </p>
                              <hr className="my-1" />
                              <p className="mb-0">
                                <strong>Drop Off Location:</strong>{" "}
                             
                                {bookingDetails?.dropoff_location?.name ||
                                  bookingDetails?.dropoff_address}
                              </p>
                              <p className="mb-0">
                                <strong>Drop Off Date:</strong>{" "}
                             
                                {formatDate(bookingDetails?.dropoff_date_time)}
                              </p>
                              </div>
                            </div>
                            <div className="col-lg-1 col-md-1 move-it-right">
                              
                            </div>
                          </div>
                        </div>
                     
                      </div>

           {!isLoginFromRegister &&(   <Link to={`/${language}/MyAccount`}state={{ tab_type: "bookings" }}>
                <Button className="button mt-4">Go To Booking Details</Button>
              </Link>
              ) }
              <Link to={`/`}>
                <Button className="button mt-4 ms-4">Go To Home</Button>
              </Link>
            </div>
            }
          </div>
          <div className="jagged-edge"></div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;

import React, { useEffect, useState } from "react";

import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import AboutSection from "../components/UI/AboutSection";
import { Container, Row, Col } from "reactstrap";
import BecomeDriverSection from "../components/UI/BecomeDriverSection";
import "../styles/about-section.css";
import aboutImg from "../assets/all-images/cars-img/bmw-offer.png";
import driveImg from "../assets/all-images/drive.jpg";
import "../styles/become-driver.css";
import OurMembers from "../components/UI/OurMembers";
import driverImg from "../assets/all-images/toyota-offer-2.png";
import { decode } from "html-entities";
import "../styles/about.css";
import { Card, Spinner } from "react-bootstrap";
import carlogo from "../assets/new-logo/logo.png";
import { useSelector } from "react-redux";
import configWeb from "../config.js/configWeb";
import { simpleGetCall } from "../config.js/SetUp";
import { useTranslation } from "react-i18next";
import FindCarForm2 from "../components/UI/FindCarForm2";
import MetaHelmet from "../components/Helmet/MetaHelmet";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.language.language);
  const [corporateLeasing, setCorporateLeasing] = useState([]);
  const [emiratesArray, setEmiratesArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const getCorporateLeasing = () => {
    const url = `${configWeb.GET_PRIVACY_POLICY(
      "about_company"
    )}?lang=${language}`;
    simpleGetCall(url)
      .then((res) => {
        if (!res?.error) {
          setCorporateLeasing(res || []); // Ensure it"s always an array
        }
      })
      .catch((error) => {
        console.log("Special offers API failed-->", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getCorporateLeasing();
  }, [language]);

  const emiratesData = () => {
    const url = `${configWeb.GET_EMIRATES}?lang=${language}`;
    simpleGetCall(url)
      .then((res) => {
        setEmiratesArray(res);
      })
      .catch((errr) => {
        console.log("errr", errr);
      })
      .finally(() => {
        // set_loading(false);
      });
  };
  useEffect(()=>{
    // emiratesData();
  },[])
  const processHTMLContent2 = (htmlContent) => {
    // Decode HTML entities
    const decodedContent = decode(htmlContent);

    // Replace \n with actual new lines (if needed)
    return decodedContent.replace(/\\n/g, " ");
  };

  const processHTMLContent = (htmlContent) => {
    // Parse the string into a DOM structure
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    // Return the cleaned up outerHTML
    return doc.body.innerHTML;
  };
  const generateStructuredData = () => {
    return {
      "@context": "https://schema.org",
      "@type": "Article",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": window.location.href,
      },
      headline: corporateLeasing?.title || "About Autostrade Company",
      description: corporateLeasing?.seo_meta_description,
      image: corporateLeasing?.image,
      author: {
        "@type": "Organization",
        name: "Autostrad",
      },
      publisher: {
        "@type": "Organization",
        name: "Autostrad",
        logo: {
          "@type": "ImageObject",
          url: carlogo,
        },
      },
      datePublished: "2024-10-11",
      dateModified: "2024-10-12",
    };
  };
  const generateBreadcrumbsStructuredData = () => {
    return {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home Page Autostrad Rent a Car",
          "item": `${configWeb.BASE_WEB_URL}`
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name":"About Us | Autostrad Rent a Car",
          "item": window?.location.href,
        },
       
      ]
    };
  };
  return (
    // <Helmet title="About">
      <>
   <MetaHelmet
        title={corporateLeasing?.title}
        description={corporateLeasing?.seo_meta_description}
        keywords={corporateLeasing?.seo_meta_tags || "car rental, affordable cars, rent a car"}
        ogTitle={corporateLeasing?.title}
        ogDescription={corporateLeasing?.seo_meta_description}
        ogImage={corporateLeasing?.image}
        ogUrl= {window.location.href}
        twitterTitle={corporateLeasing?.title}
        twitterDescription={corporateLeasing?.seo_meta_description}
        twitterImage={corporateLeasing?.image}
        twitterCard="summary_large_image"
        // twitterSite="@YourTwitterHandle"
        // twitterCreator="@AuthorTwitterHandle"
        canonicalUrl = {`${configWeb.BASE_WEB_URL}/en/about`}
        hreflangs={[
          { hreflang: "en", href: `${configWeb.BASE_WEB_URL}/en/about` },
          { hreflang: "ar", href: `${configWeb.BASE_WEB_URL}/ae/about` },
        ]}
      />


      {loading ?   <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh", width: "100vw" }}
        ><Spinner/></div>  : <>


<script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(generateStructuredData()),
            }}
          />
<script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(generateBreadcrumbsStructuredData()),
            }}
          />
         {/* <section className="pb-1 form-section" ref={parentRef}>
        <h2
          className="pt-2 m-0 text-center text-light booking__tital-dream"
          style={{ fontFamily: "Rubik" }}
        >
          {t("BOOK YOUR DREAM CAR NOW")}!
        </h2>
        <Col id="booking-form-container" lg="12" md="12" sm="12">
          <Container>
            <FindCarForm2 emiratesArray={emiratesArray} />
          </Container>
        </Col>
      </section> */}
      <CommonSection title="About Us" />

      {/* <div className="px-5">
        <div id="inner_section_main ">
          <div id="my-padding">
            <div id="container">
              <div id="row ">
                <div id="make-text-center">
                  <h5 id="card-title">
                    <b><h1>Autostrad Rent A Car</h1></b>
                  </h5>
                  <p id="creating">
                    “Creating memorable car rental experiences, one drive at a
                    time!”
                  </p>
                  <p id="content">
                    It’s just a drive …. just a journey from one place to the
                    other, a road that you may have traveled many times over!
                    <br />
                    <br />
                    Yet, each drive is a unique experience! <br />
                    <br />
                    We make sure that in everything we do, we enhance the
                    experience for you – from our commitment to provide
                    high-quality customer service and a range of transport
                    solutions to engaging with customers, partners, and
                    communities responsibly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="inner_section_main">
          <div id="vission-mission">
            <div id="container">
              <div id="row-mission">
                <div id="vission-mission-col-1">
                  <div id="box-round">
                    <div id="round">
                      <img
                        id="vission-mission-img1"
                        src="https://www.autostrad.com/admin_module/uploads/about_us/0412a5debba01734caa58af222a96319.png"
                      />
                    </div>
                    <h4 id="card-title">Our Mission</h4>
                    <p id="our-mission-content">
                      To win the heart of every customer by consistently
                      providing high-quality customized transport solutions. We
                      back our commitment with our investment in cutting-edge
                      technology and world-id customer service so that each
                      customer experience is a memorable one!
                    </p>
                  </div>
                </div>
                <div id="vission-mission-col-2">
                  <div id="box-round-2">
                    <div id="round">
                      <img
                        id="vission-mission-img1"
                        src="https://www.autostrad.com/admin_module/uploads/about_us/494bd3fe7aa031c92aa65caf49f3fddf.png"
                      />
                    </div>
                    <h4 id="card-title">Our Vision</h4>
                    <p id="our-mission-content">
                      To be the most loved, trusted, and profitable transport
                      solution provider.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        <div id="row-overlay">
        
          <div id="container-over">
          
            <div id="row-over">

            <div id="col-overlay">
           
           <div id="padd">
          
             <div id="sub-row-1">
            
               <div id="sub-col">
            
               <h2>OUR VALUES</h2>
               </div>

               <div id="sub-col">
               
               </div>
             </div>
           </div>
         </div>

              <div id="col-overlay">
           
                <div id="padd">
               
                  <div id="sub-row">
                 
                    <div id="sub-col">
                 
                      <img src="https://www.autostrad.com/admin_module/uploads/about_us/0412a5debba01734caa58af222a96319.png" />
                    </div>

                    <div id="sub-col">
                      <h4 id="text-cen">Transparency and Integrity</h4>
                      <p>
                        We pride ourselves in always honoring our commitments.
                        Be it in our dealings with stakeholders, partners or
                        customers, we believe in doing things the right way –
                        with transparency and integrity.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            
              <div id="col-overlay">
                <div id="padd">
                  <div id="sub-row">
                    <div id="sub-col">
                      <img src="https://www.autostrad.com/admin_module/uploads/about_us/cfc6aed3ae4796554466fc97cc0ddf26.png" />
                    </div>

                    <div id="sub-col">
                      <h4 id="text-cen">Top-notch Customer Service</h4>
                      <p>
                        We recognize that our customers seek a smooth experience
                        both on and off the road. To ensure that, we constantly
                        strive to not only provide high quality vehicles but
                        also world class customer service at all times. We work
                        hard to win the hearts of each one of our customers so
                        that we continue to be their first choice.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div id="col-overlay">
                <div id="padd">
                  <div id="sub-row">
                    <div id="sub-col">
                      <img src="https://www.autostrad.com/admin_module/uploads/about_us/f3ff32f42b653ec2728fc952337ef75c.png" />
                    </div>

                    <div id="sub-col">
                      <h4 id="text-cen">Quality</h4>
                      <p>
                        We are driven by our commitment to delivering
                        exceptional quality and experiences to our customers.
                        It’s the small everyday improvements that we make in the
                        maintenance of our vehicles, hygiene and safety
                        processes and training of our personnel that make the
                        big difference in assuring our customers a safe and
                        smooth driving experience. We never stop, we never
                        settle, we redefine our limits and stretch our
                        expectations as we do business and interact with the
                        world around us.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div id="col-overlay">
                <div id="padd">
                  <div id="sub-row">
                    <div id="sub-col">
                      <img src="https://www.autostrad.com/admin_module/uploads/about_us/7de1b98add91b19137cd1fc432126414.png" />
                    </div>

                    <div id="sub-col">
                      <h4 id="text-cen">Creativity and Innovation</h4>
                      <p>
                        We are constantly looking for new and better ways
                        forward. Our team is constantly encouraged to challenge
                        the status quo and push the boundaries. Finding
                        solutions to almost impossible challenges is part of our
                        success and a source of inspiration to move on to the
                        next challenge.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div id="col-overlay">
                <div id="padd">
                  <div id="sub-row">
                    <div id="sub-col">
                      <img src="https://www.autostrad.com/admin_module/uploads/about_us/f6c3f410cfc73f4094e19f898f2e28d5.png" />
                    </div>
                    <div id="sub-col">
                      <h4 id="text-cen">Value Added Solutions</h4>
                      <p>
                        We believe in going the extra mile and adding the extra
                        zing to our transport solutions. Whether it is value
                        added solutions or end to end project management
                        solutions, we are always looking for ways to simplify
                        the lives of our customers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="fleetrow">
          <div id="fleetcol">
            <h5>Our Fleet</h5>
            <p>
              Autostrad Rent A Car offers a highly comprehensive line up of
              vehicles, to suit your individual needs and budget. We offer a
              wide range of vehicles available in more than 20+ categories for
              daily rentals along with monthly and long term leases, starting
              from hatch-backs, standard cars, sedans, mini SUVs, SUVs, 4x4s,
              and Premium cars, we also offer buses and commercial vehicles for
              businesses & schools.
            </p>
            <p>
              Apart from our standard offering of the fleet, we also offer
              customized fleet management solutions including project management
              for specialized sectors like Oil & Gas and Airports.
            </p>
            <p>
              We constantly keep our fleet updated with the latest models that
              are maintained to the highest standards of performance, safety,
              and cleanliness. Our fleet is ever-expanding, which allows us to
              continuously raise and set benchmarks in the field of vehicle
              rental services.
            </p>
          </div>
          <div id="fleetcol">
            <img src="https://www.autostrad.com/admin_module/uploads/about_us/3578c6250af6b1b5bf9e61ab3ad22f3b.webp" />
          </div>
        </div>

        <div id="fleetrowtwo">
          <div id="fleetcol">
            <img
              src="https://www.autostrad.com/admin_module/uploads/about_us/6619a40bce1f7df5f9e7d8f59b7568cd.webp"
              alt=""
            />
          </div>
          <div id="fleetcol">
            <h5>Miles To Go..</h5>
            <p>
              Over the years, UAE’s population has grown exponentially and UAE
              has become a global hub for business &amp; tourism. With
              ever-changing customer demands, there is an increasing need to
              expand our services and cater to the new discerning customer with
              an international standard car rental experience.
            </p>
            <p>
              At Autostrad Rent A Car, we are not only expanding our fleet but
              also consistently investing in advanced customer-centric processes
              & systems and cutting-edge technology to create safer, seamless
              and memorable car rental experiences right from the booking to
              payment process, delivery and the drive itself!
            </p>
            <p>
              As we move forward, our vision is to be the most loved, trusted
              and profitable transport solution provider in the regions we serve
              so that we can consistently create tremendous value for our
              customers, employees, shareholders and partners.
            </p>
          </div>
        </div>
      </div> */}

      
<section className="about-html"
              dangerouslySetInnerHTML={{
                __html: processHTMLContent(corporateLeasing?.content),
              }}
            />
        </>  }
    {/* </Helmet> */}</>
  );
};

export default About;

import CommonSection from "../CommonSection";

// import "../../styles/contact.css";

import React, { useEffect, useState } from "react";
import Helmet from "../../Helmet/Helmet";
import carlogo from "../../../assets/new-logo/logo.png";
// import "../../styles/corporate.css";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import configWeb from "../../../config.js/configWeb";
import { simpleGetCall } from "../../../config.js/SetUp";
import { Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import MetaHelmet from "../../Helmet/MetaHelmet";

import './newOurServices.css'
const NewOurServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const language = useSelector((state) => state.language.language);
  const { t, i18n } = useTranslation();

  const [corporateLeasing, setCorporateLeasing] = useState([]);
  const [loading, setLoading] = useState(true);
  const getCorporateLeasing = () => {
    const url = `${configWeb.GET_PRIVACY_POLICY(
      "our_services"
    )}?lang=${language}`;
    simpleGetCall(url)
      .then((res) => {
        if (!res?.error) {
          setCorporateLeasing(res || []); // Ensure it's always an array
        }
      })
      .catch((error) => {
        console.log("Special offers API failed-->", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getCorporateLeasing();
  }, [language]);

  const generateStructuredData = () => {
    return {
      "@context": "https://schema.org",
      "@type": "Article",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": window.location.href,
      },
      headline: corporateLeasing?.title,
      description: corporateLeasing?.seo_meta_description,
      image: corporateLeasing?.image,
      author: {
        "@type": "Organization",
        name: "Autostrad",
      },
      publisher: {
        "@type": "Organization",
        name: "Autostrad",
        logo: {
          "@type": "ImageObject",
          url: carlogo,
        },
      },
      datePublished: "2024-10-11",
      dateModified: "2024-10-12",
    };
  };
  return (
    <div>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh", width: "100vw" }}
        >
          <Spinner />
        </div>
      ) : (
        <>
          <CommonSection title="OUR SERVICES" />
          <MetaHelmet
            title={corporateLeasing?.title}
            description={corporateLeasing?.seo_meta_description}
            keywords={
              corporateLeasing?.seo_meta_tags ||
              "car rental, affordable cars, rent a car"
            }
            ogTitle={corporateLeasing?.title}
            ogDescription={corporateLeasing?.seo_meta_description}
            ogImage={corporateLeasing?.image}
            ogUrl={window.location.href}
            twitterTitle={corporateLeasing?.title}
            twitterDescription={corporateLeasing?.seo_meta_description}
            twitterImage={corporateLeasing?.image}
            twitterCard="summary_large_image"
            // twitterSite="@YourTwitterHandle"
            // twitterCreator="@AuthorTwitterHandle"
            canonicalUrl={`${configWeb.BASE_WEB_URL}/en/our-services`}
            hreflangs={[
              {
                hreflang: "en",
                href: `${configWeb.BASE_WEB_URL}/en/our-services`,
              },
              {
                hreflang: "ar",
                href: `${configWeb.BASE_WEB_URL}/ae/our-services`,
              },
            ]}
          />
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(generateStructuredData()),
            }}
          />
          <section className="p-2">
            <Container>
              {/* Production */}
           {/* <section class="our-services-main-section">
            
                 <div class="wrapper-div">    
                <section class = "section-main-img">
             
                <div class = "main-img-div">
                <img class="main-img" src="https://files.autostrad.com/admin/pages/our_services/pictures/driver-woman-car-waves-back-as-sign-farewell-street.jpg" alt="girl waving" />
                </div>
                </section>

                <section class = "section-our-services-grid">
            
                <div class="our-service-text-parent" >
                  <h2 class="our-services-heading">Our Services: </h2>
                <p class = "our-service-text">At Autostrad Car Rental, we specialize in providing a comprehensive service when it comes to your transportation needs. Whether it's a requirement for a day or a year, we want you to have the best possible options to choose from.
</p>
                </div>
              
                </section>
                <section class = "section-images-grid">
              <div class = "images-grid-item">
                <a href="https://www.autostrad.com/en/contact">
                <div class="small-img-div"> 
                <img class="small-img" src="https://files.autostrad.com/admin/pages/our_services/pictures/young-male-driver-sitting-car-holding-wheel.jpg" alt="image" />
                <img class ="sub-icon" src="https://files.autostrad.com/admin/pages/our_services/icons/Untitled-4.svg" alt="icon"  />
                </div>
                </a>
                <p class="small-img-text">Daily & Weekly Rentals</p>
              
                </div>

                <div class = "images-grid-item">
                <a href="https://www.autostrad.com/en/contact">
                <div class="small-img-div">
                  <img class="small-img" src="https://files.autostrad.com/admin/pages/our_services/pictures/stylish-elegant-woman-car-salon (1).jpg" alt="" />
                  <img class ="sub-icon" src="https://files.autostrad.com/admin/pages/our_services/icons/2.svg" alt="icon"  />
                </div>
                </a>
                <p class="small-img-text"> MONTHLY SUBSCRIPTION</p>
                </div>

                <div class = "images-grid-item">
                <a href="https://www.autostrad.com/en/contact">
                <div class="small-img-div">
                  <img class="small-img" src="https://files.autostrad.com/admin/pages/our_services/pictures/handsome-bearded-businessman-black-suit-entering-his-car-while-standing-outdoors-streets-city-near-modern-office-center.jpg" alt="" />
                  <img class ="sub-icon" src="https://files.autostrad.com/admin/pages/our_services/icons/3.svg" alt="icon"  />
                </div>
                </a>
                <p class="small-img-text">LONG TERM / <br/> CORPORATE LEASING</p>
                </div>

                <div class = "images-grid-item">
                <a href="https://www.autostrad.com/en/contact">
                <div class="small-img-div">
                  <img class="small-img" src="https://files.autostrad.com/admin/pages/our_services/pictures/business-commercial-vehicle.png" alt="" />
                  <img class ="sub-icon" src="https://files.autostrad.com/admin/pages/our_services/icons/4.svg" alt="icon"  />
                </div>
                </a>
                <p class="small-img-text"> BUS TRANSPORTATION</p>
                </div>

                <div class = "images-grid-item">
                <a href="https://www.autostrad.com/en/contact">
                <div class="small-img-div">
                  <img class="small-img" src="https://files.autostrad.com/admin/pages/our_services/pictures/Bus rental_V2-01.png" alt="" />
                  <img class ="sub-icon" src="https://files.autostrad.com/admin/pages/our_services/icons/5.svg" alt="icon"  />
                </div>
                </a>
                <p class="small-img-text">SCHOOL BUSES</p>
                </div>

                <div class = "images-grid-item">
                <a href="https://www.autostrad.com/en/contact">
                <div class="small-img-div">
                  <img class="small-img" src="https://files.autostrad.com/admin/pages/our_services/pictures/business-commercial-vehicle.png" alt="" />
                  <img class ="sub-icon" src="https://files.autostrad.com/admin/pages/our_services/icons/6.svg" alt="icon"  />
                </div>
                </a>
                <p class="small-img-text">COMMERCIAL FLEET</p>
                </div>

                <div class = "images-grid-item">
                <a href="https://www.autostrad.com/en/contact">
                <div class="small-img-div">
                  <img class="small-img" src="https://files.autostrad.com/admin/pages/our_services/pictures/CAM_A_0001(1).jpg" alt="" />
                  <img class ="sub-icon" src="https://files.autostrad.com/admin/pages/our_services/icons/7.svg" alt="icon"  />
                </div>
                </a>
                <p class="small-img-text"> ELECTRIC CARS</p>
                </div>
                </section>
                </div>
              </section> */}


               {/* Staging */}
  {/* <section class="our-services-main-section">
            
                 <div class="wrapper-div">    
                <section class = "section-main-img">
             
                <div class = "main-img-div">
                <img class="main-img" src="https://files.staging.autostrad.com/admin/pages/our_services/pictures/driver-woman-car-waves-back-as-sign-farewell-street.jpg" alt="girl waving" />
                </div>
                </section>

                <section class = "section-our-services-grid">
            
                <div class="our-service-text-parent" >
                  <h2 class="our-services-heading">Our Services: </h2>
                <p class = "our-service-text">At Autostrad Car Rental, we specialize in providing a comprehensive service when it comes to your transportation needs. Whether it's a requirement for a day or a year, we want you to have the best possible options to choose from.
</p>
                </div>
              
                </section>
                <section class = "section-images-grid">
              <div class = "images-grid-item">
                <a href="https://staging.autostrad.com/en/contact">
                <div class="small-img-div"> 
                <img class="small-img" src="https://files.staging.autostrad.com/admin/pages/our_services/pictures/young-male-driver-sitting-car-holding-wheel.jpg" alt="image" />
                <img class ="sub-icon" src="https://files.staging.autostrad.com/admin/pages/our_services/icons/Untitled-4.svg" alt="icon"  />
                </div>
                </a>
                <p class="small-img-text">Daily & Weekly Rentals</p>
              
                </div>

                <div class = "images-grid-item">
                <a href="https://staging.autostrad.com/en/contact">
                <div class="small-img-div">
                  <img class="small-img" src="https://files.staging.autostrad.com/admin/pages/our_services/pictures/stylish-elegant-woman-car-salon (1).jpg" alt="" />
                  <img class ="sub-icon" src="https://files.staging.autostrad.com/admin/pages/our_services/icons/2.svg" alt="icon"  />
                </div>
                </a>
                <p class="small-img-text"> MONTHLY SUBSCRIPTION</p>
                </div>

                <div class = "images-grid-item">
                <a href="https://staging.autostrad.com/en/contact">
                <div class="small-img-div">
                  <img class="small-img" src="https://files.staging.autostrad.com/admin/pages/our_services/pictures/handsome-bearded-businessman-black-suit-entering-his-car-while-standing-outdoors-streets-city-near-modern-office-center.jpg" alt="" />
                  <img class ="sub-icon" src="https://files.staging.autostrad.com/admin/pages/our_services/icons/3.svg" alt="icon"  />
                </div>
                </a>
                <p class="small-img-text">LONG TERM / <br/> CORPORATE LEASING</p>
                </div>

                <div class = "images-grid-item">
                <a href="https://staging.autostrad.com/en/contact">
                <div class="small-img-div">
                  <img class="small-img" src="https://files.staging.autostrad.com/admin/pages/our_services/pictures/business-commercial-vehicle.png" alt="" />
                  <img class ="sub-icon" src="https://files.staging.autostrad.com/admin/pages/our_services/icons/4.svg" alt="icon"  />
                </div>
                </a>
                <p class="small-img-text"> BUS TRANSPORTATION</p>
                </div>

                <div class = "images-grid-item">
                <a href="https://staging.autostrad.com/en/contact">
                <div class="small-img-div">
                  <img class="small-img" src="https://files.staging.autostrad.com/admin/pages/our_services/pictures/Bus rental_V2-01.png" alt="" />
                  <img class ="sub-icon" src="https://files.staging.autostrad.com/admin/pages/our_services/icons/5.svg" alt="icon"  />
                </div>
                </a>
                <p class="small-img-text">SCHOOL BUSES</p>
                </div>

                <div class = "images-grid-item">
                <a href="https://staging.autostrad.com/en/contact">
                <div class="small-img-div">
                  <img class="small-img" src="https://files.staging.autostrad.com/admin/pages/our_services/pictures/business-commercial-vehicle.png" alt="" />
                  <img class ="sub-icon" src="https://files.staging.autostrad.com/admin/pages/our_services/icons/6.svg" alt="icon"  />
                </div>
                </a>
                <p class="small-img-text">COMMERCIAL FLEET</p>
                </div>

                <div class = "images-grid-item">
                <a href="https://staging.autostrad.com/en/contact">
                <div class="small-img-div">
                  <img class="small-img" src="https://files.staging.autostrad.com/admin/pages/our_services/pictures/CAM_A_0001(1).jpg" alt="" />
                  <img class ="sub-icon" src="https://files.staging.autostrad.com/admin/pages/our_services/icons/7.svg" alt="icon"  />
                </div>
                </a>
                <p class="small-img-text"> ELECTRIC CARS</p>
                </div>
                </section>
                </div>
              </section> */}
              <section className='px-1 px-lg-5'
              dangerouslySetInnerHTML={{
                __html: corporateLeasing?.content,
              }}
            />
            </Container>
          </section>
        </>
      )}
    </div>
  );
};

export default NewOurServices;

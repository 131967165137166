// import * as React from 'react';
import React, {  useContext, useEffect, useState } from "react";
import { simpleGetCall,  } from "../../config.js/SetUp";
import configWeb from "../../config.js/configWeb";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
// import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import TextField from "@mui/material";
import {
  DateTimePicker as MuiDateTimePicker,
  // TimePickerComponentProps,
} from "@mui/x-date-pickers/DateTimePicker";
// import textField from "@mui/material";
// import TextField from "@mui/material/TextField";
// import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import dayjs from "dayjs";
import "./CustomDateTimePicker3.css";
import {

  Form,
 
} from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
// import { MobileDatePicker, PickersTextField } from "@mui/x-date-pickers";

const ProSpan = styled("span")({
  display: "inline-block",
  height: "100px",
  width: "100px",
  verticalAlign: "middle",
  marginLeft: "0.3em",
  marginBottom: "0.08em",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundImage: "url(https://mui.com/static/x/pro.svg)",
});

function Label({ componentName, valueType, isProOnly }) {
  const content = (
    <span>
      <strong>{componentName}</strong> for {valueType} editing
    </span>
  );

  if (isProOnly) {
    return (
      <Stack direction="row" spacing={0.5} component="span">
        <Tooltip title="Included on Pro package">
          <a
            href="https://mui.com/x/introduction/licensing/#pro-plan"
            aria-label="Included on Pro package"
          >
            <ProSpan />
          </a>
        </Tooltip>
        {content}
      </Stack>
    );
  }

  return content;
}



export default function DropoffDateTimePicker(props) {
  const { clickonMapAddressSelectionFlagForDropoff,setClickonMapAddressSelectionFlagForDropoff } = useContext(AppContext);
  
  const {
    selectedCollectEmirate,
    deliveryOption,
    collectOption,
    selectedDropoffLocation,
    onDateTimeChange,
    selectedPickupLocation,
    formattedDate,
    formattedTime,
    month,
    booking_type,
    selectedDeliveryEmirate,
  } = props;

  // State for the dropoff datetime value
  const [value, setValue] = useState(
    booking_type === "monthly" && month && formattedDate
      ? dayjs(formattedDate)?.add(month, "day")
      : null
  );

  // State for dropoff formatted date and time
  const [formattedDate_dropoff, setFormattedDate_dropoff] = useState("");
  const [formattedTime_dropoff, setFormattedTime_dropoff] = useState("");

  // State for selected shifts and day of the week
  const [shifts, setShifts] = useState([]);
  const [dayOfWeek, setDayOfWeek] = useState(null);

  // State to manage picker behavior
  const [open, setOpen] = useState(false);
  // const [timeSelected, setTimeSelected] = useState(false);

  // Update the initial value for "daily" bookings based on formattedDate and formattedTime
  useEffect(() => {
    if (booking_type === "daily" && formattedDate && formattedTime) {
      const DateAndTime = `${formattedDate}T${formattedTime}`;
      const updatedDate = dayjs(DateAndTime).add(24, "hour");
      setValue(updatedDate);
    }
  }, [formattedDate, formattedTime, booking_type]);

  // Update dropoff formatted date and time when value changes
  useEffect(() => {
    if (/* booking_type === "daily" && */ value) {
      setFormattedDate_dropoff(dayjs(value).format("YYYY-MM-DD"));
      setFormattedTime_dropoff(dayjs(value).format("HH:mm"));
      onDateTimeChange(formattedDate_dropoff,formattedTime_dropoff)
    }
  }, [value, booking_type]);

  // Combine date and time for "monthly" bookings
  const combineDateAndTime = () => {
    const date = formattedDate ? dayjs(formattedDate)?.add(month, "day") : "";
    const timeParts = formattedTime?.split(":"); // Assuming formattedTime is in "HH:mm" format
    const combinedDateTime =
      formattedDate
        ? date?.hour(Number(timeParts[0]))?.minute(Number(timeParts[1]))
        : "";
    return combinedDateTime;
  };

  // Set the value for "monthly" bookings on initialization
  useEffect(() => {
    if (booking_type === "monthly" && month && formattedDate) {
      const value2 = combineDateAndTime();
      setValue(value2);
    }
  }, [month, formattedTime, formattedDate, booking_type]);

  // Update day of the week when formattedDate_dropoff changes
  useEffect(() => {
    if (formattedDate_dropoff) {
      const date = new Date(formattedDate_dropoff);
      setDayOfWeek(date.getDay());
    }
  }, [formattedDate_dropoff]);

  // Fetch dropoff location hours based on various parameters
  const getDropoffLocationHours = () => {
    const locationValue =
      collectOption === "collect_from_me"
        ? selectedCollectEmirate?.value || selectedDeliveryEmirate?.value
        : selectedDropoffLocation?.value ||
          selectedPickupLocation?.value ||
          selectedDeliveryEmirate?.value;

    const dayOfWeekValue = Number(dayOfWeek) + 1;

    const url =
      collectOption === "collect_from_me" ||
      (deliveryOption === "deliver_to_me" && !selectedDropoffLocation)
        ? configWeb.GET_EMIRATE_LOCATION_HOURS(locationValue, dayOfWeekValue)
        : configWeb.GET_PICKUP_LOCATION_HOURS(locationValue, dayOfWeekValue);

    simpleGetCall(url)
      .then((res) => {
        if (!res?.error) {
          setShifts(res);
        }
      })
      .catch((error) => {
        console.error("Location failed:", error);
      });
  };
  useEffect(() => {
  

    if (formattedDate_dropoff && (selectedPickupLocation || selectedDropoffLocation || selectedCollectEmirate || selectedDeliveryEmirate)) {
      getDropoffLocationHours();
    }
  }, [dayOfWeek, selectedDropoffLocation, selectedCollectEmirate/* , formattedDate_dropoff */]);
useEffect(()=>{
if(clickonMapAddressSelectionFlagForDropoff){
  getDropoffLocationHours();
}
setClickonMapAddressSelectionFlagForDropoff(false);
},[clickonMapAddressSelectionFlagForDropoff])
  // Disable specific dates based on booking type and formatted date
  const shouldDisableDate = (date) => {
    if (booking_type === "monthly") {
      const targetStartDate = dayjs(formattedDate).add(month, "day").startOf("day");
      return date.isBefore(targetStartDate, "day");
    }
    else if (booking_type === "daily") {
      // Daily booking logic: Disable dates more than 29 days from the formatted date
      if (formattedDate) {
        const targetStartDate = dayjs(formattedDate);
        const maxSelectableDate = targetStartDate.add(29, "day").endOf("day");
        return date.isBefore(targetStartDate, "day") /* || date.isAfter(maxSelectableDate, "day"); */ //commented out restriction that was restricting user to not select day after 29 days in case of daily booking
      } 
    }
    
    else if (formattedDate) {
      const targetDate = dayjs(formattedDate);
      return date.isBefore(targetDate, "day");
    }
  };

  // Disable specific times based on shifts
  const shouldDisableTime = (value, view) => {
    if (!dayjs.isDayjs(value)) return false;
    const selectedHour = value.hour();
    const selectedMinute = value.minute();

    const isWithinShift = Array.isArray(shifts) && shifts?.length > 0 && shifts?.some(({ from_hours, to_hours }) => {
      if (from_hours <= to_hours) {
        return selectedHour >= from_hours && selectedHour < to_hours;
      } else {
        return selectedHour >= from_hours || selectedHour < to_hours;
      }
    });

    if (!isWithinShift) return true;

    const isEndHour = shifts?.some(({ from_hours, to_hours }) => {
      const hasFullDayShift = shifts.some((shift) => shift.to_hours === 24);
      if (hasFullDayShift) return false;
      if (to_hours === 0) return selectedHour === 23;
      return selectedHour === to_hours;
    });

    if (isEndHour && view === "minutes" && selectedMinute !== 0) {
      return true;
    }

    return false;
  };

    useEffect(() => {
    if (formattedDate_dropoff) {
      onDateTimeChange(formattedDate_dropoff, formattedTime_dropoff);
    }
  }, [formattedDate_dropoff, formattedTime_dropoff]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Form.Group controlId="formGridAddress1">
        <Form.Label className="label-name mb-2-" /* style={{ marginBottom: "0.35rem" }} */>
          Drop-off Date / Time
        </Form.Label>

        <MuiDateTimePicker
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          slotProps={{
            textField: {
              onClick: () => setOpen(true),
            },
          }}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
            setFormattedDate_dropoff(dayjs(newValue).format("YYYY-MM-DD"));
            setFormattedTime_dropoff(dayjs(newValue).format("HH:mm"));
          }}
          timeSteps={{ hours: 1, minutes: 15 }}
          ampm={false}
          format="YYYY-MM-DD HH:mm"
          shouldDisableTime={shouldDisableTime}
          shouldDisableDate={shouldDisableDate}
        />
      </Form.Group>
    </LocalizationProvider>
  );
}

import React from "react";
import { Helmet } from "react-helmet";

const MetaHelmet = ({
  title,
  description,
  keywords,
  ogTitle,
  ogDescription,
  ogImage,
  ogUrl,
  twitterTitle,
  twitterDescription,
  twitterImage,
  twitterCard = "summary_large_image",
  twitterSite,
  twitterCreator,
  canonicalUrl,
  hreflangs = [], // Array of objects for hreflang
}) => {
  return (
    <Helmet>
      {/* <title>{title ? `Autostrad Rent A Car: - ${title}` : "Autostrad Rent A Car:"}</title> */}
      <title>{title}</title>
      <meta name="description" content={description || "Default description"} />
      <meta name="keywords" content={keywords || "car rental, rent a car"} />

  {/* Canonical URL */}
  {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}

         {/* Open Graph Tags */}
         <meta property="og:title" content={ogTitle || title} />
      <meta property="og:description" content={ogDescription || description} />
      <meta property="og:image" content={ogImage || "default-image.jpg"} />
      <meta property="og:url" content={ogUrl || window.location.href} />
      <meta property="og:type" content="website" />


      {/* Twitter Tags */}
      <meta name="twitter:card" content={twitterCard} />
      <meta name="twitter:title" content={twitterTitle || title} />
      <meta name="twitter:description" content={twitterDescription || description} />
      <meta name="twitter:image" content={twitterImage || ogImage} />
      {/* {twitterSite && <meta name="twitter:site" content={twitterSite} />}
      {twitterCreator && <meta name="twitter:creator" content={twitterCreator} />} */}

        {/* Hreflang Tags for International SEO */}
        {hreflangs.map(({ href, hreflang }, index) => (
        <link key={index} rel="alternate" hrefLang={hreflang} href={href} />
      ))}
    </Helmet>
  );
};

export default MetaHelmet;

import React, { useEffect, useState } from "react";
import Helmet from "../Helmet/Helmet";
import CommonSection from "./CommonSection";
import { Card, Col, Container, Nav, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import "../../styles/ourfleetlist.css";
import "../../styles/bookingstepper.css";
import { carData } from "../../assets/data/carData";
import { Link, useNavigate } from "react-router-dom";
import { simpleGetCall } from "../../config.js/SetUp";
import { useSelector } from "react-redux";
import configWeb from "../../config.js/configWeb";
import icon01 from "../../assets/all-images/car-parts/car-parts-01.svg.svg";
import icon1 from "../../assets/all-images/cars-img/Layer_1.svg";
import icon02 from "../../assets/all-images/car-parts/car-parts-06.svg.svg";
import icon03 from "../../assets/all-images/car-parts/car-parts-03.svg.svg";
import icon04 from "../../assets/all-images/car-parts/snow-outline.svg";
import icon05 from "../../assets/all-images/car-parts/car-door.svg";
import CustomPagination from "./Pagination";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import FindCarForm2 from "./FindCarForm2";
import MetaHelmet from "../Helmet/MetaHelmet";

const OurFleetList = () => {
  const [activeItem, setActiveItem] = useState(null);
  const [selectedCarCategory, setSelectedCarCategory] = useState(null);
  const [carCategories, setCarCategories] = useState([]);
  const { t, i18n } = useTranslation();
  const [emiratesArray, setEmiratesArray] = useState([]);

  const handleItemClick = (index) => {
    setActiveItem(index);
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };


  const listItems = [
    "All",
    "Compact Cars",
    "Economy Cars",
    "Mid-Size Cars",
    "Small SUVs",
    "Large SUVs",
    "Family Cars",
    "Premium Cars",
    "Refrigerated Vans and Trucks",
    "Pick up, Panel Vans and Trucks",
    "Mini Buses",
    "Buses",
    "Mid Size SUV",
    "Electric Vehicle",
  ];
  const navigate = useNavigate();
  const language = useSelector((state) => state.language.language);
  const [carListArray, setCarListArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);

  const car_list_page_size = 10;
  const emiratesData = () => {
    const url = `${configWeb.GET_EMIRATES}?lang=${language}`;
    simpleGetCall(url)
      .then((res) => {
        setEmiratesArray(res);
      })
      .catch((errr) => {
        console.log("errr", errr);
      })
      .finally(() => {
        // set_loading(false);
      });
  };
  useEffect(()=>{
    // emiratesData();
  },[])
  const carListingData = () => {
    const baseUrl = `${configWeb.GET_EMIRATES_CAR_LIST}?lang=${language}&page=${currentPage}&page_size=${car_list_page_size}`; //&emirate_id=${emirate_id}
    const url = selectedCarCategory
    ? `${baseUrl}&category_id=${selectedCarCategory}`
    : baseUrl;
    setLoading(true);
    simpleGetCall(url)
      .then((res) => {
        if (!res?.error) {
          setCarListArray(res?.date);
          setTotalRecords(res?.total_records);

          // setImagePath(res?.path);
        }
      })
      .catch((error) => {
        console.log("car list api failed-->", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    carListingData();
  }, [currentPage, selectedCarCategory, language]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scroll(0,0);
  };
useEffect(()=>{
  window.scroll(0,0);

},[])
  const handleCarCategorySelection = (key) => {
    setCurrentPage(1);
  
    // setSelectedCarCategory(key)

    setSelectedCarCategory((prevCategory) =>
      prevCategory === key ? null : key
    );
  };

  const getCarCategories = () => {
    const url = `${configWeb.GET_CAR_CATEGORY}?lang=${language}&page=1&page_size=1000`;
    simpleGetCall(url)
      .then((res) => {
        if (!res?.error) {
          setCarCategories(res?.data);
        }
      })
      .catch((error) => {})
      .finally(() => {});
  };
  useEffect(()=>{
    getCarCategories();
  },[language])
  const handleBookNow =()=>{

    // dispatch(setSavedCar(carId));
      // window.scrollTo({ top: 0, behavior: 'smooth' });
      navigate(`/`)
     
    
    }
  return (
    <Helmet title="OUR FLEET">
        <MetaHelmet
        title="OUR FLEET LIST"
        description=""
        keywords="car rental, affordable cars, rent a car"
        canonicalUrl = {`${configWeb.BASE_WEB_URL}/en/OurFleetList`}

        hreflangs={[
          { hreflang: "en", href: `${configWeb.BASE_WEB_URL}/en/OurFleetList` },
          { hreflang: "ar", href: `${configWeb.BASE_WEB_URL}/ae/OurFleetList` },
        ]}

        />
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh", width: "100vw" }}
        >
          {" "}
          <Spinner />
        </div>
      ) : (
        <>
        {/* <section className="pb-1 form-section" >
        <h1
          className="pt-2 m-0 text-center text-light booking__tital-dream"
          style={{ fontFamily: "Rubik" }}
        >
          {t("BOOK YOUR DREAM CAR NOW")}!
        </h1>
        <Col id="booking-form-container" lg="12" md="12" sm="12">
          <Container>
            <FindCarForm2 emiratesArray={emiratesArray} />
          </Container>
        </Col>
      </section> */}
          <CommonSection title="OUR FLEET" />

          <Container fluid className="fleet__section">
            <Row className="mb-5">


              {/* below is responsive col */}

              <Col md={12} className="car_category__section d-block d-md-none">
              {carCategories && carCategories?.length > 0 && (
                <Slider {...settings}>
                  {Array.isArray(carCategories) &&
                    carCategories.map((category) => (
                      <div key={category.id}
                      onClick={()=>handleCarCategorySelection(category?.id)}
                      className={`category-item ${selectedCarCategory === category?.id ? "active" : ""}`}
                      >
                        <span style={{ display: "inline-block" }}>
                          <img
                            className="my-1 category_img category_img-car-suv"
                            src={category?.image}
                            alt={category.name}
                          />
                          {category.name}
                        </span>
                      </div>
                    ))}
                </Slider>
              )}
              </Col>
              {/* ////////// */}



              <Col md={12} className="car_category__section d-none d-md-block">
              <Tabs
            // defaultActiveKey={selectedCarCategory}
            activeKey={selectedCarCategory}
            id="uncontrolled-tab-example"
            className="justify-content-evenly mb-3 gap-2"
            onSelect={handleCarCategorySelection}
            //   justify
          >
            {Array.isArray(carCategories) &&
              carCategories?.map((category) => (
                <Tab
                  key={category?.id}
                  eventKey={category?.id}
                  title={
                    <span style={{ display: "inline-block" }}>
                      <img className="my-1 category_img" src={category?.image} alt={category?.name} />
                      {category?.name}
                    </span>
                  }
                />
              ))}
              </Tabs>
              </Col>
            </Row>

            <Row>
              {/* <Col md={3} xs={12} className="mt-2 ps-md-5">
      <div className="list-container">
        <h6 className="list-title">Your Car List </h6>
        <ul className="list-group">
          {listItems.map((item, index) => (
            <li
              key={index}
              className={`list-group-item ${
                index === activeItem ? "active" : ""
              }`}
              onClick={() => handleItemClick(index)}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
    </Col> */}
              <Col md={12}>
                <div className="fleet-card mt-2">
                  <Container>
                    <Row>
                      {/* {carData.slice(0, 6).map((item) => (
                    <Col md={4} sm={6} className="mb-4">
                      <div className="fleet-card-overView">
                        <div className="offer__img glassBox__imgBox">
                          <img
                            src={item.imgUrl}
                            alt=""
                            className="w-100 glassBox__imgBox"
                          />
                        </div>

                        <div className="offer__item-content mt-4">
                          <h4 className="fleet__card-title">{item.carName}</h4>
                          <hr />
                          <div className="offer__item-info d-flex align-items-center justify-content-between mt-3 mb-4">
                            <span className=" d-flex align-items-center gap-1 ">
                              <img src={item.icon1} alt="" />
                              {item.automatic}
                            </span>{" "}
                            |
                            <span className=" d-flex align-items-center gap-1">
                              <img src={item.icon2} alt="" />
                              {item.seater}
                            </span>{" "}
                            |
                            <span className=" d-flex align-items-center gap-1">
                              <img src={item.icon3} alt="" />
                              {item.pertol}
                            </span>{" "}
                            |
                            <span className=" d-flex align-items-center gap-1">
                              <img
                                src={item.icon05}
                                alt=""
                                height="20px"
                                width="20px"
                              />
                              4
                            </span>
                            |
                            <span className=" d-flex align-items-center gap-1">
                              <img src={item.icon4} alt="" />
                              {item.AC}
                            </span>
                          </div>
                          <hr />
                          <div className="offer__item-info d-flex align-items-center justify-content-between mt-3 mb-4">
                            <span className=" d-flex align-items-center gap-1">
                              AED {item.price}{" "}
                              <span className="offer__plans">/Day</span>
                            </span>
                            <span className=" d-flex align-items-center gap-1">
                              AED {item.price}
                              <span className="offer__plans">/week</span>
                            </span>
                            <span className=" d-flex align-items-center gap-1">
                              AED {item.price}
                              <span className="offer__plans">/Month</span>
                            </span>
                          </div>
                          <div className="container text-center">
  <div className="row">
    <div className="col-12 col-md-6">
      <button className="w-100 btn btn-primary offer__item-btn offer__btn-rent g">
        <Link to={`/cars/${item.carName}`}>READ MORE </Link>
      </button>
    </div>
    
    <div className="col-12 col-md-6 mt-md-0 mt-2">
      <button className="w-100 btn btn-secondary offer__item-btn offer__btn-rent">
        <Link to='/CarEnquriry'>ENQUIRE</Link>
      </button>
    </div>
  </div>
</div>
                        </div>
                      </div>
                    </Col>
                  ))} */}

                      {carListArray?.length > 0 &&
                        carListArray?.map((item) => (
                          <Col
                            md={6}
                            lg={6}
                            xl={4}
                            sm={12}
                            className="mb-4"
                            key={item.id}
                          >
                            <div className="fleet-card-overView">
                              <div className="img_parent offer__img-- glassBox__imgBox--">
                                <img
                                  src={item.image}
                                  alt=""
                                  className="img_child w-100-- glassBox__imgBox--"
                                />
                              </div>

                              <div className="offer__item-content mt-4">
                                <h4 className="fleet__card-title">
                                  {item.name}
                                </h4>
                                <hr />
                                <div className="offer__item-info d-flex align-items-center justify-content-evenly mt-3 mb-4">
                                  <span className=" d-flex align-items-center gap-1 ">
                                    <img src={icon01} alt="" />
                                    {item.transmission === "Automatic"
                                      ? "A"
                                      : "NA"}
                                  </span>{" "}
                                  |
                                  <span className=" d-flex align-items-center gap-1">
                                    <img src={icon02} alt="" />
                                    {item.passengers}
                                  </span>{" "}
                                  |
                                  <span className=" d-flex align-items-center gap-1">
                                    <img src={icon03} alt="" />
                                    {item.fuel_type}
                                  </span>{" "}
                                  |
                                  <span className=" d-flex align-items-center gap-1">
                                    <img
                                      src={icon05}
                                      alt=""
                                      height="20px"
                                      width="20px"
                                    />
                                    {item.doors}
                                  </span>
                                  |
                                  <span className=" d-flex align-items-center gap-1">
                                    <img src={icon04} alt="" />A
                                  </span>
                                </div>
                                <hr />
                                {/* <div className="offer__item-info d-flex align-items-center justify-content-evenly mt-3 mb-4 flex-wrap">
                                  <span className=" d-flex align-items-center gap-1">
                                    AED {item.daily_rate}{" "}
                                    <span className="offer__plans">/Day</span>
                                  </span>
                                  <span className=" d-flex align-items-center gap-1">
                                    AED {item.weekly_rate}
                                    <span className="offer__plans">/Week</span>
                                  </span>
                                  <span className=" d-flex align-items-center gap-1">
                                    AED {item.monthly_rate}
                                    <span className="offer__plans">/Month</span>
                                  </span>
                                  
                                </div> */}
                                <div className="container text-center">
                                  <div className="row">
                                    <div className="col-12 col-md-6">
                                    {/* <Link to={`/${language}/cars/${item.id}`}> */}
                                      {/* <button className="w-100 btn btn-primary offer__item-btn offer__btn-rent g">
                                      
                                          READ MORE{" "}
                                        
                                      </button> */}
                                        <Link to={`/`} onClick={() => handleBookNow()}>
                                      <button className="w-100 btn btn-primary offer__item-btn offer__btn-rent g">
                                      
                                          Book{" "}
                                        
                                      </button>
                                      </Link>
                                    </div>

                                    <div className="col-12 col-md-6 mt-md-0 mt-2">
                                    <Link to={`/${language}/contact`} state={{car_id : item.name}} >  <button className="w-100 btn btn-secondary offer__item-btn offer__btn-rent">
                                        ENQUIRE
                                      </button></Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        ))}
                    </Row>
                    <CustomPagination
                      recordsPerPage={car_list_page_size}
                      totalRecords={totalRecords}
                      onPageChange={handlePageChange}
                      currentPage={currentPage}
                    />
                  </Container>
                </div>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </Helmet>
  );
};

export default OurFleetList;

import React, { useState, useContext, useEffect } from "react";
import "./ChooseDeliverToMePopup.css"; // Import the CSS file
import Select from "react-select";
import {
 
  Col,
  Form,
  Row,
 
  FormControl,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
// import { useLocation } from "react-router-dom";
import {
  GoogleMap,
  // useLoadScript,
  // Marker,
  MarkerF,
  useJsApiLoader,
} from "@react-google-maps/api";
// import { AppContext } from "../../context/AppContext";
import { setInputValueDropoff } from "../../reducers/Slices/inputValueDropoffSlice"; //importing action
import { setSelectedCollectEmirate } from "../../reducers/Slices/selectedCollectEmirateSlice";
// import { setDropOffEmirate } from "../../reducers/Slices/dropoffEmirateSlice";
import { setIsValidAddressCollection } from "../../reducers/Slices/isValidAddressCollection";
import { setErrorAddressCollection } from "../../reducers/Slices/errorAddressCollection";
import { useSelector, useDispatch } from "react-redux";
import { setDropOffEmirate } from "../../reducers/Slices/dropoffEmirateSlice";
import { UAE_BOUNDS, extractEmirate, filterEmirateArrayByLabel } from "../../SharedComponent/reusableFunctions";
import { setSelectedDeliveryEmirate } from "../../reducers/Slices/selectedDeliveryEmirateSlice";
// Sample data for city dropdown, to be replaced with actual data
// const cities = ["New York", "Los Angeles", "Chicago", "Houston", "Phoenix"];
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: "#fff",
    // borderColor: '#9e9e9e',
    minHeight: "30px",
    height: "45px",
    boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
  }),
};
const defaultCenter = {
  lat: 25.2048, // default latitude
  lng: 55.2708, // default longitude
};
const mapContainerStyle = {
  /*  width: '30rem',
     height: '30rem' */
  height: "400px",
  width: "100%",
};
const libraries = ["places"];

const CollectFromMePopup = (props) => {
  const {
    emiratesArray,
    handleCloseButtonForCollect,
    handleSelectAddressCollect,
    handleCollectAddressChange,
    handleCollectEmirateChange_pseudo,
    onMarkerPositionChange,
  } = props;
  const { t, i18n } = useTranslation();
  // const location = useLocation();
  const dispatch = useDispatch();
  const inputValueDropoff = useSelector(
    (state) => state.inputValueDropoff.inputValueDropoff
  );
  const errorAddressCollection = useSelector(
    (state) => state.errorAddressCollection.errorAddressCollection
  );
  const isValidAddressCollection = useSelector(
    (state) => state.isValidAddressCollection.isValidAddressCollection
  );
  const selectedCollectEmirate = useSelector(
    (state) => state.selectedCollectEmirate.selectedCollectEmirate
  );
  // const dropOffEmirate = useSelector(
  //   (state) => state.dropOffEmirate.dropOffEmirate
  // );
  const selectedDeliveryEmirate = useSelector(
    (state) => state.selectedDeliveryEmirate.selectedDeliveryEmirate
  );
  const [mapMarkerCenterFlag, setMapMarkerCenterFlag] = useState(false)
  // const pickupEmirate = useSelector(
  //   (state) => state.pickupEmirate.pickupEmirate
  // );
  // const {inputValueDropoff, setInputValueDropoff} = useContext(AppContext)
  const {
    // ready,
    // value,
    suggestions: { status, data },
    // setValue,
    // clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => defaultCenter.lat, lng: () => defaultCenter.lng },
      radius: 200 * 1000,
      componentRestrictions: {
        country: "ae",
      },

    },
    searchOptions:{
      componentRestrictions: {
        country: ["ae"],
      },
    }
  });
  


  const GEOLOCATION_API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;

  const handleCollectEmirateChange = (selectedOption) => {
    dispatch(setSelectedCollectEmirate(selectedOption));
    dispatch(setDropOffEmirate(selectedOption));
    handleCollectEmirateChange_pseudo(selectedOption);
  };
  const handleSubmit = (e) => {
    const form = e.currentTarget;

    e.preventDefault();
  };

  useEffect(() => {
    handleCollectAddressChange(inputValueDropoff);
  }, [inputValueDropoff]);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // States and handlers for Dropoff Address
  // const [addressDropoff, setAddressDropoff] = useState("");
  // const [showMapPopupDropoff, setShowMapPopupDropoff] = useState(false);
  const [centerDropoff, setCenterDropoff] = useState(defaultCenter);
  const [markerPositionDropoff, setMarkerPositionDropoff] =
    useState(defaultCenter);
    const [emirateName, setEmirateName] = useState('');
    const emiratesCoordinates = {
      1: { lat: 25.2048, lng: 55.2708 }, // Dubai
      2: { lat: 24.4539, lng: 54.3773 }, // Abu Dhabi
      3: { lat: 25.3562, lng: 55.4272 }, // Sharjah
      4: { lat: 24.2232, lng: 55.7229 }, // Al Ain
      5: { lat: 24.0975, lng: 52.7347 }, //  Al ruwais
      6: { lat: 25.1221, lng: 56.3345 }, // Fujairah
      7: { lat: 25.8007, lng: 55.9762 }, // Ras Al Khaimah
    };
  useEffect(() => {
   
    
    const defaultCoordinates = emiratesCoordinates[1];
    if(!mapMarkerCenterFlag){
      if(!inputValueDropoff){
    setMarkerPositionDropoff(
      emiratesCoordinates[selectedCollectEmirate?.value] ||
        emiratesCoordinates[selectedDeliveryEmirate?.value] ||
        defaultCoordinates
    );
      }
      
    setCenterDropoff(
      emiratesCoordinates[selectedCollectEmirate?.value] ||
        emiratesCoordinates[selectedDeliveryEmirate?.value] ||
        defaultCoordinates
    );
    }
    setMapMarkerCenterFlag(false);
  }, [selectedCollectEmirate?.value, inputValueDropoff]);

  useEffect(()=>{
    if(Array.isArray(emiratesArray) && emiratesArray.length >0 && emirateName /* && selectedCollectEmirate?.label */ && emirateName !== selectedCollectEmirate?.label){
      let emirateObj = filterEmirateArrayByLabel(emiratesArray, emirateName);
      dispatch(setSelectedCollectEmirate(emirateObj));
      dispatch(setDropOffEmirate(emirateObj));
    }
  
  },[emirateName])
  

  const {
    ready: readyDropoff,
    value: valueDropoff,
    suggestions: { status: statusDropoff, data: dataDropoff },
    setValue: setValueDropoff,
    clearSuggestions: clearSuggestionsDropoff,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => defaultCenter.lat, lng: () => defaultCenter.lng },
      radius: 200 * 1000,
    },
    componentRestrictions: {
      country: "ae",
    },
  });

 

  useEffect(() => {
    if (markerPositionDropoff) {
      onMarkerPositionChange(markerPositionDropoff); // Call the callback function
    }
  }, [markerPositionDropoff]);

  const handleInputDropoff = (e) => {
    setValueDropoff(e.target.value);
    // setInputValueDropoff(e.target.value);
    dispatch(setInputValueDropoff(e.target.value));

    dispatch(setIsValidAddressCollection(false));
    dispatch(setErrorAddressCollection(""));
    
  };

  const handleAddressSelectDropoff = async (address) => {
    setValueDropoff(address, false);
    clearSuggestionsDropoff();

    try {
      setMapMarkerCenterFlag(true);
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      setCenterDropoff({ lat, lng });
      setMarkerPositionDropoff({ lat, lng });
      // setInputValueDropoff(results[0].formatted_address);
      dispatch(setInputValueDropoff(results[0].formatted_address));

      dispatch(setIsValidAddressCollection(true));
      const emirateName= extractEmirate(results);
      setEmirateName(emirateName)
    
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleMarkerDragEndDropoff = async (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarkerPositionDropoff({ lat, lng });
    setCenterDropoff({ lat, lng });

    try {
      setMapMarkerCenterFlag(true);
      const results = await getGeocode({ location: { lat, lng } });
      // setInputValueDropoff(results[0].formatted_address);
      dispatch(setInputValueDropoff(results[0].formatted_address));
      dispatch(setIsValidAddressCollection(true));
      const emirateName= extractEmirate(results);
      setEmirateName(emirateName)
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const renderSuggestionsDropoff = () =>
    dataDropoff.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          key={place_id}
          onClick={() => handleAddressSelectDropoff(suggestion.description)}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

    const handleSelectAddress = () => {
      if(isValidAddressCollection){
        handleSelectAddressCollect();
      }
      if (!isValidAddressCollection) {
        dispatch(setErrorAddressCollection("Please select your address from suggestions or adjust the marker on the map."));
        return;
      } else {
      dispatch(setErrorAddressCollection(""));
      
      // handleSelectAddressCollect();
      }
    }

  return (
    <div className="chooseDeliverToMePopup">
      <Form onSubmit={handleSubmit} /* validated={validated} */>
        <div className="row mt-4">
          <div className="col-sm-12 col-md-6">
            <Row className=" sm-6  ">
              <Col style={{ paddingBottom: "0px" }} lg={12} md={12} sm={12}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="label-name">
                    Select Collect Emirate
                  </Form.Label>
                  <Select
                    className="find-my-car-select"
                    required
                    value={selectedCollectEmirate || selectedDeliveryEmirate || ""}
                    onChange={handleCollectEmirateChange}
                    options={emiratesArray.map((emirate) => ({
                      value: emirate.id,
                      label: emirate.name,
                    }))}
                    styles={customStyles}
                    placeholder={t("Select...")}
                  />
                </Form.Group>
              </Col>
              <Col
                style={{ paddingBottom: "0px" }}
                lg={12}
                md={12}
                sm={12}
                className="my-2"
              >
                <Form.Label className="label-name ">
                  Select Your Address
                </Form.Label>
                <Form.Control
                  value={inputValueDropoff || ""}
                  onChange={handleInputDropoff}
                  disabled={!readyDropoff}
                  placeholder="Search a location"
                  className="mb-2"
                />
                {statusDropoff === "OK" && (
                  <ul className="suggestions">
                    {renderSuggestionsDropoff()}
                  </ul>
                )}
              </Col>

              {/*  <Col lg={6} md={6} sm={12}>  mycode*/}
              <Col lg={6} md={6} sm={6} xs={6}>
                {/* changecode */}
                <button
                  className="contact__btn w-100"
                  style={{ backgroundColor: "#4078AB", height: "2.5rem" }}
                  type="button"
                  onClick={handleCloseButtonForCollect}
                >
                  Close
                </button>
              </Col>
              {/*  <Col lg={6} md={6} sm={12}>  mycode*/}
              <Col lg={6} md={6} sm={6} xs={6}>
                {/* changecode */}
                <button
                  className="contact__btn  w-100"
                  style={{ backgroundColor: "#4078AB", height: "2.5rem" }}
                  type="button"
                  onClick={handleSelectAddress}
                >
                  Select Address
                </button>
              </Col>
            </Row>

            {errorAddressCollection && (
                  <div className="text-danger mt-1">{errorAddressCollection}</div>
                )}
          </div>

          <div className="col-sm-12 col-md-6">
            {/* <Col style={{paddingBottom : '0px'}} lg={12} md={12} sm={12} > */}
            <div className="w-100 h-100">
              {/* isLoaded && */ (
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={13}
                center={centerDropoff}
                options={{
                  restriction: {
                    latLngBounds: UAE_BOUNDS, 
                    strictBounds: true, // Prevent panning outside bounds
                 
                  },
                }}
              >
                <MarkerF
                  position={markerPositionDropoff}
                  draggable={true}
                  onDragEnd={handleMarkerDragEndDropoff}
                />
              </GoogleMap>
              )}
            </div>
          
            {/* </Col> */}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CollectFromMePopup;

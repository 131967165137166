import React, { useEffect, useState } from "react";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import { Col, Container, Row, Spinner } from "reactstrap";
import "./Awards.css";
import configWeb from "../config.js/configWeb";
import { simpleGetCall } from "../config.js/SetUp";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import FindCarForm2 from "../components/UI/FindCarForm2";
import MetaHelmet from "../components/Helmet/MetaHelmet";
const Awards = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  const { t, i18n } = useTranslation();
const [awardsArray, setAwardsArray] = useState([]);
const [certificateArray, setCertificateArray] = useState([]);
const [loading, setLoading] = useState(true);
const [emiratesArray, setEmiratesArray] = useState([]);
const language = useSelector((state) => state.language.language);
  const getAwardsAndCertificate = () => {
    const url = `${configWeb.GET_AWARD_AND_CERTIFICATE}?lang=${language}&type=award`;
    simpleGetCall(url)
      .then((res) => {
        if (!res?.error) {
          setAwardsArray(res?.data || []); // Ensure it's always an array
        }
      })
      .catch((error) => {
        console.log("Special offers API failed-->", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const emiratesData = () => {
    const url = `${configWeb.GET_EMIRATES}?lang=${language}`;
    simpleGetCall(url)
      .then((res) => {
        setEmiratesArray(res);
      })
      .catch((errr) => {
        console.log("errr", errr);
      })
      .finally(() => {
        // set_loading(false);
      });
  };
  useEffect(()=>{
    emiratesData();
  },[])
  const getAwardsAndCertificate2 = () => {
    const url = `${configWeb.GET_AWARD_AND_CERTIFICATE}?lang=${language}&type=certificate`;
    simpleGetCall(url)
      .then((res) => {
        if (!res?.error) {
          setCertificateArray(res?.data || []); // Ensure it's always an array
          
        }
      })
      .catch((error) => {
        console.log("Special offers API failed-->", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  useEffect(()=>{
    getAwardsAndCertificate();
    getAwardsAndCertificate2();
  },[language])

  return (
<Helmet title="Awards And Certificates">
<MetaHelmet
        title="Awards And Certificates"
        description=""
        keywords="car rental, affordable cars, rent a car"
        canonicalUrl = {`${configWeb.BASE_WEB_URL}/en/awards-and-accreditations`}

        hreflangs={[
          { hreflang: "en", href: `${configWeb.BASE_WEB_URL}/en/awards-and-accreditations` },
          { hreflang: "ar", href: `${configWeb.BASE_WEB_URL}/ae/awards-and-accreditations` },
        ]}

        />

{loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh", width: "100vw" }}
        ><Spinner/></div> ) : <>
 {/* <section className="pb-1 form-section" ref={parentRef}>
        <h1
          className="pt-2 m-0 text-center text-light booking__tital-dream"
          style={{ fontFamily: "Rubik" }}
        >
          {t("BOOK YOUR DREAM CAR NOW")}!
        </h1>
        <Col id="booking-form-container" lg="12" md="12" sm="12">
          <Container>
            <FindCarForm2 emiratesArray={emiratesArray} />
          </Container>
        </Col>
      </section> */}
  <CommonSection title="Awards and recognition" />


  <section>
    <Container>
      <div id="section-title">
        <span id="section-subtitle">
          Autostrad Rent A Car List of Awards
        </span>
      </div>
      <p id="section-description">
        Since day one we have strived to be the UAE’s most customer-centric
        company. We’re honoured to be recognized for the work we do on
        behalf of our customers, employees, partners, and communities. Here
        are some recent awards we’ve received:
      </p>
    </Container>
    
    <div id="awards-container">

      {awardsArray?.length > 0 && awardsArray?.map((award)=>(
  <div id="award-card" key={award.id}>
  <img
    id="award-img"
    src={award.desktop}
    alt={award.alt_text}
  />
  <div id="award-body">
    <h5>{award.title}</h5>
    <div
        title={stripHtmlTags(award?.description)}
        className="award-description"
        dangerouslySetInnerHTML={{
          __html: award?.description,
        }}
      />
  </div>
</div>

      ))}
    
      {/* <div id="award-card">
        <img
          id="award-img"
          src="https://www.autostrad.com/admin_module/uploads/awards_and_recognitions/b8944bda33647a9aa50395d13cedaec1.jpg"
          alt="ITC Appreciation Ceremony AWARD"
        />
        <div id="award-body">
          <h5>ITC Appreciation Ceremony AWARD</h5>
          <p>ITC Appreciation Ceremony AWARD description</p>
        </div>
      </div>

      <div id="award-card">
        <img
          id="award-img"
          src="https://www.autostrad.com/admin_module/uploads/awards_and_recognitions/44ca910a15697edea2fae910d7f37ba8.png"
          alt="Mea AWARD"
        />
        <div id="award-body">
          <h5>Mea AWARD</h5>
          <p>Mea AWARD description</p>
        </div>
      </div> */}
    </div>


    <div id="certificates-section">
      <div id="certificates-title">
        <span id="certificates-subtitle">
          Autostrad Rent A Car List of Certificates
        </span>
      </div>

      {certificateArray?.length > 0 && certificateArray?.map((certificate) => (
  <div id="certificate-container" key={certificate.id}>
    <div id="certificate-image">
      <img
        id="certificate-img"
        src={certificate.desktop}
        alt={certificate.alt_text}
      />
    </div>
    <div id="certificate-details">
      <p id="certificate-heading">
        {certificate.title}
      </p>
      <ul id="certificate-list">
        {certificate.description.split("\n").map((line, index) => {
          const [title, description] = line.split(": ");
          return (
            <li key={index}>
              <strong>{title}:</strong> {description}
            </li>
          );
        })}
      </ul>
    </div>
  </div>
))}




      {/* <div id="certificate-container">
        <div id="certificate-image">
          <img
            id="certificate-img"
            src="https://www.autostrad.com/admin_module/uploads/awards_and_recognitions/1cffdee7faea3591f51b6dcf25de3db2.jpg"
            alt="Certificate"
          />
        </div>
        <div id="certificate-details">
          <p id="certificate-heading">
            AUTOSTRAD RENT A CAR COMPANY SMS – 45001
          </p>
          <ul id="certificate-list">
            <li>
              <strong>Proven Compliance:</strong>
              Our implementation of the ISO 45001:2018 Occupational Health
              and Safety Management System Certification Standard confirms
              that our management practices meet and exceed international
              best practice standards.
            </li>
            <li>
              <strong>Employee Safety and Excellence:</strong>
              This certification underscores our commitment to safeguarding
              our most valuable asset—our employees—while driving business
              excellence.
            </li>
            <li>
              <strong>Best Practices in Risk Management:</strong>
              We adhere to the highest standards in health and safety risk
              management, ensuring robust and effective procedures.
            </li>
            <li>
              <strong>Commitment to Health and Safety:</strong>
              Our system is meticulously designed to reduce the risks of
              work-related ill-health and injuries, providing a safe and
              healthy work environment for all employees.
            </li>
          </ul>
        </div>
      </div> */}
    </div>
  </section>

  </>}
</Helmet>

  );
};
export default Awards;

// reducers/stepperSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    pickupEmirate: null, // Initial state value
  };

  const pickupEmirateSlice = createSlice({
    name: 'pickupEmirate',
    initialState: initialState,
    reducers: {
        setPickupEmirate: (state, action) => {
        state.pickupEmirate = action.payload;
      },
    },
  });

  export const { setPickupEmirate } = pickupEmirateSlice.actions;
export default pickupEmirateSlice.reducer;
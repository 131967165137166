import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  /* Form, */ FormGroup,
  Input,
  Modal,
} from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import { useTranslation } from "react-i18next";
import FindCarForm2 from "../components/UI/FindCarForm2";
import "../styles/contact.css";
import { Button, Form, Spinner } from "react-bootstrap";
import ReactGA from 'react-ga4';
import configWeb from "../config.js/configWeb";
import { simpleGetCall, simplePostCall } from "../config.js/SetUp";
import { useSelector } from "react-redux";
import { notifyError, notifySuccess } from "../SharedComponent/notify";
import ReCAPTCHA from "react-google-recaptcha";
import { colors } from "@mui/material";
import { useLocation } from "react-router-dom";
import MetaHelmet from "../components/Helmet/MetaHelmet";

const socialLinks = [
  {
    url: "#",
    icon: "ri-facebook-line",
  },
  {
    url: "#",
    icon: "ri-instagram-line",
  },
  {
    url: "#",
    icon: "ri-linkedin-line",
  },
  {
    url: "#",
    icon: "ri-twitter-line",
  },
];

const Contact = () => {
  const location = useLocation();
  const { car_id } = location.state || {};
  const { t, i18n } = useTranslation();
  const [enquiryState, setEnquiryState] = useState({
    first_name: "",
    last_name: "",
    phone_code: "971",
    phone_number: "",
    email: "",
    type: "",
    duration: "",
    car_id: car_id,
    emirate_id: 0,
    detail: "",
    captcha: false,
  });
  const language = useSelector((state) => state.language.language);
  const [validated, setValidated] = useState(false);
  const [countries, setCountries] = useState([]);
  const [emiratesArray, setEmiratesArray] = useState([]);
  const [captchaValue, setCaptchaValue] = useState([]);
  const [carListArray, setCarListArray] = useState([]);
  const [enquiry_loading, set_enquiry_loading] = useState(false);
 

  


  const handleChange = (e) => {
    const { name, value } = e.target;
    setEnquiryState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const getCountriesData = () => {
    const url = `${configWeb.GET_COUNTRY_LIST}?lang=${language}&page_size=260`;
    simpleGetCall(url)
      .then((res) => {
        setCountries(res?.data);
      })
      .catch((errr) => {
        console.log("errr", errr);
      })
      .finally(() => {});
  };

  const emiratesData = () => {
    const url = `${configWeb.GET_EMIRATES}?lang=${language}`;
    simpleGetCall(url)
      .then((res) => {
        setEmiratesArray(res);
      })
      .catch((errr) => {
        console.log("errr", errr);
      })
      .finally(() => {
        // set_loading(false);
      });
  };
  useEffect(() => {
    getCountriesData();
    emiratesData();
    getCars();
  }, [language]);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false /* || !enquiryState.captcha */) {
      e.stopPropagation();
      setValidated(true);
    } else {
      // Process form data (e.g., send API request)
      const captcha = await postRecaptcha();
      if (captcha) {
        const enquire = await postEnquireMain();

        if (!enquire) setValidated(true);
        else setValidated(false);
      }
    }
    // setValidated(true);
  };

  const getCars = () => {
    const url = `${configWeb.GET_CAR_LIST}?lang=${language}&page=1&page_size=999999`;
    simpleGetCall(url)
      .then((res) => {
        if (!res?.error) {
          setCarListArray(res?.data);
          // setImagePath(res?.path);
        }
      })
      .catch((error) => {
        console.log("car list api failed-->", error);
      })
      .finally(() => {});
  };

  const token = "";
  const postEnquireMain = () => {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        first_name: enquiryState?.first_name,
        last_name: enquiryState?.last_name,
        phone_code: enquiryState?.phone_code,
        phone_number: enquiryState?.phone_number,
        email: enquiryState?.email,
        type: enquiryState?.type,
        duration: enquiryState?.duration,
        car_id: enquiryState?.car_id * 1,
        emirate_id: enquiryState?.emirate_id * 1,
        detail: enquiryState?.detail,
      });

      const url = configWeb.POST_ENQUIRE;
      set_enquiry_loading(true);
      simplePostCall(url, body)
        .then((res) => {
          if (res?.status === "success") {
            // setUserDetails(res);
            notifySuccess("Enquire Successfully");

            resolve(true);
            setEnquiryState({
              first_name: "",
              last_name: "",
              phone_code: "",
              phone_number: "",
              email: "",
              type: "",
              duration: "",
              car_id: null,
              emirate_id: null,
              detail: "",
              captcha: false,
            });
          } else if (res?.error) {
            notifyError(res?.message[0]);
            resolve(false);
          }
        })
        .catch((error) => {
          console.error("Banner failed:", error);
          notifyError("Something went wrong. Please try again letter.");
          resolve(false);
        })
        .finally(() => {
          set_enquiry_loading(false);
          setCaptchaValue([]);
          window.grecaptcha.reset();
        });
    });
  };
  const postRecaptcha = () => {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        g_recaptcha_response: captchaValue,
      });

      const url = configWeb.POST_CAPTCHA_VERIFY;
      set_enquiry_loading(true);
      simplePostCall(url, body)
        .then((res) => {
          // setUserDetails(res);
          if(res?.success === true){
          resolve(true);
          }else{
            resolve(false);
            notifyError("Wrong Captcha")
          }
          if (res?.error) {
            resolve(false);
          }
        })
        .catch((error) => {
          console.error("Banner failed:", error);
          resolve(false);
        })
        .finally(() => {});
    });
  };
  
  function onChange(value) {
    setCaptchaValue(value);
  }

  return (
    // <Helmet title="Contact">
    <Helmet title="Enquiry">
        <MetaHelmet
        title="Enquiry"
        description=""
        keywords="car rental, affordable cars, rent a car"
        canonicalUrl = {`${configWeb.BASE_WEB_URL}/en/contact`}
        hreflangs={[
          { hreflang: "en", href: `${configWeb.BASE_WEB_URL}/en/contact` },
          { hreflang: "ar", href: `${configWeb.BASE_WEB_URL}/ae/contact` },
        ]}

        />
      {/* <section className="pb-1 form-section" ref={parentRef}>
        <h1
          className="pt-2 m-0 text-center text-light booking__tital-dream"
          style={{ fontFamily: "Rubik" }}
        >
          {t("BOOK YOUR DREAM CAR NOW")}!
        </h1>
        <Col id="booking-form-container" lg="12" md="12" sm="12">
          <Container>
            <FindCarForm2 emiratesArray={emiratesArray} />
          </Container>
        </Col>
      </section> */}
      <CommonSection title="Enquiry" />

      <section>
        <Container>
          {/* <Row>
            <Col lg="7" md="7">
              <h6 className="fw-bold mb-4">Get In Touch</h6>

              <Form>
                <FormGroup className="contact__form">
                  <Input placeholder="Your Name" type="text" />
                </FormGroup>
                <FormGroup className="contact__form">
                  <Input placeholder="Email" type="email" />
                </FormGroup>
                <FormGroup className="contact__form">
                  <textarea
                    rows="5"
                    placeholder="Message"
                    className="textarea"
                  ></textarea>
                </FormGroup>

                <button className=" contact__btn" type="submit">
                  Send Message
                </button>
              </Form>
            </Col>

            <Col lg="5" md="5">
              <div className="contact__info">
                <h6 className="fw-bold">Contact Information</h6>
                <p className="section__description mb-0">
                  Abu Dhabi, UAE
                </p>
                <div className=" d-flex align-items-center gap-2">
                  <h6 className="fs-6 mb-0">Phone:</h6>
                  <p className="section__description mb-0">+88683896366</p>
                </div>

                <div className=" d-flex align-items-center gap-2">
                  <h6 className="mb-0 fs-6">Email:</h6>
                  <p className="section__description mb-0">example@gmail.com</p>
                </div>

                <h6 className="fw-bold mt-4">Follow Us</h6>

                <div className=" d-flex align-items-center gap-4 mt-3">
                  {socialLinks.map((item, index) => (
                    <Link
                      to={item.url}
                      key={index}
                      className="social__link-icon"
                    >
                      <i class={item.icon}></i>
                    </Link>
                  ))}
                </div>
              </div>
            </Col>
          </Row> */}
          <p className="text-center">
            If you like to book on your own, please book directly now or put in
            your request for a quotation{" "}
          </p>
          <div className="col-10 mx-auto border border-primary rounded p-2 p-lg-5">
            <div className="mb-3">
              {" "}
              <h2 className="section__title">QUOTATION REQUEST</h2>{" "}
            </div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row className="">
                <Col md={6} xs={12} className="mb-3">
                  <Form.Group controlId="first_name">
                    <Form.Label>
                      First Name <span className="text-danger">&#8727;</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="first_name"
                      value={enquiryState.first_name}
                      onChange={handleChange}
                      placeholder="Enter your first name"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter your first name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={6} xs={12} className="mb-3">
                  <Form.Group controlId="last_name">
                    <Form.Label>
                      Last Name <span className="text-danger">&#8727;</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="last_name"
                      value={enquiryState.last_name}
                      onChange={handleChange}
                      placeholder="Enter your last name"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter your last name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={6} xs={12} className="mb-3">
                  <Form.Group controlId="phone_code">
                    <Form.Label>
                      Phone Code <span className="text-danger">&#8727;</span>
                    </Form.Label>

                    <Form.Select
                      name="phone_code"
                      value={enquiryState.phone_code}
                      onChange={handleChange}
                      placeholder="Enter phone code"
                      required
                    >
                      <option value="">Code</option>
                      <option value="971">
                                  {
                                    countries?.find(
                                      (item) => item.phone_code === "971"
                                    )?.name
                                  }{" "}
                                  971{" "}
                                </option>
                      {countries?.map((country) => (
                        <option key={country.id} value={country.phone_code}>
                          {country.name} {country.phone_code}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please enter your phone code.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={6} xs={12} className="mb-3">
                  <Form.Group controlId="phone_number">
                    <Form.Label>
                      Phone Number <span className="text-danger">&#8727;</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="phone_number"
                      value={enquiryState.phone_number}
                      onChange={handleChange}
                      placeholder="Enter phone number"
                      maxLength={10}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid 10-digit phone number.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={6} xs={12} className="mb-3">
                  <Form.Group controlId="email">
                    <Form.Label>
                      Email <span className="text-danger">&#8727;</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="email"
                      name="email"
                      value={enquiryState.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid email.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={6} xs={12} className="mb-3">
                  <Form.Group controlId="emirate_id">
                    <Form.Label>
                      Emirate <span className="text-danger">&#8727;</span>
                    </Form.Label>

                    <Form.Select
                      name="emirate_id"
                      value={enquiryState.emirate_id}
                      onChange={handleChange}
                      placeholder="Enter emirate ID"
                      required
                    >
                      <option value="">Select Emirates</option>
                      {emiratesArray?.map((emirate) => (
                        <option key={emirate.id} value={emirate.id}>
                          {emirate.name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please Select an emirate ID.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={6} xs={12} className="mb-3">
                  <Form.Group controlId="type">
                    <Form.Label>
                      Enquiry Type (optional) {/* <span className="text-danger">&#8727;</span> */}
                    </Form.Label>
                    <Form.Control
                      // required
                      as="select"
                      name="type"
                      value={enquiryState.type}
                      onChange={handleChange}
                    >
                      <option value="">Select type</option>
                      <option value="individual">Individual</option>
                      <option value="corporate">Corporate</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please select a type.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={6} xs={12} className="mb-3">
                  <Form.Group controlId="duration">
                    <Form.Label>
                      Duration <span className="text-danger">&#8727;</span>
                    </Form.Label>
                    <Form.Control
                      required
                      as="select"
                      name="duration"
                      value={enquiryState.duration}
                      onChange={handleChange}
                    >
                      <option value="">Select duration</option>
                      <option value="daily">Daily</option>
                      <option value="weekly">Weekly</option>
                      <option value="monthly">Monthly</option>
                      <option value="yearly">Yearly</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please select a duration.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={6} xs={12} className="mb-3">
                  <Form.Group controlId="car_id">
                    <Form.Label>
                      Select the car that you want to enquire for (optional)
                    </Form.Label>
                    {/* <Form.Control
                      required
                      type="number"
                      name="car_id"
                      value={enquiryState.car_id}
                      onChange={handleChange}
                      placeholder="Enter car ID"
                    /> */}

                    <Form.Select
                      name="car_id"
                      value={enquiryState.car_id}
                      onChange={handleChange}
                      placeholder="Enter car ID"
                    >
                      <option value="">Select...</option>
                      {carListArray?.map((car) => (
                        <option key={car.id} value={car.name}>
                          {car.name}
                        </option>
                      ))}
                    </Form.Select>
                    {/* <Form.Control.Feedback type="invalid">
                      Please Select a car.
                    </Form.Control.Feedback> */}
                  </Form.Group>
                </Col>

                <Col xs={12} className="mb-3">
                  <Form.Group controlId="detail">
                    <Form.Label>
                      Your Remarks <span className="text-danger">&#8727;</span>
                    </Form.Label>
                    <Form.Control
                      required
                      as="textarea"
                      name="detail"
                      value={enquiryState.detail}
                      onChange={handleChange}
                      placeholder="Enter details"
                      rows={3}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide some details.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col xs={12} className="mt-3 mb-3">
                  {/* <Form.Group controlId="captcha">
            <Form.Check
              required
              type="checkbox"
              name="captcha"
              label="I'm not a robot"
              checked={enquiryState.captcha}
              onChange={(e) =>
                setEnquiryState((prev) => ({
                  ...prev,
                  captcha: e.target.checked,
                }))
              }
              feedback="Please verify that you're not a robot."
              feedbackType="invalid"
            />
          </Form.Group> */}
                  <div className="captcha-col">
                    <ReCAPTCHA
                      onChange={onChange}
                      // sitekey="6Lc7SOseAAAAAHBh-joEdg_fmL4wiJXVW8h3lUIU"
                      sitekey="6Lfp_nwpAAAAAOR7LqsSxWWZtt3vrTfroNRFqxlp"
                      // sitekey="6Lcs8HwpAAAAADfOTHdgj-6Ljv-W1dWKOWBykalo"
                    />
                  </div>
                </Col>

                <Col xs={12} className="mt-3 ">
                  <Button
                    type="submit"
                    variant="#1E5486"
                    style={{ backgroundColor: "#4078ab", color: "white" }}
                    className="w-100 btn btn-secondry"
                    disabled={enquiry_loading ? true : false}
                  >
                    {enquiry_loading ? <Spinner /> : "Submit"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Container>
      </section>
    </Helmet>
  );
};

export default Contact;

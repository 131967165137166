
import CommonSection from "./CommonSection";

import "../../styles/contact.css"

import React, { useEffect, useState } from "react";
import Helmet from "../Helmet/Helmet";
import carlogo from "../../assets/new-logo/logo.png";
import "../../styles/corporate.css";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import configWeb from "../../config.js/configWeb";
import { simpleGetCall } from "../../config.js/SetUp";
import { Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import FindCarForm2 from "./FindCarForm2";
import MetaHelmet from "../Helmet/MetaHelmet";
const TermsCondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const language = useSelector((state) => state.language.language);
  const { t, i18n } = useTranslation();
  const [corporateLeasing, setCorporateLeasing] =useState([]);
  const [loading, setLoading]= useState(true);
  const getCorporateLeasing = () => {
    const url = `${configWeb.GET_PRIVACY_POLICY('terms_and_conditions')}?lang=${language}`;
    simpleGetCall(url)
      .then((res) => {
        if (!res?.error) {
          setCorporateLeasing(res || []); // Ensure it's always an array
        }
      })
      .catch((error) => {
        console.log("Special offers API failed-->", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(()=>{
getCorporateLeasing()
  },[language]);

  const generateStructuredData = () => {
    return {
      "@context": "https://schema.org",
      "@type": "Article",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": window.location.href,
      },
      headline: corporateLeasing?.title,
      description: corporateLeasing?.seo_meta_description,
      image: corporateLeasing?.image,
      author: {
        "@type": "Organization",
        name: "Autostrad",
      },
      publisher: {
        "@type": "Organization",
        name: "Autostrad",
        logo: {
          "@type": "ImageObject",
          url: carlogo,
        },
      },
      datePublished: "2024-10-11",
      dateModified: "2024-10-12",
    };
  };
  return (
    <div>
       {loading ?   <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh", width: "100vw" }}
        ><Spinner/></div>  : <>
         {/* <section className="pb-1 form-section">
        <h1
          className="pt-2 m-0 text-center text-light booking__tital-dream"
          style={{ fontFamily: "Rubik" }}
        >
          {t("BOOK YOUR DREAM CAR NOW")}!
        </h1>
        <Col id="booking-form-container" lg="12" md="12" sm="12">
          <Container>
            <FindCarForm2 emiratesArray={emiratesArray} />
          </Container>
        </Col>
      </section> */}
      <CommonSection title="TERMS & CONDITIONS" />
      <MetaHelmet
        title={corporateLeasing?.title}
        description={corporateLeasing?.seo_meta_description}
        keywords={corporateLeasing?.seo_meta_tags || "car rental, affordable cars, rent a car"}
        ogTitle={corporateLeasing?.title}
        ogDescription={corporateLeasing?.seo_meta_description}
        ogImage={corporateLeasing?.image}
        ogUrl= {window.location.href}
        twitterTitle={corporateLeasing?.title}
        twitterDescription={corporateLeasing?.seo_meta_description}
        twitterImage={corporateLeasing?.image}
        twitterCard="summary_large_image"
        // twitterSite="@YourTwitterHandle"
        // twitterCreator="@AuthorTwitterHandle"
        canonicalUrl ={`${configWeb.BASE_WEB_URL}/en/TermsCondition`}
        hreflangs={[
          { hreflang: "en", href: `${configWeb.BASE_WEB_URL}/en/TermsCondition` },
          { hreflang: "ar", href: `${configWeb.BASE_WEB_URL}/ae/TermsCondition` },
        ]}

      />
      <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(generateStructuredData()),
            }}
          />
      <section className="p-2">
        <Container>
          {/* <div className="inner_section_main">
            <div className="blog_section padding-70">
              <div className="container">
                <div className="privacy_policy_section">
                  <p>
                    <span >
                      <span >
                        This reservation confirmation is subject to the
                        following terms and conditions. The customer
                        acknowledges having read and understood these Terms and
                        agrees to be bound by them.
                      </span>
                    </span>
                  </p>
                  <p>
                    <span >
                      <span >
                        <strong>General Terms &amp; Conditions</strong>
                      </span>
                    </span>
                  </p>
                  <ul>
                    <li>
                      <span >
                        <span >
                          <strong>Car Category:&nbsp;</strong>Specific make or
                          model preferences are subject to availability at the
                          time of rental, so only a car category can be
                          confirmed.
                        </span>
                      </span>
                    </li>
                    <li>
                      <span >
                        <span >
                          <strong>Rental Length:</strong>&nbsp;Minimum rental
                          length is 24 hours. Rental days are based on 24-hour
                          periods commencing at the time of pick up. Additional
                          day charges will apply if the Autostrad&nbsp;Rent A
                          Car vehicle is kept longer than specified (additional
                          days begin after 1 hour grace period and will be
                          billed at the available public rate)
                        </span>
                      </span>
                    </li>
                    <li>
                      <span >
                        <span >
                          <strong>Fuel</strong>:
                        </span>
                      </span>
                      <span
                        
                      >
                        &nbsp;&nbsp;All our vehicles are delivered with a full
                        tank fuel or partial tank fuel and should be returned at
                        the same level. Refueling and service charges at 20% of
                        fuel cost are applied in case cars are not returned in
                        same level.
                      </span>
                    </li>
                    <li>
                      <span >
                        <span >
                          <strong>Mileage:</strong>&nbsp;Unlimited Mileage is on
                          Daily &amp; Weekly rental. Customers who have a
                          Monthly Rental Agreement (MRA) and Long Term Agreement
                          with Autostrad&nbsp;can drive 5000 km per month
                          (depending on the vehicle)
                        </span>
                      </span>
                    </li>
                    <li>
                      <span >
                        <span >
                          <strong>Additional Mileage:</strong>&nbsp;Any accrued
                          mileage beyond the limit agreed would be charged at 50
                          Fils to 1 AED (Depending on the vehicle).
                        </span>
                      </span>
                    </li>
                    <li>
                      <span >
                        <span >
                          <strong>Age</strong>: Renters must be&nbsp;
                          <strong>21 years old</strong>&nbsp;for the rental of
                          cars falling under group A to group E. And from car
                          group F to Car group LC, the renter must have
                          completed at least 25 years of age.
                        </span>
                      </span>
                    </li>
                  </ul>
                  <p>
                    <span >
                      <span >
                        <strong>Mandatory Documents Required</strong>:
                      </span>
                    </span>
                  </p>
                  <ul>
                    <li>
                      <span >
                        <span >
                          Valid Passport copy (with visa page) or Emirates ID
                          and Driving License.
                        </span>
                      </span>
                    </li>
                    <li>
                      <span >
                        <span >
                          <strong>Valid Credit Card</strong>: A valid credit
                          card must be presented to the Autostrad&nbsp;Rent A
                          Car rental counter at the time of pickup to cover any
                          additional anticipated charges that are not covered by
                          the prepayment amount.
                        </span>
                      </span>
                    </li>
                    <li>
                      <span >
                        <span >
                          <strong>
                            <em>
                              A credit card is required as a guarantee against
                              payment for the traffic fines/ Salik/ Darb any
                              other traffic violation/ damage to the vehicle and
                              all extras other than the rental amount
                            </em>
                            .
                          </strong>
                        </span>
                      </span>
                    </li>
                    <li>
                      <span >
                        <span >
                          The customer can also use a credit card of a friend or
                          family as a guarantee, however that person needs to
                          accompany the customer at the time of rental.
                        </span>
                      </span>
                    </li>
                    <li>
                      <span >
                        <span >
                          An authorization will be obtained at the time of
                          rental. Cash deposits are not accepted.
                        </span>
                      </span>
                    </li>
                    <li>
                      <span >
                        <span >
                          Valid Driving&nbsp;License
                        </span>
                      </span>
                    </li>
                  </ul>
                  <p>
                    <span >
                      <span >
                        <strong>Payment Method:</strong>
                      </span>
                    </span>
                  </p>
                  <ul>
                    <li>
                      <span >
                        <span >
                          “We accept payments online using Visa and MasterCard
                          credit/debit card in AED. “All credit/debit cards
                          details and personally identifiable information will
                          NOT be stored, sold, shared, rented, or leased to any
                          third parties”.
                        </span>
                      </span>
                    </li>
                    <li>
                      <span >
                        <span >
                          Payment can be made by cash for extras (GPS/Baby
                          Seat/Additional Driver) at the Branch.
                        </span>
                      </span>
                    </li>
                  </ul>
                  <p>
                    <span >
                      <span >
                        <strong>Driving License:&nbsp;</strong>International
                        Customers are allowed to drive with their International
                        driving license. The&nbsp;International
                        driving&nbsp;license or International Drivers Permit
                        must be accompanied by the original domestic license of
                        the driver.
                      </span>
                    </span>
                  </p>
                  <ul>
                    <li>
                      <span >
                        <span >
                          <strong>
                            FOR UAE RESIDENTS: Only UAE license is allowed which
                            must be a minimum of 1 year old from the date of
                            issue
                          </strong>
                          . If not, the residents can provide their home country
                          license as a supporting document to rent a car
                          (subject to the approval of insurance company).
                        </span>
                      </span>
                    </li>
                    <li>
                      <span >
                        <span >
                          <strong>FOR TOURISTS/ VISITORS:&nbsp;</strong>Citizens
                          of the below countries are allowed to drive with
                          licenses issued from their home countries during their
                          visit to the UAE:
                          <br />
                          Germany, Italy, Switzerland, Poland, Finland, Spain,
                          Holland, Greece, Sweden, Romania, Belgium, Ireland,
                          Turkey, Denmark, Austria, France, UK, Norway, US,
                          Canada, Australia, Singapore, South Korea, New
                          Zealand, Hong Kong, and South Africa. Nationals from
                          Greece, Turkey, Canada, Japan, and South Korea need to
                          obtain the legal translation of driving licenses from
                          their home countries attested from their embassies or
                          consulates in order to be able to drive in the UAE
                          with such permits after their arrival in the country
                          on a visit visa.
                        </span>
                      </span>
                    </li>
                  </ul>
                  <p>
                    <span >
                      <span >
                        <strong>Inter Emirates</strong>&nbsp;
                        <strong>Charges&nbsp;(Pickup-Return):&nbsp;</strong>
                        Inter-emirate fees are charged (per trip) if the Emirate
                        where the vehicle is to be picked up is different from
                        the Emirate where it will be returned to.&nbsp;Please
                        call your respective branch to check the specific rates
                        depending on the Emirates you booked.
                      </span>
                    </span>
                  </p>
                  <ul>
                    <li>
                      <span >
                        <span >
                          Rates range from&nbsp;<strong>AED 50 – 200</strong>
                          &nbsp;per trip and will be charged depending on which
                          Emirates is booked.
                        </span>
                      </span>
                    </li>
                    <li>
                      <span >
                        <span >
                          Below are the Emirates where we have branches:
                          <br />
                          · Abu Dhabi, Al Ain, and Ruwais
                          <br />· Dubai &amp; Northern Emirates: Dubai, Sharjah,
                          Fujairah &amp; Ras Al Khaimah
                        </span>
                      </span>
                    </li>
                  </ul>
                  <p>
                    <span >
                      <span >
                        <strong>INSURANCE</strong>&nbsp;Note: A valid police
                        report is mandatory. Please check with the agent at the
                        rental branch for more information.
                      </span>
                    </span>
                  </p>
                  <p>
                    <span >
                      <span >
                        <strong>CDW (COLLISION DAMAGE WAIVER)</strong>
                        <br />
                        Renters can purchase a Collision Damage Waiver for
                        additional coverage and fee on top of the car rental
                        amount with the exception of the Luxury vehicle group.
                        With the purchase of CDW, your Damage Liability Fee is
                        reduced to the amount&nbsp;
                        <strong>AED500 – AED1000</strong>&nbsp;depending on the
                        vehicle group:
                      </span>
                    </span>
                  </p>
                  <ul>
                    <li>
                      <span >
                        <span >
                          AED 25 + VAT per day - For compact (4-5 door,
                          automatic) vehicle groups.
                        </span>
                      </span>
                    </li>
                    <li>
                      <span >
                        <span >
                          AED 30 + VAT per day - For economy SUV and standard
                          (4-5 door) vehicle groups.
                        </span>
                      </span>
                    </li>
                    <li>
                      <span >
                        <span >
                          For economy (4-5 door, automatic) vehicle groups, the
                          charge varies from AED 25 + VAT per day to AED 40 +
                          VAT per day.
                        </span>
                      </span>
                    </li>
                    <li>
                      <span >
                        <span >
                          AED 50 + VAT per day - For intermediate (4-5 door),
                          intermediate SUV, and premium Wagon groups.
                        </span>
                      </span>
                    </li>
                  </ul>
                  <p>
                    <span >
                      <span >
                        <strong>SUPER CDW</strong>
                        <br />
                        Additional purchase of Super CDW, with the exception of
                        Luxury vehicle group,&nbsp;
                        <strong>
                          <em>reduces the customer’s liability to ZERO</em>
                        </strong>
                        . Renters will not be paying anything to Autostrad for
                        damage or repairs. NOTE: A valid police report is
                        mandatory. The insurance will not be valid if the police
                        report is not provided.
                      </span>
                    </span>
                  </p>
                  <ul>
                    <li>
                      <span >
                        <span >
                          AED 40 + VAT per day - For compact (4-5 door,
                          automatic) vehicle groups.
                        </span>
                      </span>
                    </li>
                    <li>
                      <span >
                        <span >
                          AED 50 + VAT per day - For economy SUV and standard
                          (4-5 door) vehicle groups.
                        </span>
                      </span>
                    </li>
                    <li>
                      <span >
                        <span >
                          For economy (4-5 door, automatic) vehicle groups, the
                          charge varies from AED 40 + VAT per day to AED 70 +
                          VAT per day.
                        </span>
                      </span>
                    </li>
                    <li>
                      <span >
                        <span >
                          AED 100 + VAT per day - For intermediate (4-5 door),
                          intermediate SUV, and premium Wagon groups.
                        </span>
                      </span>
                    </li>
                  </ul>
                  <p>
                    <span >
                      <span >
                        Above CDW/ SCDW options are NOT available for certain
                        vehicle groups. Please&nbsp;confirm it at the branch.
                        <br />
                        The above-given options may not cover Glass, Tyres, the
                        under-body of the car, and other accessories.
                      </span>
                    </span>
                  </p>
                  <p>
                    <span >
                      <span >
                        <strong>INSURANCE EXCESS</strong>
                        <br />
                        <strong>
                          <em>If CDW/ SCDW is NOT purchased,</em>
                        </strong>
                        &nbsp;the customer will be responsible for the
                        settlement or payment of the insurance excess or
                        deductible amount which is listed on the Vehicle Hire
                        Agreement, in the event of any damage or loss [including
                        fire and theft] caused to the vehicle, provided it is
                        used, operated or driven in conformity with the vehicle
                        agreement. NOTE: A valid police report is mandatory.
                      </span>
                    </span>
                  </p>
                  <p>
                    <span >
                      <span >
                        The following excess amounts are applicable if&nbsp;
                        <strong>CDW/ SCDW</strong>&nbsp;is not availed:
                        <br />
                        The maximum excess amount range from&nbsp;
                        <strong>AED 2000 + VAT&nbsp;</strong>upto
                        <strong>
                          &nbsp;AED 5000 + VAT depending on the vehicle group.
                        </strong>
                      </span>
                    </span>
                  </p>
                  <p>
                    <span >
                      <span >
                        <strong>Note</strong>: A valid police report is
                        mandatory. Please check with the agent at the rental
                        branch for more information.
                      </span>
                    </span>
                  </p>
                  <ul>
                    <li>
                      <span >
                        <span >
                          <strong>&nbsp;Theft Waiver -&nbsp;</strong>
                        </span>
                      </span>
                      <span >
                        <span >
                          reduces the client's financial responsibility for loss
                          or theft to the vehicle to the sum of the deductible.
                        </span>
                      </span>
                    </li>
                    <li>
                      <span >
                        <span >
                          <strong>
                            Third-Party Liability Insurance (TPL)&nbsp;
                          </strong>
                          is included in your rental amount.
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>
           

              </div>
            </div>
          </div> */}
          <section className='px-1 px-lg-5'
              dangerouslySetInnerHTML={{
                __html: corporateLeasing?.content,
              }}
            />

        </Container>
      </section>
      </>}
    </div>
  );
};

export default TermsCondition;

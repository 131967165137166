import React, { useEffect } from 'react';
import BlogList from './BlogList';
import { Col, Container, Row } from 'react-bootstrap';
import Helmet from '../Helmet/Helmet';
import CommonSection from './CommonSection';

const Allblog = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
    <Helmet title="ALL BLOGS">

        <div>
        <CommonSection title="ALL BLOGS" />
        <section className="p-0">
        <Container>
          <div className="">
            <Row>
             

              <BlogList />
            </Row>
          </div>
        </Container>
      </section>
        </div>
        </Helmet>
    );
};

export default Allblog;
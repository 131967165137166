import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
 } from "reactstrap";
import Helmet from "../../../components/Helmet/Helmet";
import CommonSection from "../../../components/UI/CommonSection";
import { useTranslation } from "react-i18next";
import "../../../styles/contact.css";
import { Button, Form, Spinner } from "react-bootstrap";
import configWeb from "../../../config.js/configWeb";
import { simpleGetCall, simplePostCall } from "../../../config.js/SetUp";
import { useSelector } from "react-redux";
import { notifyError, notifySuccess } from "../../../SharedComponent/notify";
import ReCAPTCHA from "react-google-recaptcha";
import { useLocation } from "react-router-dom";
import MetaHelmet from "../../../components/Helmet/MetaHelmet";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const ChauffeurEnquiry = () => {
  const location = useLocation();
  const query = useQuery();
  const value = query.get("value") ||"";
  const label = query.get("label") || "";
  const { car_id } = value;
  const { t, i18n } = useTranslation();
  const [enquiryState, setEnquiryState] = useState({
    first_name: "",
    last_name: "",
    phone_code: "971",
    phone_number: "",
    email: "",
    car_id: value,
    detail: "",
    captcha: false,
  });
  const [full_phone_number, set_full_phone_number] = useState("");
  const [country_code, set_country_code] = useState("");
  const [phone_number, set_phone_number] = useState("");
  
  const chauffeurCars = [
    { value: "Lexus ES300h", label: "Lexus ES300h" },
    { value: "Genesis G80", label: "Genesis G80" },
    { value: "BMW 730i", label: "BMW 730i" },
    { value: "BMW 735i", label: "BMW 735i" },
    { value: "Exeed (New Addition)", label: "Exeed (New Addition)" },
    { value: "Toyota Highlander GXR (7-Seaters)", label: "Toyota Highlander GXR (7-Seaters)" },
    { value: "Citroën (7-Seaters)", label: "Citroën (7-Seaters)" }
  ] 
  const language = useSelector((state) => state.language.language);
  const [validated, setValidated] = useState(false);
  const [captchaValue, setCaptchaValue] = useState([]);
  const [enquiry_loading, set_enquiry_loading] = useState(false);
 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEnquiryState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false /* || !enquiryState.captcha */) {
      e.stopPropagation();
      setValidated(true);
    } else {
      
      const captcha = await postRecaptcha();
      if (captcha) {
        const enquire = await postEnquireMain();

        if (!enquire) setValidated(true);
        else setValidated(false);
      }
    }
  };

 
  const postEnquireMain = () => {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        name: enquiryState?.first_name,
        phone_code: country_code,
        phone_number: phone_number,
        email: enquiryState?.email,
        car: enquiryState?.car_id,
        details: enquiryState?.detail,
      });

      const url = configWeb.POST_CHAUFFEUR_ENQUIRE;
      set_enquiry_loading(true);
      simplePostCall(url, body)
        .then((res) => {
          if (res?.status === "success") {
            notifySuccess("Enquire Successfully");

            resolve(true);
            setEnquiryState({
              first_name: "",
              last_name: "",
              phone_code: "",
              phone_number: "",
              email: "",
              car_id: "",
              detail: "",
              captcha: false,
            });
            set_full_phone_number("");
            set_phone_number("");
            set_country_code("");

          } else if (res?.error) {
            notifyError(res?.message[0]);
            resolve(false);
          }
        })
        .catch((error) => {
          console.error("Banner failed:", error);
          notifyError("Something went wrong. Please try again letter.");
          resolve(false);
        })
        .finally(() => {
          set_enquiry_loading(false);
          setCaptchaValue([]);
          window.grecaptcha.reset();
        });
    });
  };
  const postRecaptcha = () => {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        g_recaptcha_response: captchaValue,
      });

      const url = configWeb.POST_CAPTCHA_VERIFY;
      set_enquiry_loading(true);
      simplePostCall(url, body)
        .then((res) => {
          if(res?.success === true){
          resolve(true);
          }else{
            resolve(false);
            notifyError("Wrong Captcha")
          }
          if (res?.error) {
            resolve(false);
          }
        })
        .catch((error) => {
          console.error("Banner failed:", error);
          resolve(false);
        })
        .finally(() => {});
    });
  };
  
  function onChange(value) {
    setCaptchaValue(value);
  }


  const handlePhoneChange = (value, data) => {
    // Extract country code from data
    const countryDialCode = `${data.dialCode}`;
    const numberWithoutCode = value.replace(countryDialCode, ""); // Remove country code from full number
    set_full_phone_number(value);
    set_country_code(countryDialCode);
    set_phone_number(numberWithoutCode);
  };

  return (
    <Helmet title="Enquiry">
        <MetaHelmet
        title="Chauffeur Enquiry"
        description=""
        keywords="car rental, affordable cars, rent a car"
        canonicalUrl = {`${configWeb.BASE_WEB_URL}/en/chauffeur-services`}
        hreflangs={[
          { hreflang: "en", href: `${configWeb.BASE_WEB_URL}/en/chauffeur-services` },
          { hreflang: "ar", href: `${configWeb.BASE_WEB_URL}/ae/chauffeur-services` },
        ]}

        />
      <CommonSection title="Chauffeur Enquiry" />

      <section>
        <Container>
          
          <div className="col-10 mx-auto border border-primary rounded p-2 p-lg-5">
            <div className="mb-3">
              {" "}
              <h2 className="section__title">CHAUFFEUR ENQUIRY</h2>{" "}
            </div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row className="">
                <Col md={6} xs={12} className="mb-3">
                  <Form.Group controlId="first_name">
                    <Form.Label>
                      Full Name <span className="text-danger">&#8727;</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="first_name"
                      value={enquiryState.first_name}
                      onChange={handleChange}
                      placeholder="Enter your first name"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter your name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>


                <Col md={6} xs={12} className="mb-3">
                  <Form.Group controlId="phone_code">
                    <Form.Label>
                      Phone Number <span className="text-danger">&#8727;</span>
                    </Form.Label>
                    <PhoneInput
                      inputProps={{
                        name: 'phone_number',
                        required: true,
                        autoFocus: true
                      }}
                      autoFormat
                      placeholder='Enter phone number'
                      country={'ae'}
                      value={full_phone_number}
                      enableSearch={true}
                      // onChange={(value)=>set_phone_number(value )}
                      onChange={handlePhoneChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} xs={12} className="mb-3">
                  <Form.Group controlId="email">
                    <Form.Label>
                      Email <span className="text-danger">&#8727;</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="email"
                      name="email"
                      value={enquiryState.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid email.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

              

                <Col md={6} xs={12} className="mb-3">
                  <Form.Group controlId="car_id">
                    <Form.Label>
                     Car 
                    </Form.Label>
                  
                    <Form.Select
                      name="car_id"
                      value={enquiryState.car_id}
                      onChange={handleChange}
                      placeholder="Enter car ID"
                      
                    >
                      <option value="">Select...</option>
                      {chauffeurCars?.map((car) => (
                        <option key={car.value} value={car.value}>
                          {car.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col xs={12} className="mb-3">
                  <Form.Group controlId="detail">
                    <Form.Label>
                      Your Details
                    </Form.Label>
                    <Form.Control
                     
                      as="textarea"
                      name="detail"
                      value={enquiryState.detail}
                      onChange={handleChange}
                      placeholder="Enter details"
                      rows={3}
                    />
                  </Form.Group>
                </Col>

                <Col xs={12} className="mt-3 mb-3">
                 
                  <div className="captcha-col">
                    <ReCAPTCHA
                      onChange={onChange}
                      sitekey="6Lfp_nwpAAAAAOR7LqsSxWWZtt3vrTfroNRFqxlp"
                    />
                  </div>
                </Col>

                <Col xs={12} className="mt-3 ">
                  <Button
                    type="submit"
                    variant="#1E5486"
                    style={{ backgroundColor: "#4078ab", color: "white" }}
                    className="w-100 btn btn-secondry"
                    disabled={enquiry_loading ? true : false}
                  >
                    {enquiry_loading ? <Spinner /> : "Submit"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Container>
      </section>
     </Helmet>
  );
};

export default ChauffeurEnquiry;

import React, { useEffect, useState } from "react";
import { Button, Card, Col, Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import "../../styles/car_and_details.css";
import arrowIcon from "../../assets/all-images/cars-img/cardArrow.svg";
import { carData } from "../../assets/data/carData";
import { useSelector, useDispatch } from "react-redux";
import "./CarsAndDetails.css";
import CarDetails from "../../pages/CarDetails";
function CarsAndDetails({
  item,
  isChecked,
  onCheckboxChange,
  isSelected,
  saved_car_flag,
  setSelectedPaymentOption,
  goToBooking,
}) {
  const values = [true, "sm-down", "md-down", "lg-down", "xl-down", "xxl-down"];
  const [fullscreen, setFullscreen] = useState(true);
  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setModalShow(true);
  }
  const { booking_number } = useParams();
  const [payButtonSelected, setPayButtonSelected] = useState(null);
  const [modalShow, setModalShow] = React.useState(false);
  const editUserBookingObject = useSelector(
    (state) => state.editUserBookingObject.editUserBookingObject
  );

  const requestBody_pickup = useSelector(
    (state) => state.requestBody_pickup.requestBody_pickup
  );

  const handleChange = () => {
    goToBooking();
  };
  const [isPaymentOptionSet, setIsPaymentOptionSet] = useState(false);
  const [isCheckboxChanged, setIsCheckboxChanged] = useState(false);

  const handlePaymentOptionChange = (option) => {
    setIsPaymentOptionSet(false);
    setIsCheckboxChanged(false);
    setSelectedPaymentOption(option);
    setPayButtonSelected(option);
    setIsPaymentOptionSet(true);
    onCheckboxChange(item.id);
    setIsCheckboxChanged(true);
  };

  const [car_id, set_car_id] = useState(null);
  const handleCarDetailsModal = (id) => {
    set_car_id(id);
    setModalShow(true);
  };
  return (
    <>
      <div
        className="col-md-4 col-lg-3 col-sm-6 col-xs-12 mb-5 "
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <label
          className={`w-100 ${
            saved_car_flag === "yes" ? "saved_car_highlighter" : ""
          }`}
        >
          {/* <input
            type="radio"
            name="product"
            className="card-input-element"
            checked={isChecked}
         
            onChange={handleChange}
          /> */}

          <div
            className={`cardetails__item w-100 glassBox ${
              isSelected ? "selected" : ""
            }`}
          >
            <div className="img_parent cardetails__img--  glassBox__imgBox--">
              <img
                src={item?.image}
                alt={item?.car_name}
                className="img_child img-fluid--  glassBox__imgBox--"
              />
            </div>

            <div className="cardetails__item-content mt-4">
           
              <h4 className="section__title ">{item?.car_name}</h4>
              <div>
              <Card.Text className={`${item.featured === 1 ? "car__details__compare m-0 hide-car-features" : "car__details__compare m-0" }`}>
                or Similar | {item?.category}
              </Card.Text>
              <hr />

              <div className="cardetails__item-info d-flex align-items-center justify-content-between mt-3 mb-4">
                <span className=" d-flex align-items-center gap-1 ">
                  <img src={carData?.[0]?.icon1} alt="" />

                  {item?.transmission}
                </span>{" "}
                |
                <span className=" d-flex align-items-center gap-1">
                  <img src={carData?.[0]?.icon2} alt="" />

                  {item?.passengers}
                </span>{" "}
                |
                <span className=" d-flex align-items-center gap-1">
                  <img src={carData?.[0]?.icon3} alt="" />
                  {item?.fuel_type}
                </span>{" "}
                |
                <span className=" d-flex align-items-center gap-1">
                  <img
                    src={carData?.[0]?.icon05}
                    alt=""
                    height="20px"
                    width="20px"
                  />
                  4
                </span>
                |
                <span className=" d-flex align-items-center gap-1">
                  <img
                    src={carData?.[0]?.icon4}
                    alt=""
                    height="20px"
                    width="20px"
                  />
                  AC
                </span>
              </div>
              </div>
              <hr />
              <div className="cardetails__item-info d-flex align-items-center justify-content-center mt-3 mb-">
                {!booking_number &&
                  (requestBody_pickup?.booking_type === "daily" ? (
                    <div className="w-100 h-100 d-flex justify-content-between pay-button-parent">
                      <div
                        className={`d-flex align-items-center justify-content-center gap-1 unselected-pay-button pay-button-child ${
                          payButtonSelected === "pay_now" && isChecked
                            ? "selected-pay-button"
                            : ""
                        } `}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handlePaymentOptionChange("pay_now");
                        }}
                      >
                        <span> AED {Number(item?.pay_now)} /Pay Now</span>
                      </div>
                      <div
                        className={`d-flex align-items-center justify-content-center gap-1 unselected-pay-button pay-button-child ${
                          payButtonSelected === "pay_later" && isChecked
                            ? "selected-pay-button"
                            : ""
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handlePaymentOptionChange("pay_later");
                        }}
                      >
                        <span className="no-wrap">
                          AED {Number(item?.pay_later).toFixed(2)} /Pay Later
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="w-100 h-100 d-flex justify-content-between pay-button-parent">
                      <div
                        className={`d-flex align-items-center justify-content-center gap-1 unselected-pay-button pay-button-child ${
                          payButtonSelected === "pay_monthly_now" && isChecked
                            ? "selected-pay-button"
                            : ""
                        } `}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handlePaymentOptionChange("pay_monthly_now");
                        }}
                      >
                        <span>
                          {" "}
                          AED {Number(item?.pay_now)} /Pay Now
                        </span>
                      </div>
                      <div
                        className={`d-flex align-items-center justify-content-center gap-1 unselected-pay-button pay-button-child ${
                          payButtonSelected === "pay_monthly_later" && isChecked
                            ? "selected-pay-button"
                            : ""
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handlePaymentOptionChange("pay_monthly_later");
                        }}
                      >
                        <span className="no-wrap">
                          AED {Number(item?.pay_later).toFixed(2)} /Pay
                          Later
                        </span>
                      </div>
                    </div>
                  ))}
                {booking_number &&
                  (requestBody_pickup?.booking_type === "daily" ? (
                    editUserBookingObject?.payment_type === "now" ? (
                      <div className="w-100 h-100 d-flex justify-content-between pay-button-parent">
                        <span
                          className={`d-flex align-items-center justify-content-center gap-1 unselected-pay-button pay-button-child ${
                            payButtonSelected === "pay_now" && isChecked
                              ? "selected-pay-button"
                              : ""
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handlePaymentOptionChange("pay_now");
                          }}
                        >
                          <span> AED {Number(item?.pay_now)} / Pay Now</span>
                        </span>
                      </div>
                    ) : (
                      editUserBookingObject?.payment_type === "later" && (
                        <div className="w-100 d-flex justify-content-between pay-button-parent">
                          <span
                            className={`d-flex align-items-center justify-content-center gap-1 unselected-pay-button pay-button-child ${
                              payButtonSelected === "pay_later" && isChecked
                                ? "selected-pay-button"
                                : ""
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handlePaymentOptionChange("pay_later");
                            }}
                          >
                            <span>
                              AED {Number(item?.pay_later).toFixed(2)} / Pay
                              Later
                            </span>
                          </span>
                        </div>
                      )
                    )
                  ) : editUserBookingObject?.payment_type === "now" ? (
                    <div className="w-100 h-100 d-flex justify-content-between pay-button-parent">
                      <div
                        className={`d-flex align-items-center justify-content-center gap-1 unselected-pay-button pay-button-child ${
                          payButtonSelected === "pay_monthly_now" && isChecked
                            ? "selected-pay-button"
                            : ""
                        } `}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handlePaymentOptionChange("pay_monthly_now");
                        }}
                      >
                        <span>
                          {" "}
                          AED {Number(item?.pay_now)} /Pay Now
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="w-100 h-100 d-flex justify-content-between pay-button-parent">
                      <div
                        className={`d-flex align-items-center justify-content-center gap-1 unselected-pay-button pay-button-child ${
                          payButtonSelected === "pay_monthly_later" && isChecked
                            ? "selected-pay-button"
                            : ""
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handlePaymentOptionChange("pay_monthly_later");
                        }}
                      >
                        <span className="no-wrap">
                          AED {Number(item?.pay_later).toFixed(2)} /Pay
                          Later
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
              {payButtonSelected && isChecked && (
                <button
                  type="button"
                  className="w-100 cardetails__item-btn car__btn-rent mt-3"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (e.target === e.currentTarget) {
                      handleChange();
                    }
                  }}
                >
                  <Link
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (e.target === e.currentTarget) {
                        handleChange();
                      }
                    }}
                  >
                    {requestBody_pickup?.booking_type === "daily"
                      ? "Great Selection! Let's Go"
                      : "View More Options"}
                  </Link>
                </button>
              )}
              {/* <button className=" w-50 cardetails__item-btn car__btn-details" onClick={()=>handleCarDetailsModal(item.id)}>
                <Link to='#'>Details</Link>
        
              </button> */}
            </div>
            <img className="card__arrow" src={arrowIcon} alt="" />
          </div>
        </label>
      </div>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          className="car__details__section car__details__header"
        >
          <Modal.Title className="fw-semibold">Car Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CarDetails car_id_modal={car_id} fromModal={true} />
        </Modal.Body>
        {/* <Modal.Footer>
                  <Button onClick={()=>setModalShow(false)}>Close</Button>
                </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default CarsAndDetails;

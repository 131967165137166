

export const transformEmirateArray = (data)=>{
  if(!Array.isArray(data)) return [];
  return data?.map(({id , name })=> ({value : id , label : name}));
}

export const filteredPickUpLocationArray =   (data, id) => {
  if(!Array.isArray(data) || typeof id !== "number") return [];
  return   data.filter((item)=>item.emirate_id === id);
}
export const transformedPickupLocationArray = (data)=>{
  if(!Array.isArray(data)) return [];

  return data?.map(({id, name , ...rest})=>({
   value : id,
   label : name,
   ...rest
  }))
}

// export const syncEmiratesDropdowns = (changedDropdown, value) => {
//   if (changedDropdown === "dropdown1") {
//     setDropdown2(value); // Sync dropdown 2 with dropdown 1
//   } else {
//     setDropdown1(value); // Sync dropdown 1 with dropdown 2
//   }
// };
export const pixelLeadEvent = (contentName) => {
  if (typeof window.fbq === 'function') {
    window.fbq('track', 'Lead', {
      value: 0.0,
      currency: "AED",
      content_name: contentName,
     
    });
  } else {
    console.log('Meta Pixel is not initialized');
  }
};


export const pixelViewContentEvent = (contentType, contentIds, value, currency = 'AED') => {
  if (typeof window.fbq === 'function') {
    window.fbq('track', 'ViewContent', {
      content_type: contentType,
      content_ids: contentIds,
      value: value,
      currency: currency,
    });
  } else {
    console.warn('Meta Pixel is not initialized');
  }
};

export  const pixelSearchEvent = (searchTerm, contentIds, value, currency = 'AED') => {
  if (typeof window.fbq === 'function') {
    window.fbq('track', 'Search', {
      search_string: searchTerm,
      content_ids: contentIds,
      value: value,
      currency: currency,
    });
  } else {
    console.warn('Meta Pixel is not initialized');
  }
};

export const pixelInitiateCheckoutEvent = (contentIds,contents , value , currency,numItems)=>{
  if(typeof window.fbq === "function"){
    window.fbq('track', 'InitiateCheckout', {
      content_ids: contentIds,
      contents: contents,
      value: value,
      currency: currency,
      num_items : numItems,
    });
  } else {
    console.log('Meta Pixel is not initialized');
  }
  }
export const pixelPurchaseEvent = (contentType,contents , value , currency)=>{
if(typeof window.fbq === "function"){
  window.fbq('track', 'Purchase', {
    content_type: contentType,
    contents: contents,
    value: value,
    currency: currency,
  });
} else {
  console.log('Meta Pixel is not initialized');
}
}
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../Helmet/Helmet";
import "../../styles/commercial.css";
import configWeb from "../../config.js/configWeb";
import { simpleGetCall } from "../../config.js/SetUp";
import { useSelector } from "react-redux";
import CommonSection from "./CommonSection";
import { Spinner } from "react-bootstrap";
import carlogo from "../../assets/new-logo/logo.png";
import FindCarForm2 from "./FindCarForm2";
import { useTranslation } from "react-i18next";
import MetaHelmet from "../Helmet/MetaHelmet";
const CommercialVehicles = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language.language);
  const [corporateLeasing, setCorporateLeasing] = useState([]);
  const [emiratesArray, setEmiratesArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const getCorporateLeasing = () => {
    const url = `${configWeb.GET_PRIVACY_POLICY(
      "buses_and_comercial"
    )}?lang=${language}`;
    simpleGetCall(url)
      .then((res) => {
        if (!res?.error) {
          setCorporateLeasing(res || []); // Ensure it's always an array
        }
      })
      .catch((error) => {
        console.log("Special offers API failed-->", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getCorporateLeasing();
  }, [language]);


  const emiratesData = () => {
    const url = `${configWeb.GET_EMIRATES}?lang=${language}`;
    simpleGetCall(url)
      .then((res) => {
        setEmiratesArray(res);
      })
      .catch((errr) => {
        console.log("errr", errr);
      })
      .finally(() => {
        // set_loading(false);
      });
  };
  useEffect(()=>{
    emiratesData();
  },[])

  const generateStructuredData = () => {
    return {
      "@context": "https://schema.org",
      "@type": "Article",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": window.location.href,
      },
      headline: corporateLeasing?.title,
      description: corporateLeasing?.seo_meta_description,
      image: corporateLeasing?.image,
      author: {
        "@type": "Organization",
        name: "Autostrad",
      },
      publisher: {
        "@type": "Organization",
        name: "Autostrad",
        logo: {
          "@type": "ImageObject",
          url: carlogo,
        },
      },
      datePublished: "2024-10-11",
      dateModified: "2024-10-12",
    };
  };
  return (
    // <Helmet title="CORPORATE LEASING & FLEET MANAGEMENT">
<>
{loading ?  <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh", width: "100vw" }}
        >
        
          <Spinner />
        </div> : <>
        {/* <section className="pb-1 form-section" >
        <h1
          className="pt-2 m-0 text-center text-light booking__tital-dream"
          style={{ fontFamily: "Rubik" }}
        >
          {t("BOOK YOUR DREAM CAR NOW")}!
        </h1>
        <Col id="booking-form-container" lg="12" md="12" sm="12">
          <Container>
            <FindCarForm2 emiratesArray={emiratesArray} />
          </Container>
        </Col>
      </section> */}
       <MetaHelmet
        title={corporateLeasing?.title}
        description={corporateLeasing?.seo_meta_description}
        keywords={corporateLeasing?.seo_meta_tags || "car rental, affordable cars, rent a car"}
        ogTitle={corporateLeasing?.title}
        ogDescription={corporateLeasing?.seo_meta_description}
        ogImage={corporateLeasing?.image}
        ogUrl= {window.location.href}
        twitterTitle={corporateLeasing?.title}
        twitterDescription={corporateLeasing?.seo_meta_description}
        twitterImage={corporateLeasing?.image}
        twitterCard="summary_large_image"
        // twitterSite="@YourTwitterHandle"
        // twitterCreator="@AuthorTwitterHandle"
        canonicalUrl =  {`${configWeb.BASE_WEB_URL}/en/CommercialVehicles`}
        hreflangs={[
          { hreflang: "en", href: `${configWeb.BASE_WEB_URL}/en/CommercialVehicles`},
          { hreflang: "ar", href: `${configWeb.BASE_WEB_URL}/ae/CommercialVehicles` },
        ]}

      />

  <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(generateStructuredData()),
            }}
          />

        <CommonSection title="Buses and Commercial Vehicles"  />
      {/* <section id="bus-section">
        <div className="inner_section_main commercial-" id="commercial">
          <div className="our-values-" id="our-values">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 text-center">
                  <div className="center image-title">
                    <h4 className="mb-5 font-size-25 ">
                      School Buses Services
                    </h4>
                    <p className="content" id="content-1">
                      Autostrad Rent A Car has been providing short & long term
                      school bus rental leasing for prestigious schools in Abu
                      Dhabi and Dubai. All our drivers are fully certified
                      ensuring safety and care for school children and peace of
                      mind for schools and parents. We have a variety of Buses
                      from top of the line brands to suit all your transport
                      requirements. From the large 58 seater Ashok Leyland
                      busses to the smaller 33 and 26 seater Rosa from
                      Mitsubishi.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="buss-point-div-" id="buss-point-div">
          <div className="container mt-5">
            <p id="main-text-1">
              <b>
                All our buses are equipped with these special features to ensure
                the safety of your child
              </b>
            </p>
            <div className="row- center-" id="flex-display-1">
              <div
                className="col-lg-6- col-md-12- col-sm-12-"
                id="flex-display-1-children-1"
              >
                <div className="point-of-buss-">
                  <div className="car-div-222-" id="car-div-222">
                    <img
                      className="img-fluid-"
                      src="https://www.autostrad.com/admin_module/uploads/corporate_leasing/1ec497b6797b66cd11de3b4cdf0a9735.png"
                      alt="Bus Feature"
                    />
                    <p className="content text-center">
                      Yellow colour school buses with the name of the school on
                      both sides.
                    </p>
                  </div>
                  <div className="car-div-222-" id="car-div-222">
                    <img
                      className="img-fluid"
                      src="https://www.autostrad.com/admin_module/uploads/corporate_leasing/db004b6ac75d3532a483a66a7b4c7189.png"
                      alt="Bus Feature"
                    />
                    <p className="content text-center">
                      A surveillance system with cameras inside and outside the
                      bus to monitor safety of the children at all times with
                      recording backup for up to 6 months.
                    </p>
                  </div>
                  <div className="car-div-222" id="car-div-222">
                    <img
                      className="img-fluid"
                      src="https://www.autostrad.com/admin_module/uploads/corporate_leasing/2c219d532d06014cff340f0afd68e33d.png"
                      alt="Bus Feature"
                    />
                    <p className="content text-center">
                      Seat belts with three points buckling system for added
                      safety.
                    </p>
                  </div>
                  <div className="car-div-222" id="car-div-222">
                    <img
                      className="img-fluid"
                      src="https://www.autostrad.com/admin_module/uploads/corporate_leasing/702ca9753901b52fa9192b495b524606.png"
                      alt="Bus Feature"
                    />
                    <p className="content text-center">
                      All buses have an emergency exit and are equipped with
                      hammers and knives to break the windows or cut the seat
                      belts in case of emergencies.
                    </p>
                  </div>
                  <div className="car-div-222" id="car-div-222">
                    <img
                      className="img-fluid"
                      src="https://www.autostrad.com/admin_module/uploads/corporate_leasing/da8594a791107d926a42c60c3480a613.png"
                      alt="Bus Feature"
                    />
                    <p className="content text-center">
                      Doors sensors to ensure that the door doesn’t close while
                      somebody is entering the bus
                    </p>
                  </div>
                  <div className="car-div-222" id="car-div-222">
                    <img
                      className="img-fluid"
                      src="https://www.autostrad.com/admin_module/uploads/corporate_leasing/170a29fba4c5965efa14f5c064d7e3f7.png"
                      alt="Bus Feature"
                    />
                    <p className="content text-center">
                      Fire detectors inside the engine to pre-emptively warn in
                      case of a fire.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6- col-md-12- col-sm-12-"
                id="flex-display-1-children-2"
              >
                <div className="side-img2">
                  <img
                    src="https://www.autostrad.com/admin_module/uploads/corporate_leasing/e49f0214651f37f9b989314ce2467695.webp"
                    className="img-fluid w-100"
                    alt="Bus Safety"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="inner_section_main about mt-5 ">
          <div
            className="our-values"
            id="our-values"
            style={{
              backgroundImage: `url(https://www.autostrad.com/assets/images/end-to-end-solutions.jpeg)`,
              backgroundColor: "#fff",
            }}
          >
            <Container>
              <Row className="mb-5">
                <Col sm="12">
                  <p
                    className="content text-center- font-size-25-" id="end-to-end-heading"
                   
                  >
                    <b>
                      End to End solutions we provide for school bus leasing
                    </b>
                  </p>
                  <div id="row-2">
                    <div id="car-div-2222">
                      <img
                        class="img-fluid"
                        src="https://www.autostrad.com/admin_module/uploads/corporate_leasing/b938f06516d797cca381325a68c49b35.png"
                      />
                      <p id="content">
                        Full ITC specifications, drivers, fuel and a coordinator
                        for each school to manage the buses and drivers
                      </p>
                    </div>
                    <div id="car-div-2222">
                      <img
                        class="img-fluid"
                        src="https://www.autostrad.com/admin_module/uploads/corporate_leasing/563b20ed7547c4e32dd52875553cdf28.png"
                      />
                      <p id="content">
                      Regular bus maintenance including a major servicing after each term.
                      </p>
                    </div>
                    <div id="car-div-2222">
                      <img
                        class="img-fluid"
                        src="https://www.autostrad.com/admin_module/uploads/corporate_leasing/dd1ee5e41cb0da37dba7c75c040e1277.png"
                      />
                      <p id="content">
                      Daily check- ups for all buses before the first trip.
                      </p>
                    </div>
                    <div id="car-div-2222">
                      <img
                        class="img-fluid"
                        src="https://www.autostrad.com/admin_module/uploads/corporate_leasing/e040d1dc4c541c2badfde7dea3bd034a.png"
                      />
                      <p id="content">
                      Sterilization for all buses before and after any trip.
                      </p>
                    </div>
                    <div id="car-div-2222">
                      <img
                        class="img-fluid"
                        src="https://www.autostrad.com/admin_module/uploads/corporate_leasing/b9e49f991d6677b6bf4960b0e01febea.png"
                      />
                      <p id="content">
                      Replacement bus in-case of a breakdown and replacement driver in case the driver is sick.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="inner_section_main- mt-5-">
          <div className="container-">
            <div>
              <div className="blog_section- padding-70-" id="row-">
                <div className="col-lg-12- col-md-12 col-sm-12-">
                  <h5 className="mb-3-" id="main-text-3">
                    <strong>Private and Airport Bus Rentals</strong>
                  </h5>
                  <p className="text-justify-">
                    We also offer safe and dependable luxury charters, airport
                    transfers, flexible group transportation services and other
                    transportation solutions at affordable costs to domestic and
                    international travellers in the UAE. All our buses are
                    equipped with comfortable reclining seats, large windows,
                    audio/video systems, luggage racks, and ample storage
                    beneath the vehicle for your belongings so that your group
                    travel is a breeze!
                  </p>
                </div>
                <div className="col-lg-12- col-md-12 col-sm-12-">
                  <div className="row-" id="bus-img-display-flex">
                    <div className="col-lg-4-">
                      <img
                        src="https://test.autostrad.com/assets/images/buses/bus-front.jpeg"
                        alt="Bus Front"
                        
                        id="bus-img"
                      />
                    </div>
                    <div className="col-lg-4">
                      <img
                        src="https://test.autostrad.com/assets/images/buses/bus-interior.jpeg"
                        alt="Bus Interior"
                        
                        id="bus-img"
                      />
                    </div>
                    <div className="col-lg-4">
                      <img
                        src="https://test.autostrad.com/assets/images/buses/bus-side.jpeg"
                        alt="Bus Side"
                        
                        id="bus-img"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 text-center mt-3">
                  <a
                    href="CarEnquriry"
                    className="btn btn-primary"
                   
                  >
                    Enquire Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section
        className=""
        dangerouslySetInnerHTML={{
          __html: corporateLeasing?.content,
        }}
      />

</>}
    {/* </Helmet> */} </>
  );
};

export default CommercialVehicles;
